import { useQuery } from "react-query";
import { getAllCategories } from "../../api/category";

export function useGetAllCategories({} = {}, queryConfig = {}) {
  return useQuery(
    ["GET_ALL_CATEGORIES"],
    () => getAllCategories(),
    queryConfig
  );
}
