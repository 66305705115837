import React from "react";
import { Link, useHistory, Route } from "react-router-dom";
import { Box, Flex, Image, Text, Divider } from "@chakra-ui/react";
// import { css } from "@emotion/react";
import PoliticaPrivacidad from "../pages/policy/politicaPrivacidad";
import TerminosCondiciones from "../pages/policy/terminosCondiciones";
import PoliticaCookies from "../pages/policy/politicaCookies";
import PoliticaUso from "../pages/policy/politicaUso";
import Ayuda from "../pages/ayuda/index";
import SobreIcuida from "../pages/SobreIcuida";
import langGeneral from "../assets/lang/general";
import lang from "../assets/lang/policycontainer";
import LangSelector from "./LangSelector";
import { FaFacebookF, FaInstagram } from "react-icons/all";
import { useLangStore } from "../hooks/state/useLangStore";
import { Container, Row, Col } from "react-bootstrap";
import { jsx, css } from "@emotion/react";

function Footer() {
  const history = useHistory();
  const currentLang = useLangStore((state) => state.currentLang);

  return (
    <Box mt="75px">
      <Box
        bg="#fff"
        p="10px"
        justifyContent="center"
        textAlign="left"
        m="0"
        css={css`
          @media (min-width: 880px) {
            display: flex;
          }
        `}
      >
        {/* <Box> */}
        <Box py="10px">
          <Box
            css={css`
              @media (max-width: 880px) {
                display: flex;
                justify-content: center;
              }
            `}
          >
            <Text fontWeight="light" fontSize="15px">
              <b>{lang[currentLang].proyectede}</b>
            </Text>
          </Box>
          <Box width="fit-content" mx="auto" py="0px">
            <Image
              width="200px"
              src={require("../assets/images/logos/LOGO-IBOD.png")}
            />
          </Box>
        </Box>
        <Box my="10px">
          <Box
            css={css`
              @media (max-width: 880px) {
                display: flex;
                justify-content: center;
              }
            `}
          >
            <Text fontSize="15px" fontWeight="light">
              <b>{lang[currentLang].collaborationof}</b>
            </Text>
          </Box>

          <Row>
            <Col xs="12" sm="6" md="3">
              <Box width="150px" alignSelf="center" mx="auto" py="20px">
                <Image
                  src={require("../assets/images/logos/logo_ayuntamiento_barcelona.jpg")}
                />
              </Box>
            </Col>
            <Col xs="12" sm="6" md="3">
              <Box width="150px" alignSelf="center" mx="auto" py="23px">
                <Image src={require("../assets/images/logos/logo-DFS.png")} />
              </Box>
            </Col>
            <Col xs="12" sm="6" md="3">
              <Box width="150px" alignSelf="center" mx="auto" py="25px">
                <Image src={require("../assets/images/logos/logo-MWCB.png")} />
              </Box>
            </Col>
            <Col xs="12" sm="6" md="3">
              <Box width="150px" alignSelf="center" mx="auto" py="5px">
                <Image
                  src={require("../assets/images/logos/Barcelona-Cuida-Horitzontal.png")}
                />
              </Box>
            </Col>
          </Row>
        </Box>
        {/* </Box> */}
      </Box>

      <Divider />
      <Box bg="#fff" borderRadius="5px" p="0px">
        <Box
          p="0px"
          mt="20px"
          backgroundColor="#fff"
          width="100%"
          borderRadius="5px"
          m="0"
        >
          <Flex py="10px" m="auto" width="fit-content" fontSize="25px">
            <Box px="10px" cursor="pointer">
              <a
                target="_blank"
                href="https://www.facebook.com/Icuidabarcelona-103193738268904"
                rel="noreferrer"
              >
                <FaFacebookF color="#CBCBCB" />
              </a>
            </Box>
            <Box px="10px" cursor="pointer">
              <a
                target="_blank"
                href="https://www.instagram.com/icuida.barcelona/"
                rel="noreferrer"
              >
                <FaInstagram color="#CBCBCB" />
              </a>
            </Box>
          </Flex>

          <Box mt="10px">
            <Box>
              <Text fontSize="xs" color="gray.1" textAlign="center">
                <Text cursor="pointer" as="a" mx="3px" href="/sobre-icuida">
                  {langGeneral[currentLang].sobreicuida}
                </Text>
                -
                <Text
                  cursor="pointer"
                  as="a"
                  mx="3px"
                  href="/tarjeta-cuidadora"
                >
                  {langGeneral[currentLang].caregiverCard}
                </Text>
                -
                <Text
                  cursor="pointer"
                  as="a"
                  mx="3px"
                  href="/terminos-condiciones"
                >
                  {langGeneral[currentLang].conditions}
                </Text>
                -
                <Text
                  cursor="pointer"
                  as="a"
                  mx="3px"
                  href="/politica-privacidad"
                >
                  {langGeneral[currentLang].privacy}
                </Text>
                -
                <Text cursor="pointer" as="a" mx="3px" href="/politica-cookies">
                  {langGeneral[currentLang].politicaCookies}
                </Text>
                -
                <Text cursor="pointer" as="a" mx="3px" href="/ayuda">
                  {langGeneral[currentLang].help}
                </Text>
                -
                <Text cursor="pointer" as="a" mx="3px" href="/politica-uso">
                  {langGeneral[currentLang].politicaUse}
                </Text>
                -
                <Text cursor="pointer" as="a" mx="3px" href="/contacta">
                  {langGeneral[currentLang].contactus}
                </Text>
                -
                <Text cursor="pointer" as="a" mx="3px" href="/recomana">
                  {langGeneral[currentLang].recommends}
                </Text>
                -
                <Text cursor="pointer" as="a" mx="3px" href="/recursos">
                  {langGeneral[currentLang].resources}
                </Text>
              </Text>
            </Box>
            <Divider width="35%" m="auto" />
            <LangSelector />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;
