import React from "react";

function IconoEventos() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29.766"
      height="23.958"
      fill="none"
      viewBox="0 0 448 512"
    >
      <path
        fill="#fff"
        d="M152 64h144V24c0-13.25 10.7-24 24-24s24 10.75 24 24v40h40c35.3 0 64 28.65 64 64v320c0 35.3-28.7 64-64 64H64c-35.35 0-64-28.7-64-64V128c0-35.35 28.65-64 64-64h40V24c0-13.25 10.7-24 24-24s24 10.75 24 24v40zM48 448c0 8.8 7.16 16 16 16h320c8.8 0 16-7.2 16-16V192H48v256z"
      ></path>
    </svg>
  );
}

export default IconoEventos;
