import React, { useState, useEffect } from "react";
import { Link, Route, useHistory } from "react-router-dom";
import {
  Avatar,
  Badge,
  Box,
  Text,
  Divider,
  IconButton,
  Input,
  Flex,
  Button,
  VStack,
  StackDivider,
  Spinner,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
} from "@chakra-ui/react";
import tagsData from "../../assets/tagsData";
import moment from "moment";
import "moment/locale/es";
import LikeIcon from "../../assets/icons/LikeIcon";
import IconoTresPuntos from "../../assets/icons/IconoTresPuntos";
import IconoRespuesta from "../../assets/icons/IconoRespuesta";
import IconoComentar from "../../assets/icons/IconoComentar";
import {
  useQuestion,
  useQuestionLike,
  useQuestionDislike,
  useQuestionDelete,
} from "../../hooks/queries/preguntas-comunidad";
import { useCreateAnswer } from "../../hooks/queries/respuesta-comunidad";
import { useAuthStore } from "../../hooks/state/useAuthStore";
import Reply from "./Reply";
import { getUser } from "../../api/UserFunctions";
import TextEditor from "../../components/ui/TextEditor";
import { useCreateNotification } from "../../hooks/queries/notification";
import { objHasUserId } from "../../functional/general";
import VerifyDelete from "../../components/utils/VerifyDelete";
import socket from "../../api/socket";
import { question } from "../../api/QuestionFunctions";
import UserAvatar from "../../components/UserAvatar";

function Article(props) {
  const questionId = props?.match?.params?.id;
  const userData = useAuthStore().data;
  const pregunta = useQuestion({ id: Number(questionId) });
  const [showEditor, setShowEditor] = useState(true);
  const [selectedUser, setSelectedUser] = useState({});
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [createAnswer, createAnswerQuery] = useCreateAnswer();
  const [questionLike, questionLikeQuery] = useQuestionLike();
  const [questionDislike, questionDislikeQuery] = useQuestionDislike();
  const [questionDelete, questionDeleteQuery] = useQuestionDelete();
  const [createNotification, notificationQuery] = useCreateNotification();
  const [respuestaValue, setRespuestaValue] = useState("");
  const [showCard, setShowCard] = useState(true);
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    if (answers?.length === 0 || answers === undefined) {
      setAnswers(pregunta?.data?.data?.Answers);
    }
  }, [pregunta?.data?.data?.Answers, answers]);

  function formatDate(date) {
    const databaseDate = new Date(date);
    return moment(databaseDate).format("L");
  }

  function getUserData() {
    if (pregunta.data.data.UserId)
      getUser(pregunta.data.data.UserId).then((res) => {
        setSelectedUser(res);
      });
  }

  function liked(data) {
    const formData = {
      userId: userData.id,
      questionId: data.id,
    };
    questionLike(formData).then((res) => {
      pregunta.refetch({ id: Number(questionId) }).then((questionData) => {
        setAnswers(questionData?.data?.Answers);
      });
      const likeformData = {
        recipientId: data.userId,
        senderId: userData.id,
        type: "questionLike",
        referenceId: data.id,
      };
      // Add if statement if userauth Id = question id to not create notification.
      if (questionId !== data.userId) {
        createNotification(likeformData);
      }
    });
  }

  function disliked(data) {
    const formData = {
      userId: userData.id,
      questionId: pregunta.data.data.id,
    };
    questionDislike(formData).then((res) =>
      pregunta.refetch({ id: Number(questionId) }).then((questionData) => {
        setAnswers(questionData?.data?.Answers);
      })
    );
  }

  function handleSubmit() {
    createAnswer({
      questionId: pregunta.data.data.id,
      userId: userData.id,
      answer: respuestaValue,
    }).then((res) => {
      pregunta.refetch({ id: Number(questionId) }).then((questionData) => {
        setAnswers(questionData?.data?.Answers);
        const formData = {
          recipientId: questionData.data.userId,
          senderId: res.data.userId,
          type: "questionAnswer",
          referenceId: pregunta.data.data.id,
        };
        // Add if statement if userauth Id = question id to not create notification.
        if (questionId !== questionData.data.userId) {
          createNotification(formData).then((questioData) => {
            socket.emit("new-notification", {
              to: questionData.data.userId,
            });
          });
        }
        setRespuestaValue("");
        setShowEditor(false);
      });
    });
  }
  function handleDelete() {
    questionDelete({
      userId: userData.id,
      questionId: pregunta.data.data.id,
    }).then(async (res) => {
      if (res) {
        await pregunta
          .refetch({ id: Number(questionId) })
          .then((questionData) => {
            setAnswers(questionData?.data?.Answers);
          });
      }
      onClose();
      setShowCard(false);
    });
  }

  async function refetchData() {
    await pregunta.refetch({ id: Number(questionId) }).then((questionData) => {
      setAnswers(questionData?.data?.Answers);
    });
  }

  useEffect(() => {
    if (!pregunta.isLoading && pregunta.data.data) {
      getUserData();
    } else if (!pregunta.isLoading && !pregunta.data.data) {
      window.location.href = "/";
    }
  }, [pregunta]);

  return (
    <React.Fragment>
      {!pregunta.isLoading && pregunta.data.data ? (
        <Box width="100%" bg="#fff" borderRadius="5px" my="20px">
          <VerifyDelete
            borrar={handleDelete}
            isOpen={isOpen}
            onClose={onClose}
          />
          <Box py="10px" px="20px">
            <Box>
              <Box my="10px" display="flex">
                <a href={"/perfil-usuario/" + pregunta.data.data.User.id}>
                  <UserAvatar userData={pregunta.data.data.User} />
                </a>
                <Box>
                  <Box ml="10px">
                    <Box as="span" fontSize="13px" fontWeight="bold">
                      {" "}
                      <a href={"/perfil-usuario/" + pregunta.data.data.User.id}>
                        {pregunta.data.data.User.firstName +
                          " " +
                          pregunta.data.data.User.lastName}
                      </a>
                    </Box>
                    <Box as="span" fontSize="13px">
                      {" "}
                      -{" "}
                      {pregunta.data.data.updatedAt
                        ? formatDate(pregunta.data.data.updatedAt)
                        : formatDate(pregunta.data.data.createdAt)}
                    </Box>
                  </Box>
                  <Box ml="10px">
                    <Box as="span" fontSize="13px">
                      {pregunta.data.data.User.type}
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Text
                  cursor="pointer"
                  mb="10px"
                  fontSize="xl"
                  fontWeight="bold"
                >
                  {pregunta.data.data?.title}
                </Text>
                <Box
                  dangerouslySetInnerHTML={{
                    __html: pregunta.data.data?.description,
                  }}
                />
              </Box>
              <Box mt="20px">
                <Box display="flex" justifyContent="space-between">
                  <Flex>
                    <Flex>
                      {objHasUserId(
                        pregunta.data.data.QuestionLikes,
                        userData.id
                      ) ? (
                        <Box
                          cursor="pointer"
                          onClick={() => {
                            disliked(pregunta.data.data);
                          }}
                        >
                          <LikeIcon />
                        </Box>
                      ) : (
                        <Box
                          cursor="pointer"
                          onClick={() => liked(pregunta.data.data)}
                        >
                          <LikeIcon color="#8B8886" />
                        </Box>
                      )}
                      <Text ml="3px">
                        {pregunta.data.data.QuestionLikes.length}
                      </Text>
                    </Flex>
                    <Flex
                      cursor="pointer"
                      ml="20px"
                      onClick={() => setShowEditor(!showEditor)}
                    >
                      <IconoRespuesta fontSize="20px" color="#8B8886" />
                      <Text ml="3px">{pregunta.data.data.Answers.length}</Text>
                    </Flex>
                  </Flex>
                  <Flex hidden={userData.id !== pregunta.data.data.userId}>
                    <Box alignSelf="center">
                      <Menu>
                        <MenuButton>
                          <IconoTresPuntos color="#8B8886" />
                        </MenuButton>
                        <MenuList>
                          <MenuItem onClick={onOpen}>
                            <Text fontSize="13px" fontWeight="semibold">
                              Borrar Mensaje
                            </Text>
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Box>
                  </Flex>
                </Box>
              </Box>
            </Box>
          </Box>
          <React.Fragment>
            <Divider />
            <Box p="10px 20px" hidden={!showEditor}>
              <Box px="10px">
                <Box my="10px">
                  <Flex>
                    <UserAvatar userData={userData} />
                    <Box>
                      <Box ml="10px">
                        {userData.lastName === null ? (
                          <Box as="span" fontSize="13px" fontWeight="bold">
                            {userData.firstName}
                          </Box>
                        ) : (
                          <Box as="span" fontSize="13px" fontWeight="bold">
                            {userData.firstName + " " + userData.lastName}
                          </Box>
                        )}
                      </Box>
                      <Box ml="10px">
                        <Box as="span" fontSize="13px">
                          {userData.type}
                        </Box>
                      </Box>
                    </Box>
                  </Flex>
                </Box>
                <TextEditor
                  setContent={setRespuestaValue}
                  initial={respuestaValue}
                />
                <Box my="10px">
                  <Button
                    width="100%"
                    colorScheme="blue"
                    borderRadius="50px"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Responder
                  </Button>
                </Box>
              </Box>
            </Box>
            <Divider />
            <VStack divider={<StackDivider borderColor="gray.200" />}>
              {answers?.length > 0 &&
                answers?.map((answer) => {
                  return (
                    <Reply
                      refetch={() => refetchData()}
                      questionId={pregunta.data.data.id}
                      answer={answer}
                    />
                  );
                })}
            </VStack>
          </React.Fragment>
        </Box>
      ) : (
        <Box pt="200px">
          <Box m="auto" width="fit-content">
            <Spinner color="orange.1" size="xl" />
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
}

export default Article;
