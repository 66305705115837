import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import QuestionAnswerCard from "../../../components/ui/QuestionAnswerCard";
import AnswerCommentCard from "../../../components/ui/AnswerCommentCard";
import QuestionNotiCard from "./cards/QuestionNotiCard";
import QuestionLikeNotiCard from "./cards/QuestionLikeNotiCard";
import AnswerLikeNotiCard from "./cards/AnswerLikeNotiCard";
import CommentLikeNotiCard from "./cards/CommentLikeNotiCard";
import EventCreateNotiCard from "./cards/EventCreateNotiCard.js";
import ChatNotiCard from "./cards/ChatNotiCard.js";

function NotificationCard({ notiData }) {
  function checkType(notiType) {
    if (notiType === "questionAnswer") {
      return (
        <QuestionAnswerCard data={notiData} questionId={notiData.referenceId} />
      );
    } else if (notiType === "answerComment") {
      return (
        <AnswerCommentCard data={notiData} answerId={notiData.referenceId} />
      );
    } else if (notiType === "like") {
      return null;
    } else if (notiType === "question") {
      return (
        <QuestionNotiCard data={notiData} questionId={notiData.referenceId} />
      );
    } else if (notiType === "questionLike") {
      return (
        <QuestionLikeNotiCard
          data={notiData}
          questionId={notiData.referenceId}
        />
      );
    } else if (notiType === "answerLike") {
      return (
        <AnswerLikeNotiCard data={notiData} answerId={notiData.referenceId} />
      );
    } else if (notiType === "commentLike") {
      return (
        <CommentLikeNotiCard data={notiData} commentId={notiData.referenceId} />
      );
    } else if (notiType === "createEvent" || notiType === "eventLike") {
      return (
        <EventCreateNotiCard
          data={notiData}
          eventId={notiData.referenceId}
          notiType={notiType}
        />
      );
    } else if (notiType === "chatNotify") {
      return <ChatNotiCard data={notiData} replyId={notiData.referenceId} />;
    }
  }

  useEffect(() => {}, [notiData]);

  return (
    <Box borderRadius="5px" mb="10px">
      {notiData && checkType(notiData.type)}
    </Box>
  );
}

export default NotificationCard;
