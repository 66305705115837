import axios from "axios";
const baseUrl = "/";
// process.env.NODE_ENV === "production" ? "/api/" :

export const createQuestion = (question) => {
  return axios
    .post(baseUrl + "static-questions", {
      userId: question.userId,
      title: question.title,
      description: question.description,
      categories: JSON.stringify(question.categories),
      username: question.username,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const getAllStaticQuestions = () => {
  return axios
    .put(baseUrl + "static-questions/all")
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const getStaticQuestions = (page) => {
  return axios
    .put(baseUrl + "static-questions/" + page)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const staticQuestion = (id) => {
  return axios
    .put(baseUrl + "static-questions/selected/" + id)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const staticQuestionsCategory = ({ category, page }) => {
  return axios
    .put(baseUrl + "static-questions/", { page, category })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const staticQuestionLike = (formData) => {
  return axios
    .post(baseUrl + "static-question-likes/like/", formData)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const staticQuestionDislike = (formData) => {
  return axios
    .delete(baseUrl + "static-question-likes/dislike/", { data: formData })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const staticQuestionViews = (id) => {
  return axios
    .put(baseUrl + "static-questions/views/" + id)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const topStaticViews = (page) => {
  return axios
    .put(baseUrl + "static-questions/top-views/mas-buscados/" + page)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const topStaticLikes = (page) => {
  return axios
    .put(baseUrl + "static-questions/top-likes/mas-mirados" + page)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const staticQuestionSearchQuery = (formData) => {
  return axios
    .put(baseUrl + "static-questions/search-query/", { query: formData.query })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const staticQuestionUpdate = (formData) => {
  return axios
    .put(baseUrl + "static-questions/update/", {
      description: formData.description,
      staticQuestionId: formData.staticQuestionId,
      userId: formData.userId,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const staticQuestionDelete = (formData) => {
  return axios
    .delete(baseUrl + "static-questions/delete", {
      data: {
        questionId: formData.questionId,
        isAdmin: formData.isAdmin,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
