import React from 'react'
import {Box, Flex, Text} from '@chakra-ui/react'
import {useLangStore} from '../hooks/state/useLangStore'

function LangSelector() {

    const currentLang = useLangStore(state=>state.currentLang)
    const setSpanish = useLangStore(state=>state.setEsp)
    const setCatalan = useLangStore(state=>state.setCat)

    return(
        <Box>
            <Flex m="auto" width="fit-content">
                <Box>
                    <Text cursor="pointer" textAlign="center" m="5px" fontSize="xs" color={currentLang === "es_ES" ? "blue.1" : "#6C6A69"} onClick={e => setSpanish()}>Español ></Text>
                </Box>
                <Box>
                    <Text cursor="pointer" textAlign="center" m="5px" fontSize="xs" color={currentLang === "ca" ? "blue.1" : "#6C6A69"} onClick={e => setCatalan()}>Catalán ></Text>
                </Box>
        </Flex>
        </Box>
    )
}

export default LangSelector