import React from 'react'
import {
  Text,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton, 
  Button} from '@chakra-ui/react'

function VerifyDelete({borrar = () => {}, isOpen, onClose }) {
  
    return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>
              <Text textAlign="center" fontSize="2xl">Está seguro?</Text>
              </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text textAlign="center">
                Está seguro que desea borrar? Este proceso no se puede deshacer.
              </Text>
            </ModalBody>
            <ModalFooter>
              <Box width="fit-content" mx="auto">
                <Button width="130px" borderRadius="20px" colorScheme="gray" mr={3} onClick={onClose}>
                  Cancelar
                </Button>
                <Button width="130px" borderRadius="20px" colorScheme="red" onClick={() => borrar()}>Borrar</Button>
              </Box>
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  )
}

export default VerifyDelete;