import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import {
  Box,
  Grid,
  Text,
  Input,
  Button,
  Flex,
  Avatar,
  Image,
} from "@chakra-ui/react";
import { MdMessage, BsPencilSquare, FaBell, FaHome } from "react-icons/all";
// import io from 'socket.io-client'
import socket from "../api/socket";
import IconoRespuesta from "../assets/icons/IconoRespuesta";
import IconoHome from "../assets/icons/IconoHome";
import XIcon from "../assets/icons/xIcon";
import IconoCategoria from "../assets/icons/IconoCategoria";
import IconoNotificacion from "../assets/icons/IconoNotificacion";
import IconoPreguntar from "../assets/icons/IconoPreguntar";
import IconoRecursos from "../assets/icons/IconoRecursos";
import IconoLupa from "../assets/icons/IconoLupa";
import { useDrawerStore } from "../hooks/state/useDrawerStore";
import { useAuthStore } from "../hooks/state/useAuthStore";
import Cookies from "js-cookie";
import Form from "../components/ui/Form";
import { TiMessages } from "react-icons/ti";

import IconoEventosMobile from "../assets/icons/IconoEventosMobile";
import UserAvatar from "./UserAvatar";

function MobileNavbar({ css }) {
  const history = useHistory();
  const userData = useAuthStore((state) => state.data);
  const setDrawerIsOpen = useDrawerStore((state) => state.setOpen);
  const [selected, setSelected] = useState(history.location.path);
  const [searchBar, setSearchBar] = useState(false);
  const [startAnimation, setStartAnimation] = useState(false);
  const [unreadChat, setUnreadChat] = useState(false);
  const [unreadNoti, setUnreadNoti] = useState(false);

  const url =
    process.env.NODE_ENV === "production" ? "/api/socket.io/" : "/socket.io/";

  // const socket = io({
  //     path: url,
  //     withCredentials: true
  // });

  function expandEffect() {
    setSearchBar(true);
    setTimeout(() => {
      setStartAnimation(true);
      console.log(1);
    }, 100);
  }

  function unexpandEffect() {
    setStartAnimation(false);
    setTimeout(() => {
      setSearchBar(false);
    }, 300);
  }

  function submitSearch(formData) {
    if (formData.query.length > 0) {
      Cookies.set("search", formData.query);
      if (window.location.pathname === "/search") {
        history.go(0);
      } else {
        history.push("/search");
      }
    }
  }

  return (
    <Box css={css}>
      <Box bg="orange.1" p="10px 5px">
        <Box>
          <Flex justifyContent="space-between">
            <Box
              width={startAnimation ? "100%" : "110px"}
              transitionDuration="1s"
              transitionProperty="width"
            >
              <Form
                className="w100"
                onSubmit={submitSearch}
                initialValues={{ query: "" }}
              >
                {({ setFieldValue, values }) => (
                  <Flex
                    transitionDuration="1s"
                    transitionProperty="width"
                    width={startAnimation ? "100%" : "110px"}
                  >
                    <Flex
                      alignSelf="center"
                      border="1px solid white"
                      borderRadius="20px"
                      height="40px"
                      p="5px 10px"
                      width={startAnimation ? "100%" : "110px"}
                      transitionDuration="1s"
                      transitionProperty="width"
                    >
                      <Flex alignSelf="center">
                        <IconoLupa
                          fontSize="15px"
                          alignSelf="center"
                          color="#fff"
                        />
                      </Flex>
                      <Input
                        bg="transparent"
                        p="3px"
                        //width="100%"
                        _placeholder={{ color: "white", fontSize: "14px" }}
                        color="white"
                        height="100%"
                        borderRadius="20px"
                        border="none!important"
                        _focus={{ outline: "none" }}
                        outline="none!important"
                        placeholder="Buscar"
                        value={values.query}
                        onClick={(e) => expandEffect()}
                        width={searchBar ? "100%" : "50px"}
                        onChange={(e) => setFieldValue("query", e.target.value)}
                      />

                      <Flex
                        display={startAnimation ? "flex" : "none"}
                        alignSelf="center"
                        onClick={(e) => unexpandEffect()}
                      >
                        <XIcon />
                      </Flex>
                    </Flex>
                  </Flex>
                )}
              </Form>
            </Box>

            <Image
              display={searchBar ? "none" : "block"}
              alignSelf="center"
              width="98.4px"
              height="24.5px"
              src={require("../assets/images/logos/icuida-letras.png")}
              onClick={() => history.push("/")}
            />
            <Flex
              display={searchBar ? "none" : "flex"}
              onClick={(e) => history.push("/preguntar")}
            >
              <Button
                width="115px"
                borderRadius="20px"
                ml="1px"
                variant="outline"
                color="white"
                alignSelf="center"
                fontSize="14px"
              >
                <Box pr="5px">
                  <IconoPreguntar bg="#fff" color="#FE6E60" />
                </Box>
                Preguntar
              </Button>
            </Flex>
          </Flex>
        </Box>
      </Box>
      <Box my="5px">
        <Box bg="#fff" borderRadius="50px">
          <Grid templateColumns="repeat(8, 1fr)" alignItems="center">
            <Box width="100%">
              <Link to="/" onClick={() => setSelected("/")}>
                <Box m="auto" width="fit-content">
                  <IconoHome
                    color={
                      history.location.pathname === "/" ? "#FE6E50" : "#8B8886"
                    }
                  />
                </Box>
              </Link>
            </Box>
            <Box width="100%">
              <Link to="/responder" onClick={() => setSelected("/responder")}>
                <Box m="auto" width="fit-content">
                  <IconoRespuesta
                    color={
                      history.location.pathname === "/responder"
                        ? "#FE6E50"
                        : "#8B8886"
                    }
                  />
                </Box>
              </Link>
            </Box>
            <Box width="100%">
              <Link to="/categorias" onClick={() => setSelected("/categorias")}>
                <Box m="auto" width="fit-content">
                  <IconoCategoria
                    color={
                      history.location.pathname === "/categorias"
                        ? "#FE6E50"
                        : "#8B8886"
                    }
                  />
                </Box>
              </Link>
            </Box>
            <Box width="100%">
              <Link
                to="/notificaciones"
                onClick={() => setSelected("/notificaciones")}
              >
                <Flex
                  mx="auto"
                  width="30px"
                  height="30px"
                  borderRadius="20px"
                  bg={unreadNoti ? "orange.2" : ""}
                >
                  <Box mx="auto" alignSelf="center">
                    <IconoNotificacion
                      color={
                        history.location.pathname === "/notification"
                          ? "#FE6E50"
                          : unreadNoti
                          ? "gray"
                          : "#8B8886"
                      }
                    />
                  </Box>
                </Flex>
              </Link>
            </Box>
            <Box width="100%">
              <Link to="/eventos" onClick={() => setSelected("/eventos")}>
                <Flex
                  mx="auto"
                  width="30px"
                  height="30px"
                  borderRadius="20px"
                  bg={unreadNoti ? "orange.2" : ""}
                >
                  <Box mx="auto" alignSelf="center">
                    <IconoEventosMobile
                      color={
                        history.location.pathname === "/eventos"
                          ? "#FE6E50"
                          : unreadNoti
                          ? "white"
                          : "#8B8886"
                      }
                    />
                  </Box>
                </Flex>
              </Link>
            </Box>
            
            <Box width="100%">
              <Link to="/recursos" onClick={() => setSelected("/recursos")}>
                <Box m="auto" width="fit-content">
                  <IconoRecursos
                    color={
                      history.location.pathname === "/recursos"
                        ? "#FE6E50"
                        : "#8B8886"
                    }
                  />
                </Box>
              </Link>
            </Box>
            
            <Box
              width="100%"
              p="10px"
              onClick={(e) => {
                history.push("/chat");
              }}
            >
              <Box
                color={
                  history.location.pathname === "/chat"
                    ? "#FE6E50"
                    : unreadChat
                    ? "white"
                    : "#8B8886"
                }
                fontSize="25px"
              >
                <Flex
                  width="30px"
                  height="30px"
                  m="auto"
                  borderRadius="20px"
                  bg={unreadChat ? "orange.2" : ""}
                >
                  <Box alignSelf="center" width="fit-content" mx="auto">
                    <TiMessages />
                  </Box>
                </Flex>
              </Box>
            </Box>
            <Box width="100%" p="10px">
              <Link to="/perfil">
                <Box m="auto" width="fit-content">
                  <UserAvatar userData={userData} onClick={(e) => setDrawerIsOpen(true)}/>
                </Box>
              </Link>
            </Box>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default MobileNavbar;
