const lang = {
  es_ES: {
    landingPage: {
      imatge: "client/src/assets/images/activitats/20240302_cronica-esp.png",
      imgAlt: "Cronica Presentación iCuida Temporada 2024",
      
      title: "Cronica Presentación iCuida Temporada 2024",
      
      text1: "El pasado sábado 2 de marzo, en las instalaciones de ",
      text1l1: {
        text: "Barcelona Cuida",
        enllas: "https://www.barcelona.cat/ciutatcuidadora/es/espacio-barcelona-cuida/que-es"
      },
      text1p1: ", se llevó a cabo el primer evento de la temporada, un acto con un propósito clave: facilitar la vida de las personas cuidadoras.",

      text2: "Iniciamos el ciclo de actividades ",
      text2b1: "“Apps que te ayudan, cuidadora”",
      text2p1: " pensadas para enseñar a trabajadoras del hogar y de los cuidados, todas las aplicaciones (apps) y herramientas digitales que sirven para tener más oportunidades de trabajo y facilitar la tarea del cuidado.",

      imatge1: "client/src/assets/images/activitats/20240302_cronica-1.jpg",
      img1Alt: "Asistentes al evento",

      text3: "En este acto de presentación hubo 5 partes:",
      text3o1: "Presentación y explicación de la Tarjeta Cuidadora",
      text3o2: "Resolver dudas sobre la utilización de la ",
      text3o2l1: {
        text: "App de iCuida",
        link: "https://icuida.barcelona/login"
      },
      text3o2p1: " a nivel de usuario básico",
      text3o3: "Explicación de la historia, el contexto de la app",
      text3o4: "Explicación de la programación de los actos a desarrollar el resto del año por parte de iCuida",
      text3o5: "Sorteo de un masaje entre las asistentes gracias a la colaboración de los ",
      text3o5l1: {
        text: "centros de estética Blau",
        link: "https://blauperfumerias.com/21-centros-de-estetica"
      },
      text3p1: "",

      text4: "En una primera parte, desde Barcelona Cuida se explicó cómo hacerse usuario de la tarjeta Cuidadora, puntualizando que está dirigida tanto a las familias como a las profesionales de las tareas de cuidado de personas adultas, destacando que la app iCuida va dirigida a las trabajadoras del hogar y los cuidados.",
      text4b1: "",
      text4p1: "",

      imatge2: "client/src/assets/images/activitats/20240302_cronica-2.png",
      img2Alt: "Tarjeta Cuidadora",

      text5: "Se dio información sobre lo que ofrece la tarjeta cuidadora:",
      text5o1: "Agenda de actividades dirigidas a las personas cuidadoras. Todas gratuitas, actividades dirigidas a familias y/o personas cuidadoras profesionales.",
      text5o2: "Canal Infocures, actividades e información específica para personas cuidadoras. Se envía información filtrada no tan general como en el caso de la agenda.",
      text5o3: "Asesoramiento especializado, donde se da información, orientación de forma personalizada.",
      text5o4: "Teléfono de apoyo psicológico gratuito.",
      text5o5: "Asesoramiento y acompañamiento jurídico. Para que las cuidadoras conozcan sus derechos y también para ayudar a las familias a realizar bien los trámites de contratación.",
      text5o6: "Programa de salud para personas cuidadoras, más dirigido a familias pero hay muchas charlas a cargo del colegio de farmacéuticos que colabora con la tarjeta, donde acuden muchas trabajadoras de los cuidados y del hogar.",
      text5o7: "Bibliotecas. Hay 5 bibliotecas en BCN donde hay un catálogo especial vinculado a los cuidados y donde también se hacen talleres gratuitos para enseñar a usar internet que puede interesar a personas cuidadoras.",
      text5o8: "Actividades de gimnasia suave tanto para personas mayores como para personas cuidadoras.",
      text5p1: "",

      text6: "Seguidamente y por parte de ",
      text6l1:{
        text: "Iniciativa Barcelona Open Data",
        enllas: "https://www.iniciativabarcelonaopendata.cat/es/"
      },
      text6p1: ", se hizo una detallada explicación del origen y el desarrollo de la aplicación iCuida desde 2018, hasta el día de hoy. Después de asegurarse que todas las asistentes podían entrar a utilizar la app de iCuida, se pasó a dar la información que se pretendía dar en esta convocatoria, que era toda la programación del resto del año.",

      imatge3: "client/src/assets/images/activitats/20240302_cronica-3.png",
      img3Alt: "Calendario de eventos",

      text7b: "Se harán 7 talleres",
      text7: " de herramientas digitales online gratuitas (portales de trabajo, servicios de autocuidados, talleres para saber cuidarse…) en concreto:",
      text7o1b: "Tres talleres básicos",
      text7o1: " para explicar la utilización de la app.",
      text7o2b: "Dos talleres avanzados",
      text7o2: " ya más técnicos más especializados con herramientas más técnicas y donde se explicará más a fondo la aplicación.",
      text7o3b: "Dos encuentros comunitarios",
      text7o3: " donde haremos dinámicas entre las asistentes donde podremos evaluar la aplicación y desde iCuida se escucharán las necesidades para mejorarla.",
      text7p1: "",

      text8: "Desde iCuida se animó a que las asistentes puedan difundir estas jornadas entre las cuidadoras para así poder ayudar a más personas de este colectivo.",
      text8b1: "",
      text8p1: "",

      text9: "En la parte final de la jornada se compartieron muchas experiencias entre las asistentes de manera que las preocupaciones de unas podían ser resueltas por las respuestas de otras que ya tenían experiencias relacionadas con el tema tratado.",
      text9b1: "",
      text9p1: "",

      text10: "Finalmente, se hizo el sorteo del masaje, gracias a la colaboración de los ",
      text10l1: {
        text: "centros de estética Blau",
        link: "https://blauperfumerias.com/21-centros-de-estetica"
      },
      text10p1: " y entregó el premio la directora de Iniciativa Barcelona Open data, ",
      text10b1: "Lourdes Muñoz",
      text10p2: ".",

      imatge4: "client/src/assets/images/activitats/20240302_cronica-4.png",
      img4Alt: "Momento de la entrega del premio",


      pointZero:{
        text: "Infrórmate del próximo evento:",
        link: "https://icuida.barcelona/esdeveniments"
      },
      
      guest: {
        text1: "Para recomendar, ",
        text2: "inicia sessión",
        text3: " si ya eres usuario de iCuida o ",
        text4: "accede al formulario de registro.",
      }
    },
    form: {
      title: "Recomienda iCuida",
      text1: "Primer recomendado",
      text2: "Segundo recomendado",
      name: "Nombre de la persona a quien recomiendas iCuida",
      phone: "Teléfono de la persona a quien recomiendas iCuida",
      email: "Email de la persona a quien recomiendas iCuida",
      send: "Enviar recomendaciones",
      success: "Las recomendaciones se han enviado correctamente",
      reset: "Seguir recomendando",
    }
  },
  ca: {
    landingPage: {
      imatge: "client/src/assets/images/activitats/20240302_cronica-cat.png",
      imgAlt: "Crònica Presentació iCuida Temporada 2024",
      
      title: "Crònica Presentació iCuida Temporada 2024",
      
      text1: "El passat dissabte 2 de Març , a les instal·lacions de ",
      text1l1: {
        text: "Barcelona Cuida",
        enllas: "https://www.barcelona.cat/ciutatcuidadora/es/espacio-barcelona-cuida/que-es"
      },
      text1p1: ", es va portar a terme el primer esdeveniment de la temporada, un acte amb un propòsit clau: facilitar la vida de persones cuidadores.",

      text2: "Vàrem iniciar el cicle d’activitats ",
      text2b1: "“Apps que t’ajuden, cuidadora”",
      text2p1: " pensades per ensenyar a treballadores de la llar i de  les cures, totes les aplicacions (apps) i eines digitals que serveixen per tenir més oportunitats de feina i facilitar la tasca de la cura.",

      imatge1: "client/src/assets/images/activitats/20240302_cronica-1.jpg",
      img1Alt: "Assistents a l'esdeveniment",

      text3: "Aquest acte de presentació va tenir 5 parts:",
      text3o1: "Presentació i explicació de la Targeta Cuidadora",
      text3o2: "Resoldre dubtes sobre la utilització de l’",
      text3o2l1: {
        text: "App de iCuida",
        link: "https://icuida.barcelona/login"
      },
      text3o2p1: " a nivell d’usuari bàsic",
      text3o3: "Explicació de la història, el context de l’app",
      text3o4: "Explicació de la programació dels actes a desenvolupar la resta de l’any per part d’iCuida",
      text3o5: "Sorteig d’un massatge entre les assitents gracies a la col·laboracio dels ",
      text3o5l1: {
        text: "centres d'estètica Blau",
        link: "https://blauperfumerias.com/21-centros-de-estetica"
      },text3p1: "",

      text4: "En una primera part, des de Barcelona Cuida es va explicar com fer-se usuari de la tarjeta Cuidadora. Puntualitzant que va dirigida tant a les families com a les professionals de les tasques de cura de persones adultes,i que l’app iCuida va dirigida a les treballadores de llar i les cures.",
      text4b1: "",
      text4p1: "",

      imatge2: "client/src/assets/images/activitats/20240302_cronica-2.png",
      img2Alt: "Tarjeta Cuidadora",

      text5: "Es va donar informació sobre el que ofereix la targeta cuidadora:",
      text5o1b: "Agenda d’activitats",
      text5o1: " dirigides a les persones cuidadores. Totes gratuites, activitats dirigides a famílies i/o persones cuidadores professionals.",
      text5o2b: "Canal Infocures",
      text5o2: ", activitats i informació específica per a persones cuidadores .S’envia informació filtrada no tan general com en el cas de l’agenda.",
      text5o3b: "Assessorament especialitzat",
      text5o3: ", on es dona informació, orientació de forma personalitzada",
      text5o4b: "Telèfon de recolzament psicològic",
      text5o4: " gratuit.",
      text5o5b: "Assessorament i acompanyament juridic",
      text5o5: ". Perque les cuidadores sàpiguen els seus drets i també per ajudar a les famílies a fer bé els tràmits de contractació.",
      text5o6b: "Programa de salut per a persones cuidadores",
      text5o6: ", mes dirigit a famílies però hi han moltes  xerrades a càrrec del col·legi de farmacèutics  que col·labora amb la targeta , on hi assiteixen moltes treballadores de les cures i de la llar.",
      text5o7b: "Biblioteques",
      text5o7: ". Hi ha 5 biblioteques a bcn on hi ha un catàleg especial vinculat a les cures i on també s’hi fan tallers gratuïts per ensenya a fer servir internet que pot interessar a persones cuidadores.",
      text5o8b: "Activitats de gimnastica suau",
      text5o8: " tant per a persones grans com per a persones cuidadores.",
      text5p1: "",

      text6: "Seguidament i per part d’",
      text6l1:{
        text: "Iniciativa Barcelona Open Data",
        enllas: "https://www.iniciativabarcelonaopendata.cat/es/"
      },
      text6p1: ", es va fer una detallada explicació de l’origen i el desenvolupament de l’aplicació iCuida desde 2018, fins el dia d’avui. Després d’assegurar-se que totes les assistents podien entrar a utilitzar l’app d’iCuida, es va passar  a donar la informació que es pretenia donar en aquesta convocatòria, que era tota la programació de la resta de l’any.",

      imatge3: "client/src/assets/images/activitats/20240302_cronica-3.png",
      img3Alt: "Calendario de eventos",

      text7b: "Es faran 7 tallers",
      text7: " d’eines digitals online gratuites (portals d’e feina, serveis d’autocures, tallers per saber.se cuidar…) en concret:",
      text7o1b: "Tres tallers bàsics",
      text7o1: " per explicar la utilització del’app.",
      text7o2b: "Dos tallers avançats",
      text7o2: " ja més tècnics, més especialitzats amb eines  més tècniques i on s’explicrà mes a fons l’aplicació.",
      text7o3b: "Dues trobades comunitàries",
      text7o3: " on farem dinàmiques entre les assistents on podrem avaluar l’aplicació i des d’iCuida s’escoltaran les necessitats per millorar-la.",
      text7p1: "",

      text8: "Des d’iCuida es va animar a que les assistents puguin difondre  aquestes jornades entre les cuidadores per aixi poder ajudar a mes persones d’aquest col·lectiu.",
      text8b1: "",
      text8p1: "",

      text9: "En la part final de la jornada es van compartir moltes experiències entre les assistents de manera que les preocupacions d’unes  podien ser resolt per altres que ja tenien experiències relacionades amb el tema tractat.",
      text9b1: "",
      text9p1: "",

      text10: "Finalment es va fer el sorteig del massatge , gràcies a la col·laboració dels ",
      text10l1: {
        text: "centres d'estètica Blau",
        link: "https://blauperfumerias.com/21-centros-de-estetica"
      },
      text10p1: " i va fer entrega del premi la directora d’Iniciativa Barcelona Open data, ",
      text10b1: "Lourdes Muñoz",
      text10p2: ".",

      imatge4: "client/src/assets/images/activitats/20240302_cronica-4.png",
      img4Alt: "Momento de la entrega del premio",


      pointZero:{
        text: "Infòrmat del próxim esdeveniment:",
        link: "https://icuida.barcelona/esdeveniments"
      },

      guest: {
        text1: "Per a recomanar, ",
        text2: "inicia sessió",
        text3: " si ja ets usuari d'iCuida o ",
        text4: "accedeix al formulari de registre.",
      }
    },
    form: {
      title: "Recomana iCuida",
      text1: "Primer recomanat",
      text2: "Segon recomanat",
      name: "Nom de la persona a qui recomanes iCuida",
      phone: "Telèfon de la persona a qui recomanes iCuida",
      email: "Email de la persona a qui recomanes iCuida",
      send: "Enviar recomanacions",
      success: "Les recomanacions s'han enviat correctament",
      reset: "Continuar recomanant",
    }
  }
};

export default lang;