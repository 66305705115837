export function objectHasId(questionList, valueId) {
  let found = false;
  for (let i = 0; i < questionList?.length; i++) {
    if (questionList[i]?.id == valueId) {
      found = true;
      break;
    }
  }

  return found;
}

export function objHasUserId(dataList, valueId) {
  let found = false;
  for (let i = 0; i < dataList?.length; i++) {
    if (dataList[i]?.userId == valueId) {
      found = true;
      break;
    }
  }

  return found;
}
