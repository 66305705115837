import React from 'react';
import {Box, Text} from '@chakra-ui/react'
import FollowUs from '../content/sticky-side/FollowUs';

function PoliticaUso() {
    return(
        <Box bg="#f1fcff">
            <Box maxW="800px" mx="auto" p="20px" borderRadius="5px">
                <Box mb="15px">
                    <Text fontWeight="bold" fontSize="25px">
                        Política de Uso Aceptable
                    </Text>
                </Box>
                <Box mb="15px">
                    <Text as="italic">
                        Última actualización: Octubre 2020
                    </Text>
                </Box>
                <Box mb="15px">
                    <Text fontSize="14px">
                iCuida es un espacio que empodera a las personas para que compartan e incrementen conocimiento por y para las trabajadoras del hogar y los cuidados.  Las siguientes reglas ayudan a que todos y todas en iCuida tengan una experiencia segura.
                    </Text>
                </Box>
                <Box mb="15px">
                    <Text fontWeight="bold">
                Sea Amable, Sea Respetuoso
                    </Text>
                </Box>
                <Box mb="15px">
                    <Text fontSize="14px">
                Siga la Política de iCuida, que incluye lo siguiente:
                    </Text>
                </Box>
                <Box mb="15px">
                    <Text fontSize="14px">
                    </Text>
                </Box>
                <Box mb="15px">
                    <Text fontSize="14px">
                Interactúe con los demás de forma constructiva
                    </Text>
                </Box>
                <Box mb="15px">
                    <Text fontSize="14px">
                Asuma que todos en iCuida están aquí para hacer de la plataforma un recurso útil, con diferentes experiencias, creencias y opiniones. Está BIEN disentir, pero por favor sea cortés, respetuoso y considerado hacia los otros miembros.
                    </Text>
                </Box>
                <Box mb="15px">
                    <Text fontSize="14px">
                Se prohíbe la incitación al odio
                    </Text>
                </Box>
                <Box mb="15px">
                    <Text fontSize="14px">
                iCuida es un espacio para el diálogo respetuoso y no tolera contenido que ataque o denigre a un individuo o grupo según su raza, sexo, religión, nacionalidad, etnia, afiliación política, orientación sexual u otras características similares. Cualquier generalización sobre estos temas se debe expresar de la manera más neutral posible.
                    </Text>
                </Box>
                <Box mb="15px">
                    <Text fontSize="14px">
                Se prohíbe el hostigamiento y el acoso
                    </Text>
                </Box>
                <Box mb="15px">
                    <Text fontSize="14px">
                No se permiten comportamientos abusivos dirigidos hacia particulares. El contacto reiterado y no deseado constituye una forma de hostigamiento. El contenido no debe expresar amenazas hacia los demás ni promover la violencia, incluyendo el daño a uno mismo.
                    </Text>
                </Box>
                <Box mb="15px">
                    <Text fontWeight="bold">
                Respete los Derechos de los Demás
                    </Text>
                </Box>
                <Box mb="15px">
                    <Text fontSize="14px">
                Identidad y Actividades Engañosas
                    </Text>
                </Box>
                <Box mb="15px">
                    <Text fontSize="14px">
                Su perfil de iCuida debe tener su nombre real o marca comercial registrada y usar credenciales correctas y veraces. No utilice iCuida para hacerse pasar por otra persona, actuar como otra entidad sin autorización o crear cuentas múltiples.
                    </Text>
                </Box>
                <Box mb="15px">
                    <Text fontWeight="">
                Propiedad intelectual y derechos personales
                    </Text>
                </Box>
                <Box mb="15px">
                    <Text fontSize="14px">
                No publique contenido que infrinja la propiedad intelectual o el derecho personal de terceros. El texto tomado de otra fuente debe ser atribuido debidamente y citado en bloque. 
                    </Text>
                </Box>
                <Box mb="15px">
                    <Text fontWeight="bold">
                Respete la aplicación web iCuida
                    </Text>
                </Box>
                <Box mb="15px">
                    <Text fontSize="14px">
                Se prohíbe el Spam
                    </Text>
                </Box>
                <Box mb="15px">
                    <Text fontSize="14px">
                        No utilice iCuida para publicar o atraer Spam. 
                    </Text>
                </Box>
                <Box mb="15px">
                    <Text fontSize="14px">
                Se prohíben las actividades ilegales o maliciosas
                    </Text>
                </Box>
                <Box mb="15px">
                    <Text fontSize="14px">
                No propague virus, malware y otros programas maliciosos ni participe de actividades que dañen o interfieran con el funcionamiento de iCuida o que busquen evadir las medidas de autenticación o seguridad. No utilice ICuida para participar en actividades ilegales o fraudulentas, ni para promover actos ilegales.
                    </Text>
                </Box>
                <Box mb="15px">
                    <Text fontSize="14px">
                Cumpla con otras políticas de iCuida 
                    </Text>
                </Box>
                <Box mb="15px">
                    <Text fontSize="14px">
                Siga las otras políticas de iCuida, que pueden ser añadidas o modificadas periódicamente.
                    </Text>
                </Box>
                <Box mb="15px">
                    <Text fontWeight="bold">
                Reportar Problemas
                    </Text>
                </Box>
                <Box mb="15px">
                    <Text fontSize="14px">
                Si usted observa algo en iCuida que cree que viola nuestras normas o políticas, por favor infórmenos usando nuestro <Text color="blue.5" as="ins"><a href="/contacta">formulario</a></Text> de contacto.
                    </Text>
                </Box>
                <Box mb="15px">
                    <Text fontSize="14px">
                Incumplir la política de conducta puede causar la eliminación de contenido, o la limitación o cancelación del acceso a iCuida del usuario/a. 
                    </Text>
                </Box>
            </Box>
        </Box>
    )
}

export default PoliticaUso;