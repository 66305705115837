import React, {useEffect, useState} from 'react';
import {Box, Text, Input, Button, Flex} from '@chakra-ui/react'
import {useHistory} from 'react-router-dom';
import {useCheckEmail} from '../../hooks/queries/usuarios'
import {useVerifyCode} from '../../hooks/queries/usuarios'
import Form from '../../components/ui/Form'
import {usePassRecoveryStore} from '../../hooks/state/usePassRecoveryStore'
import jwt_decode from "jwt-decode";

function VerificacionSeguridad() {
    const history = useHistory();
    const [checkEmail, checkEmailQuery] = useCheckEmail()
    const [verifyCode, verifyCodeQuery] = useVerifyCode()
    const setData = usePassRecoveryStore(state => state?.setData)
    const recoveryData = usePassRecoveryStore(state => state?.data)

    function resendCode(email) {
        const formData = {
            email: email
        }
        checkEmail(formData, {
            onSuccess: (res) => {
              setData(res?.data)
            },
            onError: (err) => {console.log(err)}
        })
    }

    function handleSubmit(formData) {
        formData.email= recoveryData.email
       verifyCode(formData, {
           onSuccess: (res) => {
                localStorage.setItem('recoverytoken', res?.data)
                history.push('/cambiar-contra')
           },
           onError: (err) => {
               console.log(err)
           }
       })   
    }
    if (!recoveryData) {
        history.push('/recuperar-cuenta')
    }
    useEffect(() => {
    })

    return(
    <Box maxWidth="800px" width="100%" mx="auto" pt="50px" px="10px" borderRadius="20px">
        <Form initialValues={{
            recCode: ""
        }}
        onSubmit={handleSubmit}>
            {({setFieldValue, values}) => (
                <Box>
                    <Box p="10px 20px" bg="blue.7">
                        <Text fontWeight="bold" color="#fff" textAlign="center">Verificación de seguridad</Text>
                    </Box>
                    <Box bg="#fff" px="20px">
                        <Box maxWidth="400px" width="100%" mx="auto" pt="4rem" pb="2rem">
                            <Text>Clica para obtener tu codigo de verificación. El codigo se enviara a tu correo mail.</Text>
                            <Box py="20px">
                                <Text color="blue.7">{recoveryData?.email}</Text>
                            </Box>
                            <Flex my="30px">
                                <Input
                                    name="email" 
                                    maxLength="6"
                                    value={values.recCode}
                                    onChange={e => setFieldValue('recCode', e.target.value)}
                                    placeholder="Codigo de verificación"/>
                                    <Button onClick={e => resendCode(recoveryData?.email)} fontSize="12px">Enviar codigo</Button>
                            </Flex>
                            <Box my="30px">
                                <Button type="submit" width="100%" color="#fff" bg="blue.7">Continuar</Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}
        </Form>
    </Box>
    )
}
export default VerificacionSeguridad;