import React from "react";
import { Box } from "@chakra-ui/react";
import PrivateRoute from "../../components/PrivateRoute";
import UsersList from "./sections/users";
import Contenido from "./sections/contenido";
import Metrics from "./sections/metrics";
import Eventos from "./sections/events";

function AdminPanel() {
  return (
    <Box>
      <Box maxW="1200px" mx="auto" p="20px">
        <PrivateRoute exact path="/panel-administrador/usuarios">
          <UsersList />
        </PrivateRoute>
        <PrivateRoute exact path="/panel-administrador/contenido">
          <Contenido />
        </PrivateRoute>
        <PrivateRoute exact path="/panel/metricas">
          <Metrics />
        </PrivateRoute>
        <PrivateRoute exact path="/panel/eventos">
          <Eventos />
        </PrivateRoute>
      </Box>
    </Box>
  );
}

export default AdminPanel;
