import axios from "axios";
const baseUrl = "/";
// process.env.NODE_ENV === "production" ? "/api/" :

export function createConversationReply(formData) {
  return axios
    .post(baseUrl + "conversation-reply/", {
      reply: formData.reply,
      userId: formData.userId,
      conversationId: formData.conversationId,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
}

export function getConversationReply(conversationId) {
  return axios
    .put(baseUrl + "conversation-reply/all", {
      conversationId: conversationId,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
}

export function getConversationSingleReply(id) {
  return axios
    .put(baseUrl + `conversation-reply/all/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
}
