import React, { useEffect, useState } from "react";
import { Box, Text } from "@chakra-ui/react";
import { usePreguntasOficiales } from "../hooks/queries/preguntas-oficiales";
import moment from "moment";
import "moment/locale/es";
import StaticQuestionCard from "../components/ui/question-card/StaticQuestionCard";
import { objectHasId } from "../functional/general";
import { Col, Container, Row } from "react-bootstrap";
import { css } from "@emotion/react";
import StickySide from "../pages/content/sticky-side";

export default function FaqsOfficials({ category }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [questionsArray, setQuestionsArray] = useState([]);
  const { refetch, isLoading, data } = usePreguntasOficiales({
    page: currentPage,
    category: category,
  });

  function nextPage(currentPage) {
    if (data.pages > currentPage) {
      setCurrentPage(currentPage + 1);
    }
  }

  function addQuestion(dataList) {
    setQuestionsArray((currentData) => {
      if (currentData.length !== 0) {
        dataList.map((value) => {
          if (!objectHasId(questionsArray, value.id)) {
            currentData.push(value);
          }
        });
        return currentData;
      } else {
        return dataList;
      }
    });
  }

  function viewMore() {
    refetch().then((res) => {
      nextPage(currentPage);
      addQuestion(res.result);
    });
  }

  useEffect(() => {
    if (!isLoading) {
      addQuestion(data.result);
    }
  }, [data]);

  return (
    <Box py="30px">
      <Container fluid>
        <Row>
          <Col
            md="9"
            css={css`
              @media (min-width: 0) and (max-width: 30em) {
                padding-left: 0;
                padding-right: 0;
              }
            `}
          >
            <Box width="100%">
              {questionsArray.map((pregunta, index) => (
                <StaticQuestionCard pregunta={pregunta} index={index} />
              ))}
              {!isLoading && data.pages > currentPage && (
                <Text
                  cursor="pointer"
                  color="gray.1"
                  isLoading={isLoading}
                  textAlign="center"
                  onClick={() => viewMore()}
                >
                  Ver más...
                </Text>
              )}
            </Box>
          </Col>
          <Col className="d-none d-md-block" md="3">
            <StickySide />
          </Col>
        </Row>
      </Container>
    </Box>
  );
}
