import React, { useState, useEffect } from "react";
import { Box, Text, Divider, VStack, StackDivider } from "@chakra-ui/react";
import { useGetAllNotifications } from "../hooks/queries/notification";
import NotificationCard from "../pages/profile/content/NotificationCard";
import { useAuthStore } from "../hooks/state/useAuthStore";
import { useLangStore } from "../hooks/state/useLangStore";
import lang from "../assets/lang/general";

function Notifications() {
  const userData = useAuthStore((state) => state.data);
  const { data, isLoading, refetch } = useGetAllNotifications({
    userId: userData.id,
  });
  const [notificationList, setNotificationList] = useState();
  const currentLang = useLangStore((state) => state.currentLang);

  useEffect(() => {
    if (!isLoading) setNotificationList(data);
  });

  return (
    <Box backgroundColor="white" borderRadius="5px">
      {notificationList < 1 ? (
        <Box p="10px">
          <Box p="20px">
            <Text fontSize="2xl" fontWeight="bold">
              {lang[currentLang].allnoti}
            </Text>
          </Box>
          <Divider />
          <Box p="20px">{lang[currentLang].noNotifications}</Box>
        </Box>
      ) : (
        <Box p="10px">
          <Box p="20px">
            <Text fontSize="2xl" fontWeight="bold">
              {lang[currentLang].allnoti}
            </Text>
          </Box>
          <Divider />
          {!isLoading && (
            <Box p="10px">
              {data.map((notification) => (
                <NotificationCard notiData={notification} />
              ))}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

export default Notifications;
