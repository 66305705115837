import axios from "axios";
const baseUrl = "/";
// process.env.NODE_ENV === "production" ? "/api/" :

export function createConversation(formData) {
  return axios
    .post(baseUrl + "conversation/", {
      userOne: formData.userOne,
      userTwo: formData.userTwo,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
}

export function getConversation(userId) {
  return axios
    .put(baseUrl + "conversation/user", {
      userId: userId,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
}

export function UploadFiles(files, userId, conversationId, receiverId) {
  const form = new FormData();
  form.append("userId", userId);
  form.append("conversationId", conversationId);
  form.append("receiverId", receiverId);
  form.append("file", files);

  return axios
    .post(baseUrl + "conversation/upload", form, {})
    .then((res) => {
      console.log(res);
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
}
