import React from "react";

const Errors = {
    checkErrors: function(errors, param) {

        let containsError = false;


        if (errors) {
            errors.map(val => {
                if (val.param === param) {
                    containsError = true;
                }

            })
        }
        return containsError;
    },

    showErrors: function (errors, param) {

        let errorData = errors.find(error => {
            return error.param === param;
        });

        if (errorData) {
            return (
                <p className="m-2 error-message">{errorData.msg}</p>
            )
        }

    }
}

export default Errors;