import React, { useState } from "react";
import { register } from "../api/UserFunctions";
import "../assets/css/Register.css";
import { useHistory } from "react-router-dom";
import errors from "../assets/functions/errors";
import {
  Box,
  Text,
  Input,
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  ModalBody,
  useDisclosure,
  Circle,
  Image,
  Link, Divider,
} from "@chakra-ui/react";
import { FaAngleLeft } from "react-icons/all";
import { jsx, css } from "@emotion/react";
import Form from "../components/ui/Form";
import LogInButton from "../components/ui/LogInButton";
import Errors from "../assets/functions/errors";
import { BsQuestion } from "react-icons/bs";
import { useLangStore } from "../hooks/state/useLangStore";
import lang from "../assets/lang/general";
import loginLang from "../assets/lang/login";
import recommendlang from "../assets/lang/recommend";
import formLang from "../assets/lang/formLogin";
import Footer from "../components/Footer";

function Register() {
  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState(null);
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const finalRef = React.useRef();
  const currentLang = useLangStore((state) => state.currentLang);

  function onSubmit(formData) {
    register(formData)
      .then((res) => {
        if (res) {
          setSuccess(res);
          setErrors([]);
          setTimeout(() => {
            window.location.replace("/login");
          }, 2000);
        }
      })
      .catch((err) => {
        setErrors(err);
      });
  }

  return (
    <Box>
      <Box ml="1rem" mt="1rem">
        <FaAngleLeft
          cursor="pointer"
          color="gray"
          fontSize="40px"
          onClick={(e) => history.push("/login")}
        />
      </Box>
      <Box mb="3rem">
            <Box m="auto" width="fit-content">
              <Image
                m="auto"
                width="150px"
                alt="logo-blue-orange-icuida"
                src={require("../assets/images/logos/logo-azul-orange-v2020.png")}
                onClick={() => history.push("/login")}
                cursor="pointer"
              />
            </Box>
            <Text textAlign="center" color="#6C6A69" fontSize="xl">
              {loginLang[currentLang].title}
            </Text>

      </Box>

      <Box
        bg="none"
        maxWidth="450px"
        p="1rem"
        m="auto"
        css={css`
          @media (min-width: 800px) {
            margin-top: 100px;
          }
        `}
      >
        <Text mb="20px" textAlign="center" fontSize="2xl" fontWeight="Bold" color="#f29999">
        {formLang[currentLang].texts.register}
        </Text>
        <Text textAlign="center">{loginLang[currentLang].informativeText1}</Text>
        <Text textAlign="center" mt="10px" mb="20px">{loginLang[currentLang].informativeText2}</Text>

        <Box mx="auto">
          <Form
            initialValues={{
              first_name: "",
              last_name: "",
              phone: "",
              email: "",
              password: "",
              password2: "",
              card: "",
              recommendName1: "",
              recommendPhone1: "",
              recommendEmail1: "",
              recommendName2: "",
              recommendPhone2: "",
              recommendEmail2: "",
            }}
            onSubmit={onSubmit}
          >
            {({ setFieldValue, values }) => (
              <Box>
                <div className="form-group">
                  <Input
                    borderRadius="50px"
                    type="first_name"
                    backgroundColor="#fff"
                    name="first_name"
                    placeholder={formLang[currentLang].form.name}
                    required="required"
                    value={values.first_name}
                    onChange={(e) =>
                      setFieldValue("first_name", e.target.value)
                    }
                  />
                  {Errors && Errors.showErrors(errors, "first_name")}
                </div>
                <div className="form-group">
                  <Input
                    borderRadius="50px"
                    type="last_name"
                    backgroundColor="#fff"
                    name="last_name"
                    placeholder={formLang[currentLang].form.surname}
                    required="required"
                    value={values.last_name}
                    onChange={(e) => setFieldValue("last_name", e.target.value)}
                  />
                  {Errors && Errors.showErrors(errors, "last_name")}
                </div>
                <div className="form-group">
                  <Input
                    borderRadius="50px"
                    type="phone"
                    backgroundColor="#fff"
                    name="phone"
                    placeholder={formLang[currentLang].form.phone}
                    required="required"
                    value={values.phone}
                    onChange={(e) => setFieldValue("phone", e.target.value)}
                  />
                  {Errors && Errors.showErrors(errors, "phone")}
                </div>
                <div className="form-group">
                  <Input
                    borderRadius="50px"
                    type="card"
                    backgroundColor="#fff"
                    name="card"
                    placeholder={formLang[currentLang].form.careGiverCard}
                    // required="required"
                    value={values.card}
                    onChange={(e) => setFieldValue("card", e.target.value)}
                  />
                  {Errors && Errors.showErrors(errors, "card")}

                  <Box
                    position="relative"
                    float="right"
                    bottom="29px"
                    left="25px"
                    ms="2"
                    display="flex"
                    alignSelf="center"
                    alignItems="center"
                    justifyContent="center"
                    cursor="pointer"
                    width="20px"
                    height="20px"
                    border="1px solid red"
                    bg="white"
                    borderRadius="20px"
                    onClick={onOpen}
                  >
                    <BsQuestion color="red" />
                  </Box>
                </div>
                  <Box marginBottom="4" textAlign="center">
                      <b>
                          <Link
                              textAlign="center"
                              color="blue.600"
                              href="/tarjeta-cuidadora"
                          >
                              {lang[currentLang].popupRegisterLink}
                          </Link>
                      </b>
                  </Box>

                <Modal
                  finalFocusRef={finalRef}
                  isOpen={isOpen}
                  onClose={onClose}
                  size="md"
                >
                  <ModalOverlay p="0">
                    <ModalContent>
                      <ModalHeader
                        margin="15px 10px 0px 0px"
                        padding="16px 24px 0 "
                      >
                        {lang[currentLang].popupRegisterTitle}
                      </ModalHeader>

                      <ModalCloseButton borderRadius="20px" />

                      <ModalBody>
                        <Box
                          color="gray"
                          margin="0px 0px 5px 0px"
                          padding="0px 24px 16px "
                        >
                          {lang[currentLang].popupRegisterSubtitle}
                        </Box>

                        <Box marginBottom="4" textAlign="center">
                          <Image
                            width="300px"
                            src={require("../assets/images/tarjetaCuidadora1.png")}
                            m="auto"
                          />
                        </Box>
                        <Box
                          color="gray"
                          margin="0px 0px 0px 0px"
                          padding="0px 24px 0px "
                          textAlign="center"
                        >
                          {lang[currentLang].popupRegisterBodyText}
                        </Box>
                        <Box marginBottom="4" textAlign="center">
                          <b>
                            <Link
                              textAlign="center"
                              color="blue.600"
                              href="/tarjeta-cuidadora"
                            >
                              {lang[currentLang].popupRegisterLink}
                            </Link>
                          </b>
                        </Box>
                      </ModalBody>
                      <ModalFooter>
                        {/* <Button colorScheme="blue" mr={3} onClick={onClose}>
                          Cerrar
                        </Button> */}
                      </ModalFooter>
                    </ModalContent>
                  </ModalOverlay>
                </Modal>
                <div className="form-group">
                  <Input
                    borderRadius="50px"
                    type="email"
                    backgroundColor="#fff"
                    name="email"
                    placeholder={formLang[currentLang].form.email}
                    required="required"
                    value={values.email}
                    onChange={(e) => setFieldValue("email", e.target.value)}
                  />
                  {Errors && Errors.showErrors(errors, "email")}
                </div>
                <div className="form-group">
                  <Input
                    borderRadius="50px"
                    type="password"
                    backgroundColor="#fff"
                    name="password"
                    placeholder={formLang[currentLang].form.passw}
                    required="required"
                    value={values.password}
                    onChange={(e) => setFieldValue("password", e.target.value)}
                  />
                  {Errors && Errors.showErrors(errors, "password")}
                </div>
                <div className="form-group">
                  <Input
                    borderRadius="50px"
                    type="password"
                    backgroundColor="#fff"
                    name="password2"
                    placeholder={formLang[currentLang].form.passw2}
                    required="required"
                    value={values.password2}
                    onChange={(e) => setFieldValue("password2", e.target.value)}
                  />
                  {Errors && Errors.showErrors(errors, "password2")}
                </div>

                <Text mb="20px" mt="20px" textAlign="center" fontSize="2xl" fontWeight="Bold" color="#f29999">
                  {recommendlang[currentLang].form.title}</Text>
                <Text textAlign="center">{recommendlang[currentLang].form.text1}</Text>
                <div className="form-group">
                  <Input
                    backgroundColor="#fff"
                    name="recommendName1"
                    placeholder={recommendlang[currentLang].form.name}
                    value={values.recommendName1}
                    onChange={(e) =>
                      setFieldValue("recommendName1", e.target.value)
                    }
                    borderRadius="50px!important"
                  />
                  {Errors && Errors.showErrors(errors, "recommendName1")}
                </div>
                <div className="form-group">
                  <Input
                    backgroundColor="#fff"
                    name="recommendPhone1"
                    placeholder={recommendlang[currentLang].form.phone}
                    value={values.recommendPhone1}
                    onChange={(e) =>
                      setFieldValue("recommendPhone1", e.target.value)
                    }
                    borderRadius="50px!important"
                  />
                  {Errors && Errors.showErrors(errors, "recommendPhone1")}
                </div>
                <div className="form-group">
                  <Input
                    backgroundColor="#fff"
                    className="place"
                    name="recommendEmail1"
                    placeholder={recommendlang[currentLang].form.email}
                    value={values.recommendEmail1}
                    onChange={(e) =>
                      setFieldValue("recommendEmail1", e.target.value)
                    }
                    borderRadius="50px!important"
                  />
                  {Errors && Errors.showErrors(errors, "recommendEmail1")}
                </div>
                <Divider  mb="20px" />
                <Text textAlign="center">{recommendlang[currentLang].form.text2}</Text>
                <div className="form-group">
                  <Input
                    backgroundColor="#fff"
                    name="recommendName2"
                    placeholder={recommendlang[currentLang].form.name}
                    value={values.recommendName2}
                    onChange={(e) =>
                      setFieldValue("recommendName2", e.target.value)
                    }
                    borderRadius="50px!important"
                  />
                  {Errors && Errors.showErrors(errors, "recommendName2")}
                </div>
                <div className="form-group">
                  <Input
                    backgroundColor="#fff"
                    name="recommendPhone2"
                    placeholder={recommendlang[currentLang].form.phone}
                    value={values.recommendPhone2}
                    onChange={(e) =>
                      setFieldValue("recommendPhone2", e.target.value)
                    }
                    borderRadius="50px!important"
                  />
                  {Errors && Errors.showErrors(errors, "recommendPhone2")}
                </div>
                <div className="form-group">
                  <Input
                    mb="10px"
                    backgroundColor="#fff"
                    className="place"
                    name="recommendEmail2"
                    placeholder={recommendlang[currentLang].form.email}
                    value={values.recommendEmail2}
                    onChange={(e) =>
                      setFieldValue("recommendEmail2", e.target.value)
                    }
                    borderRadius="50px!important"
                  />
                  {Errors && Errors.showErrors(errors, "recommendEmail2")}
                  {success && (
                    <div className="success-message rounded py-1 mt-2">
                      <p className="m-2">{success}</p>
                    </div>
                  )}
                </div>
                <LogInButton bg="#66CCCC" type="submit" content={formLang[currentLang].texts.register2}/>
              </Box>
            )}
          </Form>
          <div className="m-3">
            <Text
              as="ins"
              cursor="pointer"
              onClick={() => history.push("/password-recover")}
            >
              {formLang[currentLang].texts.forget}
            </Text>
          </div>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
}

export default Register;
