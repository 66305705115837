import React, {useState, useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import {Box, Text,Button} from '@chakra-ui/react'
import Loading from '../../../components/Loading';
import {useSearchQuestion} from '../../../hooks/queries/preguntas-comunidad'
import QuestionCard from '../../../components/ui/question-card/QuestionCard'
import {objectHasId} from '../../../functional/general'

function Comunidad({searchInput}) {

    const history = useHistory();
    const {data, isLoading} = useSearchQuestion({query: searchInput})
    const [dataSearched, setDataSearch] = useState([])
    function addQuestion(dataList) {
        
        setDataSearch(currentData => {
            if (currentData.length !== 0) {
                
                dataList.map(value => {
                    if (!objectHasId(dataSearched,value.id)) {
                        currentData.push(value)
                    } 
                })
                return currentData;
            } else {
                return dataList;
            }          
        })
    }

    useEffect(() => {
        if (!isLoading){
            addQuestion(data.result)
        }
    }, [data])

    return (
    <Box>
        {!isLoading ? 
        <React.Fragment>
               {data.result.length !== 0 ?
                    <Box>
                        <Box>
                            {   dataSearched.length > 0 &&
                                dataSearched.map(pregunta => <QuestionCard pregunta={pregunta}/>)
                            }
                        </Box>
                    </Box>
                    : 
                    <Box>
                        <Box mb="20px">
                            <Text fontSize="20px">No hemos podido encontrar: <Text as="span" fontWeight="bold">{searchInput}</Text></Text>
                        </Box>
                        <Button bg="blue.5" color="white" borderRadius="20px" onClick={() => history.push("/preguntar")}>Añadir pregunta</Button>
                    </Box>
               }
        </React.Fragment> 
        :
        <Loading/>
        }
    </Box>
    )
}

export default Comunidad;