import React, { useState } from "react";
import {
  Box,
  Button,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Input,
  Stack,
  StackDivider,
  Switch,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {
  useCreateConversation,
  useGetConversation,
} from "../../../../hooks/queries/conversation";
import { useAuthStore } from "../../../../hooks/state/useAuthStore";
import ChatSideBlock from "./ChatSideBlock";
import { useEffect } from "react";
import { getConversation } from "../../../../api/conversation";
import {
  useSearchUser,
  useUpdateProfile,
} from "../../../../hooks/queries/usuarios";
import lang from "../../../../assets/lang/general";
import { useLangStore } from "../../../../hooks/state/useLangStore";
import { useDispatch, useSelector } from "react-redux";
import { NotificatioinData, removeNoti } from "../../../../store/action";
import { deleteChatNoti, getChatCount } from "../../../../api/notification";

function ChatSidebar({ selectRoom, selectedRoom, message }) {
  const [searchUser, setSearchUser] = useState("");
  const [searchResponse, setSearchResponse] = useState("");
  const userData = useAuthStore((state) => state.data);
  // const {data,isLoading, keepPreviousData, refetch} = useGetConversation({userId: userData.id})
  const [userDatail, setUserDatail] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const [updateProfile, updateProfileQuery] = useUpdateProfile();
  const [searchUserQuery, searchQuery] = useSearchUser();
  const [editProfileSuccess, setEditProfileSuccess] = useState(false);
  const updateAuthData = useAuthStore((state) => state.setData);
  const [createConversation, createQuestionQuery] = useCreateConversation();
  const currentLang = useLangStore((state) => state.currentLang);
  const receivenoti = useSelector((state) => state?.notification?.items);
  const [notification, setNotification] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    new Promise(async (resolve, reject) => {
      const response = await getConversation(userData.id);
      resolve(response);
      setUserDatail(response);
      // selectRoom(response[0]);
    });
  }, []);

  useEffect(() => {
    if (message && message?.conversationId && userDatail?.length !== 0) {
      // const fromIndex = userDatail?.findIndex((data) => data?.id === message?.conversationId);
      // const element = userDatail?.splice(fromIndex, 1)[0];
      // userDatail?.splice(0, 0, element);
      // setUserDatail(userDatail);
      new Promise(async (resolve, reject) => {
        const response = await getConversation(userData.id);
        resolve(response);
        setUserDatail(response);
      });
    }
  }, [message]);

  const handleSwitch = (connect) => {
    const data = {
      userId: userData?.id,
      firstName: userData?.firstName,
      lastName: userData?.lastName,
      type: userData?.type,
      description: userData?.description,
      connect: connect,
    };
    updateProfile(data)
      .then((res) => {
        updateAuthData(res);
        setEditProfileSuccess(true);
        setTimeout(() => {
          setEditProfileSuccess(false);
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  function handleSearchUser(query) {
    setSearchResponse("");
    const formData = {
      search: query,
      userId: userData?.id,
    };
    if (query.length > 2) {
      searchUserQuery(formData)
        .then((res) => {
          setSearchResponse(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  function sendMessage(userId) {
    createConversation(
      {
        userOne: Number(userData.id),
        userTwo: Number(userId),
      },
      {
        onSuccess: (res) => {
          selectRoom(res[0]);
          setUserDatail((currentData) => [res[0], ...currentData]);
          setSearchUser("");
        },
        onError: (err) => {
          console.log(err);
        },
      }
    );
  }

  useEffect(() => {
    if (Object.keys(receivenoti).length !== 0) {
      setNotification((notification) => [...notification, ...receivenoti]);
    }
  }, [receivenoti]);

  function handleNotification(value) {
    let index = notification.findIndex(
      (data) => data?.conversationId === value?.id
    );
    if (index !== -1) {
      notification.splice(index, 1);
      setNotification(notification);
      dispatch(removeNoti(value?.id));
      deleteChatNoti({
        receiverId: userData?.id,
        conversationId: value?.id,
      });
    }
  }
  useEffect(() => {
    if (selectRoom && notification?.length > 0) {
      handleNotification(selectedRoom);
    }
  }, [notification]);

  useEffect(() => {
    getChatCount(userData?.id).then((data) => {
      if (data) {
        setNotification(data?.result);
      }
    });
  }, [receivenoti]);

  return (
    <Box height="100%" borderRight="1px solid #E2E8F0">
      <Box>
        <Flex style={{ justifyContent: "space-between", alignItems: "center" }}>
          <Box p="10px" justifyContent="flex-start">
            <Text fontSize="xl" fontWeight="bold">
              Chat
            </Text>
          </Box>
          <Box display="flex" p="3px" mt="8px">
            <Text fontWeight="bold" fontSize="15px" mr="8px" mt="1px">
              {userData?.connect === true ? "Disponible" : "No disponible"}
            </Text>
            <Switch
              mt="3px"
              id="isCkecked"
              defaultChecked={userData?.connect}
              onChange={(data) => handleSwitch(data.target.checked)}
            />
          </Box>
        </Flex>
      </Box>
      <div className="search-form mb-2 ms-">
        <Box p="10px">
          <Input
            className="form-control mr-sm-2"
            backgroundColor="white"
            width="100% !important"
            type="search"
            aria-label="Search"
            placeholder="buscar usuario"
            id="searchInput"
            value={searchUser}
            onChange={(e) => {
              setSearchUser(e.target.value);
              handleSearchUser(e.target.value);
            }}
          />
        </Box>
      </div>
      <Box>
        <Stack
          divider={<StackDivider borderColor="gray.200" />}
          spacing={1}
          align="stretch"
        >
          {searchUser?.length > 0
            ? searchResponse?.length > 0 &&
              searchResponse?.map((value, index) => {
                return (
                  <>
                    <Box
                      key={index}
                      p="10px"
                      cursor="pointer"
                      onClick={() =>
                        value?.conversion
                          ? `${
                              (selectRoom(value?.conversion), setSearchUser(""))
                            }`
                          : sendMessage(value?.id)
                      }
                    >
                      <ChatSideBlock conversationData={value} search={true} />
                    </Box>
                  </>
                );
              })
            : userDatail?.length > 0 &&
              userDatail?.map((value, index) => {
                return (
                  <>
                    {/* <Box key={index} bg={ selectedRoom?.id === value?.id ? "#E2E8F0": "  "} p="10px" cursor="pointer" onClick={() => handleClick(value)}><ChatSideBlock userDatail={userDatail} message={message} conversationData={value}/></Box> */}
                    <Box
                      key={index}
                      bg={
                        selectedRoom?.id === value?.id && !searchUser
                          ? "#E2E8F0"
                          : "  "
                      }
                      p="10px"
                      cursor="pointer"
                      onClick={() => {
                        selectRoom(value);
                        if (notification?.length > 0) {
                          handleNotification(value);
                        }
                      }}
                    >
                      <ChatSideBlock
                        conversationData={value}
                        receivenoti={notification}
                        selectedRoom={selectedRoom}
                      />
                    </Box>
                  </>
                );
              })}
        </Stack>
      </Box>
    </Box>
  );
}

export default ChatSidebar;
