import React, { useEffect, useState } from "react";
import {
  Badge,
  Box,
  Text,
  Avatar,
  VStack,
  StackDivider,
} from "@chakra-ui/react";
import { usePreguntasComunidad } from "../hooks/queries/preguntas-comunidad";
import QuestionCard from "./ui/question-card/QuestionCard";
import { objectHasId } from "../functional/general";
import { questionsCategory } from "../api/QuestionFunctions";

export default function NewStaticQuestion({ category }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [questionsArray, setQuestionsArray] = useState([]);
  const { refetch, isLoading, data } = usePreguntasComunidad({
    page: currentPage,
    category: category,
  });

  function nextPage(currentPage) {
    if (data.pages > currentPage) {
      setCurrentPage(currentPage + 1);
    }
  }

  useEffect(() => {
    new Promise(async (resolve, reject) => {
      const response = await questionsCategory({
        page: currentPage,
        category: category,
      });
      resolve(response);
      setQuestionsArray(response.result);
    });
  }, [category, data]);

  function addQuestion(dataList) {
    setQuestionsArray((currentData) => {
      if (currentData.length !== 0) {
        dataList.map((value) => {
          if (!objectHasId(questionsArray, value.id)) {
            currentData.push(value);
          }
        });
        return currentData;
      } else {
        return dataList;
      }
    });
  }

  function viewMore() {
    refetch().then((res) => {
      nextPage(currentPage);
      addQuestion(res.result);
    });
  }

  //   useEffect(() => {
  //     if (!isLoading) {
  //       addQuestion(data.result);
  //     }
  //   }, [data]);

  return (
    <Box>
      {questionsArray?.length > 0 &&
        questionsArray?.map((pregunta, index) => (
          <QuestionCard
            key={index}
            refetch={() => refetch()}
            pregunta={pregunta}
            index={index}
          />
        ))}
      {!isLoading && data?.pages > currentPage && (
        <Text
          cursor="pointer"
          color="gray.1"
          isLoading={isLoading}
          textAlign="center"
          onClick={() => viewMore()}
        >
          Ver más...
        </Text>
      )}
    </Box>
  );
}
