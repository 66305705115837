import React from "react";
import { useHistory } from "react-router-dom";
import { Box, Flex, Divider, Text, Image } from "@chakra-ui/react";
import { FaFacebookF, FaInstagram } from "react-icons/all";
import LangSelector from "../../../components/LangSelector";
import lang from "../../../assets/lang/general";
import { useLangStore } from "../../../hooks/state/useLangStore";

function FollowUs() {
  const history = useHistory();
  const currentLang = useLangStore((state) => state.currentLang);

  function privacyPolicy() {
    return (
      <a
        href="https://www.iubenda.com/privacy-policy/87634187"
        class="iubenda-black iubenda-embed"
        title="Privacy Policy "
      >
        Privacy Policy
      </a>
    );
  }

  return (
    <Box
      p="20px"
      mt="20px"
      backgroundColor="#fff"
      width="100%"
      borderRadius="5px"
    >
      <Box>
        <Text textAlign="center" fontSize="xl" fontWeight="bold">
          {lang[currentLang].followus}
        </Text>
      </Box>
      <Flex py="20px" m="auto" width="fit-content" fontSize="40px">
        <Box px="20px" cursor="pointer">
          <a
            target="_blank"
            href="https://www.facebook.com/Icuidabarcelona-103193738268904"
            rel="noreferrer"
          >
            <FaFacebookF color="#CBCBCB" />
          </a>
        </Box>
        <Box px="20px" cursor="pointer">
          <a
            target="_blank"
            href="https://www.instagram.com/icuida.barcelona/"
            rel="noreferrer"
          >
            <FaInstagram color="#CBCBCB" />
          </a>
        </Box>
      </Flex>
      <Divider my="5px" />
      <Box mt="20px">
        <Box
          width="fit-content"
          m="auto"
          cursor="pointer"
          onClick={(e) => history.push("/")}
        >
          <Image
            width="70px"
            src={require("../../../assets/images/logos/logo-azul-orange-v2020.png")}
          />
        </Box>
        <Box>
          <Text fontSize="xs" color="gray.1" textAlign="center">
            <Text cursor="pointer" as="a" mx="3px" href="/sobre-icuida">
              {lang[currentLang].sobreicuida}
            </Text>
            -
            <Text cursor="pointer" as="a" mx="3px" href="/tarjeta-cuidadora">
              {lang[currentLang].caregiverCard}
            </Text>
            -
            <Text cursor="pointer" as="a" mx="3px" href="/terminos-condiciones">
              {lang[currentLang].conditions}
            </Text>
            -
            <Text cursor="pointer" as="a" mx="3px" href="/politica-privacidad">
              {lang[currentLang].privacy}
            </Text>
            -
            <Text cursor="pointer" as="a" mx="3px" href="/politica-cookies">
              {lang[currentLang].politicaCookies}
            </Text>
            -
            <Text cursor="pointer" as="a" mx="3px" href="/ayuda">
              {lang[currentLang].help}
            </Text>
            -
            <Text cursor="pointer" as="a" mx="3px" href="/politica-uso">
              {lang[currentLang].politicaUse}
            </Text>
            -
            <Text cursor="pointer" as="a" mx="3px" href="/recomana">
              {lang[currentLang].recommends}
            </Text>
            -
            <Text cursor="pointer" as="a" mx="3px" href="/recursos">
              {lang[currentLang].resources}
            </Text>
            -
            <Text cursor="pointer" as="a" mx="3px" href="/contacta">
              {lang[currentLang].contactus}
            </Text>
          </Text>
        </Box>
        <Divider />
        <LangSelector />
      </Box>
    </Box>
  );
}

export default FollowUs;
