import axios from "axios";
const baseUrl = "/";
// process.env.NODE_ENV === "production" ? "/api/" :

export const askQuestion = (question) => {
  return axios
    .post(baseUrl + "questions", {
      title: question.title,
      categories: JSON.stringify(question.categories),
      userId: question.userId,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      const errors = err.response.data.errors;
      if (errors) {
        const errorArray = Object.keys(errors).map((i) => errors[i]);
        throw errorArray;
      }
    });
};

export const questions = (page) => {
  return axios
    .put(baseUrl + "questions/" + page)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const getAllQuestions = () => {
  return axios
    .put(baseUrl + "questions/all")
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const getUserQuestions = (id) => {
  return axios
    .put(baseUrl + "questions/user-questions/", { userId: id })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const getAnswerQuestion = (id) => {
  return axios
    .put(baseUrl + "questions/answer-question/", { questionId: id })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const questionsCategory = ({ category, page }) => {
  return axios
    .put(baseUrl + "questions/", { category, page })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const getToAnswers = ({ page }) => {
  return axios
    .put(baseUrl + "questions/to-answer", { page })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const search = (tag) => {
  return axios
    .put(baseUrl + "questions/search/" + tag)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const question = (id) => {
  return axios
    .put(baseUrl + "questions/selected/" + id)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const questionLike = (formData) => {
  return axios
    .post(baseUrl + "question-likes/like/", formData)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const questionDislike = (formData) => {
  return axios
    .delete(baseUrl + "question-likes/dislike/", { data: formData })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const questionViews = (id) => {
  return axios
    .put(baseUrl + "questions/views/" + id)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const topViews = (page) => {
  return axios
    .put(baseUrl + "questions/top-views/mas-buscados/" + page)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const topLikes = (page) => {
  return axios
    .put(baseUrl + "questions/top-likes/mas-mirados/" + page)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const questionSearchQuery = (formData) => {
  return axios
    .put(baseUrl + "questions/search-query/", { query: formData.query })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const questionDelete = (formData) => {
  return axios
    .delete(baseUrl + "questions/delete", {
      data: {
        questionId: formData.questionId,
        userId: formData.userId,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
