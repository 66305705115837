import io from "socket.io-client";
import { NotificatioinData, processData } from "../store/action/index";

let socket;
// const url =
//   process.env.NODE_ENV === "production" ? "/api/socket.io/" : "/socket.io/";
const url = "/socket.io/";
export const RunSocket = () => {
  socket = io({
    path: url,
    withCredentials: true,
  });
};
export const receiveMSG = (dispatch) => {
  socket.on(`receive-message`, (data) => {
    if (data) {
      dispatch && dispatch(processData(data));
    }
  });
};
export const receiveNOTI = (dispatch) => {
  socket.on(`chat-noti`, (data) => {
    if (data) {
      dispatch && dispatch(NotificatioinData(data));
    }
  });
};

export const sendMessage = (data, { payload }) => {
  socket.emit(data, payload);
};
export default socket;
