/** jsx @jsx */
import React, { useState, useEffect } from "react";
import { jsx, css } from "@emotion/react";
import jwt_decode from "jwt-decode";
import {
  Box,
  Avatar,
  Text,
  Flex,
  Button,
  Divider,
  Grid,
  Image,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
} from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import { useAuthStore } from "../../hooks/state/useAuthStore";
import { useDrawerStore } from "../../hooks/state/useDrawerStore";
import { Row, Col, Container } from "react-bootstrap";
import IconoAjustes from "../../assets/icons/IconoAjustes";
import IconoPerfil from "../../assets/icons/IconoPerfil";
import IconoRecomienda from "../../assets/icons/IconoRecomienda";
// import IconoAñadir from "../../assets/icons/IconoAñadir";
import IconoPreguntar from "../../assets/icons/IconoPreguntar";
import IconoRespuesta from "../../assets/icons/IconoRespuesta";
import IconoNotificacion from "../../assets/icons/IconoNotificacion";
import IconoComentar from "../../assets/icons/IconoComentar";
import Questions from "./content/Questions";
import Answers from "./content/Answers";
import Settings from "./content/Settings";
import Comments from "./content/Comments";
import Notifications from "./content/Notifications";
import EditProfile from "./content/EditProfile";
import Recommends from "./content/Recommends";
import LangSelector from "../../components/LangSelector";
import { useLangStore } from "../../hooks/state/useLangStore";
import lang from "../../assets/lang/user-profile";
import langGeneral from "../../assets/lang/general";

function Profile() {
  const history = useHistory();
  const [contentActive, setContentActive] = useState(1);
  const userData = useAuthStore((state) => state.data);
  const logOut = useAuthStore((state) => state.logOut);
  const drawerIsOpen = useDrawerStore((state) => state.isOpen);
  const setDrawerIsOpen = useDrawerStore((state) => state.setOpen);
  const currentLang = useLangStore((state) => state.currentLang);
  const params = useLocation();
  const id = params.search;
  const Id = id.charAt(id.length - 1);

  useEffect(() => {
    if (Id) {
      setContentActive(5);
    }
  }, []);

  const contentComponents = [
    { id: 1, component: <Questions userData={userData} /> },
    { id: 2, component: <Answers userData={userData} /> },
    { id: 3, component: <Comments userData={userData} /> },
    { id: 4, component: <Notifications userData={userData} /> },
    { id: 5, component: <EditProfile userData={userData} /> },
    { id: 6, component: <Settings userData={userData} /> },
    { id: 7, component: <Recommends userData={userData} /> },
  ];

  function drawerOpen() {
    setDrawerIsOpen(true);
  }

  function drawerClose() {
    setDrawerIsOpen(false);
  }
  return (
    <Container fluid>
      <Box maxWidth="1200px" py="20px">
        <Box
          mb="30px"
          p="20px"
          bg="white"
          borderRadius="5px"
          boxShadow="0px 3px 6px #00000029"
        >
          <Row>
            <Col lg="3">
              <Box
                my="10px"
                width={{ base: "fit-content", lg: "initial" }}
                m={{ base: "auto", lg: "0 30px 0 0" }}
              >
                <Avatar
                  size="xl"
                  name={userData.firstName}
                  src={userData.imgUrl ?? null}
                />
              </Box>
            </Col>
            <Col lg="6">
              <Box
                my="10px"
                width={{ base: "fit-content", lg: "initial" }}
                mx={{ base: "auto", lg: "initial" }}
                textAlign={{ base: "center", lg: "initial" }}
                flexGrow="1"
              >
                {userData.lastName === null ? (
                  <Text
                    fontSize="md"
                    textTransform="capitalize"
                    fontWeight="bold"
                  >
                    {userData.firstName}
                  </Text>
                ) : (
                  <Text
                    fontSize="md"
                    textTransform="capitalize"
                    fontWeight="bold"
                  >
                    {userData.firstName + " " + userData.lastName}
                  </Text>
                )}

                <Text>{userData.type}</Text>
                <Text fontSize="13px">{userData.description}</Text>
              </Box>
            </Col>
            <Col md="3">
              <Flex
                css={css`
                  display: flex;
                  height: 100%;
                  @media (max-width: 1000px) {
                    display: none;
                  }
                `}
              >
                <Box m="auto" alignSelf="center">
                  <Button
                    colorScheme="teal"
                    variant="outline"
                    onClick={(e) => {
                      setContentActive(5);
                    }}
                  >
                    Editar
                  </Button>
                </Box>
              </Flex>
            </Col>
          </Row>
        </Box>
        <Row>
          <Col className="d-none d-md-block" md="3">
            <Box
              position="sticky"
              top="20px"
              bg="white"
              borderRadius="5px"
              boxShadow="0px 3px 6px #00000029"
            >
              <Box p="20px">
                <Text>Mi perfil</Text>
              </Box>
              <Divider />
              <Box p="10px 30px">
                <Box>
                  <Flex
                    py="15px"
                    cursor="pointer"
                    onClick={(e) => {
                      setContentActive(1);
                    }}
                  >
                    <Box alignSelf="center">
                      <IconoPreguntar
                        fontSize="20px"
                        bg={contentActive === 1 ? "#FE6E50" : "#000"}
                      />
                    </Box>
                    <Text
                      ml="5px"
                      color={contentActive === 1 ? "#FE6E50" : "#000"}
                    >
                      {lang[currentLang].myQuestions}
                    </Text>
                  </Flex>
                  <Flex
                    py="15px"
                    cursor="pointer"
                    onClick={(e) => {
                      setContentActive(2);
                    }}
                  >
                    <IconoRespuesta
                      fontSize="20px"
                      color={contentActive === 2 ? "#FE6E50" : "#000"}
                    />
                    <Text
                      ml="5px"
                      color={contentActive === 2 ? "#FE6E50" : "#000"}
                    >
                      {lang[currentLang].myAnswers}
                    </Text>
                  </Flex>
                  <Flex
                    py="15px"
                    cursor="pointer"
                    onClick={(e) => {
                      setContentActive(3);
                    }}
                  >
                    <IconoComentar
                      color={contentActive === 3 ? "#FE6E50" : "#000"}
                    />
                    <Text
                      ml="5px"
                      color={contentActive === 3 ? "#FE6E50" : "#000"}
                    >
                      {lang[currentLang].myComments}
                    </Text>
                  </Flex>
                </Box>
                <Divider />
                <Box>
                  <Flex
                    py="15px"
                    cursor="pointer"
                    onClick={(e) => {
                      setContentActive(4);
                    }}
                  >
                    <IconoNotificacion
                      color={contentActive === 4 ? "#FE6E50" : "#000"}
                    />
                    <Text
                      ml="5px"
                      color={contentActive === 4 ? "#FE6E50" : "#000"}
                    >
                      {lang[currentLang].myNotifications}
                    </Text>
                  </Flex>
                  <Flex
                    py="15px"
                    cursor="pointer"
                    onClick={(e) => {
                      setContentActive(5);
                    }}
                  >
                    <IconoPerfil
                      color={contentActive === 5 ? "#FE6E50" : "#000"}
                    />
                    <Text
                      ml="5px"
                      color={contentActive === 5 ? "#FE6E50" : "#000"}
                    >
                      {lang[currentLang].editProfile}
                    </Text>
                  </Flex>
                  <Flex
                    py="15px"
                    cursor="pointer"
                    onClick={(e) => {
                      setContentActive(7);
                    }}
                  >
                    <IconoRecomienda
                      color={contentActive === 7 ? "#FE6E50" : "#000"}
                    />
                    <Text
                      ml="5px"
                      color={contentActive === 7 ? "#FE6E50" : "#000"}
                    >
                      {lang[currentLang].recommends}
                    </Text>
                  </Flex>
                  <Flex
                    py="15px"
                    cursor="pointer"
                    onClick={(e) => {
                      setContentActive(6);
                    }}
                  >
                    <IconoAjustes
                      color={contentActive === 6 ? "#FE6E50" : "#000"}
                    />
                    <Text
                      ml="5px"
                      color={contentActive === 6 ? "#FE6E50" : "#000"}
                    >
                      {lang[currentLang].settings}
                    </Text>
                  </Flex>
                </Box>
                <Divider />
                <Box>
                  <Flex py="5px" cursor="pointer">
                    <Text
                      ml="5px"
                      color="gray.1"
                      onClick={() => history.push("/ayuda")}
                    >
                      {lang[currentLang].help}
                    </Text>
                  </Flex>
                  <Flex py="5px" cursor="pointer" onClick={(e) => logOut()}>
                    <Text ml="5px" color="gray.1">
                      {lang[currentLang].closeSession}
                    </Text>
                  </Flex>
                </Box>
                <Divider />
                <Box py="20px">
                  <Box width="fit-content" m="auto">
                    <Box
                      px="20px"
                      _hover={{ color: "orange.1" }}
                      cursor="pointer"
                      onClick={(e) => {
                        history.push("/");
                      }}
                    >
                      <Image
                        width="70px"
                        src={require("../../assets/images/logos/logo-azul-orange-v2020.png")}
                      />
                    </Box>
                  </Box>
                  <Box>
                    <Text fontSize="xs" color="gray.1" textAlign="center">
                      <Text
                        cursor="pointer"
                        as="span"
                        mx="3px"
                        onClick={() => {
                          history.push("/sobre-icuida");
                        }}
                      >
                        {langGeneral[currentLang].sobreicuida}
                      </Text>
                      -
                      <Text
                        cursor="pointer"
                        as="span"
                        mx="3px"
                        onClick={() => {
                          history.push("/terminos-condiciones");
                        }}
                      >
                        {langGeneral[currentLang].conditions}
                      </Text>
                      -
                      <Text
                        cursor="pointer"
                        as="span"
                        mx="3px"
                        onClick={() => {
                          history.push("/politica-privacidad");
                        }}
                      >
                        {langGeneral[currentLang].privacy}
                      </Text>
                      -
                      <Text
                        cursor="pointer"
                        as="span"
                        mx="3px"
                        onClick={() => {
                          history.push("/aviso-legal");
                        }}
                      >
                        {langGeneral[currentLang].avisoLegal}
                      </Text>
                      -
                      <Text
                        cursor="pointer"
                        as="span"
                        mx="3px"
                        onClick={() => {
                          history.push("/politica-cookies");
                        }}
                      >
                        {langGeneral[currentLang].politicaCookies}
                      </Text>
                      -
                      <Text
                        cursor="pointer"
                        as="span"
                        mx="3px"
                        onClick={() => {
                          history.push("/ayuda");
                        }}
                      >
                        {langGeneral[currentLang].help}
                      </Text>
                      -
                      <Text
                        cursor="pointer"
                        as="span"
                        mx="3px"
                        onClick={() => {
                          history.push("/contacta");
                        }}
                      >
                        {langGeneral[currentLang].contactus}
                      </Text>
                      -
                      <Text
                        cursor="pointer"
                        as="span"
                        mx="3px"
                        onClick={() => {
                          history.push("/recomana");
                        }}
                      >
                        {langGeneral[currentLang].recommends}
                      </Text>
                      - 
                      <Text
                          cursor="pointer"
                          as="span"
                          mx="3px"
                          onClick={() => {
                            history.push("/recursos");
                          }}
                      >
                        {langGeneral[currentLang].resources}
                      </Text>
                    </Text>
                  </Box>
                  <LangSelector />
                </Box>
              </Box>
            </Box>
          </Col>
          <Col md="9">
            {contentComponents.map((value) => {
              if (value.id === contentActive) return value.component;
            })}
          </Col>
        </Row>
      </Box>
      <Drawer
        placement="left"
        onClose={drawerClose}
        isOpen={drawerIsOpen}
        blockScrollOnMount={false}
      >
        <DrawerOverlay />
        <DrawerContent maxH="100vh">
          <DrawerHeader borderBottomWidth="1px">
            <Text>Mi cuenta</Text>
          </DrawerHeader>
          <DrawerBody>
            <Box p="10px 30px">
              <Box px="30px">
                <Flex
                  py="15px"
                  cursor="pointer"
                  onClick={(e) => {
                    setContentActive(1);
                    drawerClose();
                  }}
                >
                  <Box alignSelf="center">
                    <IconoPreguntar
                      fontSize="20px"
                      bg={contentActive === 1 ? "#FE6E50" : "#000"}
                    />
                  </Box>
                  <Text
                    ml="5px"
                    color={contentActive === 1 ? "#FE6E50" : "#000"}
                  >
                    {lang[currentLang].myQuestions}
                  </Text>
                </Flex>
                <Flex
                  py="15px"
                  cursor="pointer"
                  onClick={(e) => {
                    setContentActive(2);
                    drawerClose();
                  }}
                >
                  <IconoRespuesta
                    fontSize="20px"
                    color={contentActive === 2 ? "#FE6E50" : "#000"}
                  />
                  <Text
                    ml="5px"
                    color={contentActive === 2 ? "#FE6E50" : "#000"}
                  >
                    {lang[currentLang].myAnswers}
                  </Text>
                </Flex>
                <Flex
                  py="15px"
                  cursor="pointer"
                  onClick={(e) => {
                    setContentActive(3);
                    drawerClose();
                  }}
                >
                  <IconoComentar
                    color={contentActive === 3 ? "#FE6E50" : "#000"}
                  />
                  <Text
                    ml="5px"
                    color={contentActive === 3 ? "#FE6E50" : "#000"}
                  >
                    {lang[currentLang].myComments}
                  </Text>
                </Flex>
              </Box>
              <Divider />
              <Box px="30px">
                <Flex
                  py="15px"
                  cursor="pointer"
                  onClick={(e) => {
                    setContentActive(4);
                    drawerClose();
                  }}
                >
                  <IconoNotificacion
                    color={contentActive === 4 ? "#FE6E50" : "#000"}
                  />
                  <Text
                    ml="5px"
                    color={contentActive === 4 ? "#FE6E50" : "#000"}
                  >
                    {lang[currentLang].myNotifications}
                  </Text>
                </Flex>
                <Flex
                  py="15px"
                  cursor="pointer"
                  onClick={(e) => {
                    setContentActive(5);
                    drawerClose();
                  }}
                >
                  <IconoPerfil
                    color={contentActive === 5 ? "#FE6E50" : "#000"}
                  />
                  <Text
                    ml="5px"
                    color={contentActive === 5 ? "#FE6E50" : "#000"}
                  >
                    {lang[currentLang].editProfile}
                  </Text>
                </Flex>
                <Flex
                  py="15px"
                  cursor="pointer"
                  onClick={(e) => {
                    setContentActive(7);
                    drawerClose();
                  }}
                >
                  <IconoRecomienda
                    color={contentActive === 7 ? "#FE6E50" : "#000"}
                  />
                  <Text
                    ml="5px"
                    color={contentActive === 7 ? "#FE6E50" : "#000"}
                  >
                    {lang[currentLang].recommends}
                  </Text>
                </Flex>
                <Flex
                  py="15px"
                  cursor="pointer"
                  onClick={(e) => {
                    setContentActive(6);
                    drawerClose();
                  }}
                >
                  <IconoAjustes
                    color={contentActive === 6 ? "#FE6E50" : "#000"}
                  />
                  <Text
                    ml="5px"
                    color={contentActive === 6 ? "#FE6E50" : "#000"}
                  >
                    {lang[currentLang].settings}
                  </Text>
                </Flex>
              </Box>
              <Divider />
              <Box px="30px">
                <Flex
                  py="5px"
                  cursor="pointer"
                  onClick={() => history.push("/ayuda")}
                >
                  <Text ml="5px" color="gray.1">
                    {lang[currentLang].help}
                  </Text>
                </Flex>
                <Flex py="5px" cursor="pointer" onClick={(e) => logOut()}>
                  <Text ml="5px" color="gray.1">
                    {lang[currentLang].closeSession}
                  </Text>
                </Flex>
              </Box>
              <Divider />
              <Box py="20px">
                <Box width="fit-content" m="auto">
                  <Image
                    width="70px"
                    src={require("../../assets/images/logos/logo-azul-orange-v2020.png")}
                  />
                </Box>
                <Box>
                  <Text fontSize="xs" color="gray.1" textAlign="center">
                    <Text
                      cursor="pointer"
                      as="span"
                      mx="3px"
                      onClick={() => {
                        history.push("/sobre-icuida");
                      }}
                    >
                      {langGeneral[currentLang].sobreicuida}
                    </Text>
                    -
                    <Text
                      cursor="pointer"
                      as="span"
                      mx="3px"
                      onClick={() => {
                        history.push("/terminos-condiciones");
                      }}
                    >
                      {langGeneral[currentLang].conditions}
                    </Text>
                    -
                    <Text
                      cursor="pointer"
                      as="span"
                      mx="3px"
                      onClick={() => {
                        history.push("/politica-privacidad");
                      }}
                    >
                      {langGeneral[currentLang].privacy}
                    </Text>
                    -
                    <Text
                      cursor="pointer"
                      as="span"
                      mx="3px"
                      onClick={() => {
                        history.push("/aviso-legal");
                      }}
                    >
                      {langGeneral[currentLang].avisoLegal}
                    </Text>
                    -
                    <Text
                      cursor="pointer"
                      as="span"
                      mx="3px"
                      onClick={() => {
                        history.push("/politica-cookies");
                      }}
                    >
                      {langGeneral[currentLang].politicaCookies}
                    </Text>
                    -
                    <Text
                      cursor="pointer"
                      as="span"
                      mx="3px"
                      onClick={() => {
                        history.push("/ayuda");
                      }}
                    >
                      {langGeneral[currentLang].help}
                    </Text>
                    -
                    <Text
                      cursor="pointer"
                      as="span"
                      mx="3px"
                      onClick={() => {
                        history.push("/contacta");
                      }}
                    >
                      {langGeneral[currentLang].contactus}
                    </Text>
                    -
                    <Text
                      cursor="pointer"
                      as="span"
                      mx="3px"
                      onClick={() => {
                        history.push("/recomana");
                      }}
                    >
                      {langGeneral[currentLang].recommends}
                    </Text>
                    -
                    <Text
                        cursor="pointer"
                        as="span"
                        mx="3px"
                        onClick={() => {
                          history.push("/recursos");
                        }}
                    >
                      {langGeneral[currentLang].resources}
                    </Text>
                  </Text>
                </Box>
                <LangSelector />
              </Box>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Container>
  );
}

export default Profile;
