import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Divider,
  Flex,
  Avatar,
  Input,
  Button,
  Select,
  Textarea,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  ModalBody,
  useDisclosure,
  Image,
  Link,
} from "@chakra-ui/react";

import { jsx, css } from "@emotion/react";
import Form from "../../../components/ui/Form";
import { Field } from "formik";
import { Container, Row, Col } from "react-bootstrap";
import { FaGoogle, FaFacebookF } from "react-icons/all";
import {
  useUpdateProfile,
  useUploadPhoto,
} from "../../../hooks/queries/usuarios";
import FileUpload from "../../../components/FileUpload";
import { useAuthStore } from "../../../hooks/state/useAuthStore";
import SuccessWarning from "../../../components/warnings/SuccessWarning";
import { BsQuestion } from "react-icons/bs";
// import "../../../assets/css/EditProfile.css";
import { useLangStore } from "../../../hooks/state/useLangStore";
import lang from "../../../assets/lang/user-profile";
import langPopUp from "../../../assets/lang/general";

function EditProfile({ userData }) {
  const [updateProfile, updateProfileQuery] = useUpdateProfile();
  const [uploadPhoto, setUploadPhoto] = useUploadPhoto();
  const [profilePhoto, setProfilePhoto] = useState("");
  const [editProfileSuccess, setEditProfileSuccess] = useState(false);
  const [editPhotoSuccess, setEditPhotoSuccess] = useState(false);
  const updateAuthData = useAuthStore((state) => state.setData);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const finalRef = React.useRef();
  const currentLang = useLangStore((state) => state.currentLang);

  function handleChangePerfil(data) {
    const finalData = { userId: userData.id, ...data };
    updateProfile(finalData)
      .then((res) => {
        console.log(res);
        updateAuthData(res);
        setEditProfileSuccess(true);
        setTimeout(() => {
          setEditProfileSuccess(false);
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleEditPhoto(data) {
    const formData = new FormData();

    formData.append("userId", userData.id);
    formData.append("image", data.image, data.image.name);

    uploadPhoto(formData, {
      onSuccess: (res) => {
        updateAuthData(res);
        setEditPhotoSuccess(true);
        setTimeout(() => {
          setEditPhotoSuccess(false);
        }, 3000);
      },
      onError: (err) => console.log(err),
    });
  }

  useEffect(() => {
    console.log(editProfileSuccess);
    console.log(editPhotoSuccess);
  }, []);

  return (
    <Box
      p="20px"
      h="100%"
      bg="white"
      borderRadius="5px"
      boxShadow="0px 3px 6px #00000029"
    >
      <Box p="10px">
        <Text>Editar Perfil</Text>
      </Box>
      <Divider />
      <Box p="10px">
        <Row>
          <Col md="5">
            <Box p="20px">
              <Form
                initialValues={{
                  image: "",
                }}
                onSubmit={handleEditPhoto}
              >
                {(initialValue, values) => (
                  <Box>
                    <Flex>
                      <Box mr="15px" alignSelf="center">
                        <Avatar
                          size="xl"
                          name={userData.firstName}
                          src={profilePhoto !== "" ? profilePhoto : userData.imgUrl}
                        />
                      </Box>
                      <Box alignSelf="center">
                        <Box>
                          <Field
                            name="image"
                            component={FileUpload}
                            setProfilePhoto={setProfilePhoto}
                          />
                          <Text fontWeight="bold">Editar foto</Text>
                        </Box>
                      </Box>
                    </Flex>
                    <SuccessWarning
                      hidden={!editPhotoSuccess}
                      content="Se ha cambiado correctamente."
                    />
                    <Button
                      width="100%"
                      mt="10px"
                      isLoading={setUploadPhoto.isLoading}
                      type="submit"
                    >
                      Guardar
                    </Button>
                  </Box>
                )}
              </Form>
            </Box>
          </Col>
          <Col md="7">
            <Box p="20px" width="100%">
              <Form
                initialValues={{
                  firstName: userData.firstName ?? "",
                  lastName: userData.lastName ?? "",
                  type: userData.type ?? "",
                  description: userData.description ?? "",
                  caregiver: userData.careGiverCard ?? "",
                  phone: userData.phone ?? "",
                }}
                onSubmit={handleChangePerfil}
              >
                {({ setFieldValue, values }) => (
                  <Box>
                    <Box my="10px">
                      <Flex justifyContent="space-between">
                        <Input
                          name="firstName"
                          placeholder="Nombre"
                          value={values.firstName}
                          onChange={(e) =>
                            setFieldValue("firstName", e.target.value)
                          }
                          borderRadius="50px!important"
                        />
                        <Input
                          name="lastName"
                          placeholder="Apellido"
                          value={values.lastName}
                          onChange={(e) =>
                            setFieldValue("lastName", e.target.value)
                          }
                          borderRadius="50px!important"
                        />
                      </Flex>
                    </Box>

                    <Box my="10px">
                      <Input
                        required
                        className="place"
                        name="caregiver"
                        placeholder="Identificador de tarjeta de cuidador"
                        value={values.caregiver}
                        onChange={(e) =>
                          setFieldValue("caregiver", e.target.value)
                        }
                        borderRadius="50px!important"
                      />
                    </Box>
                    <Box
                      position="absolute"
                      right="7px"
                      top="89px"
                      ms="2"
                      display="flex"
                      alignSelf="center"
                      alignItems="center"
                      justifyContent="center"
                      cursor="pointer"
                      width="20px"
                      height="20px"
                      border="1px solid red"
                      bg="white"
                      borderRadius="20px"
                      onClick={onOpen}
                    >
                      <BsQuestion color="red" />
                    </Box>
                    <Box my="10px">
                      <Input
                        required
                        className="place"
                        name="phone"
                        placeholder="Teléfono"
                        value={values.phone}
                        onChange={(e) =>
                          setFieldValue("phone", e.target.value)
                        }
                        borderRadius="50px!important"
                      />
                    </Box>
                    <Box my="10px">
                      <Select
                        name="type"
                        defaultValue={values.type}
                        placeholder="Ocupación"
                        values={values.type}
                        onChange={(e) => setFieldValue("type", e.target.value)}
                        borderRadius="50px!important"
                      >
                        <option value="Trabajadora">
                          Trabajadora / Cuidadora
                        </option>
                        <option value="Profesional">Profesional</option>
                        <option value="Persona/Familia contratadora">
                          Persona/Familia contratadora
                        </option>
                        <option value="Entidad">Entidad</option>
                        <option value="Entidad">Otro</option>
                      </Select>
                    </Box>
                    <Box my="10px">
                      <Textarea
                        name="description"
                        placeholder="Descripción sobre ti"
                        minHeight="200px"
                        maxLength="300"
                        height="fit-content"
                        onChange={(e) =>
                          setFieldValue("description", e.target.value)
                        }
                        value={values.description}
                        borderRadius="5px!important"
                      />
                    </Box>
                    <SuccessWarning
                      hidden={!editProfileSuccess}
                      content="Los datos se han cambiado correctamente."
                    />

                    <Button
                      isLoading={updateProfileQuery.isLoading}
                      borderRadius="50px"
                      color="#fff"
                      bg="blue.1"
                      type="submit"
                    >
                      Guardar
                    </Button>
                  </Box>
                )}
              </Form>
            </Box>
          </Col>
        </Row>
      </Box>
      <Modal
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        size="md"
      >
        <ModalOverlay p="0">
          <ModalContent>
            <ModalHeader margin="15px 10px 0px 0px" padding="16px 24px 0 ">
              {langPopUp[currentLang].popupRegisterTitle}
            </ModalHeader>

            <ModalCloseButton borderRadius="20px" />

            <ModalBody>
              <Box
                color="gray"
                margin="0px 0px 5px 0px"
                padding="0px 24px 16px "
              >
                {langPopUp[currentLang].popupRegisterSubtitle}
              </Box>

              <Box marginBottom="4" textAlign="center">
                <Image
                  width="300px"
                  src={require("../../../assets/images/tarjetaCuidadora1.png")}
                  m="auto"
                />
              </Box>
              <Box
                color="gray"
                margin="0px 0px 0px 0px"
                padding="0px 24px 0px "
                textAlign="center"
              >
                {langPopUp[currentLang].popupRegisterBodyText}
              </Box>
              <Box marginBottom="4" textAlign="center">
                <b>
                  <Link
                    textAlign="center"
                    color="blue.600"
                    href="/tarjeta-cuidadora"
                  >
                    {langPopUp[currentLang].popupRegisterLink}
                  </Link>
                </b>
              </Box>
            </ModalBody>
            <ModalFooter>
              {/* <Button colorScheme="blue" mr={3} onClick={onClose}>
                          Cerrar
                        </Button> */}
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </Box>
  );
}

export default EditProfile;
