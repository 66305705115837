import {Avatar, Image} from "@chakra-ui/react";

function UserAvatar({userData, onClick = null, children})
{
    return (
        <div style={{position: 'relative', left: '0', top: '0'}}>
            <Avatar style={{position: 'relative', left: '0', top: '0'}} size="sm" name={userData?.firstName} src={userData?.imgUrl} onClick={onClick}/>
            {userData?.verifiedCheck && <Image style={{position: 'absolute', left: '22px', top: '22px'}} width="14px" src={require("../assets/images/verifiedCheck.png")} />}
            {children}
        </div>
    );
}

export default UserAvatar;