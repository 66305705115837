import React, { useState } from "react";
import {
  Box,
  Text,
  Divider,
  Flex,
  Input,
  Button,
} from "@chakra-ui/react";

import Form from "../../../components/ui/Form";
import { Row, Col } from "react-bootstrap";

import {
  useRecommendUsers,
} from "../../../hooks/queries/recommend";
import SuccessWarning from "../../../components/warnings/SuccessWarning";
import { useLangStore } from "../../../hooks/state/useLangStore";
import lang from "../../../assets/lang/recommend";

function Recommends({ userData }) {
  const [recommendUsers, recommendQuery] = useRecommendUsers();
  const [recommendSuccess, setRecommendSuccess] = useState(false);
  const currentLang = useLangStore((state) => state.currentLang);

  function handleRecommends(data) {
    const finalData = { userId: userData.id, ...data };
    recommendUsers(finalData)
      .then((res) => {
        console.log(res);
        setRecommendSuccess(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <Box
      p="20px"
      h="100%"
      bg="white"
      borderRadius="5px"
      boxShadow="0px 3px 6px #00000029"
    >
      <Box p="10px">
        <Text>{lang[currentLang].form.title}</Text>
      </Box>
      <Divider />
      <Box p="10px">
        <Row>
          <Col md="12">
            <Box p="20px" width="100%">
              {!recommendSuccess &&
              <Form
                initialValues={{
                  name1: "",
                  phone1: "",
                  email1: "",
                  name2: "",
                  phone2: "",
                  email2: "",
                }}
                onSubmit={handleRecommends}
              >
                {({ setFieldValue, values }) => (
                  <Box>
                    <Text>{lang[currentLang].form.text1}</Text>
                    <Divider mb="20px" />
                    <Box my="10px">
                      <Flex justifyContent="space-between">
                        <Input
                          required
                          name="name1"
                          placeholder={lang[currentLang].form.name}
                          value={values.name1}
                          onChange={(e) =>
                            setFieldValue("name1", e.target.value)
                          }
                          borderRadius="50px!important"
                        />
                        <Input
                          required
                          name="phone1"
                          placeholder={lang[currentLang].form.phone}
                          value={values.phone1}
                          onChange={(e) =>
                            setFieldValue("phone1", e.target.value)
                          }
                          borderRadius="50px!important"
                        />
                      </Flex>
                    </Box>
                    <Box my="10px">
                      <Input
                        required
                        className="place"
                        name="email1"
                        placeholder={lang[currentLang].form.email}
                        value={values.email1}
                        onChange={(e) =>
                          setFieldValue("email1", e.target.value)
                        }
                        borderRadius="50px!important"
                      />
                    </Box>

                    <Text mt="20px">{lang[currentLang].form.text2}</Text>
                    <Divider  mb="20px" />
                    <Box my="10px">
                      <Flex justifyContent="space-between">
                        <Input
                          name="name2"
                          placeholder={lang[currentLang].form.name}
                          value={values.name2}
                          onChange={(e) =>
                            setFieldValue("name2", e.target.value)
                          }
                          borderRadius="50px!important"
                        />
                        <Input
                          name="phone2"
                          placeholder={lang[currentLang].form.phone}
                          value={values.phone2}
                          onChange={(e) =>
                            setFieldValue("phone2", e.target.value)
                          }
                          borderRadius="50px!important"
                        />
                      </Flex>
                    </Box>
                    <Box my="10px">
                      <Input
                        className="place"
                        name="email2"
                        placeholder={lang[currentLang].form.email}
                        value={values.email2}
                        onChange={(e) =>
                          setFieldValue("email2", e.target.value)
                        }
                        borderRadius="50px!important"
                      />
                    </Box>

                    <Button
                      isLoading={recommendQuery.isLoading}
                      borderRadius="50px"
                      color="#fff"
                      bg="blue.1"
                      type="submit"
                      mt="10px"
                    >{lang[currentLang].form.send}</Button>
                  </Box>
                )}
              </Form>}
              <SuccessWarning
                hidden={!recommendSuccess}
                content={lang[currentLang].form.success}
              />
              {recommendSuccess &&
                < Button
                borderRadius="50px"
                color="#fff"
                bg="blue.1"
                onClick={() => setRecommendSuccess(false)}
                mt="10px"
                >{lang[currentLang].form.reset}</Button>
              }
            </Box>
          </Col>
        </Row>
      </Box>
    </Box>
  );
}

export default Recommends;
