const lang = {
  ca: {
    myQuestions: "Les meves preguntes",
    myAccount: "El meu perfil",
    myAnswers: "Les meves respostes",
    myComments: "Les meves comentaris",
    myNotifications: "Notificacions",
    editProfile: "Edita Perfil",
    settings: "Ajustaments",
    help: "Ajuda",
    closeSession: "Tancar Sesió",
    notifications: "Notificacions",
    checkOptions: "Marca les opcions que t'interessin",
    notificationsEmail: "Notifications via correu electronico:",
    notiQuestions: "Notificar-me les respostes a les meves preguntes",
    notiComments: "Notificar-me comentaris a les meves respostes",
    notiLikes:
      "Notificar-me M'agrada marcats en les teves preguntes, respostes o comentaris",
    notiMessages: "Notificar-me Missatges de xat",
    notiCommunityQuestions: "Notificar-me Noves preguntes en la comunitat",
    notiEvents: "Notificar-me Nous esdeveniments",
    recommends: "Recomana iCuida",
    resources: "Recursos iCuida",
  },
  es_ES: {
    myQuestions: "Mis preguntas",
    myAccount: "Mi perfil",
    myAnswers: "Mis respuestas",
    myComments: "Mis comentarios",
    myNotifications: "Notificaciones",
    editProfile: "Editar Perfil",
    settings: "Ajustes",
    help: "Ayuda",
    closeSession: "Cerrar Sesión",
    notifications: "Notificaciones",
    checkOptions: "Marca las opciones que te interesen",
    notificationsEmail: "Notificaciones via correo electrónico:",
    notiQuestions: " Notificarme las respuestas a mis preguntas",
    notiComments: " Notificarme comentarios a mis respuestas",
    notiLikes:
      "Notificarme Me gusta marcados en tus preguntas, respuestas o comentarios",
    notiMessages: "Notificarme Mensajes de chat",
    notiCommunityQuestions: "Notificarme Nuevas preguntas en la comunidad",
    notiEvents: "Notificarme Nuevos eventos",
    recommends: "Recomana iCuida",
    resources: "Recursos iCuida",
  },
};

export default lang;
