import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import { insert } from "formik";

function TextEditor({
  setContent,
  initialValue = "",
  defaultValue,
  initial,
  plugins = [
    "advlist autolink lists link image charmap print preview anchor",
    "searchreplace visualblocks code fullscreen",
    "insertdatetime media table paste code help wordcount",
    "link",
  ],
  toolbar = "bold italic backcolor | | link image media | \
    alignleft aligncenter alignright alignjustify | \
    bullist numlist outdent indent | removeformat | link",
}) {
  function handleEditorChange(content, editor) {
    setContent(content);
  }

  return (
    <Editor
      apiKey="18xykigy7jn0kh6crhvoosfzjf2ilvo7ypmxmhgmarngyskm"
      // initialValue={defaultValue}
      value={defaultValue}
      init={{
        min_height: 500,
        width: "100%",
        language: "es",
        language_url: "/tinymce-lang/es.js",
        menubar: false,
        statusbar: false,
        plugins: plugins,
        toolbar: toolbar,
      }}
      defaultValue="abc"
      onEditorChange={handleEditorChange}
    />
  );
}

export default TextEditor;
