import React from 'react';
import {Box, Button} from '@chakra-ui/react'
import TextEditor from '../ui/TextEditor'

function UpdateTextEditor({setContent, initialValue, content, clear, submit}) {
    return(
        <Box>
            <TextEditor plugins="" toolbar="" setContent={setContent} initialValue={initialValue}/>
            <Box my="10px">
                <Button onClick={() => clear()}>Cancelar</Button>
                <Button ml="3px" bg="blue.1" color="white" disabled={!content} onClick={() => {submit()}}>Guardar</Button>
            </Box>
        </Box>
    )
}

export default UpdateTextEditor;