import React from "react";
import { Box, Text, Image, Button } from "@chakra-ui/react";
import { useLangStore } from "../hooks/state/useLangStore";
import Timeline from "../components/Timeline";

function SobreIcuida() {
  const currentLang = useLangStore((state) => state.currentLang);

  return (
    <Box>
      {currentLang === "es_ES" && (
        <Box pt="20px" bg="#f1fcff">
          <Box maxW="800px" m="auto">
            <Box borderRadius="5px" p="20px">
              <Box>
                <Box>
                  <Box mx="auto" width="fit-content">
                    <Image
                      width="100px"
                      src={require("../assets/images/logos/logo-azul-orange-v2020.png")}
                    />
                  </Box>
                </Box>
                <Box py="32px">
                  <Text fontSize="32px" fontWeight="100" textAlign="center">
                    ¿Por qué existe iCuida?
                  </Text>
                </Box>
                <Box py="10px" fontSize="17px">
                  <Text>
                    La misión de iCuida es compartir información de manera
                    colaborativa por y para las trabajadoras del hogar y del
                    cuidado de personas de Barcelona. Un espacio para encontrar
                    respuestas, realizar preguntas, responder y comentar.
                    Queremos ayudar a crear una comunidad donde compartir
                    conocimiento y aprender unas de otras.
                  </Text>
                </Box>
                <Box width="fit-content" mx="auto">
                  <a
                    target="_blank"
                    href={require("../assets/files/Folleto1.pdf")}
                    rel="noreferrer"
                  >
                    <Button
                      width="100px"
                      bg="orange.1"
                      borderRadius="20px"
                      color="white"
                    >
                      Díptico
                    </Button>
                  </a>
                </Box>
              </Box>
              <Box>
                <Box py="32px">
                  <Text fontSize="32px" fontWeight="100" textAlign="center">
                    Pregunta, responde, conecta, ayuda a otras y empodérate.
                  </Text>
                </Box>
                <Box py="10px" fontSize="17px">
                  <Text mb="20px">
                    La esencia de iCuida son{" "}
                    <Text as="span" fontWeight="bold">
                      las preguntas
                    </Text>{" "}
                    que pueden afectar a las trabajadoras del hogar y
                    cuidadoras, preguntas sobre trámites básicos al llegar a la
                    ciudad de Barcelona, sobre empleo, asesoría, grupos de
                    apoyo, derechos laborales, formación, cuidado propio y de
                    terceros, entre otros.
                  </Text>
                  <Text mb="20px">
                    iCuida{" "}
                    <Text as="span" fontWeight="bold">
                      es un lugar donde hacer preguntas y obtener respuestas.
                    </Text>{" "}
                    Pero también, es un espacio para conectar e interactuar con
                    otras usuarias/os.
                  </Text>
                  <Text mb="20px">
                    Queremos poner en contacto a trabajadoras del hogar,
                    cuidadoras, personas o familias contratadoras, profesionales
                    del sector y equipo técnico de Barcelona Cuida, para que
                    puedan dialogar y co-crear información de valor e interés
                    para este colectivo.
                  </Text>
                  <Text mb="20px">
                    <Text as="span">
                      Las respuestas de iCuida proceden de usuarias o usuarios
                      como tú, y/o de
                      <a
                        target="_blank"
                        href="https://www.barcelona.cat/ciutatcuidadora/es/espacio-barcelona-cuida/que-es"
                        rel="noreferrer"
                      >
                        Barcelona Cuida
                      </a>
                      , el perfil oficial de nuestra aplicación web.
                    </Text>
                  </Text>
                  <Text mb="20px">
                    iCuida junta a personas para que colaboren entre sí.
                    Queremos que iCuida sea el lugar donde las trabajadoras y
                    cuidadoras expresen su opinión y desarrollen debates.
                    Esperamos que la respuesta de iCuida, sea{" "}
                    <Text as="span" fontWeight="bold">
                      la respuesta para empoderar y aprender de manera
                      colectiva.
                    </Text>
                  </Text>
                </Box>
              </Box>
              <Box>
                <Box py="32px">
                  <Text fontSize="32px" fontWeight="100" textAlign="center">
                    Un perfil oficial que puede responder tus dudas.
                  </Text>
                </Box>
                <Box py="10px" fontSize="17px">
                  <Text mb="20px">
                    iCuida posee un{" "}
                    <Text as="span" fontWeight="bold">
                      perfil oficial
                    </Text>{" "}
                    “
                    <Text as="ins" fontWeight="bold">
                      <a
                        target="_blank"
                        href="https://www.barcelona.cat/ciutatcuidadora/es/espacio-barcelona-cuida/que-es"
                        rel="noreferrer"
                      >
                        Barcelona Cuida
                      </a>
                    </Text>
                    ”, su equipo técnico colabora con nuestra aplicación web
                    para responder preguntas de las usuarias y usuarios.
                  </Text>
                  <Text mb="20px">
                    Las respuestas de Barcelona Cuida proceden de{" "}
                    <Text as="span" fontWeight="bold">
                      expertos y personas con conocimiento contrastado y
                      actualizado.
                    </Text>{" "}
                    Barcelona Cuida podrá informarte y orientarte sobre los
                    recursos, programas y equipamientos que tiene Barcelona
                    dentro del ámbito de los cuidados. Podrá asesorarte sobre
                    empleabilidad y búsqueda de empleo, cuestiones básicas sobre
                    derecho laboral, seguridad social y extranjería.
                  </Text>
                  <Text mb="20px">
                    El perfil de Barcelona Cuida está especialmente pensado para
                    dar respuesta a las preguntas de la Comunidad iCuida.
                  </Text>
                  <Text mb="20px">¡Gracias por utilizar iCuida!</Text>
                </Box>
              </Box>
            </Box>
            <Timeline />
          </Box>
        </Box>
      )}
      {currentLang === "ca" && (
        <Box pt="20px" bg="#f1fcff">
          <Box maxW="800px" m="auto">
            <Box borderRadius="5px" p="20px">
              <Box>
                <Box>
                  <Box mx="auto" width="fit-content">
                    <Image
                      width="100px"
                      src={require("../assets/images/logos/logo-azul-orange-v2020.png")}
                    />
                  </Box>
                </Box>
                <Box py="32px">
                  <Text fontSize="32px" fontWeight="100" textAlign="center">
                    Per què existeix iCuida?
                  </Text>
                </Box>
                <Box py="10px" fontSize="17px">
                  <Text>
                    La missió de iCuida és compartir informació de manera
                    col·laborativa per i per a les treballadores de la llar i de
                    la cura de persones de Barcelona. Un espai per trobar
                    respostes, fer preguntes, respondre i comentar. Volem ajudar
                    a crear una comunitat on compartir coneixement i aprendre
                    unes de les altres.
                  </Text>
                </Box>
                <Box width="fit-content" mx="auto">
                  <a
                    target="_blank"
                    href={require("../assets/files/Folleto1.pdf")}
                    rel="noreferrer"
                  >
                    <Button
                      width="100px"
                      bg="orange.1"
                      borderRadius="20px"
                      color="white"
                    >
                      Díptico
                    </Button>
                  </a>
                </Box>
              </Box>
              <Box>
                <Box py="32px">
                  <Text fontSize="32px" fontWeight="100" textAlign="center">
                    Pregunta, respon, connecta, ajuda a altres i apoderat.
                  </Text>
                </Box>
                <Box py="10px" fontSize="17px">
                  <Text mb="20px">
                    L'essència de iCuida són{" "}
                    <Text as="span" fontWeight="bold">
                      les preguntes
                    </Text>{" "}
                    que poden afectar les treballadores de la llar i cuidadores,
                    preguntes sobre tràmits bàsics a l'arribar a la ciutat de
                    Barcelona, ​​sobre ocupació, assessoria, grups de suport,
                    drets laborals, formació, cura propi i de tercers, entre
                    d'altres.
                  </Text>
                  <Text mb="20px">
                    iCuida és{" "}
                    <Text as="span" fontWeight="bold">
                      un lloc on fer preguntes i obtenir respostes.
                    </Text>{" "}
                    Però també, és un espai per connectar i interactuar amb
                    altres usuàries.
                  </Text>
                  <Text mb="20px">
                    Volem posar en contacte a treballadores de la llar,
                    cuidadores, persones o famílies contractadores,
                    professionals de sector i equip tècnic de Barcelona Cuida,
                    perquè puguin dialogar i co-crear informació de valor i
                    interès per a aquest col·lectiu.
                  </Text>
                  <Text mb="20px">
                    <Text as="span" fontWeight="bold">
                      Les respostes de iCuida procedeixen d'usuàries com tu i /
                      o de
                    </Text>
                    <a
                      target="_blank"
                      href="https://www.barcelona.cat/ciutatcuidadora/es/espacio-barcelona-cuida/que-es"
                      rel="noreferrer"
                    >
                      Barcelona Cuida
                    </a>
                    , qui posseeix un perfil d'usuari oficial a la nostra
                    aplicació web disposat a respondre les preguntes de les
                    usuàries i usuaris.
                  </Text>
                  <Text mb="20px">
                    iCuida junta a persones perquè col·laborin entre si. Volem
                    que iCuida sigui el lloc on les treballadores i cuidadores
                    expressin la seva opinió i desenvolupin debats. Esperem que
                    la resposta de iCuida, sigui{" "}
                    <Text as="span" fontWeight="bold">
                      la resposta per apoderar i aprendre de manera col·lectiva.
                    </Text>
                  </Text>
                </Box>
              </Box>
              <Box>
                <Box py="32px">
                  <Text fontSize="32px" fontWeight="100" textAlign="center">
                    Un perfil oficial que pot respondre els teus dubtes.
                  </Text>
                </Box>
                <Box py="10px" fontSize="17px">
                  <Text mb="20px">
                    iCuida posseeix un{" "}
                    <Text as="span" fontWeight="bold">
                      perfil oficial:
                    </Text>{" "}
                    “
                    <Text as="ins" fontWeight="bold">
                      <a
                        target="_blank"
                        href="https://www.barcelona.cat/ciutatcuidadora/es/espacio-barcelona-cuida/que-es"
                        rel="noreferrer"
                      >
                        Barcelona Cuida
                      </a>
                    </Text>
                    ”, el seu equip tècnic col·labora amb la nostra aplicació
                    web per a respondre preguntes de les usuàries i usuaris.
                  </Text>
                  <Text mb="20px">
                    Les respostes de Barcelona Cuida procedeixen{" "}
                    <Text as="span" fontWeight="bold">
                      d'experts i persones amb coneixement contrastat i
                      actualitzat.
                    </Text>{" "}
                    Barcelona Cuida podrà informar-te i orientar-te sobre els
                    recursos, programes i equipaments que té Barcelona dins de
                    l'àmbit de les cures. Podrà assessorar-te sobre ocupabilitat
                    i recerca de feina, qüestions bàsiques sobre dret laboral,
                    seguretat social i estrangeria.
                  </Text>
                  <Text mb="20px">
                    El perfil de Barcelona Cuida està especialment pensat per
                    donar resposta a les preguntes de la Comunitat iCuida.
                  </Text>
                  <Text mb="20px">Gràcies per utilitzar iCuida!</Text>
                </Box>
              </Box>
            </Box>
            <Timeline />
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default SobreIcuida;
