import { combineReducers } from "redux";
import { NotificationReducer } from "./notify";
import { ProcessReducer } from "./process";
// define the object and call the action
const rootReducers = combineReducers({
  message: ProcessReducer,
  notification: NotificationReducer,
});
// else return default root reducer
export default rootReducers;
