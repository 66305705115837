const lang = {
    "es_ES": {
        team: "Equipo técnico de iCuida",
        mariseladesc: "Mentora y Gestora de proyecto / Elaboración y sistematización de la información.",
        vincent: "Desarrollador Full Stack.",
        conchita: "Diseñadora gráfica y Diseñadora UX-UI",
        collaborators: "Colaboradora externa:",
        diana: "Ganadora 2da Hackaton Dades x dones y Consultora UX-UI.",
        proyectede: "Un proyecto de:",
        collaborationof: "Con la colaboración de:"
    },
    "ca": {
        team: "Equip tècnic de iCuida",
        mariseladesc: "Mentora i Gestora de projecte / Elaboració i sistematització de la informació.",
        vincent: "Desenvolupador Full Stack.",
        conchita: "Dissenyadora gràfica i Dissenyadora UX-*UI",
        collaborators: "Col·laboradora externa:",
        diana: "Guanyadora 2da Hackaton Dades x dones i Consultora UX-*UI.",
        proyectede: "Un projecte de:",
        collaborationof: "Amb la col·laboració de:"
    }
}

export default lang;