import create from 'zustand';
import Cookies from 'js-cookie'

export const useLangStore = create(set => ({
    currentLang: localStorage.getItem('lang') ?? 'es_ES',
    setEsp: e => {
        localStorage.setItem('lang','es_ES')
        set({currentLang: 'es_ES'})
    },
    setCat: e => {
        localStorage.setItem('lang', 'ca')
        set({currentLang: 'ca'})
    }
}))