import React from 'react';
import {Box, Input} from '@chakra-ui/react';


function FileUpload(props) {
  const {field, form, setProfilePhoto} = props;

  const handleChange = (e) => {
    const file  =  e.currentTarget.files[0];
    const reader = new FileReader();
    const imgTag = document.getElementById("myimage");
    imgTag.title = file.name;
    reader.onload = function(event) {
      imgTag.src = event.target.result;
      setProfilePhoto(event.target.result)
    };
    reader.readAsDataURL(file);
    form.setFieldValue(field.name, file);
  };

  return (
    <Box opacity="0" position="absolute">
      <input type={'file'} onChange={(o) => handleChange(o)} className={'form-control'}/>
        <img src={''} alt="" id={'myimage'}/>
    </Box>
  );
}

export default FileUpload;