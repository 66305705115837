import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Box, Button, Checkbox, Text } from "@chakra-ui/react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import {
  useGetAllUsers,
  useUserBlock,
  useUserUnblock,
} from "../../../../../hooks/queries/usuarios";
import moment from "moment";
import "moment/locale/es";
import { AiFillEye } from "react-icons/ai";
import { ImBlocked } from "react-icons/im";
import { Table } from "react-bootstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

function List() {
  const { isLoading, data, refetch } = useGetAllUsers();
  const [userBlock, userBlockQuery] = useUserBlock();
  const [userUnblock, userUnblockQuery] = useUserUnblock();
  const [rowData, setRowData] = useState([]);
  const history = useHistory();

  useEffect(() => {
    if (!isLoading) {
      setRowData(data);
    }
  });

  function formatDate(date) {
    const databaseDate = new Date(date);

    return moment(databaseDate).format("L");
  }

  function setUserBlock(id) {
    userBlock({ userId: id })
      .then((res) => {
        refetch();
      })
      .catch((err) => console.log(err));
  }

  function setUserUnblock(id) {
    userUnblock({ userId: id })
      .then((res) => {
        refetch();
      })
      .catch((err) => console.log(err));
  }

  return (
    <Box bg="white" overflowX="auto">
      {!isLoading && (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th></th>
              <th>#</th>
              <th>Nombre</th>
              <th>Apellido</th>
              <th>Email</th>
              <th>Creado</th>
              <th>Ver</th>
              <th>Deshabilitar</th>
            </tr>
          </thead>
          <tbody>
            {rowData.map((value) => (
              <tr>
                <td>
                  <Box width="fit-content">
                    <Checkbox />
                  </Box>
                </td>
                <td>{value.id}</td>
                <td>{value.firstName}</td>
                <td>{value.lastName}</td>
                <td>{value.email}</td>
                <td>{formatDate(value.createdAt)}</td>
                <td>
                  <Box
                    cursor="pointer"
                    _hover={{ color: "blue" }}
                    width="fit-content"
                    mx="auto"
                    onClick={() => {
                      history.push("/perfil-usuario/" + value.id);
                    }}
                  >
                    <a>
                      <AiFillEye fontSize="20px" />
                    </a>
                  </Box>
                </td>
                <td>
                  <Box cursor="pointer" width="fit-content" mx="auto">
                    {value.isBlocked ? (
                      <a>
                        <ImBlocked
                          color="blue"
                          fontSize="20px"
                          onClick={(e) => setUserUnblock(value.id)}
                        />
                      </a>
                    ) : (
                      <a>
                        <ImBlocked
                          color="red"
                          fontSize="20px"
                          onClick={(e) => setUserBlock(value.id)}
                        />
                      </a>
                    )}
                  </Box>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Box>
  );
}

export default List;
