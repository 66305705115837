import React, {useState} from 'react';
import {Box, Image, Skeleton} from '@chakra-ui/react'

function StoryBoardCard({data}) {

    const [imageMounted, setImageMounted] = useState(false)

    return(
        <Box>
            { data &&
            <React.Fragment>
                <Box maxW="376px" mx="auto">
                    <Box width="fit-content" mx="auto" bg="white">
                        <Image onLoad={e => setImageMounted(true)} src={require('../../assets/images/storyboard/'+data.img)}/>
                        <Skeleton hidden={imageMounted} height="432px" width="376px"/>
                    </Box>
                    <Box textAlign="center" bg="white" maxW="376px" height="200px" mx="auto" p="20px" pb="0">
                        <Box  fontSize="xl" mb="20px" height={!data.description ? '100%' : 'initial'}>
                            {data.title}
                        </Box>
                        <Box  fontSize="xs" display={!data.description ? 'none': 'block'}>
                            {data.description}
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
            }
        </Box>
    )
}

export default StoryBoardCard;