import React, { useState } from "react";
import { Box, Text, Select, Input, Textarea, Button } from "@chakra-ui/react";
import Form from "../components/ui/Form";
import { useContactUs } from "../hooks/queries/contact";
import { useLangStore } from "../hooks/state/useLangStore";
import TextEditor from "../components/ui/TextEditor";
import SuccessWarning from "../components/warnings/SuccessWarning";
import lang from "../assets/lang/contact";
import { useAuthStore } from "../hooks/state/useAuthStore";

function Contact() {
  const [contactUs, contactUsQuery] = useContactUs();
  const [RespuestaValue, setRespuestaValue] = useState("");
  const [sentSuccess, setSentSuccess] = useState(false);
  const currentLang = useLangStore((state) => state.currentLang);
  const userData = useAuthStore((state) => state.data);

  function handleSubmit(formData) {
    formData.description = RespuestaValue;
    contactUs(formData, {
      onSuccess: (res) => {
        setSentSuccess(true);
        setTimeout(() => {
          setSentSuccess(false);
        }, 3000);
        console.log("sent");
      },
      onError: (err) => console.log(err),
    });
  }

  return (
    <Box>
      {userData && (
        <Box
          bg="#fff"
          p="20px"
          borderRadius="5px"
          mt="20px"
          width="100%"
          margin="auto"
        >
          <Box mb="20px">
            <Text fontWeight="bold" fontSize="20px">
              {lang[currentLang].send}
            </Text>
          </Box>
          <Form
            initialValues={{
              tema: "",
              email: "",
              asunto: "",
            }}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, values }) => (
              <Box>
                <Box mb="15px">
                  <label>{lang[currentLang].howcanwehelp}</label>
                  <Select
                    name="tema"
                    value={values.tema}
                    onChange={(e) => setFieldValue("tema", e.target.value)}
                  >
                    <option value="Necesito ayuda con mi cuenta">
                      {lang[currentLang].needhelp}
                    </option>
                    <option value="Tengo una consulta de seguridad">
                      {lang[currentLang].question}
                    </option>
                    <option value="Opinión general">
                      {lang[currentLang].opinion}
                    </option>
                    <option value="Quiero eliminar mi cuenta y mis datos">
                      {lang[currentLang].searchUsers}
                    </option>
                  </Select>
                </Box>
                <Box mb="15px">
                  <label>{lang[currentLang].email} *</label>
                  <Input
                    name="email"
                    type="email"
                    value={values.email}
                    onChange={(e) => setFieldValue("email", e.target.value)}
                  />
                </Box>
                <Box mb="15px">
                  <label>{lang[currentLang].about} *</label>
                  <Input
                    name="asunto"
                    value={values.asunto}
                    onChange={(e) => setFieldValue("asunto", e.target.value)}
                  />
                </Box>
                <Box mb="15px">
                  <label>{lang[currentLang].description} *</label>
                  <TextEditor setContent={setRespuestaValue} />
                </Box>
                <Button
                  isLoading={contactUsQuery.isLoading}
                  bg="blue.5"
                  type="submit"
                  color="#fff"
                >
                  Enviar
                </Button>
                <Box pt="20px">
                  <SuccessWarning
                    hidden={!sentSuccess}
                    content="Se ha enviado."
                  />
                </Box>
              </Box>
            )}
          </Form>
        </Box>
      )}

      {!userData && (
        <Box
          bg="#fff"
          p="20px"
          borderRadius="5px"
          mt="20px"
          width="60%"
          margin="50px auto"
        >
          <Box mb="20px" margin="auto">
            <Text fontWeight="bold" fontSize="20px">
              {lang[currentLang].send}
            </Text>
          </Box>
          <Form
            initialValues={{
              tema: "",
              email: "",
              asunto: "",
            }}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, values }) => (
              <Box>
                <Box mb="15px">
                  <label>{lang[currentLang].howcanwehelp}</label>
                  <Select
                    name="tema"
                    value={values.tema}
                    onChange={(e) => setFieldValue("tema", e.target.value)}
                  >
                    <option value="Necesito ayuda con mi cuenta">
                      {lang[currentLang].needhelp}
                    </option>
                    <option value="Tengo una consulta de seguridad">
                      {lang[currentLang].question}
                    </option>
                    <option value="Opinión general">
                      {lang[currentLang].opinion}
                    </option>
                    <option value="Quiero eliminar mi cuenta y mis datos">
                      {lang[currentLang].deleteaccount}
                    </option>
                  </Select>
                </Box>
                <Box mb="15px">
                  <label>{lang[currentLang].email} *</label>
                  <Input
                    name="email"
                    type="email"
                    value={values.email}
                    onChange={(e) => setFieldValue("email", e.target.value)}
                  />
                </Box>
                <Box mb="15px">
                  <label>{lang[currentLang].about} *</label>
                  <Input
                    name="asunto"
                    value={values.asunto}
                    onChange={(e) => setFieldValue("asunto", e.target.value)}
                  />
                </Box>
                <Box mb="15px">
                  <label>{lang[currentLang].description} *</label>
                  <TextEditor setContent={setRespuestaValue} />
                </Box>
                <Button
                  isLoading={contactUsQuery.isLoading}
                  bg="blue.5"
                  type="submit"
                  color="#fff"
                >
                  Enviar
                </Button>
                <Box pt="20px">
                  <SuccessWarning
                    hidden={!sentSuccess}
                    content="Se ha enviado."
                  />
                </Box>
              </Box>
            )}
          </Form>
        </Box>
      )}
    </Box>
  );
}

export default Contact;
