import React, {Component} from 'react';
import {Box, Flex, Image, Spinner} from '@chakra-ui/react'
import '../assets/css/Loading.css';

export default class Loading extends Component {
    render() {
        return(
             <Box height="100vh"> 
                <Flex height="100%">
                    <Box alignSelf="center" mx="auto" role="loading">
                        <Box mx="auto" width="fit-content">
                                <Spinner 
                                thickness="4px"
                                emptyColor="gray.200"
                                speed="0.6s"
                                color="orange.1"
                                size="lg"
                                />
                        </Box>
                    </Box>
                </Flex>
             </Box>
               )
            }
        }                      
