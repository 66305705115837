import React from 'react'
import {Text, Box} from '@chakra-ui/react'


function SuccessWarning({content, hidden}) {
    return(
        <Box hidden={hidden} mb="5px" p="10px" borderRadius="20px" border="1px solid" borderColor="green.300">
            <Text color="green.300">{content}</Text>
        </Box>
    )
}

export default SuccessWarning;