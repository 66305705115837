import create from 'zustand';
import jwt_decode from 'jwt-decode';

export const usePassRecoveryStore = create(set => ({
    recoveryToken: localStorage.recoverytoken ?? undefined,
    data: localStorage.recoverytoken ? jwt_decode(localStorage.recoverytoken): undefined,
    setData: (token) => {
       set(state => ({data: jwt_decode(token)}))
    },
    logOut: () => {
        window.localStorage.clear();
        set(state => ({data: null, recoveryToken: null}))
        window.location.href = '/';
    }
}))