import { useQuery, useMutation} from "react-query";
import {questionsCategory, question, getAllQuestions, 
    getUserQuestions, getAnswerQuestion, getToAnswers,
    questionLike, questionDislike, questionSearchQuery, questionDelete
} from "../../api/QuestionFunctions";

export function usePreguntasComunidad({page, category} = {}, queryConfig = {}) {
    return useQuery(['GET_PREGUNTAS_COMUNIDAD'], () => questionsCategory({page, category}), queryConfig);
}

export function useGetAllQuestions(queryConfig = {}) {
    return useQuery(['GET_TODAS_PREGUNTAS_COMUNIDAD'], () => getAllQuestions(), queryConfig);
}

export function useQuestionsToAnswer({page} = {}, queryConfig = {}) {
    return useQuery(['GET_PREGUNTAS_PARA_RESPONDER'], () => getToAnswers({page}), queryConfig);
}

export function useUserQuestions({userId} = {}, queryConfig = {}) {
    return useQuery(['GET_PREGUNTAS_USUARIO'], () => getUserQuestions(userId), queryConfig)
}

export function useAnswerQuestion({questionId} = {}, queryConfig = {}) {
    return useQuery(['GET_PREGUNTA_DE_RESPUESTA'], () => getAnswerQuestion(questionId), queryConfig)
}

export function useQuestion({id} = {}, queryConfig = {}) {
    return useQuery(['GET_PREGUNTA_COMUNIDAD'], () => question(id), queryConfig)
}

export function useSearchQuestion(formData, queryConfig = {}) {
    return useQuery(['GET_PREGUNTA_BUSCADA'], () => questionSearchQuery(formData), queryConfig)
}

export function useQuestionLike(queryConfig = {}) {
    return useMutation(questionLike, queryConfig)
}

export function useQuestionDislike(queryConfig = {}) {
    return useMutation(questionDislike, queryConfig)
}

export function useQuestionDelete(queryConfig = {}) {
    return useMutation(questionDelete, queryConfig)
}