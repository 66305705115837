import React from 'react'
import {useDisclosure, Box, Button, Text, Flex} from '@chakra-ui/react'
import {Slide, CloseButton} from '@chakra-ui/react'
import { useState } from 'react'
import CarouselButton from '../components/CarouselButton'
import Cookies from 'js-cookie'
import { useEffect } from 'react'

function SlideUp() {
    const { isOpen, onToggle } = useDisclosure()
    const [isShown, setIsShown] = useState(true);

    useEffect(() => {
        setIsShown(localStorage.getItem('tutorialShown') === 'false' ? false: true)
    }, [])

    function hideTutorial() {
        localStorage.setItem('tutorialShown', false)
        setIsShown(false)
    }

    return (
      <>
        <Slide direction="bottom" in={isShown} style={{ zIndex: 10 }}>
          <Box
            p="40px"
            color="white"
            mt="4"
            bg="blue.2"
            rounded="md"
            shadow="md"
          >
            <Box>

                <Flex width="fit-content" mx="auto">
                    <Box cursor="pointer" onClick={() => {hideTutorial()}} position="absolute" right="15px" top="30px">
                        <CloseButton size="lg"/>
                    </Box>
                    <Box width="fit-content" alignSelf="center">
                        <Text>¿Quieres conocer como funciona iCuida? Consulta nuestro </Text>
                    </Box>
                    <Box ml="5px" width="fit-content" alignSelf="center">
                        <CarouselButton/>
                    </Box>
                </Flex>
            </Box>
          </Box>
        </Slide>
      </>
    )
  }

  export default SlideUp