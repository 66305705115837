import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Flex,
  Input,
  Button,
  Text,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  ModalBody,
  useDisclosure,
} from "@chakra-ui/react";
import { Avatar } from "@chakra-ui/react";
import { useAuthStore } from "../../../hooks/state/useAuthStore";
import {
  useGetConversationReply,
  useCreateConversationReply,
} from "../../../hooks/queries/conversation-reply";
import Loading from "../../Loading";
import Form from "../../../components/ui/Form";
import "moment/locale/es";
import { FaArrowLeft } from "react-icons/fa";
import ChatWindow from "./ChatWindow";
// import socket from '../../../api/socket'
import { AttachmentIcon } from "@chakra-ui/icons";
import { sendMessage } from "../../../api/socket";
import { getConversationReply } from "../../../api/conversation-reply";
import { useDispatch, useSelector } from "react-redux";
import { UploadFiles } from "../../../api/conversation";
import UserAvatar from "../../UserAvatar";

function ChatMain({
  setSendUser,
  setSelectedRoom,
  selectedRoom,
  message,
  sendUser,
}) {
  const userData = useAuthStore((state) => state.data);
  const { data, isLoading, refetch } = useGetConversationReply({
    conversationId: selectedRoom?.id,
  });
  const [createConversationReply, conversationReplyQuery] =
    useCreateConversationReply();
  let receiveMsg = useSelector((state) => state?.message);
  const [contact, setContact] = useState();
  const [chat, setChat] = useState([]);
  const inputRef = useRef();
  const [selectedFile, setSelectedFile] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [image, setImage] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const finalRef = React.useRef();
  const regFileType = /\.(pdf|docx|xlsx|pptx|txt|zip|mp4)$/;

  function handleFileInput(e) {
    console.log(e.target.files[0].size);
    if (e.target.files[0].size > 5e6) {
      window.alert("Por favor sube un fichero menor a 5mb");
      return false;
    }

    setImage(e.target.files[0]);
    setImageUrl(URL.createObjectURL(e?.target?.files[0]));
  }

  async function handleUploadImage() {
    let receiverId =
      userData.id !== selectedRoom?.UserOne?.id
        ? selectedRoom?.UserOne.id
        : selectedRoom?.UserTwo.id;

    // console.log(image.size);
    const url = await UploadFiles(
      image,
      userData.id,
      selectedRoom?.id,
      receiverId
    );

    if (url) {
      // emitMessage(url?.location);
      setSelectedFile("");
      onClose();
      setImageUrl("");
      if (selectedRoom && selectedRoom?.id) {
        new Promise(async (resolve, reject) => {
          const response = await getConversationReply(selectedRoom?.id);
          resolve(response);
          setChat(response);
        });
      }
    }
  }

  useEffect(() => {
    if (selectedRoom && selectedRoom?.id) {
      new Promise(async (resolve, reject) => {
        const response = await getConversationReply(selectedRoom?.id);
        resolve(response);
        setChat(response);
      });
    }
  }, [selectedRoom]);

  function addMessage(newMessage) {
    setChat((currentData) => [...currentData, newMessage]);
  }

  function newConversationReply(newReply) {
    createConversationReply(newReply);
  }

  function emitMessage(formData) {
    // socket.emit('send-message', {
    //     reply: formData.message,
    //     userId: userData.id,
    //     conversationId: selectedRoom.id,
    //     receipt: userData.id !== selectedRoom.UserOne.id ?  selectedRoom.UserOne.id : selectedRoom.UserTwo.id
    // })

    sendMessage("send-message", {
      payload: {
        reply: !image ? formData.message : formData,
        userId: userData.id,
        conversationId: selectedRoom?.id,
        receipt:
          userData.id !== selectedRoom?.UserOne?.id
            ? selectedRoom?.UserOne.id
            : selectedRoom?.UserTwo.id,
        receiverId:
          userData.id !== selectedRoom?.UserOne?.id
            ? selectedRoom?.UserOne.id
            : selectedRoom?.UserTwo.id,
      },
    });

    setSendUser({
      reply: !image ? formData.message : formData,
      userId: userData.id,
      conversationId: selectedRoom?.id,
    });
    // socket.emit('send-notification', {
    //     reply: formData.message,
    //     userId: userData.id,
    //     conversationId: selectedRoom.id,
    //     to: contact.id
    // })
    addMessage({
      reply: !image ? formData.message : formData,
      userId: userData.id,
      conversationId: selectedRoom?.id,
    });
    //  newConversationReply({
    //    reply: formData.message,
    //    userId: userData.id,
    //    conversationId: selectedRoom.id,
    //  })
  }

  useEffect(() => {
    setContact(
      userData.id !== selectedRoom?.UserOne?.id
        ? selectedRoom.UserOne
        : selectedRoom.UserTwo
    );
  }, [selectedRoom, userData]);

  useEffect(() => {
    if (
      receiveMsg &&
      receiveMsg?.createdAt &&
      receiveMsg?.userId !== userData?.id &&
      receiveMsg?.conversationId === selectedRoom?.id
    ) {
      addMessage({
        reply: receiveMsg.reply,
        userId: receiveMsg.userId,
        conversationId: receiveMsg.conversationId,
      });
    }
  }, [receiveMsg?.createdAt]);

  // useEffect(() => {
  //     refetch().then(res => {
  //         setChat(res)
  //     })
  // },[])

  const handleImageShare = () => {
    inputRef.current?.click();
  };

  return (
    <Box>
      <>
        <Flex borderBottom="1px solid gray" p="5px">
          <Box
            alignSelf="center"
            onClick={(e) => setSelectedRoom({})}
            mr="10px"
          >
            <FaArrowLeft color="gray" fontSize="20px" />
          </Box>
          <Flex alignSelf="center">
            <UserAvatar userData={contact} />
          </Flex>
          <Box ml="10px">
            <Text fontSize="xl">
              {contact?.firstName} {contact?.lastName}
            </Text>
            <Text fontSize="md">{contact?.type}</Text>
          </Box>
        </Flex>
        {!isLoading ? (
          <ChatWindow chat={chat} userData={userData} contact={contact} />
        ) : (
          <Loading />
        )}
        <Box p="10px">
          <Divider mb="10px" />
          <Form
            initialValues={{
              message: "",
            }}
            onSubmit={(values, actions) => {
              if (values.message !== "") {
                emitMessage(values);
                actions.resetForm({
                  values: {
                    message: "",
                  },
                });
              }
            }}
          >
            {({ setFieldValue, values }) => (
              <Flex borderRadius="5px" overflow="auto">
                <Box width="100%" pr="20px">
                  <Box
                    cursor="pointer"
                    position="relative"
                    top="29px"
                    right="25px"
                    style={{ zIndex: "9999" }}
                  >
                    <AttachmentIcon
                      cursor="pointer"
                      float="right"
                      onClick={onOpen}
                    />
                  </Box>
                  <Input
                    width="100%"
                    p="20px"
                    name="message"
                    borderRadius="30px"
                    value={values?.message}
                    onChange={(e) => {
                      setFieldValue("message", e.target.value);
                    }}
                  />
                </Box>
                <Button
                  position="relative"
                  top="17px"
                  borderRadius="30px"
                  p="20px"
                  bg="blue.1"
                  type="submit"
                >
                  Enviar
                </Button>
              </Flex>
            )}
          </Form>
        </Box>
        <Modal
          finalFocusRef={finalRef}
          isOpen={isOpen}
          onClose={onClose}
          size="md"
        >
          <ModalOverlay p="0">
            <ModalContent>
              <ModalHeader
                margin="15px 10px 0px 0px"
                padding="16px 24px 0"
                textAlign="center"
              >
                Subir archivo
              </ModalHeader>
              <ModalCloseButton
                borderRadius="20px"
                onClick={() => setImageUrl("")}
              />
              <ModalBody>
                <Box
                  color="gray"
                  margin="0px 0px 5px 0px"
                  padding="0px 24px 16px "
                >
                  <Button marginTop="4">
                    <input
                      type="file"
                      // accept='image/*'
                      ref={inputRef}
                      onChange={(e) => handleFileInput(e)}
                    />
                  </Button>
                </Box>
                {imageUrl &&
                imageUrl.length !== 0 &&
                !regFileType.test(image?.name) ? (
                  <Box display="flex" justifyContent="center" p="5px">
                    <img
                      src={imageUrl}
                      alt="img"
                      height="100px"
                      width="100px"
                    ></img>
                  </Box>
                ) : null}
              </ModalBody>
              <ModalFooter justify-content="center" width="90%">
                <Button
                  colorScheme="blue"
                  mr={3}
                  width="80%"
                  disabled={!imageUrl}
                  onClick={() => {
                    handleUploadImage();
                  }}
                >
                  Subir
                </Button>
              </ModalFooter>
            </ModalContent>
          </ModalOverlay>
        </Modal>
      </>
    </Box>
  );
}
export default ChatMain;
