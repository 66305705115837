import React, { useState, useEffect } from "react";
import { Box, Text, Divider, Stack, Checkbox, Button } from "@chakra-ui/react";

import lang from "../../../assets/lang/user-profile";
import { useLangStore } from "../../../hooks/state/useLangStore";
import { useUpdateProfile } from "../../../hooks/queries/usuarios";
import Form from "../../../components/ui/Form";
import { useAuthStore } from "../../../hooks/state/useAuthStore";
import { toast } from "react-toastify";

function Notifications({ userData }) {
  const [questions, setQuestions] = useState(true);
  const currentLang = useLangStore((state) => state.currentLang);
  const [updateProfile, updateProfileQuery] = useUpdateProfile();
  const updateAuthData = useAuthStore((state) => state.setData);
  const [editProfileSuccess, setEditProfileSuccess] = useState(false);
  function handleChangePerfil(data) {
    const finalData = {
      userId: userData.id,
      firstName: userData.firstName,
      lastName: userData.lastName,
      AnstoQueNoti: data.questions,
      ComtoAnsNoti: data.comments,
      QueAnsorComNoti: data.likes,
      ChatMsgNoti: data.messages,
      NewQuetoComuNoti: data.cmquestions,
      NewEvent: data.events,
    };

    updateProfile(finalData)
      .then((res) => {
        toast.success("El perfil se ha actualizado correctamente");
        updateAuthData(res);
        setEditProfileSuccess(true);
        setTimeout(() => {
          setEditProfileSuccess(false);
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <Box
      p="20px"
      h="100%"
      bg="white"
      borderRadius="5px"
      boxShadow="0px 3px 6px #00000029"
    >
      <Box p="10px">
        <Text> {lang[currentLang].notifications}</Text>
      </Box>
      <Divider mb="5px" />
      <Stack>
        <Text mb="20px" mt="20px">
          {lang[currentLang].checkOptions}
        </Text>
        <Text>{lang[currentLang].notificationsEmail}</Text>
        <Form
          initialValues={{
            questions: userData?.AnstoQueNoti,
            comments: userData?.ComtoAnsNoti,
            likes: userData?.QueAnsorComNoti,
            messages: userData?.ChatMsgNoti,
            cmquestions: userData?.NewQuetoComuNoti,
            events: userData?.NewEvent,
          }}
          onSubmit={handleChangePerfil}
        >
          {({ setFieldValue, values }) => (
            <div>
              <Box>
                <Checkbox
                  name="questions"
                  values={values.questions}
                  defaultChecked={userData?.AnstoQueNoti}
                  onChange={(e) => setFieldValue("questions", e.target.checked)}
                >
                  {lang[currentLang].notiQuestions}
                </Checkbox>
              </Box>
              <Box>
                <Checkbox
                  name="comments"
                  values={values.comments}
                  defaultChecked={userData?.ComtoAnsNoti}
                  onChange={(e) => setFieldValue("comments", e.target.checked)}
                >
                  {lang[currentLang].notiComments}
                </Checkbox>
              </Box>
              <Box>
                <Checkbox
                  name="likes"
                  values={values.likes}
                  defaultChecked={userData?.QueAnsorComNoti}
                  onChange={(e) => setFieldValue("likes", e.target.checked)}
                >
                  {lang[currentLang].notiLikes}
                </Checkbox>
              </Box>
              <Box>
                <Checkbox
                  name="messages"
                  values={values.messages}
                  defaultChecked={userData?.ChatMsgNoti}
                  onChange={(e) => setFieldValue("messages", e.target.checked)}
                >
                  {lang[currentLang].notiMessages}
                </Checkbox>
              </Box>
              <Box>
                <Checkbox
                  name="cmquestions"
                  values={values.cmquestions}
                  defaultChecked={userData?.NewQuetoComuNoti}
                  onChange={(e) =>
                    setFieldValue("cmquestions", e.target.checked)
                  }
                >
                  {lang[currentLang].notiCommunityQuestions}
                </Checkbox>
              </Box>
              <Box>
                <Checkbox
                  name="events"
                  values={values.events}
                  defaultChecked={userData?.NewEvent}
                  onChange={(e) => setFieldValue("events", e.target.checked)}
                >
                  {lang[currentLang].notiEvents}
                </Checkbox>
              </Box>
              <Box>
                {" "}
                <Button type="submit" mt="20px" colorScheme="blue">
                  Guardar
                </Button>
              </Box>
            </div>
          )}
        </Form>
      </Stack>
    </Box>
  );
}

export default Notifications;
