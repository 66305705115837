import { useMutation, useQuery} from "react-query";
import {submitComment, getUserComments, commentLike, commentDislike, updateComment, commentDelete} from "../../api/CommentFunctions";

export function useUserComments({userId} = {}, queryConfig = {}) {
    return useQuery(['GET_RESPUESTAS_USUARIO'], () => getUserComments(userId), queryConfig)
}

export function useCreateComment(queryConfig = {}) {
    return useMutation(submitComment, queryConfig)
}

export function useUpdateComment(queryConfig = {}) {
    return useMutation(updateComment, queryConfig)
}

export function useCommentLike(queryConfig = {}) {
    return useMutation(commentLike, queryConfig)
}

export function useCommentDislike(queryConfig = {}) {
    return useMutation(commentDislike, queryConfig)
}

export function useCommentDelete(queryConfig = {}) {
    return useMutation(commentDelete, queryConfig)
}