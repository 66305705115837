const lang = {
  es_ES: {
    allnoti: "Todas las notificaciones",
    followus: "Síguenos",
    prjectOf: "Un proyecto de:",
    welcome: "Bienvenida a categorías",
    explore: "Explora tus intereses en iCuida",
    addQuestion: "Añadir pregunta",
    questionPlaceholder:
      'Empieza la pregunta con "Qué", "Cómo", "Por qué", "Donde"',
    verifyQuestion: "Haz que tu pregunta sea concisa y relacionada",
    verifyQuestion2: "Comprueba que no haya errores",
    sobreicuida: "Sobre iCuida",
    conditions: "Condiciones",
    privacy: "Privacidad",
    avisoLegal: "Aviso legal",
    politicaCookies: "Politicas de cookies",
    politicaUse: "Politica de uso",
    contactus: "Contáctanos",
    caregiverCard: "Tarjeta Cuidadora",
    help: "Ayuda",
    projectof: "Un proyecto de",
    colaborationof: "Con la colaboración de:",
    conversations: "Selecciona un chat para continuar...",
    comunity: "Comunidad",
    faqsoficial: "FAQS oficiales",
    popupTitle:
      "Vemos que no tienes el identificador de la Tarjeta de Cuidadora introducido en tu perfil.",
    popupLink: "¿Que es la Tarjeta de Cuidadora?",
    popupQuestion: "¿Quieres introducir el identificador de la tarjeta?",
    popupGoToProfileOption: "Ir al perfil ahora",
    popupDontShowMessage: "No volver a mostrar este mensaje",
    popupRegisterTitle: "¿Qué es el Identificador Tarjeta Cuidadora?",
    popupRegisterSubtitle:
      "Es el identificador que aparece en tu tarjeta Cuidadora, tal y como se muestra en esta imagen.",
    popupRegisterBodyText: "Si no tienes Tarjeta Cuidadora mira aqui",
    popupRegisterLink: "qué es y cómo conseguirla",
    noNotifications: "Todavía no tienes notificaciones.",
    searchUsers: "Buscar usuarios",
    newEvent: "La comunidad Icuida ha publicado un nuevo evento:",
    likeEvent: "ha indicado me gusta en el evento:",
    caregiverCardSoonData: "Información Tarjeta Cuidadora",
    noEvents: "Sin Eventos",
    recommends: "Recomienda y gana",
    resources: "Recursos iCuida",
    ramainTimeText1: "Quedan",
    ramainTimeText2: "días para que finalice el tiempo de prueba",
  },
  ca: {
    allnoti: "Totes les notificacions",
    followus: "Segueix-nos",
    prjectOf: "Un projecte de:",
    welcome: "Benvinguda a categories",
    explore: "Explora els teus interessos en iCuida",
    addQuestion: "Afegir pregunta",
    questionPlaceholder: 'Comença la pregunta amb "Què", "Com", Per què", "On"',
    verifyQuestion: "Fes que la teva pregunta sigui concisa i relacionada",
    verifyQuestion2: "Comprova que no hi hagi errors",
    sobreicuida: "Sobre iCuida",
    conditions: "Condicions",
    privacy: "Privacitat",
    avisoLegal: "Avís legal",
    politicaCookies: "Politica de cookies",
    politicaUse: "Politica d'ús",
    contactus: "Contacta'ns",
    caregiverCard: "Targeta Cuidadora",
    help: "Ajuda",
    projectof: "Un projecte de",
    colaborationof: "Amb el suport de:",
    conversations: "Selecciona un xat per a continuar...",
    comunity: "Comunitat",
    faqsoficial: "FAQS oficials",
    popupTitle:
      "Veiem que no tens l'identificador de la Targeta de Cuidadora introduït al teu perfil.",
    popupLink: "Què és la Targeta de Cuidadora?",
    popupQuestion: "Vols introduir l'identificador de la targeta?",
    popupGoToProfileOption: "Anar al perfil ara",
    popupDontShowMessage: "No tornar a mostrar aquest missatge",
    popupRegisterTitle: "Què és l'identificador Targeta Cuidadora?",
    popupRegisterSubtitle:
      "És l'identificador que apareix en la teva targeta Cuidadora, tal com es mostra en aquesta imatge.",
    popupRegisterBodyText: "Si no tens Targeta Cuidadora mira aquí",
    popupRegisterLink: "què és i com aconseguir-la",
    noNotifications: "Encara no tens notificacions.",
    searchUsers: "Buscar usuaris",
    newEvent: "La comunitat Icuida ha publicat un nou esdeveniment:",
    likeEvent: "ha indicat m'agrada en l'esdeveniment:",
    caregiverCardSoonData: "Informació Targeta Cuidadora",
    noEvents: "Sense Esdeveniments",
    recommends: "Recomana i guanya",
    resources: "Recursos iCuida",
    ramainTimeText1: "Queden",
    ramainTimeText2: "dies perquè finalitzi el temps de prova",
  },
};

export default lang;
