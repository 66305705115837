const lang = {
  es_ES: {
    texts: {
      register: "Regístrate",
      register2: "Registrar",
      forget: "¿Olvidaste la contraseña?"
      
    },
    form: {
      name: "Nombre",
      surname: "Apellidos",
      phone: "Teléfono",
      careGiverCard: "Identificador de la Tarjeta Cuidadora",
      email: "email",
      passw: "Contraseña (mínimo 8 carácteres)",
      passw2: "Reescribir contraseña",
    }
  },
  ca: {
    texts: {
      register: "Registra't",
      register2: "Registrar",
      forget: "Has oblidat la contrasenya?"
      
    },
    form: {
      name: "Nom",
      surname: "Cognoms",
      phone: "Telèfon",
      careGiverCard: "Identificador de la Targeta Cuidadora",
      email: "email",
      passw: "Contrasenya (mínim 8 caràcters)",
      passw2: "Reescriure contrasenya",
    }
  }
};

export default lang;
