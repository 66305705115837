import {Box, Text, Image} from "@chakra-ui/react";
import React from "react";
import {useLangStore} from "../../hooks/state/useLangStore";
import lang from "../../assets/lang/croniques";

function RecommendContent() {
  const currentLang = useLangStore((state) => state.currentLang);

  return (

    <Box maxW="800px" mx="auto" p="20px" borderRadius="5px">
      <Box bm='15px'>
          <Image src={lang[currentLang].landingPage.imatge} alt={lang[currentLang].landingPage.imgAlt}/>
      </Box>
      <Box mb="15px" mt="15px">
        <Text fontWeight="bold" fontSize="25px" color='tomato'>
          {lang[currentLang].landingPage.title}
        </Text>
      </Box>
      <Box mb="15px">
        <Text fontSize="16px">
          {lang[currentLang].landingPage.text1}
          <a href={lang[currentLang].landingPage.text1l1.enllas} target='_blank'>{lang[currentLang].landingPage.text1l1.text}</a>
          {lang[currentLang].landingPage.text1p1}
        </Text>{" "}
      </Box>
      <Box mb="15px">
        <Text fontSize="16px">
          {lang[currentLang].landingPage.text2}
          <strong>{lang[currentLang].landingPage.text2b1}</strong>
          {lang[currentLang].landingPage.text2p1}
        </Text>{" "}
      </Box>
      <Box mb='15px'>
          <Image src={lang[currentLang].landingPage.imatge1} alt={lang[currentLang].landingPage.img1Alt}/>
      </Box>
      <Box mb="15px">
        <Text fontSize="16px">
          {lang[currentLang].landingPage.text3}
          <ol>
            <li>{lang[currentLang].landingPage.text3o1}</li>
            <li>{lang[currentLang].landingPage.text3o2}
            <a href={lang[currentLang].landingPage.text3o2l1.link} target='_blank'>{lang[currentLang].landingPage.text3o2l1.text}</a>
            {lang[currentLang].landingPage.text3o2p1}</li>
            <li>{lang[currentLang].landingPage.text3o3}</li>
            <li>{lang[currentLang].landingPage.text3o4}</li>
            <li>{lang[currentLang].landingPage.text3o5}
            <a href={lang[currentLang].landingPage.text3o5l1.link} target='_blank'>{lang[currentLang].landingPage.text3o5l1.text}</a>
            </li>
          </ol>
          {lang[currentLang].landingPage.text3p1}
        </Text>
      </Box>
      <Box mb="15px">
        <Text fontSize="16px">
          {lang[currentLang].landingPage.text4}
        </Text>
      </Box>
      
      <Box mb='15px'>
          <Image src={lang[currentLang].landingPage.imatge2} alt={lang[currentLang].landingPage.img2Alt}/>
      </Box>

      <Box mb="15px">
        <Text fontSize="16px">
          {lang[currentLang].landingPage.text5}
          <ul>
            <li><strong>{lang[currentLang].landingPage.text5o1b}</strong>{lang[currentLang].landingPage.text5o1}</li>
            <li><strong>{lang[currentLang].landingPage.text5o2b}</strong>{lang[currentLang].landingPage.text5o2}</li>
            <li><strong>{lang[currentLang].landingPage.text5o3b}</strong>{lang[currentLang].landingPage.text5o3}</li>
            <li><strong>{lang[currentLang].landingPage.text5o4b}</strong>{lang[currentLang].landingPage.text5o4}</li>
            <li><strong>{lang[currentLang].landingPage.text5o5b}</strong>{lang[currentLang].landingPage.text5o5}</li>
            <li><strong>{lang[currentLang].landingPage.text5o6b}</strong>{lang[currentLang].landingPage.text5o6}</li>
            <li><strong>{lang[currentLang].landingPage.text5o7b}</strong>{lang[currentLang].landingPage.text5o7}</li>
            <li><strong>{lang[currentLang].landingPage.text5o8b}</strong>{lang[currentLang].landingPage.text5o8}</li>
          </ul>
        </Text>
      </Box>

      <Box mb="15px">
        <Text fontSize="16px">
          {lang[currentLang].landingPage.text6}
          <a href={lang[currentLang].landingPage.text6l1.enllas} target='_blank'>{lang[currentLang].landingPage.text6l1.text}</a>
          {lang[currentLang].landingPage.text6p1}
        </Text>{" "}
      </Box>

      <Box mb='15px'>
          <Image src={lang[currentLang].landingPage.imatge3} alt={lang[currentLang].landingPage.img3Alt}/>
      </Box>

      <Box mb="15px">
        <Text fontSize="16px">
        <strong>{lang[currentLang].landingPage.text7b}</strong>{lang[currentLang].landingPage.text7}
          <ul>
            <li><strong>{lang[currentLang].landingPage.text7o1b}</strong>{lang[currentLang].landingPage.text7o1}</li>
            <li><strong>{lang[currentLang].landingPage.text7o2b}</strong>{lang[currentLang].landingPage.text7o2}</li>
            <li><strong>{lang[currentLang].landingPage.text7o3b}</strong>{lang[currentLang].landingPage.text7o3}</li>
          </ul>
        </Text>
      </Box>

      <Box mb="15px">
        <Text fontSize="16px">
          {lang[currentLang].landingPage.text8}
        </Text>
      </Box>
      <Box mb="15px">
        <Text fontSize="16px">
          {lang[currentLang].landingPage.text9}
        </Text>
      </Box>
      <Box mb="15px">
        <Text fontSize="16px">
          {lang[currentLang].landingPage.text10}
          <a href={lang[currentLang].landingPage.text10l1.link} target='_blank'>{lang[currentLang].landingPage.text10l1.text}</a>
          {lang[currentLang].landingPage.text10p1}
          <strong>{lang[currentLang].landingPage.text10b1}</strong>{lang[currentLang].landingPage.text10p2}
        </Text>
      </Box>

      <Box mb='15px'>
          <Image src={lang[currentLang].landingPage.imatge4} alt={lang[currentLang].landingPage.img4Alt}/>
      </Box>
 
      <Box mb="15px">
        <Text fontSize="16px">
          {lang[currentLang].landingPage.pointZero.text}{" "}
          <a href={lang[currentLang].landingPage.pointZero.link} target='_blank'>{lang[currentLang].landingPage.pointZero.link}</a>
        </Text>
      </Box>
      
      
    </Box>
  );
}

export default RecommendContent;