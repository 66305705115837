import React from 'react';
import {useHistory} from 'react-router-dom'
import {Box, Text, UnorderedList, ListItem} from '@chakra-ui/react'
import FollowUs from '../content/sticky-side/FollowUs';

function PoliticaCookies() {

    const history = useHistory();

    return (
        <Box bg="#f1fcff">
            <Box maxW="800px" mx="auto">
                <Box mb="15px">
                    <Text fontWeight="bold" fontSize="26px">
                        Políticas de Cookies 
                    </Text>
                </Box>
                <Box mb="15px">
                    <Text>
            Última actualización: Octubre 2020
                        
                    </Text>
                </Box>
                <Box mb="15px">
                    <Text>
            iCuida utiliza cookies propias y de terceros para recopilar información y mejorar tu experiencia de navegación. Una de las formas en las que recopilamos información es a través del uso de la tecnología llamada “cookies”. En iCuida utilizamos cookies para varias cosas.

                    </Text>
                </Box>
                <Box mb="15px">
                    <Text fontWeight="semibold" fontSize="18px">
            ¿Qué es una cookie?

                    </Text>
                </Box>
                <Box mb="15px">
                    <Text>
            Una “cookie” es una pequeña cantidad de texto que se almacena en tu navegador (p.ej. Chrome de Google o Safari de Apple) cuando navegas por la mayoría de los sitios web.

                    </Text>
                </Box>
                <Box mb="15px">
                    <Text fontWeight="semibold" fontSize="18px">
            ¿Qué NO es una cookie?

                    </Text>
                </Box>
                <Box mb="15px">
                    <Text>
            No es un virus, ni un troyano, ni un gusano, ni spam, ni spyware, ni abre ventanas pop-up.

                    </Text>
                </Box>
                <Box mb="15px">
                    <Text fontWeight="semibold" fontSize="18px">
            ¿Qué información almacena una cookie?

                    </Text>
                </Box>
                <Box mb="15px">
                    <Text>
            Las cookies no suelen almacenar información sensible sobre usted, como tarjetas de crédito o datos bancarios, fotografías o información personal, etc. Los datos que guardan son de carácter técnico, estadísticos, preferencias personales, personalización de contenidos, etc.

                    </Text>
                </Box>
                <Box mb="15px">
                    <Text>
            El servidor web no le asocia a usted como persona, sino a su navegador web. De hecho, si usted navega habitualmente con el navegador Chrome y prueba a navegar por la misma web con el navegador Firefox, verá que la web no se da cuenta de que es usted la misma persona porque en realidad está asociando la información al navegador, no a la persona.

                    </Text>
                </Box>
                <Box mb="15px">
                    <Text fontWeight="semibold" fontSize="18px">
            ¿Qué tipo de cookies existen?

                    </Text>
                </Box>
               <Box ml="20px">
                <UnorderedList>
                        <ListItem>
                            <Box mb="15px">
                                <Text>
                                    Cookies técnicas: Son las más elementales y permiten, entre otras cosas, saber cuándo está navegando un humano o una aplicación automatizada, cuándo navega un usuario anónimo y uno registrado, tareas básicas para el funcionamiento de cualquier web dinámica.
                                </Text>
                            </Box>
                        </ListItem>
                        <ListItem>
                            <Box mb="15px">
                                <Text>
                        Cookies de análisis: Recogen información sobre el tipo de navegación que está realizando, las secciones que más utiliza, productos consultados, franja horaria de uso, idioma, etc.

                                </Text>
                            </Box>
                        </ListItem>
                        <ListItem>
                            <Box mb="15px">
                                <Text>
                        Cookies publicitarias: Muestran publicidad en función de su navegación, su país de procedencia, idioma, etc.

                                </Text>
                            </Box>
                        </ListItem>
                </UnorderedList>
               </Box>
                <Box mb="15px">
                    <Text>
            Cookies aceptadas por la normativa y cookies exceptuadas

                    </Text>
                </Box>
                <Box mb="15px">
                    <Text>
            Según la directiva de la UE, las cookies que requieren el consentimiento informado por parte del usuario son las cookies de analítica, y las de publicidad y afiliación, quedando exceptuadas las de carácter técnico y las necesarias para el funcionamiento del sitio web o la prestación de servicios expresamente solicitados por el usuario.

                    </Text>
                </Box>
                <Box mb="15px">
                    <Text fontWeight="semibold" fontSize="18px">
                        ¿Qué cookies utiliza esta web?
                    </Text>
                </Box>
                <Box mb="15px">
                    <Text>
            Esta web utiliza cookies propias y de terceros. En este sitio web se utilizan las cookies que se detallan a continuación:

                    </Text>
                </Box>
                <Box mb="15px">
                    <Text fontSize="18px">
                Cookies propias

                    </Text>
                </Box>
                <Box>
                    <UnorderedList>
                        <ListItem>
                            <Box mb="15px">
                                <Text>
                                    <Text as="span" fontWeight="bold">Inicio de sesión:</Text> Las cookies para iniciar sesión te permiten entrar y salir de tu cuenta de https://icuida.barcelona/
                                </Text>
                            </Box>
                        </ListItem>
                        <ListItem>
                            <Box mb="15px">
                                <Text>
                                <Text as="span" fontWeight="bold">Personalización:</Text> Las cookies nos ayudan a recordar con qué personas o sitios web has interactuado, para que podamos mostrarte contenido relacionado.
                                </Text>
                            </Box>
                        </ListItem>
                        <ListItem>
                            <Box mb="15px">
                                <Text>
                                <Text as="span" fontWeight="bold"> Preferencias:</Text> Las cookies nos permiten recordar tus ajustes y preferencias, como el idioma preferido y tu configuración de privacidad.
                                </Text>
                            </Box>
                        </ListItem>
                        <ListItem>
                            <Box mb="15px">
                                <Text>
                                <Text as="span" fontWeight="bold">Seguridad:</Text> Utilizamos cookies para evitarte riesgos de seguridad. Principalmente para detectar cuándo alguien está intentando piratear tu cuenta de icuida.barcelona 
                                </Text>
                            </Box>
                        </ListItem>
                    </UnorderedList>
                </Box>
                <Box mb="15px">
                    <Text fontSize="18px">
                Cookies de terceros
                    </Text>
                </Box>
                <Box mb="15px">
                    <Text fontWeight="bold" fontSize="18px">
                Módulo Google Analytics: Cookies analiticas:
                    </Text>
                </Box>
                <Box mb="15px">
                    <Text>
                Esta web utiliza servicios de análisis; concretamente, Google Analytics para ayudar al website a analizar el uso que hacen los usuarios del sitio web y mejorar la usabilidad del mismo, pero en ningún caso se asocian a datos que pudieran llegar a identificar al usuario. Google Analytics es un servicio analítico de web prestado por Google Inc. El usuario puede consultar aquí el tipo de cookies utilizadas por Google.
                    </Text>
                </Box>
                <Box mb="15px">
                    <Text>
                Módulo redes sociales: Cookies de redes sociales:
                    </Text>
                </Box>
                <Box mb="15px">
                    <Text>
                Las cookies de redes sociales pueden almacenarse en su navegador mientras navega por <Text as="ins" color="blue.5" cursor="pointer"><a target="_blank" href="https://icuida.barcelona/">https://icuida.barcelona/</a></Text>.
                    </Text>
                </Box>
                <Box mb="15px">
                    <Text>
                A continuación, tienes información sobre las cookies de las redes sociales que utiliza esta web en sus propias políticas de cookies:
                    </Text>
                </Box>
                <Box m="20px">
                    <UnorderedList>
                        <ListItem>
                            <Box mb="15px">
                                <Text>
                                    Cookies de Facebook, ver más información en su <Text as="ins" color="blue.5" cursor="pointer"><a target="_blank" href="https://www.facebook.com/policies/cookies/">política de cookies</a></Text>.
                                </Text>
                            </Box>
                        </ListItem>
                        <ListItem>
                            <Box mb="15px">
                                <Text>
                                    Cookies de Twitter, ver más información en su <Text as="ins" color="blue.5" cursor="pointer"><a target="_blank" href="https://twitter.com/es/privacy">política de cookies</a></Text>.
                                </Text>
                            </Box>
                        </ListItem>
                        <ListItem>
                            <Box mb="15px">
                                <Text>
                                    Cookies de Instagram, ver más información en su <Text as="ins" color="blue.5" cursor="pointer"><a target="_blank" href="https://www.facebook.com/policies/cookies/">política de cookies</a></Text>.
                                </Text>
                            </Box>
                        </ListItem>
                        <ListItem>
                            <Box mb="15px">
                                <Text>
                                    Cookies de YouTube, ver más información en su <Text as="ins" color="blue.5" cursor="pointer"><a target="_blank" href="https://policies.google.com/technologies/types?hl=es">política de cookies</a></Text>.
                                </Text>
                            </Box>
                        </ListItem>
                    </UnorderedList>
                </Box>
                <Box mb="15px">
                    <Text fontSize="18px" fontWeight="semibold">
                ¿Se pueden eliminar las cookies?
                    </Text>
                </Box>
                <Box mb="15px">
                    <Text>
                Sí, y no sólo eliminar, también bloquear, de forma general o particular para un dominio específico.
                    </Text>
                </Box>
                <Box mb="15px">
                    <Text>
                Para eliminar las cookies de un sitio web debe ir a la configuración de su navegador y allí podrá buscar las asociadas al dominio en cuestión y proceder a su eliminación.
                    </Text>
                </Box>
                <Box ml="20px">
                    <UnorderedList>
                        <ListItem>
                            <Box mb="15px">
                                <Text>
                                    Configuración de cookies para <Text as="ins" color="blue.5" cursor="pointer"><a target="_blank" href="https://support.google.com/chrome/answer/95647?hl=es">GoogleChrome</a></Text>.
                                </Text>
                            </Box>
                        </ListItem>
                        <ListItem>
                            <Box mb="15px">
                                <Text>
                                    Configuración de cookies para <Text as="ins" color="blue.5" cursor="pointer"><a target="_blank" href="https://support.apple.com/es-es/HT201265">Apple Safari</a></Text>.
                                </Text>
                            </Box>
                        </ListItem>
                        <ListItem>
                            <Box mb="15px">
                                <Text>
                                    Configuración de cookies para <Text as="ins" color="blue.5" cursor="pointer"><a target="_blank" href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-10">Internet Explorer</a></Text>.
                                </Text>
                            </Box>
                        </ListItem>
                        <ListItem>
                            <Box mb="15px">
                                <Text>
                                    Configuración de cookies para <Text as="ins" color="blue.5" cursor="pointer"><a target="_blank" href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectlocale=es&redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we">Mozilla FireFox</a></Text>.
                                </Text>
                            </Box>
                        </ListItem>
                    </UnorderedList>
                </Box>
                <Box mb="15px">
                    <Text fontSize="18px" fontWeight="semibold">
                Aceptación de cookies
                    </Text>
                </Box>
                <Box mb="15px">
                    <Text>
                Si el usuario sigue navegando después de haberle informado sobre nuestra Política de cookies, entendemos que acepta la utilización de las cookies.
                    </Text>
                </Box>
                <Box mb="15px">
                    <Text>
                Al acceder a este sitio web o aplicación por primera vez, verá una advertencia donde se le informa de la utilización de las cookies y donde puede consultar esta política de cookies. Si el usuario consiente la utilización de cookies, continúa navegando o hace clic en algún link, entenderá que ha consentido nuestra política de cookies y, por tanto, la instalación de las mismas en su equipo o dispositivo.
                    </Text>
                </Box>
                <Box mb="15px">
                    <Text>
                Además del uso de nuestras cookies propias, permitimos a terceros establecer cookies y acceder a ellas en su ordenador. El consentimiento del uso de las cookies de estas empresas está ligado a la navegación por este sitio web.
                    </Text>
                </Box>
                <Box mb="15px">
                    <Text fontSize="18px" fontWeight="semibold">
                Más información sobre las cookies
                    </Text>
                </Box>
                <Box mb="15px">
                    <Text>
                Puedes consultar el reglamento sobre cookies publicado por la Agencia Española de Protección de Datos en su <Text as="ins" color="blue.5"><a href="https://www.aepd.es/es">«Guía sobre el uso de las cookies»</a></Text> y obtener más información sobre las cookies en Internet: <Text as="ins" color="blue.5"><a href="https://www.aboutcookies.org/">aboutcookies.org</a></Text>.
                    </Text>
                </Box>
                <Box mb="15px">
                    <Text>
                Si desea tener un mayor control sobre la instalación de cookies, puede instalar programas o complementos a su navegador, conocidos como herramientas de “Do Not Track”, que le permitirán escoger aquellas cookies que desea permitir.
                    </Text>
                </Box>
                <Box mb="15px">
                    <Text fontSize="18px" >
                NOTAS ADICIONALES
                    </Text>
                </Box>
                <Box mb="15px">
                    <Text>
                Ni esta web ni sus representantes legales se hacen responsables ni del contenido ni de la veracidad de las políticas de privacidad que puedan tener los terceros mencionados en esta política de cookies.
                    </Text>
                </Box>
                <Box mb="15px">
                    <Text>
                Los navegadores web son las herramientas encargadas de almacenar las cookies y desde este lugar debe efectuar su derecho a eliminación o desactivación de las mismas. Ni esta web ni sus representantes legales pueden garantizar la correcta o incorrecta manipulación de las cookies por parte de los mencionados navegadores.
                    </Text>
                </Box>
                <Box mb="15px">
                    <Text>
                En algunos casos es necesario instalar cookies para que el navegador no olvide tu decisión de no aceptación de las mismas.
                    </Text>
                </Box>
                <Box mb="15px">
                    <Text>
                En el caso de las cookies de Google Analytics, esta empresa almacena las cookies en servidores ubicados en Estados Unidos y se compromete a no compartirlas con terceros, excepto en los casos en los que sea necesario para el funcionamiento del sistema o cuando la ley obligue a tal efecto. Según Google no guarda su dirección IP. Google Inc. es una compañía adherida al Acuerdo de Puerto Seguro (Privacy Shield) que garantiza que todos los datos transferidos serán tratados con un nivel de protección acorde a la normativa europea. Para cualquier duda o consulta acerca de esta política de cookies no dude en comunicarse con nosotros a través del correo electrónico equipo@iniciativabarcelonaopendata.cat También es importante que leas nuestra política de privacidad, para saber cómo recopilamos datos y cómo son tratados, así como tus derechos.
                    </Text>
                </Box>
            </Box>
        </Box>
    )
}

export default PoliticaCookies;