import React from "react";
import { Link, useHistory, Route } from "react-router-dom";
import { Box, Flex, Image, Text, Divider } from "@chakra-ui/react";
import { jsx, css } from "@emotion/react";
import PoliticaPrivacidad from "../pages/policy/politicaPrivacidad";
import TerminosCondiciones from "../pages/policy/terminosCondiciones";
import PoliticaCookies from "../pages/policy/politicaCookies";
import PoliticaUso from "../pages/policy/politicaUso";
import Ayuda from "../pages/ayuda/index";
import SobreIcuida from "../pages/SobreIcuida";
import FollowUs from "../pages/content/sticky-side/FollowUs";
import lang from "../assets/lang/policycontainer";
import { useLangStore } from "../hooks/state/useLangStore";
import { Row, Col, Container } from "react-bootstrap";
import Contact from "./Contacto";

import Navbar from "../components/Navbar";
import NavbarAdmin from "../components/NavbarAdmin";
import PrivateRoute from "../components/PrivateRoute";
import MobileNavbar from "../components/MobileNavbar";
import AdminPanel from "./admin";
import { useAuthStore } from "../hooks/state/useAuthStore";
import StickySide from "./content/sticky-side";
import TarjetaCuidadora from "./TarjetaCuidadora";
import Footer from "../components/Footer";

function PoliciesContainer(categories) {
  const history = useHistory();
  const currentLang = useLangStore((state) => state.currentLang);
  const userData = useAuthStore((state) => state.data);

  return (
    <Box>
      <main role="main" className="App icuida">
        {userData && (
          <React.Fragment>
            <Navbar
              cssStyle={css`
                @media (max-width: 1200px) {
                  display: none;
                }
              `}
            />
            <MobileNavbar
              css={css`
                @media (min-width: 1200px) {
                  display: none;
                }
              `}
            />
          </React.Fragment>
        )}
        {userData && userData.isAdmin && <NavbarAdmin />}
        {userData && userData.isAdmin && (
          <PrivateRoute
            exact
            path={[
              "/panel-administrador/usuarios",
              "/panel-administrador/contenido",
            ]}
          >
            <AdminPanel />
          </PrivateRoute>
        )}
      </main>

      {/* When you are log in */}

      {userData && (
        <Box className="icuida" maxWidth="1200px" m="auto">
          <Box py="20px">
            <Container fluid>
              <Row>
                <Col
                  md="9"
                  css={css`
                    @media (min-width: 0) and (max-width: 30em) {
                      padding-left: 0;
                      padding-right: 0;
                    }
                  `}
                >
                  <Box width="100%">
                    <Route path="/sobre-Icuida">
                      <SobreIcuida />
                    </Route>
                    <Route path="/terminos-condiciones">
                      <TerminosCondiciones />
                    </Route>
                    <Route path="/politica-cookies">
                      <PoliticaCookies />
                    </Route>
                    <Route path="/politica-privacidad">
                      <PoliticaPrivacidad />
                    </Route>
                    <Route path="/politica-uso">
                      <PoliticaUso />
                    </Route>
                    <Route path="/ayuda">
                      <Ayuda />
                    </Route>
                    <Route path="/contacta">
                      <Contact />
                    </Route>
                    <Route path="/tarjeta-cuidadora">
                      <TarjetaCuidadora />
                    </Route>
                  </Box>
                </Col>
                <Col className="d-none d-md-block" md="3">
                  <StickySide categories={categories} />
                </Col>
              </Row>
            </Container>
          </Box>
        </Box>
      )}

      {!userData && (
        <React.Fragment>
          <Box width="100%" bg="orange.1" height="64px">
            <Box mx="auto" height="100%" maxW="1000px">
              <Flex px="20px" height="100%">
                <Box alignSelf="center" height="fit-content">
                  <Link to="/" className="navbar-brand p-0">
                    <Image
                      width="98.4px"
                      height="24.5px"
                      src={require("../assets/images/logos/icuida-letras.png")}
                      onClick={() => history.push("/")}
                    />
                  </Link>
                </Box>
              </Flex>
            </Box>
          </Box>
          {/* <Box> */}
          <Box
            className="icuida"
            maxWidth="1200px"
            m="auto"
            md="9"
            borderRadius="25px"
            padding="20px"
            css={css`
              @media (min-width: 0) and (max-width: 30em) {
                padding-left: 0;
                padding-right: 0;
              }
            `}
          >
            <Route path="/sobre-icuida">
              <SobreIcuida />
            </Route>
            <Route path="/terminos-condiciones">
              <TerminosCondiciones />
            </Route>
            <Route path="/politica-cookies">
              <PoliticaCookies />
            </Route>
            <Route path="/politica-privacidad">
              <PoliticaPrivacidad />
            </Route>
            <Route path="/politica-uso">
              <PoliticaUso />
            </Route>
            <Route path="/ayuda">
              <Ayuda />
            </Route>
            <Route path="/contacta">
              <Contact />
            </Route>
            <Route path="/tarjeta-cuidadora">
              <TarjetaCuidadora />
            </Route>
          </Box>
          <Footer />
        </React.Fragment>
      )}
    </Box>
  );
}

export default PoliciesContainer;
