import axios from "axios";
import Cookies from "js-cookie";
import { isExpired, decodeToken } from "react-jwt";
const baseUrl = "/";
// process.env.NODE_ENV === "production" ? "/api/" :

export function getUser(userId) {
  return axios
    .put(baseUrl + "users/", {
      id: userId,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      const errors = err.response.data.errors;
      if (errors) {
        const errorArray = Object.keys(errors).map((i) => errors[i]);
        throw errorArray;
      }
    });
}

export function getAllUsers() {
  return axios
    .put(baseUrl + "users/all")
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      const errors = err.response.data.errors;
      if (errors) {
        const errorArray = Object.keys(errors).map((i) => errors[i]);
        throw errorArray;
      }
    });
}

export function uploadPhoto(image) {
  return axios
    .post(baseUrl + "users/upload-photo", image)
    .then((res) => {
      localStorage.setItem("usertoken", res.data);
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
}

export function getSearchUser(search) {
  return axios
    .put(baseUrl + "users/search-user", {
      userId: search.userId,
      search: search.search,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
}

export function updateProfile(userData) {
  console.log(userData);
  return axios
    .put(baseUrl + "users/update-profile", {
      userId: userData.userId,
      firstName: userData.firstName,
      lastName: userData.lastName,
      phone: userData.phone,
      type: userData.type,
      description: userData.description,
      reminder: userData.reminder,
      card: userData.caregiver,
      connect: userData.connect,
      AnstoQueNoti: userData.AnstoQueNoti,
      ComtoAnsNoti: userData.ComtoAnsNoti,
      QueAnsorComNoti: userData.QueAnsorComNoti,
      ChatMsgNoti: userData.ChatMsgNoti,
      NewQuetoComuNoti: userData.NewQuetoComuNoti,
      NewEvent: userData.NewEvent,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
}

export function updatePassword(userData) {
  return axios
    .put(baseUrl + "users/change-password", userData)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      const errors = err.response.data.errors;
      if (errors) {
        const errorArray = Object.keys(errors).map((i) => errors[i]);
        throw errorArray;
      }
    });
}

export const register = (newUser) => {
  console.log(newUser);
  return axios
    .post(baseUrl + "users/register", {
      first_name: newUser.first_name,
      last_name: newUser.last_name,
      phone: newUser.phone,
      email: newUser.email,
      password: newUser.password,
      password2: newUser.password2,
      recommendName1: newUser.recommendName1,
      recommendPhone1: newUser.recommendPhone1,
      recommendEmail1: newUser.recommendEmail1,
      recommendName2: newUser.recommendName2,
      recommendPhone2: newUser.recommendPhone2,
      recommendEmail2: newUser.recommendEmail2,
    })
    .then((res) => {
      return "Te has registrado correctamente";
    })
    .catch((err) => {
      const errors = err.response.data.errors;
      if (errors) {
        const errorArray = Object.keys(errors).map((i) => errors[i]);
        throw errorArray;
      }
    });
};

export const login = (user) => {
  return axios
    .post(baseUrl + "users/login", {
      email: user.email,
      password: user.password,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      const errors = err.response.data.errors;
      if (errors) {
        if (errors.verify) {
          window.location.href = "/enviar-codigo/" + errors.verify.msg;
        } else {
          const errorArray = Object.keys(errors).map((i) => errors[i]);
          throw errorArray;
        }
      }
    });
};

export const loginFacebook = (user) => {
  return axios
    .post(baseUrl + "users/login-facebook", {
      name: user.name,
      email: user.email,
      imgUrl: user.picture.data.url,
    })
    .then((res) => {
      let token = res.data;
      return token;
    })
    .catch((err) => {
      throw err;
    });
};

export const loginGoogle = (user) => {
  return axios
    .post(baseUrl + "users/login-google", {
      name: user.name,
      firstName: user.givenName,
      lastName: user.familyName,
      email: user.email,
      imgUrl: user.imageUrl,
    })
    .then((res) => {
      let token = res.data;
      return token;
    })
    .catch((err) => {
      throw err;
    });
};

export const verifyMail = (id) => {
  return axios
    .put(baseUrl + "users/comfirmation/" + id)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export function checkEmail(formData) {
  return axios
    .put(baseUrl + "users/email-exists", { email: formData.email })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
}

export function verifyCode(formData) {
  return axios
    .put(baseUrl + "users/verify-code", {
      email: formData.email,
      recCode: formData.recCode,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
}

export function newPassword(formData) {
  return axios
    .put(baseUrl + "users/new-password", {
      userId: formData.userId,
      newPassword: formData.newPassword,
      passwordConfirmation: formData.passwordConfirmation,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
}

export const SendVeriMail = (id) => {
  return axios
    .put(baseUrl + "users/resend-code/" + id)
    .then((res) => {
      return "Codigo enviado";
    })
    .catch((err) => {
      return err;
    });
};

export const userBlock = (formData) => {
  return axios
    .put(baseUrl + "users/block", {
      id: formData.userId,
    })
    .then((res) => {
      return res.data.user;
    })
    .catch((err) => {
      throw err;
    });
};

export const userUnblock = (formData) => {
  return axios
    .put(baseUrl + "users/unblock", {
      id: formData.userId,
    })
    .then((res) => {
      return res.data.user;
    })
    .catch((err) => {
      throw err;
    });
};

export const userLogout = (id) => {
  return axios
    .put(baseUrl + `users/logout/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};
