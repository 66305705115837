import React, { useEffect, useState } from "react";
import { Box, Text, Input, Button, Flex } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { useNewPassword } from "../../hooks/queries/usuarios";
import Form from "../../components/ui/Form";
import { usePassRecoveryStore } from "../../hooks/state/usePassRecoveryStore";
import jwt_decode from "jwt-decode";

function SetNewPass() {
  const history = useHistory();
  const [newPassword, newPasswordQuery] = useNewPassword();
  const [success, setSuccess] = useState(false);

  const setData = usePassRecoveryStore((state) => state.setData);
  const recoveryData = usePassRecoveryStore((state) => state.data);

  function handleSubmit(formData) {
    formData.userId = recoveryData.id;
    newPassword(formData, {
      onSuccess: (res) => {
        setSuccess(true);
        setTimeout(() => {
          localStorage.clear();
          history.push("/login");
        }, 2000);
      },
      onError: (err) => {
        console.log(err);
      },
    });
    console.log(formData);
  }

  useEffect(() => {
    if (!recoveryData) {
      history.push("/recuperar-cuenta");
    }
  });

  return (
    <Box
      maxWidth="800px"
      width="100%"
      mx="auto"
      pt="50px"
      px="10px"
      borderRadius="20px"
    >
      <Form
        initialValues={{
          newPassword: "",
          passwordConfirmation: "",
        }}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values }) => (
          <Box>
            <Box p="10px 20px" bg="blue.7">
              <Text fontWeight="bold" color="#fff" textAlign="center">
                Nueva Contraseña
              </Text>
            </Box>
            <Box bg="#fff" px="20px">
              <Box maxWidth="400px" width="100%" mx="auto" pt="4rem" pb="2rem">
                <Text>
                  Por favor añada una nueva contraseña para la siguiente cuenta.
                </Text>

                <Box py="20px">
                  <Text color="blue.7">{recoveryData?.email}</Text>{" "}
                </Box>
                <Box my="15px">
                  <Box my="20px">
                    <Input
                      name="newPassword"
                      type="password"
                      minLength="8"
                      maxLength="50"
                      value={values.newPassword}
                      onChange={(e) =>
                        setFieldValue("newPassword", e.target.value)
                      }
                      placeholder="Nueva contraseña"
                    />
                  </Box>
                  <Box my="20px">
                    <Input
                      name="passwordConfirmation"
                      type="password"
                      minLength="8"
                      maxLength="50"
                      value={values.passwordConfirmation}
                      onChange={(e) =>
                        setFieldValue("passwordConfirmation", e.target.value)
                      }
                      placeholder="Confirmar contraseña"
                    />
                  </Box>
                </Box>
                <Box
                  hidden={!success}
                  bg="green.100"
                  p="10px 20px"
                  borderRadius="5px"
                >
                  <Text>La contraseña se ha cambiado correctamente</Text>
                </Box>
                <Box my="30px">
                  <Button
                    isLoading={newPasswordQuery.isLoading}
                    type="submit"
                    width="100%"
                    color="#fff"
                    bg="blue.7"
                  >
                    Confirmar
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Form>
    </Box>
  );
}
export default SetNewPass;
