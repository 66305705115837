import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, Flex, Avatar, Text, Divider } from "@chakra-ui/react";

import { getConversationSingleReply } from "../../../../api/conversation-reply";
import UserAvatar from "../../../../components/UserAvatar";

function ChatNotiCard({ data, replyId }) {
  const history = useHistory();
  const [ReplyData, setReplyData] = useState();
  function getReplyData() {
    getConversationSingleReply(replyId)
      .then((res) => {
        if (res) {
          setReplyData(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (!ReplyData) {
      getReplyData();
    }
  }, [ReplyData]);

  return (
    <Box>
      {data && data?.SenderId?.firstName && ReplyData?.reply && (
        <Box>
          <Flex mb="10px">
            <UserAvatar userData={data?.SenderId} />
            <Box ml="10px">
              <Text>{data.SenderId.firstName} Te envió un mensaje:</Text>
              <Text color="gray.2" fontSize="sm">
                {data?.SenderId?.type}
              </Text>
            </Box>
          </Flex>
          <Box>
            <Text
              cursor="pointer"
              onClick={(e) => history.push("/chat")}
              fontWeight="bold"
              dangerouslySetInnerHTML={{ __html: ReplyData?.reply }}
            ></Text>
          </Box>
          <Divider />
        </Box>
      )}
    </Box>
  );
}

export default ChatNotiCard;
