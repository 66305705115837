import React from "react";
import { Box, Text, Divider, Flex, Center } from "@chakra-ui/react";
import {
  useEventDislike,
  useEventLike,
  useGetAllEvents,
  useGetEvents,
} from "../hooks/queries/event";
import { useState, useEffect } from "react";
import moment from "moment";
import "moment/locale/es";
import { objHasUserId } from "../functional/general";
import LikeIcon from "../assets/icons/LikeIcon";
import { useAuthStore } from "../hooks/state/useAuthStore";
import { useCreateNotification } from "../hooks/queries/notification";
import { createNotification } from "../api/notification";
import { getAllUsers } from "../api/UserFunctions";
import { useLangStore } from "../hooks/state/useLangStore";
import lang from "../assets/lang/general";

function Eventos(props) {
  const [currentPage, setCurrentPage] = useState(1);
  const { isLoading, data, refetch } = useGetAllEvents({ page: currentPage });
  const [eventsArray, setEventsArray] = useState([]);
  const [eventLike, eventLikeQuery] = useEventLike();
  const [eventDislike, commentDislikeQuery] = useEventDislike();
  const [eventData, setEventData] = useState();
  const userData = useAuthStore((state) => state.data);
  const event = useGetEvents({ page: currentPage });
  const [userDetail, setUserDetail] = useState({});
  const currentLang = useLangStore((state) => state.currentLang);

  function nextPage(currentPage) {
    if (data.TotalPage > currentPage) {
      setCurrentPage(currentPage + 1);
    }
  }
  function addEvents(dataList) {
    if (dataList?.length > 0) {
      setEventsArray([...eventsArray, ...dataList]);
    }
  }

  function viewMore() {
    nextPage(currentPage);
  }

  useEffect(() => {
    if (currentPage) {
      refetch().then((res) => {
        addEvents(res?.data);
      });
    }
  }, [currentPage]);

  useEffect(() => {
    if (!isLoading && eventsArray?.length === 0) {
      setEventsArray(data?.data);
    }
    getAllUsers().then((data) => {
      if (data) {
        const found = data?.find((element) => element.isAdmin === true);
        setUserDetail(found);
      }
    });
  }, [data]);

  function formatDate(date) {
    const databaseDate = new Date(date);
    return moment(databaseDate).format("L");
  }

  function liked(data) {
    const formData = {
      userId: userData.id,
      eventId: data?.id,
    };
    eventLike(formData).then((res) => {
      const likeformData = {
        recipientId: userDetail?.id,
        senderId: userData?.id,
        type: "eventLike",
        referenceId: data?.id,
      };
      // Add if statement if userauth Id = question id to not create notification.
      if (userData?.id !== userDetail?.id) {
        createNotification(likeformData);
      }

      event.refetch();
    });
  }

  useEffect(() => {
    setEventsArray(event?.data?.data);
  }, [event]);

  return (
    <Box>
      {!isLoading &&
        eventsArray?.length > 0 &&
        eventsArray?.map((value, index) => (
          <Box key={index} width="100%" bg="#fff" borderRadius="3px" mb="10px">
            <Box ps="20px" p="20px 20px 5px 40px">
              <Flex padding="10px 0 10px">
                <Box fontWeight="1000" ms="2px">
                  {value?.title}
                </Box>
              </Flex>
              <Flex fontSize="14px">
                <Box me="2px">{formatDate(value?.day)}</Box>
                <Text>-</Text>
                <Box ms="2px">{value?.hour}H</Box>
              </Flex>
              <Box fontSize="14px" paddingBottom="2px">
                <b>{value?.place}</b>
              </Box>
              <Center widht="50px">
                <Divider orientation="horizontal" />
              </Center>
              <Box
                fontSize="14px"
                p="5px 0px"
                dangerouslySetInnerHTML={{
                  __html: `<div>${value?.description}</div>`,
                }}
              ></Box>
              <Divider />
              <Box cursor="pointer" p="5px 0px">
                <Flex>
                  <Box cursor="pointer" onClick={() => liked(value)}>
                    <LikeIcon color="#8B8886" />
                  </Box>
                  <Text ml="3px">{value?.EventsLikes?.length}</Text>
                </Flex>
              </Box>
            </Box>
          </Box>
        ))}
      {!isLoading && eventsArray?.length < 1 && (
        <Box backgroundColor="white" borderRadius="5px" p="30px">
          <Text fontSize="2xl" fontWeight="bold">
            {lang[currentLang].noEvents}
          </Text>
        </Box>
      )}
      {!isLoading && data?.TotalPage !== currentPage && (
        <Text
          cursor="pointer"
          hidden={!isLoading && eventsArray?.length >= data?.count}
          textAlign="center"
          onClick={() => {
            viewMore();
          }}
        >
          Ver más...
        </Text>
      )}
    </Box>
  );
}

export default Eventos;
