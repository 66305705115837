import axios from "axios";
const baseUrl = "/";
// process.env.NODE_ENV === "production" ? "/api/" :

export const getComments = (id) => {
  return axios
    .put(baseUrl + "comments/question-comments/" + id)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};

export function getAllComments() {
  return axios
    .put(baseUrl + "comments/")
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
}

export const getUserComments = (id) => {
  return axios
    .put(baseUrl + "comments/user-comments/", { userId: id })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const comment = (id) => {
  return axios
    .put(baseUrl + "comments/selected/" + id)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const submitComment = (commentData) => {
  console.log("hello");
  return axios
    .post(baseUrl + "comments/", commentData)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const updateComment = (commentData) => {
  return axios
    .put(baseUrl + "comments/update", {
      comment: commentData.comment,
      commentId: commentData.commentId,
      userId: commentData.userId,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const commentLike = (formData) => {
  return axios
    .post(baseUrl + "comment-likes/like/", formData)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const commentDislike = (formData) => {
  return axios
    .delete(baseUrl + "comment-likes/dislike/", { data: formData })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const commentDelete = (formData) => {
  return axios
    .delete(baseUrl + "comments/delete", {
      data: {
        commentId: formData.commentId,
        userId: formData.userId,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
