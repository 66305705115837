import React from 'react';

export default ({color = "#000"}) => (
<svg xmlns="http://www.w3.org/2000/svg" width="19.778" height="21.76" viewBox="0 0 19.778 21.76">
  <g id="icono_Comentarios_gris" transform="translate(0.75 0.75)">
    <path id="Trazado_660" data-name="Trazado 660" d="M213.276,65.2c-5.048,0-9.139,3.834-9.139,8.563a8.728,8.728,0,0,0,7.343,8.4v2.225a1.133,1.133,0,0,0,1.872.785l5.662-4.743A8.975,8.975,0,0,0,221.742,77a7.718,7.718,0,0,0,.673-3.23c0-4.729-4.091-8.563-9.139-8.563m-4.4,10a1.62,1.62,0,1,1,1.725-1.616,1.673,1.673,0,0,1-1.725,1.616m4.278,0a1.62,1.62,0,1,1,1.725-1.616,1.673,1.673,0,0,1-1.725,1.616m4.278,0a1.62,1.62,0,1,1,1.725-1.616,1.674,1.674,0,0,1-1.725,1.616" transform="translate(-204.137 -65.204)" fill="#fff" stroke={color} stroke-width="1.5"/>
  </g>
</svg>
)

