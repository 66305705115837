import React from 'react'

export default  () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15.828" height="15.828" viewBox="0 0 15.828 15.828">
    <g id="X_nuevapregunta" transform="translate(1.414 1.414)">
      <line id="Línea_1" data-name="Línea 1" x2="13" y2="13" fill="none" stroke="#6c6a69" stroke-linecap="round" stroke-width="2"/>
      <line id="Línea_2" data-name="Línea 2" x1="13" y2="13" fill="none" stroke="#6c6a69" stroke-linecap="round" stroke-width="2"/>
    </g>
  </svg>
)
