import React, { useEffect, useState } from "react";
import { css, jsx } from "@emotion/react";
import "../../assets/css/Landing.css";
import { Link } from "react-router-dom";
import { Box, Image, Avatar, Text, Flex, Skeleton } from "@chakra-ui/react";
import CategoryCard from "./CategoryCard";
import { useLangStore } from "../../hooks/state/useLangStore";
import lang from "../../assets/lang/general";

export default function Categorias({ categories }) {
  const currentLang = useLangStore((state) => state.currentLang);

  return (
    <React.Fragment>
      <main role="main">
        <Box maxW="800px" mx="auto">
          <Box p="20px">
            <Text fontWeight="bold">{lang[currentLang].welcome}</Text>
            <Text>{lang[currentLang].explore}</Text>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            css={css`
              flex-flow: row wrap;
            `}
          >
            {categories &&
              categories?.length &&
              categories.map((categoria, index) => (
                <CategoryCard categoria={categoria} />
              ))}
            <hr></hr>
          </Box>
        </Box>
      </main>
    </React.Fragment>
  );
}
