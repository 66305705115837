/** jsx @jsx */
import React, { useState, useEffect } from 'react';
import {jsx, css} from '@emotion/react'
import jwt_decode from 'jwt-decode';
import {Box, 
    Avatar, 
    Text, 
    Flex, 
    Button, 
    Divider, 
    Grid, 
    Image,
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerHeader,
    DrawerBody
} from '@chakra-ui/react';
import {useHistory} from 'react-router-dom'
import {useAuthStore} from '../../hooks/state/useAuthStore';
import {useDrawerStore} from '../../hooks/state/useDrawerStore';
import {useCreateConversation} from '../../hooks/queries/conversation'

import {Row, Col, Container} from 'react-bootstrap'
import IconoPreguntar from '../../assets/icons/IconoPreguntar'
import IconoRespuesta from '../../assets/icons/IconoRespuesta'
import IconoComentar from '../../assets/icons/IconoComentar'
import Questions from './content/Questions'
import Answers from './content/Answers'
import Comments from './content/Comments'

import {useGetUser} from '../../hooks/queries/usuarios'

function UserProfile(props) {

    const userId = props.match.params.id;
    const userData = useAuthStore(state => state.data);
    const logOut = useAuthStore(state => state.logOut);
    const history = useHistory();
    const [contentActive, setContentActive] = useState(1);
    const [createConversation ,createQuestionQuery] = useCreateConversation();
    const {isLoading, data, refetch} = useGetUser({id: userId});
    const drawerIsOpen = useDrawerStore(state => state.isOpen);
    const setDrawerIsOpen = useDrawerStore(state => state.setOpen)
    
    const contentComponents = [
        {id: 1, component: <Questions userData={data}/>},
        {id: 2, component: <Answers userData={data}/>},
        {id: 3, component: <Comments userData={data}/>},

    ]

    function drawerOpen() {
        setDrawerIsOpen(true)
    }

    function drawerClose() {
        setDrawerIsOpen(false)
    }

    function sendMessage() {
        createConversation({
            userOne: Number(userData.id),
            userTwo: Number(userId)
        }, 
        {
            onSuccess: (res) => {
                history.push('/chat')
            },
            onError: err => {
                console.log(err)
            }
        }
            )
    }

    useEffect(() => {
        if (userData.id === Number(userId)) {
            history.push('/perfil')
        }
    })

    return(
        <Container fluid>

            {
                !isLoading && 
                <>
                <Box maxWidth="1200px" py="20px">
                
                <Row>
                    <Col className="d-none d-md-block" md={{span:3, order: 1}}>
                        <Box textAlign="center">
                            <Avatar size="2xl" name={data.firstName} src={data.imgUrl?? null}/>
                            <Box my="10px">
                                <Text fontSize="lg" textTransform="capitalize" fontWeight="bold">{data.firstName + " " + data.lastName}</Text>
                                <Text fontSize="sm">{data.type}</Text>
                            </Box>
                        </Box>
                        <Box position="sticky"
                            top="20px" 
                            borderRadius="5px">
                            <Box p="10px 30px">
                                <Box>
                                    <Flex py="15px" 
                                        cursor="pointer"
                                        textAlign="center"
                                        mx="auto"
                                        width="fit-content"
                                        onClick={e => {setContentActive(1)}}>
                                        <Box alignSelf="center">
                                            <IconoPreguntar fontSize="20px" bg={contentActive === 1 ? "#FE6E50": "#000"}/>
                                        </Box>
                                        <Text ml="5px" color={contentActive === 1 ? "#FE6E50": "#000"}>Preguntas</Text>
                                    </Flex>
                                    <Flex py="15px" 
                                        cursor="pointer"
                                        textAlign="center"
                                        mx="auto"
                                        width="fit-content"
                                        onClick={e => {setContentActive(2)}}>
                                        <IconoRespuesta fontSize="20px" color={contentActive === 2 ? "#FE6E50": "#000"}/>
                                        <Text ml="5px" color={contentActive === 2 ? "#FE6E50": "#000"}>Respuestas</Text>
                                    </Flex>
                                    <Flex py="15px" 
                                        cursor="pointer"
                                        textAlign="center"
                                        mx="auto"
                                        width="fit-content"
                                        onClick={e => {setContentActive(3)}}>
                                        <IconoComentar color={contentActive === 3 ? "#FE6E50": "#000"}/>
                                        <Text ml="5px" color={contentActive === 3 ? "#FE6E50": "#000"}>Comentarios</Text>
                                    </Flex>
                                </Box>
                                <Divider/>
                                <Box py="20px">
                                    <Box width="fit-content" m="auto">
                                        <Image width="70px" src={require("../../assets/images/logos/logo-azul-orange-v2020.png")}/>
                                    </Box>
                                    <Box>
                                        <Text fontSize="xs" color="gray.1" textAlign="center">
                                            <Text cursor="pointer" as="span" mx="3px" onClick={() => {history.push('/sobre-icuida')}}>Sobre iCuida</Text>- 
                                            <Text cursor="pointer" as="span" mx="3px" onClick={() => {history.push('/terminos-condiciones')}}>Condiciones</Text>- 
                                            <Text cursor="pointer" as="span" mx="3px" onClick={() => {history.push('/politica-privacidad')}}>Privacidad</Text>-
                                            <Text cursor="pointer" as="span" mx="3px" onClick={() => {history.push('/aviso-legal')}}>Aviso legal</Text>- 
                                            <Text cursor="pointer" as="span" mx="3px" onClick={() => {history.push('/politica-cookies')}}>Politica de cookies</Text>- 
                                            <Text cursor="pointer" as="span" mx="3px" onClick={() => {history.push('/ayuda')}}>Ayuda</Text>- 
                                            <Text cursor="pointer" as="span" mx="3px" onClick={() => {history.push('/contacta')}}>Contáctanos</Text>
                                        </Text>
                                    </Box>
                                </Box>
                            </Box>  
                        </Box>
                    </Col>
                    <Col xs={{span: 12, order: 3}} md={{span:6, order: 2}}>
                        <Box mb="15px" p="20px" bg="white" borderRadius="5px" boxShadow="0px 3px 6px #00000029">
                            <Flex justifyContent="flex-end">
                                <Box>
                                    <Button colorScheme="teal" 
                                            variant="outline"
                                            onClick={e => sendMessage()}
                                    >Enviar Mensaje</Button>
                                </Box>               
                            </Flex>
                        </Box>
                        <Box mb="15px">

                        {
                            contentComponents.map(value => {
                                if ( value.id === contentActive)
                                return value.component
                            })
                        }
                        </Box>
                    </Col>
                    <Col xs={{span: 12, order: 2}} md={{span:3, order: 3}}>
                        <Box mb="15px" p="20px" bg="white" minH="150px" borderRadius="5px" boxShadow="0px 3px 6px #00000029">
                            <Box mb="5px">
                                <Text fontWeight="bold" fontSize="small" color="gray.1">Biografia</Text>
                            </Box>
                            <Text>{data.description}</Text>
                        </Box>
                    </Col>
                </Row>
            </Box>
            <Drawer placement="left" onClose={drawerClose} isOpen={drawerIsOpen} blockScrollOnMount={false}>
                <DrawerOverlay />
                <DrawerContent maxH="100vh">
                <DrawerHeader borderBottomWidth="1px"><Text>Mi cuenta</Text></DrawerHeader>
                <DrawerBody>
                    <Box p="10px 30px">
                        <Box px="30px">
                            <Flex py="15px" 
                                cursor="pointer"
                                onClick={e => {
                                            setContentActive(1)
                                            drawerClose()
                                        }}>
                                <Box alignSelf="center">
                                    <IconoPreguntar fontSize="20px" bg={contentActive === 1 ? "#FE6E50": "#000"}/>
                                </Box>
                                <Text ml="5px" color={contentActive === 1 ? "#FE6E50": "#000"}>Preguntas</Text>
                            </Flex>
                            <Flex py="15px" 
                                cursor="pointer"
                                onClick={e => {
                                            setContentActive(2)
                                            drawerClose()
                                        }}>
                                <IconoRespuesta fontSize="20px" color={contentActive === 2 ? "#FE6E50": "#000"}/>
                                <Text ml="5px" color={contentActive === 2 ? "#FE6E50": "#000"}>Respuestas</Text>
                            </Flex>
                            <Flex py="15px" 
                                cursor="pointer"
                                onClick={e => {
                                            setContentActive(3)
                                            drawerClose()
                                        }}>
                                <IconoComentar color={contentActive === 3 ? "#FE6E50": "#000"}/>
                                <Text ml="5px" color={contentActive === 3 ? "#FE6E50": "#000"}>Comentarios</Text>
                            </Flex>
                        </Box>
                        <Box py="20px">
                            <Box width="fit-content" m="auto">
                                <Image width="70px" src={require("../../assets/images/logos/logo-azul-orange-v2020.png")}/>
                            </Box>
                            <Box>
                                <Text fontSize="xs" color="gray.1" textAlign="center">
                                    <Text cursor="pointer" as="span" mx="3px" onClick={() => {history.push('/sobre-icuida')}}>Sobre iCuida</Text>- 
                                    <Text cursor="pointer" as="span" mx="3px" onClick={() => {history.push('/terminos-condiciones')}}>Condiciones</Text>- 
                                    <Text cursor="pointer" as="span" mx="3px" onClick={() => {history.push('/politica-privacidad')}}>Privacidad</Text>-
                                    <Text cursor="pointer" as="span" mx="3px" onClick={() => {history.push('/aviso-legal')}}>Aviso legal</Text>- 
                                    <Text cursor="pointer" as="span" mx="3px" onClick={() => {history.push('/politica-cookies')}}>Politica de cookies</Text>- 
                                    <Text cursor="pointer" as="span" mx="3px" onClick={() => {history.push('/ayuda')}}>Ayuda</Text>- 
                                    <Text cursor="pointer" as="span" mx="3px" onClick={() => {history.push('/contacta')}}>Contáctanos</Text>
                                </Text>
                            </Box>
                        </Box>
                    </Box>  
                </DrawerBody>
                </DrawerContent>
            </Drawer>
                </>
            }
        </Container>
        )
    
}

export default UserProfile;