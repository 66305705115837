import React, { useState, useEffect } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import ChatMain from "./sections/ChatMain";
import ChatSideBar from "./sections/chat-sidebar";
import io from "socket.io-client";
import MediaQuery from "react-responsive";
import { useLangStore } from "../../hooks/state/useLangStore";
import lang from "../../assets/lang/general";
import socket, { receiveMSG, RunSocket } from "../../api/socket";
import { useDispatch, useSelector } from "react-redux";
// import socket from '../../api/socket'

function Chat() {
  const [selectedRoom, setSelectedRoom] = useState({});
  const [sendUser, setSendUser] = useState({});
  const [message, setMessage] = useState({});
  let getmsg = useSelector((state) => state?.message);
  const dispatch = useDispatch();
  const currentLang = useLangStore((state) => state.currentLang);
  // const room = selectedRoom ? `${selectedRoom.UserOne.id}-${selectedRoom.UserTwo.id}`: null;
  // const url = process.env.NODE_ENV === 'production' ? "/api/socket.io/" : "/socket.io/";
  // const socket = io({
  //     path: url,
  //     query: {id: room},
  //     withCredentials: true
  // });
  // useEffect(() => {
  // socket.on("receive-message", (data) => {
  // setMessage(getmsg?.message?.data);

  // })
  // const data = newService.receiveMsg();
  // console.log(data);
  // },[getmsg, message])

  useEffect(() => {
    RunSocket();
  }, [socket]);

  useEffect(() => {
    if (Object.keys(getmsg).length === 0) {
      receiveMSG(dispatch);
    }
  }, [getmsg]);

  useEffect(() => {
    setSendUser(Object.keys(getmsg).length !== 0 ? getmsg : message);
  }, [message, getmsg]);

  return (
    <>
      <MediaQuery minWidth="824px">
        <Box p="30px">
          <Flex borderRadius="5px" borderWidth="1px" bg="white">
            <Box width="30%">
              <ChatSideBar
                selectedRoom={selectedRoom}
                selectRoom={(data) => setSelectedRoom(data)}
                message={sendUser}
                getmsg={getmsg}
                // setSendUser={(data) => setSendUser(data)}
              />
            </Box>
            <Box width="70%">
              {selectedRoom !== undefined && selectedRoom && Object.keys(selectedRoom).length !== 0 ? (
                // socket={socket} room={room}
                <ChatMain
                  selectedRoom={selectedRoom}
                  setSelectedRoom={(data) => setSelectedRoom(data)}
                  message={getmsg}
                  sendUser={sendUser}
                  setSendUser={(data) => setMessage(data)}
                />
              ) : (
                <Flex height="400px">
                  <Box alignSelf="center" mx="auto">
                    <Text fontSize="lg" fontWeight="bold" color="gray">
                      {lang[currentLang].conversations}
                    </Text>
                  </Box>
                </Flex>
              )}
            </Box>
          </Flex>
        </Box>
      </MediaQuery>
      <MediaQuery maxWidth="824px">
        <Box p="30px">
          {selectedRoom !== undefined && selectedRoom && Object.keys(selectedRoom).length !== 0 ? (
            <Box bg="white" width="100%" borderRadius="5px">
              {/* socket={socket} room={room} */}
              <ChatMain
                selectedRoom={selectedRoom}
                setSelectedRoom={(data) => setSelectedRoom(data)}
                message={getmsg}
                sendUser={sendUser}
                setSendUser={(data) => setMessage(data)}
              />
            </Box>
          ) : (
            <Box>
              <Flex borderRadius="5px" borderWidth="1px" bg="white">
                <Box width="100%">
                  <ChatSideBar
                    selectedRoom={selectedRoom}
                    selectRoom={(data) => setSelectedRoom(data)}
                    message={sendUser}
                    // setSendUser={(data) => setSendUser(data)}
                  />
                </Box>
              </Flex>
            </Box>
          )}
        </Box>
      </MediaQuery>
    </>
  );
}

export default Chat;
