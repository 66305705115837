const lang = {
  es_ES: {
    titleCaregiverCardPage: "Tarjeta Cuidadora",
    whatIs: "¿Qué es?",
    titleCaregiverCardMeaning:
      "La Tarjeta Cuidadora es una herramienta gratuita que sirve para acceder a recursos personalizados dirigidos a cuidadores. La Tarjeta quiere reconocer su tarea, acompañarlos en el proceso de los cuidados y facilitar su actividad cuidadora y contribuir a su bienestar.",
    caregiverCardOffers: "La Tarjeta Cuidadora ofrece:",
    pointOne:
      "Información y asesoramiento personalizado presencial, online y telefónico sobre recursos dirigidos a cuidadores.",
    pointTwo:
      "Acceso a una programación estable de actividades relacionales y grupos de apoyo donde vincularse con otras personas que comparten una situación similar.",
    pointThree:
      "Acceso a un servicio telefónico gratuito de acompañamiento emocional.",
    pointFour:
      "Asesoramiento jurídico a familias y trabajadoras del hogar y de los cuidados en cuanto a aspectos relacionados con la contratación.",
    pointFive:
      "Acceso a los recursos específicos que se ofrecen a las personas cuidadoras como farmacias, bibliotecas, hospitales, mercados municipales o actividades deportivas.",
    pointSix:
      "Acceso a diferentes actividades de ocio para disfrutar individualmente o compartirlas con la persona que cuidan.",
    whoIsItAddressedTo: "¿A quién se dirige?",
    addressedTo_sentence1:
      "Las personas que cuidan a personas enfermas, dependientes, con discapacidad o personas mayores en la ciudad de Barcelona pueden disponer de esta Tarjeta.",
    addressedTo_sentence2:
      "La pueden obtener las personas cuidadoras del entorno cercano a la persona cuidada, las trabajadoras del hogar y del cuidado y las profesionales del cuidado.",
    howCanIgetTt: "¿Cómo puedo conseguirla?",
    getIt_sentence1:
      "La tarjeta es gratuita y se consigue a través de dos mecanismos:",
    getIt_option1:
      "En línea: mediante un formulario disponible en la plataforma web del Espai Barcelona Cuida ",
    getIt_option2:
      "Las personas que prefieran realizar el trámite presencialmente pueden hacerlo en el Espacio Barcelona Cuida (c/Viladomat, 127) y en todas las zonas Vila Veïna de la ciudad.",
  },
  ca: {
    titleCaregiverCardPage: "Targeta Cuidadora",
    whatIs: "Què és?",
    titleCaregiverCardMeaning:
      "La Targeta Cuidadora és una eina gratuïta que serveix per accedir a recursos personalitzats adreçats a persones cuidadores. La Targeta vol reconèixer la seva tasca, acompanyar-les en el procés de les cures i facilitar la seva activitat cuidadora i contribuir al seu benestar.",
    caregiverCardOffers: "La Targeta Cuidadora ofereix:",
    pointOne:
      "Informació i assessorament personalitzat presencial, online i telefònic sobre recursos adreçats a les persones cuidadores.",
    pointTwo:
      "Accés a una programació estable d'activitats relacionals i a grups de suport on vincular-se amb altres persones que comparteixen una situació similar.",
    pointThree:
      "Accés a un servei telefònic gratuït d'acompanyament emocional.",
    pointFour:
      "Assessorament jurídic a famílies i treballadores de la llar i de les cures pel que fa a aspectes relacionats amb la contractació.",
    pointFive:
      "Accés als recursos específics que s'ofereixen a les persones cuidadores com ara farmàcies, biblioteques, hospitals, mercats municipals o activitats esportives.",
    pointSix:
      "Accés a diferents activitats d'oci per gaudir-ne individualment o compartir-les amb la persona que cuiden.",
    whoIsItAddressedTo: "A qui s'adreça?",
    addressedTo_sentence1:
      "Les persones que tenen cura de persones malaltes, depenents, amb discapacitat o persones grans a la ciutat de Barcelona poden disposar d'aquesta Targeta.",
    addressedTo_sentence2:
      "La poden obtenir les persones cuidadores de l'entorn proper a la persona cuidada, les treballadores de la llar i de la cura i les professionals de la cura.",
    howCanIgetTt: "Com la puc aconseguir?",
    getIt_sentence1:
      "La targeta és gratuïta i es pot aconseguir a través de dos mecanismes:",
    getIt_option1:
      "En línia: mitjançant un formulari disponible a la plataforma web de l'Espai Barcelona Cuida  ",
    getIt_option2:
      "Les persones que prefereixin fer el tràmit presencialment ho poden fer a l’Espai Barcelona Cuida (c/Viladomat, 127) i a totes les zones Vila Veïna de la ciutat.",
  },
};

export default lang;
