import axios from "axios";
const baseUrl = "/";
// process.env.NODE_ENV === "production" ? "/api/" :

export function createNotification(newNotification) {
  return axios
    .post(baseUrl + "notifications/", newNotification)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
}

export function createBcnCuidaNotification(newNotification) {
  return axios
    .post(
      baseUrl + "notifications/barcelona-cuida-notification",
      newNotification
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
}

export function getAllNotifications(userId) {
  return axios
    .put(baseUrl + "notifications/user-notifications", { userId: userId })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
}

export function getChatCount(userId) {
  return axios
    .put(baseUrl + `chatnotify/${userId}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
}

export function deleteChatNoti(data) {
  return axios
    .delete(
      baseUrl + `chatnotify/delete/${data?.receiverId}/${data?.conversationId}`
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
}
