import React, {useState} from 'react';
import { useHistory} from 'react-router-dom'
import {Box, Text, Skeleton, Flex, Image} from '@chakra-ui/react'
import {useLangStore} from '../../hooks/state/useLangStore'

function CategoryCard({categoria}) {

    const history = useHistory();
    const [imageMounted, setImageMounted] = useState(false)
    const currentLang = useLangStore(state=>state.currentLang)

    return(
        <Box cursor="pointer" width={{base: "160px", md: "167px"}} borderWidth="1px" rounded="lg" overflow="hidden" m="10px" onClick={e => history.push("/c/"+categoria.name) }>
            { categoria.imgUrl ?
                <Image height="59px" hidden={!imageMounted} width="100%" onLoad={e => setImageMounted(true)} src={require('../../assets/images/categorias/'+categoria.imgUrl)}/>
                : ""
            }
                <Skeleton hidden={imageMounted} height="59px"/>
            <Flex minH="130px" p="20px 10px" bg="#fff">
                <Box height="100%" alignSelf="center" margin="auto">
                    <Text
                        fontSize="sm"
                        textAlign="center"
                        mb="3px"
                        fontWeight="semibold"
                        lineHeight="tight">
                        {currentLang === "es_ES" ? categoria.category : categoria.categoryCat}
                    </Text>
                </Box>
            </Flex>

        </Box>
    )
}

export default CategoryCard;