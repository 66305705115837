/** jsx */
import React from "react";
import { jsx, css } from "@emotion/react";
import { ReactQueryDevtools } from "react-query-devtools";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import { Box } from "@chakra-ui/react";
import Navbar from "./components/Navbar";
import MobileNavbar from "./components/MobileNavbar";
import NavbarAdmin from "./components/NavbarAdmin";
import Content from "./pages/content/index";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Profile from "./pages/profile/index";
import Chat from "./components/live-chat/index";
import UserProfile from "./pages/profile/UserProfile";
import ForgotPassword from "./pages/forgot-password/index";
import SetNewPass from "./pages/forgot-password/SetNewPass";
import VerificacionSeguridad from "./pages/forgot-password/VerificacionSeguridad";
// import PreguntasOficiales from "./components/PreguntasOficiales";
import PrivateRoute from "./components/PrivateRoute";
import PublicRoute from "./components/PublicRoute";
import SlideUp from "./components/SlideUp";
import VerifyMail from "./pages/VerifyMail";
import SendVerifyMail from "./components/SendVeriMail";
import ErrorPage from "./pages/ErrorPage";
import Loading from "./components/Loading";
import "./assets/data-pdf/beca.2015.1.arquitecturas.del.cuidado.pdf";
import "./assets/data-pdf/Condiciones-de-trabajo-y-riesgos-de-las-actividades-en-el-hogar.pdf";
import "./assets/data-pdf/Diptic-generic-Sants-Montjuic-ES_tcm24-49747.pdf";
import "./assets/data-pdf/Diptic_generic-esp1-derechos-laborales.pdf";
import "./assets/data-pdf/economia_i_politica_13.pdf";
import "./assets/data-pdf/El-cuidado-de-las-personas-un-reto-para-el-siglo-XXI.pdf";
// import "./assets/data-pdf/Empleadas-de-hogar-un-caso-de-discriminación.pdf";
import "./assets/data-pdf/estudio-servicio-domestico-INE-2012.pdf";
import "./assets/data-pdf/FOLLETOPARA-PERSONAS-EMPLEADORAS.pdf";
import "./assets/data-pdf/guia_cuidem_qui_ens_cuida.pdf";
import "./assets/data-pdf/informeceres_21febrer2018-i.pdf";
// import "./assets/data-pdf/resumen_ejecutivo-Informe-sobre-la-evolución.pdf";
import "./assets/data-pdf/temps-cura-i-ciudadania-web.pdf";
import "./assets/data-pdf/tesi-lacuraacatalunya.pdf";
import "./assets/data-pdf/tracant_vides_dignes_cap_a_una_economia_feminista_0.pdf";
import "./assets/data-pdf/El-Trabajo-domestico-cuentaFUNCAS.pdf";
import customTheme from "./theme";
import { useGetAllCategories } from "./hooks/queries/categoria";
import { useCategoryStore } from "./hooks/state/useCategoryStore";
import { useAuthStore } from "./hooks/state/useAuthStore";
import { useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import forgotPassword from "./pages/forgot-password";
import CookieSticker from "./components/cookies/CookieSticker";
import PoliciesContainer from "./pages/PoliciesContainer";
import AdminPanel from "./pages/admin";
import Cookies from "js-cookie";
import Contact from "./pages/Contacto";
import FaqsOfficials from "./pages/FaqsOfficials";
import { Provider } from "react-redux";
// import store from './store';
import rootReducers from "./store/reducer";
import { legacy_createStore as createStore } from "redux";
import CreateEvent from "./pages/admin/sections/events/createEvent";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import RecommendLanding from "./pages/recommend-landing/RecommendLanding";
import Resources from "./pages/Resources/Resources";
import Esdeveniments from "./pages/Esdeveniments/EsdevenimentsLanding";
import Croniques from "./pages/croniques/CronicaLanding";

const store = createStore(rootReducers);

function App() {
  const getAllCategories = useGetAllCategories();
  const setCategories = useCategoryStore((state) => state.setAllCategories);
  const userData = useAuthStore((state) => state.data);

  useEffect(() => {
    if (!getAllCategories.isLoading) {
      setCategories(getAllCategories);
    }
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Box
          height="100vh"
          width="100%"
          position="fixed"
          zIndex="-1"
          background="linear-gradient(180deg,rgba(254,110,96,0.82) 0%,rgba(178,228,242,0.34) 30%) !important"
        />
        {!getAllCategories.isLoading ? (
          <Switch>
            <PublicRoute path="/register" component={Register} />
            <PublicRoute path="/verificar/:id" component={VerifyMail} />
            <PublicRoute path="/enviar-codigo/:id" component={SendVerifyMail} />
            <PublicRoute path="/login" component={Login} />
            <PublicRoute path="/recuperar-cuenta" component={ForgotPassword} />
            <PublicRoute
              path="/verificacion"
              component={VerificacionSeguridad}
            />
            <PublicRoute path="/cambiar-contra" component={SetNewPass} />
            <Route path="/recomana" component={RecommendLanding} />
            <Route path="/recursos" component={Resources} />
            <Route path="/esdeveniments" component={Esdeveniments} />
            <Route path="/croniques" component={Croniques} />

            <Route
              exact
              path={[
                "/terminos-condiciones",
                "/politica-uso",
                "/politica-privacidad",
                "/politica-cookies",
                "/sobre-Icuida",
                "/ayuda",
                "/contacta",
                "/tarjeta-cuidadora",
              ]}
            >
              <PoliciesContainer />
            </Route>

            <main role="main" className="App icuida">
              {userData && (
                <React.Fragment>
                  <Navbar
                    cssStyle={css`
                      @media (max-width: 1200px) {
                        display: none;
                      }
                    `}
                  />
                  <MobileNavbar
                    css={css`
                      @media (min-width: 1200px) {
                        display: none;
                      }
                    `}
                  />
                </React.Fragment>
              )}
              {userData && userData.isAdmin && <NavbarAdmin />}
              {userData && userData.userCreator && <NavbarAdmin />}
              {userData && userData.isAdmin && (
                <PrivateRoute
                  exact
                  path={[
                    "/panel-administrador/usuarios",
                    "/panel-administrador/contenido",
                    "/panel/metricas",
                    "/panel/eventos",
                  ]}
                >
                  <AdminPanel />
                </PrivateRoute>
              )}{" "}
              {userData && userData.userCreator && (
                <PrivateRoute
                  exact
                  path={["/panel/metricas", "/panel/eventos"]}
                >
                  <AdminPanel />
                </PrivateRoute>
              )}
              <Box className="icuida" maxWidth="1200px" m="auto">
                <PrivateRoute path="/perfil" component={Profile} />
                <PrivateRoute path="/chat" component={Chat} />

                <PrivateRoute
                  path="/perfil-usuario/:id"
                  component={UserProfile}
                />

                <PrivateRoute
                  exact
                  path="/preguntas-oficiales"
                  component={FaqsOfficials}
                />

                <PrivateRoute
                  exact
                  path="/crear-evento"
                  component={CreateEvent}
                />
                <PrivateRoute
                  exact
                  path={[
                    "/",
                    "/test",
                    "/search",
                    "/responder",
                    "/preguntar",
                    "/pregunta/:id",
                    "/crear-evento/:id",
                    "/pregunta-estatica/:id",
                    "/categorias",
                    "/c/:category",
                    "/crear-pregunta-estatica",
                    "/notificaciones",
                    "/eventos",
                  ]}
                  categories={getAllCategories.data}
                  component={Content}
                />
              </Box>
              {/*<Route>
                            <ErrorPage/>
                        </Route>*/}
              <SlideUp />
            </main>
          </Switch>
        ) : (
          <Loading />
        )}
        {/* <Footer/>*/}
        <ReactQueryDevtools initialIsopen />
      </Router>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Provider>
  );
}

export default App;
