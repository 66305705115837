
import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {
    Box, Flex,
    Avatar, Text,
    Divider
} from '@chakra-ui/react'

import {comment} from '../../../../api/CommentFunctions'
import UserAvatar from "../../../../components/UserAvatar";


function CommentLikeNotiCard({data,commentId}) {

    const history = useHistory()

    const [commentData, setCommentData] = useState(null)

    function getCommentData() {
        comment(commentId)
        .then(res => {
            if (res.data) {
                setCommentData(res.data)
            }
        })
        .catch(err => {
            console.log(err)
        })
    }

    useEffect(() =>{ 
        getCommentData()
        
    }, [])

    return (
        <Box>
            {
                (data.SenderId?.firstName && commentData?.comment) &&
                <Box>
                    <Flex mb="10px">
                        <UserAvatar userData={data.SenderId} />
                        <Box ml="10px">
                            <Text>{data.SenderId.firstName} le gusta tu comentario:</Text>
                            <Text color="gray.2" fontSize="sm">{data.SenderId.type}</Text>
                        </Box>
                    </Flex>
                    <Box>
                        <Text  cursor="pointer" onClick={e => history.push("/pregunta/" + commentData.id)} fontWeight="bold" dangerouslySetInnerHTML={{__html:commentData.comment}}></Text>
                    </Box>
                    <Divider/>
                </Box>
            }
        </Box>
    )
}

export default CommentLikeNotiCard;