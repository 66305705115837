import React, { useState, Component } from "react";
import {
  Box,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Image,
  useDisclosure,
} from "@chakra-ui/react";
import Slider from "react-slick";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { ReactComponent as ClicaTimeline } from "../assets/images/timeline/Clica_Timeline_icuida.svg";
import { ReactComponent as TimelineOne } from "../assets/images/timeline/timeline_01_icuida.svg";
import { ReactComponent as TimelineTwo } from "../assets/images/timeline/timeline_02_icuida.svg";
import { ReactComponent as TimelineThree } from "../assets/images/timeline/timeline_03_icuida.svg";
import { ReactComponent as TimelineFour } from "../assets/images/timeline/timeline_04_icuida.svg";
import { ReactComponent as TimelineFive } from "../assets/images/timeline/timeline_05_icuida.svg";
import { ReactComponent as TimelineSix } from "../assets/images/timeline/timeline_06_icuida.svg";
import { ReactComponent as TimelineSeven } from "../assets/images/timeline/timeline_07_icuida.svg";

function TimelineButton() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const finalRef = React.useRef();

  const data = [
    <TimelineOne />,
    <TimelineTwo />,
    <TimelineThree />,
    <TimelineFour />,
    <TimelineFive />,
    <TimelineSix />,
    <TimelineSeven />,
  ];

  const settings = {
    dots: false,
    infinite: false,
    autoplay: true,
    speed: 3000,
    slideToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <Box width="fit-content" mx="auto" pb="20px">
        <ClicaTimeline onClick={onOpen} />
      </Box>
      <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay p="0">
          <ModalContent widht="100%">
            <ModalBody>
              {/*<AliceCarousel
                        disableDotsControls
                        touchMoveDefaultEvents="true"
                        animationDuration={800}
                        items={data}
                        />*/}
              <Slider widht="100%" {...settings}>
                {data.map((val) => val)}
              </Slider>
            </ModalBody>
            <Text
              mb="10px"
              fontWeight="semibold"
              onClick={() => onClose()}
              textAlign="center"
            >
              Cerrar
            </Text>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  );
}

class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <div>
        <h2> Single Item</h2>
        <Slider {...settings}>
          <div>
            <h3>1</h3>
          </div>
          <div>
            <h3>2</h3>
          </div>
          <div>
            <h3>3</h3>
          </div>
          <div>
            <h3>4</h3>
          </div>
          <div>
            <h3>5</h3>
          </div>
          <div>
            <h3>6</h3>
          </div>
        </Slider>
      </div>
    );
  }
}

export default TimelineButton;
