import {Box, Text, Image} from "@chakra-ui/react";
import React from "react";
import {useLangStore} from "../../hooks/state/useLangStore";
import lang from "../../assets/lang/resources";

function RecommendContent() {
  const currentLang = useLangStore((state) => state.currentLang);

  return (

    <Box maxW="800px" mx="auto" p="20px" borderRadius="5px">
      {/* 
      <Box mb="15px" mt="15px">
        <Text fontWeight="bold" fontSize="25px" color='tomato' align='center'>
          {lang[currentLang].landingPage.title}
        </Text>
      </Box>
      */}
      <Box mb="15px">
        <Text fontSize="22px">
        <a href={lang[currentLang].viz1.url} target='_blank'>{lang[currentLang].viz1.title}</a>
        </Text>{" "}
      </Box>
      <Box mb="15px">
        <Text fontSize="16px">
          {lang[currentLang].viz1.text1}
        </Text>{" "}
      </Box>
      <Box mb="15px">
      <div className="App" dangerouslySetInnerHTML={{__html: lang[currentLang].viz1.code}}></div>
      </Box>
      <Box mb="15px">
        <Text fontSize="11px">
        {lang[currentLang].viz1.presource}<a href={lang[currentLang].viz1.urlSource} target='_blank'>{lang[currentLang].viz1.source}</a>
        </Text>{" "}
      </Box>

      <Box mb="15px">
        <Text fontSize="22px">
          {lang[currentLang].viz2.title}
        </Text>{" "}
      </Box>
      <Box mb="15px">
        <Text fontSize="16px">
          {lang[currentLang].viz2.text1}
        </Text>{" "}
      </Box>
      <Box mb="15px">
        <Text fontSize="16px">
          {lang[currentLang].viz2.text2}
        </Text>{" "}
      </Box>
      <Box mb="15px">
      <div className="App" dangerouslySetInnerHTML={{__html: lang[currentLang].viz2.code}}></div>
      </Box>
      <Box mb="15px">
        <Text fontSize="11px">
        {lang[currentLang].viz2.presource}<a href={lang[currentLang].viz1.urlSource} target='_blank'>{lang[currentLang].viz2.source}</a>
        </Text>{" "}
      </Box>

      <Box mb="15px">
        <Text fontSize="22px">
          {lang[currentLang].viz3.title}
        </Text>{" "}
      </Box>
      <Box mb="15px">
        <Text fontSize="16px">
          {lang[currentLang].viz3.text1}
        </Text>{" "}
      </Box>
      <Box mb="15px">
        <Text fontSize="16px">
          {lang[currentLang].viz3.text2}
        </Text>{" "}
      </Box>
      <Box mb="15px">
      <div className="App" dangerouslySetInnerHTML={{__html: lang[currentLang].viz3.code}}></div>
      </Box>
      <Box mb="15px">
        <Text fontSize="11px">
        {lang[currentLang].viz3.presource}<a href={lang[currentLang].viz3.urlSource} target='_blank'>{lang[currentLang].viz3.source}</a>
        </Text>{" "}
      </Box>

      <Box mb="15px">
        <Text fontSize="22px">
          {lang[currentLang].viz4.title}
        </Text>{" "}
      </Box>
      <Box mb="15px">
        <Text fontSize="16px">
          {lang[currentLang].viz4.text1}
        </Text>{" "}
      </Box>
      
      <Box mb="15px">
      <div className="App" dangerouslySetInnerHTML={{__html: lang[currentLang].viz4.code}}></div>
      </Box>
      <Box mb="15px">
        <Text fontSize="11px">
        {lang[currentLang].viz4.presource}<a href={lang[currentLang].viz4.urlSource} target='_blank'>{lang[currentLang].viz4.source}</a>
        </Text>{" "}
      </Box>
       
    </Box>
  );
}

export default RecommendContent;