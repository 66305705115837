import axios from "axios";
const baseUrl = "/";
// process.env.NODE_ENV === "production" ? "/api/" :

export function getAllCategories() {
  return axios
    .put(baseUrl + "categories/")
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
}
