import React, { useState } from "react";
import { jsx, css } from "@emotion/react";
import { login } from "../api/UserFunctions";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import GoogleLog from "../components/GoogleLog";
import jwt_decode from "jwt-decode";
import FacebookLog from "../components/FacebookLog";
import {
  Box,
  Button,
  Text,
  Input,
  Image,
  Icon,
  IconButton,
  Flex,
} from "@chakra-ui/react";
import LogInButton from "../components/ui/LogInButton";
import { FaGoogle, FaFacebookF, FaAngleLeft } from "react-icons/all";
import Form from "../components/ui/Form";
import Errors from "../assets/functions/errors";
import { useAuthStore } from "../hooks/state/useAuthStore";
import { useLogIn } from "../hooks/queries/usuarios";
import Loading from "../components/Loading";
import Cookies from "js-cookie";
import CookieSticker from "../components/cookies/CookieSticker";
import lang from "../assets/lang/login";
import { useLangStore } from "../hooks/state/useLangStore";

import PoliciesContainer from "./PoliciesContainer";
import Footer from "../components/Footer";

function Login() {
  const [errors, setErrors] = useState([]);
  const [blocked, setBlocked] = useState(null);
  const [blockedGoogle, setBlockedGoogle] = useState(null);
  const [blockedFacebook, setBlockedFacebook] = useState(null);

  const setUser = useAuthStore((state) => state.setData);
  const [logIn, logInQuery] = useLogIn();
  const [iniciarSesion, setIniciarSesion] = useState(false);
  const currentLang = useLangStore((state) => state.currentLang);

  const history = useHistory();

  function onSubmit(formData) {
    setBlocked(null);
    logIn(formData, {
      onSuccess: (res) => {
        if (res) {
          const decoded = jwt_decode(res);
          if (decoded.isVerified) {
            setUser(res);
            Cookies.set("usertoken", res, { expires: 30 });
            history.push("/");
          } else history.push("/enviar-codigo/" + decoded.id);
        }
      },
      onError: (err) => {
        if(err[0]?.param === 'blocked') {
          setBlocked(err[0]);
        } else {
          setErrors(err);
        }
      },
    });
  }

  return (
    <Box minHeight="100%">
      <React.Fragment>
        <CookieSticker />

       {/*
        <Box ml="1rem" mt="1rem">
          <FaAngleLeft
            cursor="pointer"
            hidden={!iniciarSesion}
            color="gray"
            fontSize="40px"
            onClick={(e) => setIniciarSesion(false)}
          />
        </Box>
        */}

        <Box
          bg="none"
          maxWidth="450px"
          p="1rem"
          m="auto"
          css={css`
            @media (min-width: 800px) {
              margin-top: 100px;
            }
          `}
        >
          
          {/*
          <Box hidden={iniciarSesion} mb="3rem">
            
          </Box>
        */}

          <Box mt="0px" mb="45px">
          <Box m="auto" width="fit-content">
              <Image
                m="auto"
                width="150px"
                alt="logo-blue-orange-icuida"
                src={require("../assets/images/logos/logo-azul-orange-v2020.png")}
                onClick={(e) => setIniciarSesion(false)}
                cursor="pointer"
              />
            </Box>
            <Text textAlign="center" color="#6C6A69" fontSize="xl">
              {lang[currentLang].title}
            </Text>
              
            {/* per anular 
            <Text marginTop='20px' textAlign="center" color="#f29999" fontSize="S">
              <strong>{lang[currentLang].date}</strong>
            </Text>
              {<Image
                marginTop='5px'
                borderRadius='20px'
                src={lang[currentLang].imageBanner}
                alt={lang[currentLang].altBanner}
                onClick={() => history.push("/esdeveniments")}
                onClick={() => history.push("/croniques")} 
                cursor="pointer"
              />}
              */}
            </Box>

          {
            <Box mb="1rem" hidden={!iniciarSesion}>
              <Text
                mb="20px"
                textAlign="center"
                fontSize="2xl"
                color="#f29999"
              >
                {lang[currentLang].login}
              </Text>
              <Form
                initialValues={{
                  email: "",
                  password: "",
                }}
                onSubmit={onSubmit}
              >
                {({ setFieldValue, values }) => (
                  <Box>
                    <div className="form-group">
                      <div
                        className="wrap-input100 validate-input m-b-16"
                        data-validate="Valid email is required: ex@abc.xyz"
                      >
                        <Input
                          className="form-control"
                          backgroundColor="#fff"
                          type="email"
                          name="email"
                          borderRadius="50px"
                          placeholder={lang[currentLang].email}
                          value={values.email}
                          onChange={(e) =>
                            setFieldValue("email", e.target.value)
                          }
                        />
                        {Errors && Errors.showErrors(errors, "email")}
                      </div>
                    </div>
                    <div
                      className=" wrap-input100 validate-input m-b-16 form-group"
                      data-validate="Password is required"
                    >
                      <Input
                        type="password"
                        className="form-control"
                        backgroundColor="#fff"
                        name="password"
                        borderRadius="50px"
                        placeholder={lang[currentLang].passw}
                        value={values.password}
                        onChange={(e) =>
                          setFieldValue("password", e.target.value)
                        }
                      />
                      {Errors && Errors.showErrors(errors, "password")}
                      {blocked &&
                          <>
                            <Text mt="20px" fontSize="sm">{lang[currentLang].blockedText1}{blocked.extraParams.userName}{lang[currentLang].blockedText2}</Text>
                            <Text fontSize="sm">{lang[currentLang].blockedText3}</Text>
                            <Text fontSize="sm">{lang[currentLang].blockedText4} <a style={{color:"blue"}} href="/tarjeta-cuidadora">aquí</a>.</Text>
                            <Text fontSize="sm">{lang[currentLang].blockedText5}<a style={{color:"blue"}} href="mailto:icuidabcn@gmail.com">icuidabcn@gmail.com</a>{lang[currentLang].blockedText6}</Text>
                            <Text fontSize="sm">{lang[currentLang].blockedText7}</Text>
                          </>
                      }
                    </div>
                    <Button
                      type="submit"
                      bg="#FE6E60"
                      color="#fff"
                      border="#6C6A69"
                      width="100%"
                      fontWeight="thin"
                      borderRadius="50px"
                      aria-pressed="true"
                    >
                      {lang[currentLang].login}
                    </Button>
                    
                    <Button 
                      marginTop="10px" 
                      color="#fff"
                      bg="#8B8886"
                      width="100%"
                      fontWeight="thin"
                      borderRadius="50px"
                      aria-pressed="true"
                      onClick={(e) => setIniciarSesion(false)}
                    >
                      {lang[currentLang].return}
                    </Button>
                  </Box>
                )}
              </Form>
            </Box>
          }

            

          <div
            mb="1rem"
            hidden={iniciarSesion}
            className="text-center social-btn"
          >
            <GoogleLog setBlockedGoogle={setBlockedGoogle}>
              {" "}
              <i className="fa fa-google"> </i>
              <b> Google</b>
            </GoogleLog>
            {blockedGoogle &&
                <>
                  <Text mt="20px" fontSize="sm">{lang[currentLang].blockedText1}{blockedGoogle.extraParams.userName}{lang[currentLang].blockedText2}</Text>
                  <Text fontSize="sm">{lang[currentLang].blockedText3}</Text>
                  <Text fontSize="sm">{lang[currentLang].blockedText4} <a style={{color:"blue"}} href="/tarjeta-cuidadora">aquí</a>.</Text>
                  <Text fontSize="sm">{lang[currentLang].blockedText5}<a style={{color:"blue"}} href="mailto:icuidabcn@gmail.com">icuidabcn@gmail.com</a>{lang[currentLang].blockedText6}</Text>
                  <Text fontSize="sm" mb="20px">{lang[currentLang].blockedText7}</Text>
                </>
            }
            <FacebookLog setBlockedFacebook={setBlockedFacebook}>
              <i className="fa fa-facebook-official"></i>
              <b> Facebook</b>
            </FacebookLog>
            {blockedFacebook &&
                <>
                  <Text mt="20px" fontSize="sm">{lang[currentLang].blockedText1}{blockedFacebook.extraParams.userName}{lang[currentLang].blockedText2}</Text>
                  <Text fontSize="sm">{lang[currentLang].blockedText3}</Text>
                  <Text fontSize="sm">{lang[currentLang].blockedText4} <a style={{color:"blue"}} href="/tarjeta-cuidadora">aquí</a>.</Text>
                  <Text fontSize="sm">{lang[currentLang].blockedText5}<a style={{color:"blue"}} href="mailto:icuidabcn@gmail.com">icuidabcn@gmail.com</a>{lang[currentLang].blockedText6}</Text>
                  <Text fontSize="sm" mb="20px">{lang[currentLang].blockedText7}</Text>
                </>
            }
            <LogInButton
              bg="#66CCCC"
              content={lang[currentLang].login}
              onClick={() => setIniciarSesion(!iniciarSesion)}
            />

            <LogInButton
              bg="#8B8886"
              content={lang[currentLang].register}
              onClick={(e) => history.push("/register")}
            />
          </div>
          <Box maxWidth="250px" textAlign="center" m="auto">
            <Box mb="10px">
              <Text
                cursor="pointer"
                onClick={() => history.push("/recuperar-cuenta")}
                color="#1264A3"
              >
                {lang[currentLang].forgotPass}
              </Text>
            </Box>
            <Box>
              <Text textAlign="center" mb="5px" fontSize="xs" color="#6C6A69">
                {lang[currentLang].text1}
                <Text
                  cursor="pointer"
                  as="ins"
                  onClick={() => history.push("/terminos-condiciones")}
                  color="#1264A3"
                >
                  {" "}
                  {lang[currentLang].serviceCond}
                </Text>{" "}
                {lang[currentLang].text2}
                <Text
                  cursor="pointer"
                  as="ins"
                  onClick={() => history.push("/politica-privacidad")}
                  color="#1264A3"
                >
                  {" "}
                  {lang[currentLang].termsprivacy}
                </Text>{" "}
                {lang[currentLang].text3}
              </Text>
            </Box>
          </Box>
          
        </Box>
        <Box>
          <Footer />
        </Box>
      </React.Fragment>
    </Box>
  );
}

export default Login;
