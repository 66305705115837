import React from 'react'

export default ({color = "url(#linear-gradient)"}) => (
  <svg id="icono_like" width="20" height="20" viewBox="0 0 21.453 20.059">
    <defs>
      <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#6bdeff"/>
        <stop offset="0.023" stop-color="#2676e1"/>
        <stop offset="0.633" stop-color="#f29999"/>
        <stop offset="1" stop-color="#fe6e60"/>
      </linearGradient>
    </defs>
    <path id="Trazado_656" data-name="Trazado 656" d="M251.533,70.287a3.627,3.627,0,0,0-3.646-2.376h-2.123a8.4,8.4,0,0,0,.759-3.405,6.861,6.861,0,0,0-.1-1.2,2.575,2.575,0,0,0-3-2.349c-.633.072-.85.674-1.375,2.131a20.7,20.7,0,0,1-1.9,4.193,7.09,7.09,0,0,1-2.722,2.224v-.12h-7.005v10.41h7.007v-.573a8.777,8.777,0,0,1,1.877.025l1.255.728.937.584c.909.491,4.892.538,6.6.284a3.274,3.274,0,0,0,2.748-2.2C251.012,78.046,252.524,72.691,251.533,70.287Zm-2.016,7.977a1.923,1.923,0,0,1-1.624,1.211,19.766,19.766,0,0,1-5.752-.134l-.831-.521-1.575-.884a8.383,8.383,0,0,0-2.312-.087V70.993a8.652,8.652,0,0,0,3.875-2.963,18.351,18.351,0,0,0,1.688-3.517,1.282,1.282,0,0,0,.968-2.191c.623.044.977.426,1.112,1.21a5.577,5.577,0,0,1,.079.962,7.537,7.537,0,0,1-1.075,3.725l-.7,1.065,4.517,0a2.278,2.278,0,0,1,2.374,1.525C250.945,72.473,249.951,76.757,249.516,78.264Z" transform="translate(-230.414 -60.933)" fill={color}/>
    <path id="Trazado_657" data-name="Trazado 657" d="M234.5,79.568a1.29,1.29,0,1,0,1.29,1.291A1.292,1.292,0,0,0,234.5,79.568Z" transform="translate(-231.353 -67.197)" fill="#fff"/>
    <path id="Trazado_658" data-name="Trazado 658" d="M234.5,84.546a1.29,1.29,0,1,0,1.29,1.29A1.292,1.292,0,0,0,234.5,84.546Z" transform="translate(-231.353 -68.871)" fill="#fff"/>
    <path id="Trazado_659" data-name="Trazado 659" d="M234.5,74.59a1.29,1.29,0,1,0,1.29,1.29A1.292,1.292,0,0,0,234.5,74.59Z" transform="translate(-231.353 -65.524)" fill="#fff"/>
  </svg>
)

