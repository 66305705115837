import axios from "axios";
const baseUrl = "/";
// process.env.NODE_ENV === "production" ? "/api/" :

export const getAnswers = (id) => {
  return axios
    .put(baseUrl + "answers/question-answers/" + id)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export function getAllAnswers() {
  return axios
    .put(baseUrl + "answers/")
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
}

export const getUserAnswers = (id) => {
  return axios
    .put(baseUrl + "answers/user-answers/", { userId: id })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const getAnswer = (id) => {
  return axios
    .put(baseUrl + "answers/selected/", { answerId: id })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const submitAnswer = (answerData) => {
  return axios
    .post(baseUrl + "answers/:id", answerData)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const answer = (id) => {
  return axios
    .put(baseUrl + "answers/selected/", {
      answerId: id,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const updateAnswer = (answerData) => {
  return axios
    .put(baseUrl + "answers/update", {
      answer: answerData.answer,
      answerId: answerData.answerId,
      userId: answerData.userId,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const answerLike = (formData) => {
  return axios
    .post(baseUrl + "answer-likes/like/", formData)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const answerDislike = (formData) => {
  return axios
    .delete(baseUrl + "answer-likes/dislike/", { data: formData })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const answerDelete = (formData) => {
  return axios
    .delete(baseUrl + "answers/delete", {
      data: {
        answerId: formData.answerId,
        userId: formData.userId,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
