import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  Select,
  Box,
  Text,
  UnorderedList,
  ListItem,
  Button,
  Flex,
  Avatar,
  Textarea,
} from "@chakra-ui/react";
import Errors from "../assets/functions/errors";
import { askQuestion } from "../api/QuestionFunctions";
import { useAuthStore } from "../hooks/state/useAuthStore";
import { useLangStore } from "../hooks/state/useLangStore";
import lang from "../assets/lang/general";
import { useCreateBcnCuidaNotification } from "../hooks/queries/notification";
import { css } from "@emotion/react";
import UserAvatar from "./UserAvatar";

export default function CreateQuestion({ categories }) {
  const userData = useAuthStore((state) => state.data);
  const currentLang = useLangStore((state) => state.currentLang);

  const history = useHistory();

  const [title, setTitle] = useState();
  const [createBcnCuidaNotification, bcnCuidaNotificationQuery] =
    useCreateBcnCuidaNotification();
  const [categorias, setCategories] = useState();
  const [linkTag, setLinkTag] = useState();
  const [editorState, setEditorState] = useState();
  const [errors, setErrors] = useState([]);

  function updateTags(tags) {
    setCategories(tags);
  }

  function onSubmit(e) {
    e.preventDefault();

    const questionData = {
      title: title,
      categories: [categorias],
      userId: userData.id,
    };

    askQuestion(questionData)
      .then((res) => {
        if (res) {
          const formData = {
            senderId: userData.id,
            type: "question",
            referenceId: res.data.id,
          };

          createBcnCuidaNotification(formData).then((newNoti) => {
            history.push("/pregunta/" + res.data.id);
          });
        }
      })
      .catch((err) => {
        setErrors(err);
      });
  }

  return (
    <Box p="50px 20px">
      <Box p="20px">
        <Text fontWeight="semibold" fontSize="md">
          {lang[currentLang].addQuestion}
        </Text>
      </Box>
      <Box mb="20px" bg="blue.6" mx="auto" p="20px" borderRadius="5px">
        <Box>
          <Box>
            <UnorderedList>
              <ListItem color="blue.5">
                <Text color="blue.5">{lang[currentLang].verifyQuestion}</Text>
              </ListItem>
              <ListItem color="blue.5">
                <Text color="blue.5">{lang[currentLang].verifyQuestion2}</Text>
              </ListItem>
            </UnorderedList>
          </Box>
        </Box>
      </Box>
      <form className="card p-2" noValidate onSubmit={onSubmit}>
        <Box className="form-group">
          <Flex mb="20px" flexDirection="column">
            <Box my="10px" display="flex" alignSelf="flex-start">
              <a>
                <UserAvatar userData={userData} />
              </a>
              <Box>
                <Box ml="10px">
                  {userData.lastName === null ? (
                    <Box as="span" fontSize="13px" fontWeight="bold">
                      {userData.firstName}
                    </Box>
                  ) : (
                    <Box as="span" fontSize="13px" fontWeight="bold">
                      {userData.firstName + " " + userData.lastName}
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
            <Box alignSelf="flex-start">
              {/* <Text fontSize="11px" p="5px">
                Categorias:
              </Text> */}

              {/* <Select
                borderRadius="20px"
                defaultValue={localStorage.getItem("category")}
                onChange={(e) => {
                  setCategories(e.target.value);
                }}
                // placeholder="Categorías"
                fontSize="12px"
              >
                {categories?.length &&
                  categories.map((category) => (
                    <option value={category.name}>
                      {currentLang === "es_ES"
                        ? category.category
                        : category.categoryCat}
                    </option>
                  ))}
              </Select> */}

              <Select
                borderRadius="20px"
                defaultValue={localStorage.getItem("category")}
                onChange={(e) => {
                  setCategories(e.target.value);
                }}
                placeholder="Categoria"
                fontSize="12px"
                isRequired
              >
                {categories.map((category) => (
                  <option value={category.name}>
                    {currentLang === "es_ES"
                      ? category.category
                      : category.categoryCat}
                  </option>
                ))}
              </Select>
            </Box>
          </Flex>
          <Box width="100%" px="10px">
            <Textarea
              name="title"
              borderBottom="1px solid #cbcbcb"
              minHeight="30px"
              borderRadius="0"
              p="0"
              border="none"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder={lang[currentLang].questionPlaceholder}
            />
          </Box>
        </Box>
        {Errors && Errors.showErrors(errors, "title")}
        {Errors && Errors.showErrors(errors, "categories")}

        <Button borderRadius="20px" bg="blue.7" color="white" type="submit">
          Enviar
        </Button>
      </form>
    </Box>
  );
}
