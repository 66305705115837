const lang = {
  es_ES: {
    landingPage: {
      title: "Cuidadora cuídate, sorteamos un masaje",
      text1bold: "iCuida",
      text2: "tiene como objetivo cuidar a todas las personas que os dedicáis con amor y dedicación a cuidar a los demás. Es por ello que estamos renovando la app y añadiendo mejoras día a día, y para celebrarlo ",
      text3bold: "queremos regalar un masaje relajante",
      text4: ".",
      pointOne: {
        title: "¿Qué puedo ganar?",
        text: "El objeto de la promoción es el sorteo de un masaje en las perfumerías BLAU. El sorteo se realizará el sábado 16 de diciembre de 2023.",
      },
      pointTwo: {
        title: "¿Quién puede participar?",
        text: "Podrán participar en el sorteo las personas mayores de edad que trabajen como cuidadoras o trabajadoras del hogar.",
      },
      pointThree: {
        title: "¿Qué tengo que hacer para participar?",
        text1: "Para poder participar en los sorteos las personas usuarias de iCuida tendrán que recomendar iCuida a personas no usuarias que a su vez se tendrán que dar de alta en iCuida.",
        text2: "La recomendación se realizará a través de las casillas existentes en el formulario de alta de nuevas usuarias, o en el apartado de recomienda iCuida del perfil de la usuaria.",
        text3: "Para poder participar en los sorteos las nuevas usuarias se tendrán que dar de alta hasta las 23:59 del día anterior al sorteo.",
      },
      title1bold:"Normas de participación",
      pointFour: {
        title: "Inscripción en el sorteo",
        text1: "Para poder optar a cualquiera de los sorteos, la persona participante deberá recomendar a las nuevas usuarias a través del formulario habilitado a tal efecto, introduciendo el correo electrónico y teléfono de las personas recomendadas. Las personas recomendadas recibirán un correo electrónico para completar el alta en la plataforma iCuida. Una vez hayan finalizado el alta, la persona participante entrará en el sorteo previsto.",
        text2: "Una misma usuaria podrá participar tantas veces como desee,  por cada nueva usuaria recomendada se conseguirá una participación."
      },
      pointFive: {
        title: "Protección de datos y privacidad",
        text: "iCuida sólo utilizará los datos recogidos para las comunicaciones relacionadas con esta promoción. Las personas que lo deseen podrán hacer uso de su derecho de modificación y supresión en el correo electrónico equipo@iniciativabarcelonaopendata.cat."
      },
      pointSix: {
        title: "Notificación y entrega de premios",
        text1: "El sorteo se realizará  durante el encuentro comunitario del 16 de diciembre de 2023. Las personas premiadas no presentes en el acto serán notificadas mediante una llamada telefónica, y se concretará con la persona premiada la entrega del premio. ",
        text1bold: "Es muy importante que la persona participante rellene en su perfil el campo de teléfono para poder ser contactada en caso de resultar ganadora.",
        text2: "Los premios sorteados no pueden canjearse en metálico ni se aceptan cambios. La persona premiada con un masaje deberá contactar con el centro para reservar día y hora para disfrutar del masaje."
      },
      pointSeven: {
        title: "Aceptación de la normativa",
        text1: "Las personas participantes de esta promoción aceptan en su totalidad las normas presentes en este documento en el momento de participar.",
        text2: "iCuida podrá excluir del sorteo a cualquier participante si detecta indicios de fraude en su participación.",
      },
      pointEight: {
        title: "Resolución de imprevistos",
        text: "El personal de iCuida resolverá cualquier imprevisto no contemplado en este documento y su decisión será inapelable."
      },
      text5: "Este documento recoge las normas de participación en las acciones de dinamización y promoción de iCuida desde el 1 de diciembre de 2023 al 16 de diciembre de 2023.",
      guest: {
        text1: "Para recomendar, ",
        text2: "inicia sessión",
        text3: " si ya eres usuario de iCuida o ",
        text4: "accede al formulario de registro.",
      }
    },
    form: {
      title: "Recomienda iCuida",
      text1: "Primer recomendado",
      text2: "Segundo recomendado",
      name: "Nombre de la persona a quien recomiendas iCuida",
      phone: "Teléfono de la persona a quien recomiendas iCuida",
      email: "Email de la persona a quien recomiendas iCuida",
      send: "Enviar recomendaciones",
      success: "Las recomendaciones se han enviado correctamente",
      reset: "Seguir recomendando",
    }
  },
  ca: {
    landingPage: {
      title: "Cuidadora cuida’t, sortejem un massatge",
      text1bold: "iCuida",
      text2: "té com objectiu cuidar a totes les persones que us dediqueu amb amor i dedicació a cuidar els altres. És per això que estem renovant l’app i afegint millores dia a dia, i per celebrar-ho ",
      text3bold: "volem regalar-vos un massatge",
      text4: ".",
      pointOne: {
        title: "Què puc guanyar?",
        text: "L’objecte de la promoció és el sorteig d’un massatge a les perfumeries BLAU. El sorteig es farà el dissabte 16 de desembre de 2023.",
      },
      pointTwo: {
        title: "Qui pot participar?",
        text: "Podran participar en el sorteig les persones majors d’edat que treballin com a cuidadores o treballadores de la llar.",
      },
      pointThree: {
        title: "Què haig de fer per participar?",
        text1: "Per poder participar en els sorteig les persones usuàries d’iCuida hauran de recomanar iCuida a altres persones no usuàries que al seu torn s’hauran de donar d’alta a iCuida.",
        text2: "La recomanació es realitzarà a través de les caselles existents al formulari d'alta de noves usuàries, o a l'apartat de recomana iCuida del perfil de la usuària.",
        text3: "Per poder participar en els sorteig les noves usuàries s’hauran hagut de donar d’alta fins a les 23:59 del dia anterior al sorteig.",
      },
      title1bold:"Normes de participació",
      pointFour: {
        title: "Inscripció en el sorteig",
        text1: "Per poder optar a qualsevol dels sortejos la persona participant haurà de recomanar a les noves usuàries a través del formulari habilitat a tal efecte, introduint el correu electrònic i telèfon de les persones recomanades. Les persones recomanades rebran un correu electrònic per completar l’alta a la plataforma iCuida. Un com hagin finalitzat l’alta la persona participant entrarà en el sorteig previst.",
        text2: "Una mateixa usuària podrà participar tantes vegades com vulgui, per cada nova usuària registrada s’aconseguirà una participació."
      },
      pointFive: {
        title: "Protecció de dades i privacitat",
        text: "iCuida només usarà les dades recollides per a les comunicacions relacionades amb aquesta promoció. Les persones que ho desitgin podran fer ús del seu dret de modificació i supressió al correu electrònic equipo@iniciativabarcelonaopendata.cat."
      },
      pointSix: {
        title: "Notificació i entrega de premis",
        text1: "El sorteig es realitzarà durant la trobada comunitària del 16 de desembre de 2023. Les persones premiades no presents a l’acte seran notificades mitjançant una trucada telefònica, i es concretarà amb la persona premiada l’entrega del premi. ",
        text1bold: "És molt important que la persona participant empleni en el seu perfil el camp del telèfon per tal de poder ser contactada en cas de ser la guanyadora.",
        text2: "Els premis sortejats no poden bescanviar-se en metàl·lic ni s’accepten canvis. La persona premiada amb un massatge haurà de contactar amb el centre per tal de reservar dia i hora per gaudir del massatge."
      },
      pointSeven: {
        title: "Acceptació de la normativa",
        text1: "Les persones participants d’aquesta promoció accepten en la seva totalitat les normes presents en aquest document en el moment de participar-hi.",
        text2: "iCuida podrà excloure del sorteig a qualsevol participant si detecta indicis de frau en la seva participació.",
      },
      pointEight: {
        title: "Resolució d’imprevistos",
        text: "El personal d’iCuida resoldrà qualsevol imprevist no contemplat en aquest document i la seva decisió serà inapel·lable."
      },
      text5: "Aquest document recull les normes de participació en les accions de dinamització i promoció d’iCuida des del 1 de desembre de 2023 fins al 16 de desembre 2023.",
      guest: {
        text1: "Per recomanar, ",
        text2: "inicia sessió",
        text3: " si ja ets usuari d'iCuida o ",
        text4: "accedeix al formulari de registre.",
      }
    },
    form: {
      title: "Recomana iCuida",
      text1: "Primer recomanat",
      text2: "Segon recomanat",
      name: "Nom de la persona a qui recomanes iCuida",
      phone: "Telèfon de la persona a qui recomanes iCuida",
      email: "Email de la persona a qui recomanes iCuida",
      send: "Enviar recomanacions",
      success: "Les recomanacions s'han enviat correctament",
      reset: "Continuar recomanant",
    }
  }
};

export default lang;