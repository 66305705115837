import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, Text, Input, Button } from "@chakra-ui/react";
import { useCheckEmail } from "../../hooks/queries/usuarios";
import Form from "../../components/ui/Form";
import { usePassRecoveryStore } from "../../hooks/state/usePassRecoveryStore";
import jwt_decode from "jwt-decode";

function ForgotPassword() {
  const history = useHistory();
  const [checkEmail, checkEmailQuery] = useCheckEmail();
  const setData = usePassRecoveryStore((state) => state?.setData);
  const recoveryData = usePassRecoveryStore((state) => state?.data);

  function handleSubmit(formData) {
    checkEmail(formData, {
      onSuccess: (res) => {
        if (res) {
          setData(res?.data);
          localStorage.setItem("recoverytoken", res?.data);
          history.push("/verificacion");
        }
      },
      onError: (err) => {
        console.log(err);
      },
    });
  }

  return (
    <>
      <Box
        maxWidth="800px"
        width="100%"
        mx="auto"
        pt="50px"
        px="10px"
        borderRadius="20px"
      >
        <Form
          initialValues={{
            email: "",
          }}
          onSubmit={(values, actions) => {
            handleSubmit(values);
          }}
        >
          {({ setFieldValue, values }) => (
            <Box>
              <Box p="10px 20px" bg="blue.7">
                <Text fontWeight="bold" color="#fff" textAlign="center">
                  Contraseña Olvidada
                </Text>
              </Box>
              <Box bg="#fff" px="20px">
                <Box
                  maxWidth="400px"
                  width="100%"
                  mx="auto"
                  pt="4rem"
                  pb="2rem"
                >
                  <Box my="30px">
                    <Input
                      name="email"
                      type="email"
                      value={values.email}
                      onChange={(e) => setFieldValue("email", e.target.value)}
                      placeholder="Introduzca su correo"
                    />
                  </Box>
                  <Box my="30px">
                    <Button
                      isLoading={checkEmailQuery.isLoading}
                      type="submit"
                      width="100%"
                      color="#fff"
                      bg="blue.7"
                    >
                      Continuar
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Form>
      </Box>
    </>
  );
}
export default ForgotPassword;
