import React, {useState} from 'react';
import {useHistory} from 'react-router-dom'
import {
    Box,
    Badge,
    Avatar,
    Text, Flex
} from '@chakra-ui/react'
import {usePreguntasComunidad} from '../../hooks/queries/preguntas-comunidad'
import DateFormatter from '../../components/DateFormatter'
import moment from "moment";
import 'moment/locale/es'
import IconoRespuesta from '../../assets/icons/IconoRespuesta'
import IconoComentar from '../../assets/icons/IconoComentar'
import { useEffect } from 'react';
import Loading from '../../components/Loading'
import UserAvatar from "../../components/UserAvatar";

function Card({pregunta, index}) {
    const history = useHistory()

    function redirect(url) {
        history.push(url)
    }

    return(
       <Box>
           <Box key={index} width="100%" bg="#fff" borderRadius="3px"  mb="10px">
           <Box py="10px" px="20px">
               <Box>
                   {
                       JSON.parse(pregunta.categories) &&
                       JSON.parse(pregunta.categories).map((category) => {
                           return <Flex>
                               <Text fontSize="sm">Pregunta</Text>
                                <Text mx="3px">&bull;</Text>
                               {category ?<Badge alignSelf="center" mr="3px" fontSize="10px" key={category}
                                                colorScheme="blue">{category}</Badge> 
                                : ''}
                           </Flex>
                       })
                   }
               </Box>
               <Box>
                   <Box my="10px" display="flex">
                        <a href={"/perfil-usuario/" + pregunta.User.id}>
                            <UserAvatar userData={pregunta.User} />
                       </a>
                       <Box>
                           <Box ml="10px">
                               <Box as="span" fontSize="13px" fontWeight="bold"><a href={"/perfil-usuario/" + pregunta.User.id}>{pregunta.User.firstName + " " +pregunta.User.lastName}</a></Box>
                               <Box as="span" fontSize="13px"> - {pregunta.updatedAt ? <DateFormatter date={pregunta.updatedAt}/> : <DateFormatter date={pregunta.createdAt}/>}</Box>
                           </Box>
                           <Box  ml="10px">
                               <Box as="span" fontSize="13px">Tipo de usuario</Box>
                           </Box>
                       </Box>
                   </Box>
                   <Box>
                       <Text cursor="pointer"
                             onClick={() => redirect("/pregunta/" + pregunta.id)}
                             mb="10px"
                             fontSize="md"
                             fontWeight="bold">{pregunta.title}</Text>
                       <Box dangerouslySetInnerHTML={{__html: pregunta.description}}></Box>
                   </Box>
                   <Box my="10px">
                       <Box display="flex" justifyContent="space-between">
                           <Flex>
                              <Flex cursor="pointer" onClick={() => history.push("/pregunta/" + pregunta.id)}>
                                <IconoRespuesta fontSize="20px" color="#8B8886"/>
                                <Text ml="3px">{pregunta.Answers.length}</Text>
                              </Flex>
                           </Flex>
                       </Box>
                   </Box>
               </Box>
           </Box>
       </Box>
       </Box>
    )
}

export default Card;