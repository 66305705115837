import React, {useState} from 'react'
import {Box, Text, Flex} from '@chakra-ui/react'
import Carousel from '../ui/Carousel'
import StoryBoardCard from './StoryBoardCard'
import { useEffect } from 'react'

function Storyboard() {

    const [items, setItems] = useState([])

    const data = [
        {
        img: 'grupo-301.svg',
        title: <Flex height="100%">
                    <Box alignSelf="center" width="fit-content" mx="auto">
                        <Text textAlign="center">Bienvenida/o a iCuida</Text>
                    </Box>
                </Flex>,
        description: ''
        },
        {
        img: 'grupo-302.svg',
        title: <Text>¿Cómo utilizar iCuida?</Text>,
        description: <Text>iCuida existe para <Text as="strong">consultar, hacer y responder preguntas.</Text> Cuando quieras saber <Text as="strong">algo sobre el trabajo del hogar y los cuidados</Text>, iCuida te ofrece respuestas y contenido de gente que sabe la respuesta. iCuida cuenta con un perfil oficial “Barcelona Cuida” dispuesto a responder preguntas de sus usuarias y usuarios.</Text>
        },
        {
        img: 'grupo-303.png',
        title: <Text>¿Cómo consultar información en iCuida?</Text>,
        description: <Box>
                        <Text textAlign="center">Hay cuatro formas de consultar información en iCuida</Text>
                        <Text mt="10px" textAlign="center">1) Ve a la sección <Text as="strong">“Categorías”...</Text></Text>
                    </Box>
        },
        {
        img: 'grupo-304.png',
        title: <Text>¿Cómo consultar información en iCuida?</Text>,
        description: <Text textAlign="center">Clica en la que más se ajuste a lo que buscas, revisa si alguna de ellas responde a tu pregunta.</Text>
        },
        {
        img: 'grupo-305.png',
        title: <Text>¿Cómo consultar información en iCuida?</Text>,
        description: <Box>
                        <Text textAlign="center">2) En Home, clica el botón <Text as="strong">“Faqs oficiales”...</Text></Text>
                    </Box>
        },
        {
        img: 'grupo-306.png',
        title: <Text>¿Cómo consultar información en iCuida?</Text>,
        description: <Text textAlign="center">Aquí encontrarás todas las preguntas y respuestas que hemos recopilado de documentos oficiales del Ayuntamiento de Barcelona.</Text>
        },
        {
        img: 'grupo-307.png',
        title: <Text>¿Cómo consultar información en iCuida?</Text>,
        description: <Box>
            <Text>3) En Home, clica el botón <Text as="strong">“Comunidad”...</Text></Text>
        </Box>
        },
        {
        img: 'grupo-308.png',
        title: <Text>¿Cómo consultar información en iCuida?</Text>,
        description: <Text alignText="center">Aquí encontrarás todas las preguntas y respuestas de las usuarias y usuarios de iCuida.</Text>
        },
        {
        img: 'grupo-309.png',
        title: <Text>¿Cómo consultar información en iCuida?</Text>,
        description: <Text>4) Ve al <Text as="strong">“Buscador”</Text> ubicado en el menú superior...</Text>
        },
        {
        img: 'grupo-310.png',
        title: <Text>¿Cómo consultar información en iCuida?</Text>,
        description: <Text textAlign="center">Añade una palabra clave relacionada con tu duda, te mostrará todos los resultados que contenga esa palabra.</Text>
        },
        {
        img: 'grupo-311.png',
        title: <Text>¿Cómo preguntar?</Text>,
        description: <Box>
            <Text textAlign="center">Tienes tres opciones:</Text>
            <Text mt="10px" textAlign="center"> 1) En el botón <Text as="strong">“Preguntar”</Text> del menú superior.</Text>
        </Box>
        },
        {
        img: 'grupo-312.png',
        title: <Text>¿Cómo preguntar?</Text>,
        description: <Text textAlign="center">2) En <Text as="strong">“Home”</Text>, debajo del mensaje de bienvenida, clica en el recuadro "Cual es tu pregunta o enlace"</Text>
        },
        {
        img: 'grupo-313.png',
        title: <Text>¿Cómo preguntar?</Text>,
        description: <Text textAlign="center">En ambos casos, solo debes añadir tu pregunta...</Text>
        },
        {
        img: 'grupo-314.png',
        title: <Text>¿Cómo preguntar?</Text>,
        description: <Text textAlign="center">Selecciona una de las <Text as="strong">categorías</Text> y clicar el botón <Text as="strong">“Enviar”.</Text></Text>
        },
        {
        img: 'grupo-315.png',
        title: <Text>¿Cómo preguntar?</Text>,
        description: <Text textAlign="center">3) Ve a <Text as="strong">“Categorías''...</Text></Text>
        },
        {
        img: 'grupo-316.png',
        title: <Text>¿Cómo preguntar?</Text>,
        description: <Text textAlign="center">Selecciona la categoría relacionada con tu duda...</Text>
        },
        {
        img: 'grupo-317.png',
        title: <Text>¿Cómo preguntar?</Text>,
        description: <Text textAlign="center">Añade tu pregunta y clica el botón <Text as="strong">“Enviar”.</Text></Text>
        },
        {
        img: 'grupo-318.png',
        title: <Text>¿Cómo responder?</Text>,
        description: <Box>
                        <Text textAlign="center">Hay dos formas de responder:</Text>
                        <Text mt="10px" textAlign="center">1) Ve a la sección <Text as="strong">“Responder”</Text>, aquí encuentras las preguntas más recientes.</Text>
                    </Box>
        },
        {
        img: 'grupo-319.png',
        title: <Text>¿Cómo responder?</Text>,
        description: <Text align="center">2) Ve a <Text as="strong">“Comunidad”</Text></Text>
        },
        {
        img: 'grupo-320.png',
        title: <Text>¿Cómo responder?</Text>,
        description: <Text textAlign="center">En las dos opciones, debes clicar el icono <Text as="strong">“Responder”...</Text></Text>
        },
        {
        img: 'grupo-321.png',
        title: <Text>¿Cómo responder?</Text>,
        description: <Text textAlign="center">Añade tu respuesta y clica el botón <Text as="strong">“Responder”.</Text></Text>
        },

    ]

    function prepareItems() {
        const cards =  data.map((val) => <StoryBoardCard data={val}/>)

        setItems(cards)
    }

    useEffect(() => {
        prepareItems();
    }, [])

    return(
        <Box>
            <Carousel items={items}/> 
        </Box>
    )
}

export default Storyboard;