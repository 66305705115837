import React, { Component } from 'react'
import { SendVeriMail} from '../api/UserFunctions'

export default class SendVerifyMail extends Component {

    state = {
        msg: '',
        loading: false,
        sent: false
    }

    getUrl() {
        // eslint-disable-next-line
        let pathname = window.location.pathname;

        let pathId = pathname.split('/').filter(val => {
            return Number.isInteger(Number(val)) && val !== "";
        });

        return Number(pathId[0]);

    }

    verify = () => {
        this.setState({loading: true})
        SendVeriMail(this.getUrl())
            .then(res => {
                this.setState({
                    msg: res,
                    loading: false,
                    sent: true
                })
            })
    }

    spinner = () => {
        return(
            <div className="spinner-border text-info" role="status">
                <span className="src-online">Cargando...</span>
            </div>
        )
    }

    reenviar = () => {
        this.setState({sent: false})
    }

    render() {
        return(<div className="container py-5 icuida-blue text-center">
            <div className="jumbotron">
                <h1 className="display-4">Verifica tu correo email!</h1>
                <p className="lead">Recibir codigo de verificación en tu email.</p>
                {this.state.sent ? <div>
                        <div className="alert alert-success"><strong>Codigo enviado</strong></div>
                        <div><a className="text-primary" href="#" onClick={this.reenviar}>Enviar codigo otra vez</a></div>
                    </div>

                    :
                    <button className="btn btn-primary btn-lg" disabled={this.state.loading} onClick={() => {
                        this.verify()
                    }}>Enviar codigo
                        {this.state.loading &&
                        <div className="ml-2 spinner-border text-info" role="status"/>
                        }
                    </button>
                }

            </div>
        </div>)
    }
}