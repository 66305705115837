import React from 'react';

export default ({color = "#000"}) => (
<svg id="home_gris" width="29.766" height="23.958" viewBox="0 0 29.766 23.958">
  <g id="noun_Home_1560062">
    <g id="Grupo_12" data-name="Grupo 12" transform="translate(0 0)">
      <path id="Trazado_3" data-name="Trazado 3" d="M23.881,969.362a1.273,1.273,0,0,0-.669.227L9.418,980.479a1.3,1.3,0,0,0-.238,1.577,1.128,1.128,0,0,0,1.577.136l13.125-10.357,13.125,10.357a1.115,1.115,0,0,0,1.577-.136,1.236,1.236,0,0,0-.238-1.577L24.55,969.589a1.109,1.109,0,0,0-.669-.227Zm-8.712,10.629-2.178,1.724v10.516a1.089,1.089,0,0,0,1.089,1.089h6.9a1.089,1.089,0,0,0,1.089-1.089v-4.356a1.815,1.815,0,0,1,3.63,0v4.356a1.089,1.089,0,0,0,1.089,1.089h6.9a1.089,1.089,0,0,0,1.089-1.089V981.715l-2.178-1.724v11.151H27.874v-3.267a3.993,3.993,0,1,0-7.986,0v3.267H15.169Z" transform="translate(-9 -969.362)" fill={color}/>
    </g>
  </g>
</svg>

)

