import { useQuery, useMutation} from "react-query";
import {staticQuestionsCategory, staticQuestion,createQuestion,
        staticQuestionLike, staticQuestionDislike,
        staticQuestionUpdate,staticQuestionSearchQuery, getAllStaticQuestions,
        staticQuestionDelete} from "../../api/StaticQuestions";

export function usePreguntasOficiales({page, category} = {}, queryConfig = {}) {
    return useQuery(['GET_PREGUNTAS_OFICIALES'], () => staticQuestionsCategory({page, category}), queryConfig);
}

export function useGetAllStaticQuestions(queryConfig = {}) {
    return useQuery(['GET_TODAS_PREGUNTAS_OFICIALES'], () => getAllStaticQuestions(), queryConfig);
}

export function useStaticQuestion({id} = {}, queryConfig = {}) {
    return useQuery(['GET_PREGUNTA_STATICA_COMUNIDAD'], () => staticQuestion(id), queryConfig)
}

export function useSearchStaticQuestion(formData, queryConfig = {}) {
    return useQuery(['GET_PREGUNTA_STATICA_BUSCADA'], () => staticQuestionSearchQuery(formData), queryConfig)
}

export function useCreateOficial({queryConfig = {}} = {}) {
    return useMutation(createQuestion, queryConfig)
}
export function useUpdateOficial({queryConfig = {}} = {}) {
    return useMutation(staticQuestionUpdate, queryConfig)
}

export function useStaticQuestionLike(queryConfig = {}) {
    return useMutation(staticQuestionLike, queryConfig)
}

export function useStaticQuestionDislike(queryConfig = {}) {
    return useMutation(staticQuestionDislike, queryConfig)
}

export function useStaticQuestionDelete(queryConfig = {}) {
    return useMutation(staticQuestionDelete, queryConfig)
}