import { useQuery } from "react-query";
import { getAllMetrics, getLastMonthMetrics } from "../../api/metrics";

export function useGetAllMetrics({} = {}, queryConfig = {}) {
  return useQuery(["GET_ALL_METRICS"], () => getAllMetrics(), queryConfig);
}

export function useGetLastMonthMetrics({} = {}, queryConfig = {}) {
  return useQuery(
    ["GET_ALL_LAST_MONTH_METRICS"],
    () => getLastMonthMetrics(),
    queryConfig
  );
}
