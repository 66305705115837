import React, {useState, useEffect} from 'react';
import {Link, Route, useHistory} from "react-router-dom";
import {Avatar, Badge, Box, Text, Divider, 
    IconButton, Input, Flex, Button, VStack, StackDivider, MenuButton, MenuList, Menu, MenuItem}
from "@chakra-ui/react";
import tagsData from "../../../assets/tagsData";
import moment from "moment";
import 'moment/locale/es'
import IconoComentar from '../../../assets/icons/IconoComentar'
import LikeIcon from '../../../assets/icons/LikeIcon'
import {useStaticQuestionLike} from '../../../hooks/queries/preguntas-oficiales'
import {useStaticQuestionDislike} from '../../../hooks/queries/preguntas-oficiales'
import {useAuthStore} from '../../../hooks/state/useAuthStore'
import {objHasUserId} from '../../../functional/general'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
import { staticQuestionsCategory } from '../../../api/StaticQuestions';

export default function({pregunta, index, refetch, type}) {

    const history = useHistory();
    const userData = useAuthStore(state=>state.data)
    const [staticQuestionLike, staticQuestionLikeQuery] = useStaticQuestionLike();
    const [staticQuestionDislike, staticQuestionDislikeQuery] = useStaticQuestionDislike();
    const [totalLikes, setTotalLikes] = useState(pregunta.StaticQuestionLikes.length)
    const [ellipsisShow, setEllipsisShow] = useState(true)
    

    function liked() {
        const formData = {
            userId: userData.id,
            questionId: pregunta.id
        }
        staticQuestionLike(formData)
        .then(res => {
            setTotalLikes(pregunta.StaticQuestionLikes.length + 1)
            
        })
    }

    function disliked() {
        const formData = {
            userId: userData.id,
            questionId: pregunta.id
        }
        staticQuestionDislike(formData)
        .then(res => {
            setTotalLikes(pregunta.StaticQuestionLikes.length - 1)
            
        })
    }

    function formatDate(date)  {
        const databaseDate = new Date(date);

        return moment(databaseDate).format('L');
    }

    function redirect(url) {
        history.push(url)
    }

    return(
        <Box key={index} width="100%" bg="linear-gradient(180deg, #fff 0%, #D2EDF5FC 10%, #FCFCFC)" boxShadow="0 1px 2px #c1c1c1" borderRadius="3px"  mb="10px">
            <Box py="10px" px="20px">
                <Box>
                    {
                        JSON.parse(pregunta.categories) &&
                        JSON.parse(pregunta.categories).map((category) => {
                            return <Badge mr="3px" fontSize="10px" key={category}
                                          colorScheme="blue"
                            >{category ? category : ''}</Badge>
                        })
                    }
                </Box>
                <Box>
                    <Box className="card-body article-card">
                        <Text cursor="pointer"
                              onClick={() => redirect("/pregunta-estatica/" + pregunta.id)}
                              mb="10px"
                              fontSize="lg"
                              fontWeight="bold">{pregunta.title}</Text>
                              {!ellipsisShow ?
                                  <Box dangerouslySetInnerHTML={{__html: pregunta.description}}></Box>
                                  :
                                  <Box onClick={() => setEllipsisShow(false)}>
                                      <Box pointerEvents="none" >
                                        <HTMLEllipsis
                                            unsafeHTML={pregunta.description}
                                            maxLine="3"
                                            ellipsis="    (ver más...)"
                                        />
                                      </Box>
                                  </Box>

                              }
                        
                    </Box>
                    <Box mt="20px">
                        <Box display="flex" justifyContent="space-between">
                            <Flex>
                                <Flex>
                                    { objHasUserId(pregunta.StaticQuestionLikes, userData.id) ? 
                                        <Box cursor="pointer" onClick={() =>{disliked()}}><LikeIcon/></Box> 
                                        :
                                        <Box cursor="pointer" onClick={() => liked()}><LikeIcon color="#8B8886"/></Box>
                                    }
                                    <Text ml="3px">{totalLikes}</Text>
                                </Flex>
                            </Flex>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )

}