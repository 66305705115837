import React from 'react'

export default ({color = "#000"}) => (
<svg id="perfil_black" xmlns="http://www.w3.org/2000/svg" width="19.406" height="19.406" viewBox="0 0 19.406 19.406">
  <g id="icono_perfil_gris">
    <g id="Grupo_275" data-name="Grupo 275">
      <path id="Trazado_605" data-name="Trazado 605" d="M496.879-290.434a9.5,9.5,0,0,1-6.96,6.979,9.8,9.8,0,0,1-7.061-.864l-3.564.842a1.092,1.092,0,0,1-1.284-1.418l1.063-3.1a9.791,9.791,0,0,1-.989-7.383,9.494,9.494,0,0,1,6.96-6.89A9.731,9.731,0,0,1,496.879-290.434Z" fill={color} transform="translate(-477.767 302.561)"/>
    </g>
    <g id="user_2_" data-name="user (2)" transform="translate(2.426 1.884)">
      <g id="Grupo_104" data-name="Grupo 104" transform="translate(2.892)">
        <g id="Grupo_103" data-name="Grupo 103">
          <path id="Trazado_597" data-name="Trazado 597" d="M114.785,0a3.994,3.994,0,1,0,3.994,3.994A4,4,0,0,0,114.785,0Z" transform="translate(-110.791 0)" fill="#fff"/>
        </g>
      </g>
      <g id="Grupo_106" data-name="Grupo 106" transform="translate(0 8.274)">
        <g id="Grupo_105" data-name="Grupo 105">
          <path id="Trazado_598" data-name="Trazado 598" d="M37.906,250.747a3.723,3.723,0,0,0-.4-.733,4.965,4.965,0,0,0-3.436-2.163.735.735,0,0,0-.506.122,4.258,4.258,0,0,1-5.023,0,.654.654,0,0,0-.506-.122,4.929,4.929,0,0,0-3.436,2.163,4.293,4.293,0,0,0-.4.733.371.371,0,0,0,.017.331,6.956,6.956,0,0,0,.471.7,6.631,6.631,0,0,0,.8.907,10.471,10.471,0,0,0,.8.7,7.954,7.954,0,0,0,9.489,0,7.675,7.675,0,0,0,.8-.7,8.056,8.056,0,0,0,.8-.907,6.11,6.11,0,0,0,.471-.7A.3.3,0,0,0,37.906,250.747Z" transform="translate(-24.165 -247.841)" fill="#fff"/>
        </g>
      </g>
    </g>
  </g>
</svg>  
) 

