import React from "react";
import { Box, Flex, Text, Avatar } from "@chakra-ui/react";
import moment from "moment";
import "moment/locale/es";
import { useEffect, useRef } from "react";
import { AiFillFilePdf } from "react-icons/ai";
import validationFileExtension from "../../../validationFile";
import UserAvatar from "../../UserAvatar";

function ChatWindow({ chat, userData, contact }) {
  const regFileType = /\.(pdf|docx|xlsx|pptx|txt|zip|mp4)$/;
  const regIMG = /\.(jpg|jpeg|png|webp|avif|gif|svg|bmp|apng|image|tif)$/;
  const regLink =
    /((http|https)\:\/\/)?[a-zA-Z0-9\.\/\?\:@\-_=#]+\.([a-zA-Z0-9\&\.\/\?\:@\-_=#])*/g;

  const lastMessageRef = useRef();

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ smooth: true });
    }
  }, [lastMessageRef.current, chat]);

  return (
    <Box>
      <>
        {chat && (
          <Box width="100%" height="400px" overflow="auto" p="10px">
            {chat?.length > 0 &&
              chat?.map((val, index) => {
                const lastMessage = chat.length - 1 === index;
                return (
                  <Box mb="5px" key={index}>
                    <Flex
                      ref={lastMessage ? lastMessageRef : null}
                      py="10px"
                      justifyContent={
                        val?.userId === userData?.id ? "flex-end" : "flex-start"
                      }
                    >
                      <Box display={val?.userId === userData?.id ? "none" : ""}>
                        <UserAvatar userData={contact} />
                      </Box>
                      <Box
                        maxW="75%"
                        mx="10px"
                        borderRadius="10px"
                        bg={val?.userId === userData?.id ? "blue.4" : "gray.2"}
                        mb="3px"
                        width="fit-content"
                      >
                        {val?.isFile &&
                        regIMG?.test(val?.reply?.toLowerCase()) ? (
                          <img
                            src={
                              window.location.protocol +
                              "//" +
                              window.location.host +
                              "/conversation/file/" +
                              val?.id +
                              "?userId=" +
                              userData?.id +
                              "&contactId=" +
                              contact?.id
                            }
                            height="200px"
                            width="200px"
                            alt="img"
                          />
                        ) : val?.isFile &&
                          regFileType?.test(val?.reply?.toLowerCase()) ? (
                          <a
                            href={
                              window.location.protocol +
                              "//" +
                              window.location.host +
                              "/conversation/file/" +
                              val?.id
                            }
                          >
                            {validationFileExtension(val?.reply?.toLowerCase())}
                          </a>
                        ) : val?.isFile ? (
                          <a
                            href={
                              window.location.protocol +
                              "//" +
                              window.location.host +
                              "/conversation/file/" +
                              val?.id
                            }
                          >
                            {val?.reply}
                          </a>
                        ) : regLink?.test(val?.reply?.toLowerCase()) ? (
                          <a
                            href={
                              window.location.protocol +
                              "//" +
                              window.location.host +
                              "/conversation/file/" +
                              val?.id
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            {val?.reply}
                          </a>
                        ) : (
                          <Text color="#303030" p="6px">
                            {val.reply}
                          </Text>
                        )}
                      </Box>
                      <Box display={val.userId !== userData?.id ? "none" : ""}>
                        <UserAvatar userData={userData} />
                      </Box>
                    </Flex>
                    <Flex
                      justifyContent={
                        val?.userId === userData?.id ? "flex-end" : "flex-start"
                      }
                    >
                      <Text color="gray" fontSize="xs">
                        {moment(val?.createdAt).format("LT")}
                      </Text>
                    </Flex>
                  </Box>
                );
              })}
          </Box>
        )}
      </>
    </Box>
  );
}

export default ChatWindow;
