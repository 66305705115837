import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import Cookies from "js-cookie";
import { Box, Text, Divider, Button, HStack } from "@chakra-ui/react";
import FAQS from "./content/FAQS";
import Comunidad from "./content/Comunidad";

function Search() {
  const history = useHistory();
  const searchInput = Cookies.get("search");
  const [showTab, setShowTab] = useState("comunidad");

  useEffect(() => {
    if (searchInput === "undefined") {
      history.push("/");
    }
  }, []);

  return (
    <Box>
      <Box
        backgroundColor="white"
        overflow="hidden"
        borderRadius="5px"
        mb="10px"
      >
        <Box p="20px">
          <HStack justifyContent="flex-end">
            <Button
              _focus={{ outline: "none" }}
              border="none"
              borderRadius="20px"
              color={showTab === "comunidad" ? "#fff" : "#000"}
              bg={showTab === "comunidad" ? "orange.1" : "gray.3"}
              onClick={() => setShowTab("comunidad")}
            >
              Comunidad
            </Button>
            <Button
              _focus={{ outline: "none" }}
              border="none"
              borderRadius="20px"
              color={showTab === "oficial" ? "#fff" : "#000"}
              bg={showTab === "oficial" ? "orange.1" : "gray.3"}
              onClick={() => setShowTab("oficial")}
            >
              FAQs Oficiales
            </Button>
          </HStack>
        </Box>
      </Box>
      <Box mb="20px">
        <Text fontSize="20px">
          Resultados de{" "}
          <Text as="span" fontWeight="bold">
            {searchInput}
          </Text>
        </Text>
        <Divider />
      </Box>
      {showTab === "oficial" ? <FAQS searchInput={searchInput} /> : ""}
      {showTab === "comunidad" ? <Comunidad searchInput={searchInput} /> : ""}
    </Box>
  );
}

export default Search;
