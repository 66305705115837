import React from 'react';

export default ({fontSize="20px" ,bg= "#FE6E60", color="#fff"}) => (
  <svg id="Icono_preguntar" xmlns="http://www.w3.org/2000/svg" width={fontSize} height={fontSize} viewBox="0 0 18.905 18.905">
    <g id="Icono_imagen_cabecera">
      <g id="Grupo_304" data-name="Grupo 304" style={{isolation:'isolate'}}>
        <g id="Grupo_275" data-name="Grupo 275">
          <path id="Trazado_605" data-name="Trazado 605" d="M496.386-290.747a9.255,9.255,0,0,1-6.78,6.8,9.545,9.545,0,0,1-6.879-.842l-3.472.82A1.064,1.064,0,0,1,478-285.351l1.036-3.019a9.538,9.538,0,0,1-.963-7.193,9.248,9.248,0,0,1,6.78-6.712A9.48,9.48,0,0,1,496.386-290.747Z" transform="translate(-477.767 302.561)" fill={bg}/>
        </g>
      </g>
    </g>
    <g id="Grupo_1267" data-name="Grupo 1267" transform="translate(6 4)">
      <path id="Trazado_645" data-name="Trazado 645" d="M153.441,70.446a2.881,2.881,0,0,1,.457-1.5,3.571,3.571,0,0,1,1.335-1.255,4.079,4.079,0,0,1,2.047-.5,4.238,4.238,0,0,1,1.92.416,3.146,3.146,0,0,1,1.286,1.13,2.845,2.845,0,0,1,.454,1.555,2.471,2.471,0,0,1-.259,1.158,3.546,3.546,0,0,1-.614.858q-.356.362-1.279,1.216a5.419,5.419,0,0,0-.409.424,1.707,1.707,0,0,0-.228.334,1.678,1.678,0,0,0-.116.3c-.028.1-.069.279-.124.532a.844.844,0,0,1-.893.809.894.894,0,0,1-.656-.264,1.062,1.062,0,0,1-.266-.785,2.975,2.975,0,0,1,.2-1.131,2.867,2.867,0,0,1,.517-.84,12.128,12.128,0,0,1,.87-.858q.479-.436.694-.657a2.218,2.218,0,0,0,.359-.494,1.226,1.226,0,0,0,.146-.59,1.4,1.4,0,0,0-.446-1.049,1.6,1.6,0,0,0-1.151-.427,1.561,1.561,0,0,0-1.215.431,3.483,3.483,0,0,0-.66,1.271q-.255.879-.967.879a.933.933,0,0,1-.709-.307A.954.954,0,0,1,153.441,70.446Zm3.659,8.518a1.154,1.154,0,0,1-.8-.307,1.1,1.1,0,0,1-.342-.858,1.125,1.125,0,0,1,.331-.824,1.091,1.091,0,0,1,.809-.335,1.059,1.059,0,0,1,.794.335,1.139,1.139,0,0,1,.324.824,1.1,1.1,0,0,1-.338.855A1.12,1.12,0,0,1,157.1,78.964Z" transform="translate(-153.441 -67.198)" fill={color}/>
    </g>
  </svg>
  )
