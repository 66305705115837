import {Link, useHistory} from "react-router-dom";
import {useLangStore} from "../../hooks/state/useLangStore";
import {useAuthStore} from "../../hooks/state/useAuthStore";
import {Box, Flex, Image, Text} from "@chakra-ui/react";
import React from "react";
import Navbar from "../../components/Navbar";
import {css} from "@emotion/react";
import MobileNavbar from "../../components/MobileNavbar";
import NavbarAdmin from "../../components/NavbarAdmin";
import PrivateRoute from "../../components/PrivateRoute";
import AdminPanel from "../admin";
import {Col, Container, Row} from "react-bootstrap";
import RecommendContent from "../recommend-landing/RecommendContent";
import RecommendForm from "../recommend-landing/RecommendForm";
import StickySide from "../content/sticky-side";
import lang from "../../assets/lang/resources";
import Footer from "../../components/Footer";
import ResourcesContent from "./ResourcesContent";
import ResourcesPrivate from "./ResourcesPrivate";


function Resources(categories) {
  const history = useHistory();
  const currentLang = useLangStore((state) => state.currentLang);
  const userData = useAuthStore((state) => state.data);

  return (
      <Box>
        <main role="main" className="App icuida">
          {userData && (
              <React.Fragment>
                <Navbar
                    cssStyle={css`
                @media (max-width: 1200px) {
                  display: none;
                }
              `}
                />
                <MobileNavbar
                    css={css`
                @media (min-width: 1200px) {
                  display: none;
                }
              `}
                />
              </React.Fragment>
          )}
          {userData && userData.isAdmin && <NavbarAdmin/>}
          {userData && userData.isAdmin && (
              <PrivateRoute
                  exact
                  path={[
                    "/panel-administrador/usuarios",
                    "/panel-administrador/contenido",
                  ]}
              >
                <AdminPanel/>
              </PrivateRoute>
          )}
        </main>

        {userData && (
            <Box className="icuida" maxWidth="1200px" m="auto">
              <Box py="20px">
                <Container fluid>
                  <Row>
                    <Col
                        md="9"
                        css={css`
                    @media (min-width: 0) and (max-width: 30em) {
                      padding-left: 0;
                      padding-right: 0;
                    }
                  `}
                    >
                      <Box width="100%">
                        <Box bg="#ffffff">
                          <ResourcesContent/>
                          <ResourcesPrivate/>
                        </Box>
                      </Box>
                    </Col>
                    <Col className="d-none d-md-block" md="3">
                      <StickySide categories={categories}/>
                    </Col>
                  </Row>
                </Container>
              </Box>
            </Box>
        )}

        {!userData && (
            <React.Fragment>
              <Box width="100%" bg="orange.1" height="64px">
                <Box mx="auto" height="100%" maxW="1000px">
                  <Flex px="20px" height="100%">
                    <Box alignSelf="center" height="fit-content">
                      <Link to="/" className="navbar-brand p-0">
                        <Image
                            width="98.4px"
                            height="24.5px"
                            src={require("../../assets/images/logos/icuida-letras.png")}
                            onClick={() => history.push("/")}
                        />
                      </Link>
                    </Box>
                  </Flex>
                </Box>
              </Box>

              <Box
                  className="icuida"
                  maxWidth="1200px"
                  m="auto"
                  md="9"
                  borderRadius="25px"
                  padding="20px"
                  css={css`
              @media (min-width: 0) and (max-width: 30em) {
                padding-left: 0;
                padding-right: 0;
              }
            `}
              >
                <Box bg="#f1fcff">
                  <ResourcesContent/>
                  <Box maxW="800px" mx="auto" p="20px" borderRadius="5px">
                    <Text>{lang[currentLang].landingPage.guest.text1}<Text as="ins" color="blue.5" fontWeight="bold"><a href="/login">{lang[currentLang].landingPage.guest.text2}</a></Text>{lang[currentLang].landingPage.guest.text3}<Text as="ins" color="blue.5" fontWeight="bold"><a href="/register">{lang[currentLang].landingPage.guest.text4}</a></Text></Text>
                </Box>
                </Box>
                

              </Box>

              <Footer/>

            </React.Fragment>
        )}
      </Box>

  );
}

export default Resources;
