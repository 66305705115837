import { useQuery, useMutation} from "react-query";
import { createEvent, deleteEvent, eventDislike, eventLike, getAllEvent, getEvent, updateEvent, updateEventID } from "../../api/event";

export function useCreateEvent({queryConfig = {}} = {}) {
    return useMutation(createEvent, queryConfig)
}

export function useUpdateEvent({queryConfig = {}} = {}) {
    return useMutation(updateEvent, queryConfig)
}

export function useUpdateID({queryConfig = {}} = {}) {
    return useMutation(updateEventID, queryConfig)
}

export function useDeleteEvent({queryConfig = {}} = {}) {
    return useMutation(deleteEvent, queryConfig)
}

export function useGetAllEvents({page},queryConfig = {}) {
    return useQuery(['GET_ALL_EVENTS'], () => getAllEvent({page}), queryConfig);
}

export function useEventLike(queryConfig = {}) {
    return useMutation(eventLike, queryConfig)
}

export function useEventDislike(queryConfig = {}) {
    return useMutation(eventDislike, queryConfig)
}

export function useGetEvents({page} ,queryConfig = {}) {
    return useQuery(['GET_EVENTS'], () => getEvent({page}), queryConfig);
}
