import create from "zustand";
import jwt_decode from "jwt-decode";
import { useHistory, Link } from "react-router-dom";
import Cookies from "js-cookie";

export const useAuthStore = create((set) => ({
  userToken: Cookies.get("usertoken") ?? undefined,
  data: Cookies.get("usertoken")
    ? jwt_decode(Cookies.get("usertoken"))
    : undefined,
  setData: (token) => {
    Cookies.remove("usertoken", token);
    Cookies.set("usertoken", token);
    set((state) => ({ data: jwt_decode(token) }));
  },
  logOut: () => {
    set((state) => ({ data: null, userToken: null }));
    Cookies.remove("usertoken");
    window.history.pushState("", "", "/");
  },
  refresh: (data) => {
    set((state) => ({ data: data }));
  },
}));
