const lang = {
    ca: {
        title: "Informem als que ens cuiden",
        date: "29/06/24 Primer Taller Avançat d'Eines Digitals",
        imageBanner: "/client/src/assets/images/activitats/20240601_tb2_ca-home.png",
        altBanner: "Primer Taller Avançat d'Eines Digitals. Juny 2024",
        googleLogin: " Seguir amb Google",
        facebookLogin: " Seguir amb Facebook",
        login: "Iniciar Sessió",
        register: "Registra't amb correu electrònic",
        forgotPass: "¿Ha oblidat la seva contrasenya?",
        text1: "Al continuar indiques que acceptes les",
        text2: "i que entens",
        text3: "de iCuida",
        serviceCond: "condicions de servei",
        termsprivacy: "la política de privacitat",
        email: "Correu electrònic",
        passw: "Contrasenya",
        return: "Tornar enrera",
        informativeText1: "Per tal de fer ús de l’aplicatiu iCuida s’ha de tenir la Targeta Cuidadora de Barcelona Cuida. Per saber més sobre la targeta cuidadora i com obtenir-la pots prèmer aquí.",
        informativeText2: "Es permet el registre de prova a iCuida durant un període d’un mes. Si passat aquest mes no s’ha introduït el número de la Targeta Cuidadora al perfil personal el sistema bloquejarà l’accés.",
        blockedText1: "Hola ",
        blockedText2: "! 😊",
        blockedText3: "Hem notat que no has omplert la \"Targeta Cuidadora\" al teu perfil. El teu accés a iCuida ha estat bloquejat temporalment.",
        blockedText4: "No et preocupis, obtenir la targeta a través de Barcelona Cuida és fàcil t'ho expliquem",
        blockedText5: "Un cop tinguis la targeta, escriu-nos a ",
        blockedText6: " i estarem encantats de restablir el teu accés.",
        blockedText7: "Gràcies per ser part d’iCuida! ❤️",
        },
    es_ES: {
            title: "Informamos a quienes nos cuidan",
            date: "29/06 Primer Taller Avanzado de Herramientas Digitales",
            imageBanner: "/client/src/assets/images/activitats/20240601_tb2_es-home.png",
            altBanner: "Primer Taller Avanzado de Herramientas Digitales. Junio 2024",
            googleLogin: ' Seguir con Google',
            facebookLogin: ' Seguir con Facebook',
            login: 'Iniciar Sesión',
            register: 'Regístrate con correo electrónico',
            forgotPass: '¿Has olvidado tu contraseña?',
            text1: "Al continuar indicas que aceptas las",
            serviceCond: "condiciones de servicio",
            text2: "y que entiendes",
            termsprivacy: "la politica de privacidad",
            text3: "de iCuida",
            email: "Correo electrónico",
            passw: "Contraseña",
            return: "Volver atrás",
            informativeText1: "Para usar el aplicativo iCuida se debe tener la Tarjeta Cuidadora de Barcelona Cuida. Para saber más acerca de la tarjeta y cómo obtenerla puedes pulsar aquí.",
            informativeText2: "Se permite el registro de prueba en iCuida durante un mes. Si pasado este mes no se ha introducido el número de la Tarjeta Cuidadora en el perfil personal el sistema bloqueará el acceso.",
            blockedText1: "¡Hola ",
            blockedText2: "! 😊",
            blockedText3: "Hemos notado que no has rellenado la \"Tarjeta Cuidadora\" en tu perfil. Tu acceso a iCuida ha sido bloqueado temporalmente.",
            blockedText4: "No te preocupes, obtener la tarjeta a través de Barcelona Cuida es fácil te lo explicamos",
            blockedText5: "Una vez tengas la tarjeta, escríbenos a ",
            blockedText6: " y estaremos encantados de restablecer tu acceso.",
            blockedText7: "¡Gracias por ser parte de iCuida! ❤️",
        }
}

export default lang;