import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css'

function Carousel({items}) {

    return (
        <React.Fragment>
                <AliceCarousel
                    disableDotsControls
                    touchMoveDefaultEvents="true"
                    swipeDelta="9999"
                    animationType="fadeout"
                    animationDuration={800}
                    items={items}
                />
        </React.Fragment>
    )
}

export default Carousel;