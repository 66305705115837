import React, { useState, useRef, useEffect } from "react";
import { jsx, css } from "@emotion/react";
import {
  Box,
  Flex,
  Avatar,
  Text,
  VStack,
  StackDivider,
  Input,
  Button,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  useDisclosure,
} from "@chakra-ui/react";
import IconoComentar from "../../assets/icons/IconoComentar";
import IconoTresPuntos from "../../assets/icons/IconoTresPuntos";
import LikeIcon from "../../assets/icons/LikeIcon";
import IconoRespuesta from "../../assets/icons/IconoRespuesta";
import moment from "moment";
import "moment/locale/es";
import Comment from "./Comment";
import { useCreateComment } from "../../hooks/queries/comentario-communidad";
import { useAuthStore } from "../../hooks/state/useAuthStore";
import TextEditor from "../../components/ui/TextEditor";
import { useCreateNotification } from "../../hooks/queries/notification";
import { useAnswerLike } from "../../hooks/queries/respuesta-comunidad";
import { useAnswerDislike } from "../../hooks/queries/respuesta-comunidad";
import { useAnswerDelete } from "../../hooks/queries/respuesta-comunidad";
import { useUpdateAnswer } from "../../hooks/queries/respuesta-comunidad";
import { objHasUserId } from "../../functional/general";
import UpdateTextEditor from "../../components/utils/UpdateTextEditor";
import VerifyDelete from "../../components/utils/VerifyDelete";
import { data } from "jquery";
import socket from "../../api/socket";
import UserAvatar from "../../components/UserAvatar";

function Reply({ questionId, answer, refetch }) {
  // console.log("refetch funtion :", refetch);
  // console.log("refetch questionId :", questionId);
  // console.log("refetch answer :", answer);
  const node = useRef();

  const [comment, setComment] = useState(false);
  const [commentValue, setCommentValue] = useState("");
  const userData = useAuthStore().data;
  const [answerLike, answerLikeQuery] = useAnswerLike();
  const [answerDislike, answerDislikeQuery] = useAnswerDislike();
  const [answerDelete, answerDeleteQuery] = useAnswerDelete();
  const [answerData, setAnswerData] = useState({});
  const [updateAnswer, setUpdateAnswer] = useUpdateAnswer();
  const [createNotification, notificationQuery] = useCreateNotification();
  const [editAnswer, setEditAnswer] = useState(false);
  const [newAnswerContent, setNewAnswerContent] = useState("");
  const [createComment, createCommentQuery] = useCreateComment();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    setAnswerData(answer);
  }, [answer, answerData]);

  function liked(data) {
    const formData = {
      userId: userData.id,
      answerId: answerData?.id,
    };
    answerLike(formData).then((res) => {
      setAnswerData((prevAnswerData) => {
        prevAnswerData.AnswerLikes = updateAnswerLike(
          prevAnswerData.AnswerLikes
        );
        return prevAnswerData;
      });
      const likeformData = {
        recipientId: data.userId,
        senderId: userData.id,
        type: "answerLike",
        referenceId: data.id,
      };
      // Add if statement if userauth Id = question id to not create notification.
      if (userData.id !== data.userId) {
        createNotification(likeformData);
      }
      refetch();
    });
  }

  function updateAnswerLike(likeArray) {
    const likeIndex = likeArray.findIndex((val) => val.userId === userData.id);

    if (likeIndex !== -1) {
      likeArray.splice(likeIndex, 1);
    } else {
      likeArray = [
        ...likeArray,
        {
          answerId: answer.id,
          userId: userData.id,
        },
      ];
    }

    return likeArray;
  }

  function disliked() {
    const formData = {
      userId: userData.id,
      answerId: answerData?.id,
    };
    answerDislike(formData).then((res) => {
      setAnswerData((prevAnswerData) => {
        prevAnswerData.AnswerLikes = updateAnswerLike(
          prevAnswerData.AnswerLikes
        );
        return prevAnswerData;
      });
      refetch();
    });
  }

  function handleUpdateAnswer() {
    if (newAnswerContent) {
      updateAnswer({
        userId: userData.id,
        answer: newAnswerContent,
        answerId: answerData?.id,
      }).then((res) => {
        setAnswerData((oldData) => {
          oldData.answer = newAnswerContent;
          return oldData;
        });

        refetch();
        setEditAnswer(false);
      });
    }
  }

  function clearAnswerContent() {
    setNewAnswerContent("");
    setEditAnswer(false);
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [commentValue]);

  function formatDate(date) {
    const databaseDate = new Date(date);

    return moment(databaseDate).format("L");
  }

  function handleClick(e) {
    if (commentValue.length === 0) {
      return setComment(false);
    }
  }

  function handleSubmit() {
    createComment({
      questionId: questionId,
      answerId: answerData?.id,
      userId: userData.id,
      comment: commentValue,
    }).then((res) => {
      refetch();
      const formData = {
        recipientId: answerData?.userId,
        senderId: userData.id,
        referenceId: answerData?.id,
        type: "answerComment",
      };
      // Add if statement if userauth Id = question id to not create notification.
      if (answerData?.userId !== userData.id) {
        createNotification(formData);
      }
      setCommentValue("");
      setComment(false);
      socket.emit("new-notification", {
        to: answerData?.userId,
      });
    });
  }

  function handleDelete() {
    answerDelete({
      userId: userData.id,
      answerId: answerData?.id,
    }).then((res) => {
      console.log(res);
      refetch();
      onClose();
    });
  }
  console.log(answerData);
  return (
    <Box p="20px" width="100%" ref={node}>
      <Flex
        css={css`
          h1 {
            font-size: 2em;
            font-weight: bolder;
            margin-top: 0.67em;
            margin-bottom: 0.67em;
          }
          h2 {
            font-size: 1.5em;
            font-weight: bolder;
            margin-top: 0.83em;
            margin-bottom: 0.83em;
          }
          h3 {
            font-size: 1.17em;
            font-weight: bolder;
            margin-top: 1.17em;
            margin-bottom: 1.17em;
          }
          h4 {
            font-size: 1em;
            font-weight: bolder;
            margin-top: 1.33em;
            margin-bottom: 1.33em;
          }
          h5 {
            font-size: 0.83em;
            font-weight: bolder;
            margin-top: 1.67em;
            margin-bottom: 1.67em;
          }
          h6 {
            font-size: 0.67em;
            font-weight: bolder;
            margin-top: 2.33em;
            margin-bottom: 2.33em;
          }
          p {
            margin-bottom: 10px;
          }
          iframe {
            width: 100%;
          }
        `}
      >
        <VerifyDelete borrar={handleDelete} isOpen={isOpen} onClose={onClose} />
        <Box width="100%">
          <Box pb="10px">
            <Box my="10px" display="flex">
              <a href={"/perfil-usuario/" + answerData?.User?.id}>
                <UserAvatar userData={answerData?.User} />
              </a>
              <Box>
                <Box ml="10px">
                  <Box as="span" fontSize="13px" fontWeight="bold">
                    {" "}
                    <a href={"/perfil-usuario/" + answerData?.User?.id}>
                      {answerData?.User?.firstName +
                        " " +
                        answerData?.User?.lastName}
                    </a>
                  </Box>
                  <Box as="span" fontSize="13px">
                    {" "}
                    -{" "}
                    {answerData?.updatedAt
                      ? formatDate(answerData?.updatedAt)
                      : formatDate(answerData?.createdAt)}
                  </Box>
                </Box>
                <Box ml="10px">
                  <Box as="span" fontSize="13px">
                    Tipo de usuario
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box ml="10px">
              {editAnswer ? (
                <Box>
                  <UpdateTextEditor
                    content={newAnswerContent}
                    initialValue={answerData?.answer}
                    setContent={setNewAnswerContent}
                    clear={clearAnswerContent}
                    submit={handleUpdateAnswer}
                  />
                </Box>
              ) : (
                <Box as="span" fontSize="13px">
                  <Box
                    dangerouslySetInnerHTML={{ __html: answerData?.answer }}
                  />
                </Box>
              )}
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Flex>
                <Flex>
                  {answerData &&
                  objHasUserId(answerData?.AnswerLikes, userData.id) ? (
                    <Box
                      cursor="pointer"
                      onClick={() => {
                        disliked(answerData);
                      }}
                    >
                      <LikeIcon />
                    </Box>
                  ) : (
                    <Box cursor="pointer" onClick={() => liked(answerData)}>
                      <LikeIcon color="#8B8886" />
                    </Box>
                  )}
                  <Text ml="3px">{answerData?.AnswerLikes?.length}</Text>
                </Flex>
                <Flex cursor="pointer" ml="20px">
                  <Box onClick={() => setComment(true)}>
                    <IconoRespuesta fontSize="20px" color="#8B8886" />
                  </Box>
                  <Text ml="3px">{answerData?.Comments?.length}</Text>
                </Flex>
              </Flex>
              <Flex hidden={userData.id !== answerData?.userId}>
                <Box alignSelf="center">
                  <Menu>
                    <MenuButton>
                      <IconoTresPuntos color="#8B8886" />
                    </MenuButton>
                    <MenuList>
                      <MenuItem
                        onClick={() => {
                          setEditAnswer(true);
                        }}
                      >
                        <Text fontSize="13px" fontWeight="semibold">
                          Editar Mensaje
                        </Text>
                      </MenuItem>
                      <MenuItem onClick={onOpen}>
                        <Text fontSize="13px" fontWeight="semibold">
                          Borrar Mensaje
                        </Text>
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Box>
              </Flex>
            </Box>

            <Box hidden={!comment} p="10px 20px">
              <Box>
                <TextEditor setContent={setCommentValue} />
                <Box mt="10px">
                  <Button
                    width="100%"
                    colorScheme="blue"
                    borderRadius="50px"
                    onClick={() => handleSubmit()}
                  >
                    Comentar
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
          <VStack borderLeft="2px solid #CBCBCB">
            {answerData?.Comments?.map((comment) => (
              <Comment refetch={refetch} comment={comment} />
            ))}
          </VStack>
        </Box>
      </Flex>
    </Box>
  );
}

export default Reply;
