import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Route } from "react-router-dom";
import {
  Box,
  Text,
  Divider,
  HStack,
  Button,
  Input,
  Image,
  Flex,
  Spacer,
  Avatar,
  Textarea,
} from "@chakra-ui/react";
import PreguntasComunidad from "./PreguntasComunidad";
import PreguntasOficiales from "./PreguntasOficiales";
import { Row, Col } from "react-bootstrap";
import { useAuthStore } from "../hooks/state/useAuthStore";
import XIcono from "../assets/icons/xIcon";
import { useCategoryStore } from "../hooks/state/useCategoryStore";
import { useLangStore } from "../hooks/state/useLangStore";
import lang from "../assets/lang/welcome";
import langGen from "../assets/lang/general";
import UserAvatar from "./UserAvatar";

export default function NewQuestions(category) {
  const [showTab, setShowTab] = useState("comunidad");
  const currentCategory = useCategoryStore((state) => state.category);
  const userData = useAuthStore((state) => state.data);
  const history = useHistory();
  const currentLang = useLangStore((state) => state.currentLang);
  const [announce, setAnnounce] = useState(true);

  function doubleString(string) {
    let answer = "";

    for (let i = 0; i < string.length; i++)
      answer = answer + string[i] + string[i];

    return answer;
  }

  return (
    <Box m="auto">
      <Box>
        <Route exact path="/">
          <Box
            hidden={!announce}
            position="relative"
            mb="20px"
            bg="blue.6"
            mx="auto"
            p="20px"
            borderRadius="5px"
          >
            <Box>
              <Box>
                <Text mb="10px">{lang[currentLang].welcome}</Text>
                <Text>
                  {lang[currentLang].text1}{" "}
                  <Text as="span" fontWeight="bold">
                    {lang[currentLang].text2}
                  </Text>{" "}
                  {lang[currentLang].text3}{" "}
                  <Text
                    as="ins"
                    cursor="pointer"
                    color="blue.5"
                    onClick={(e) => history.push("/sobre-icuida")}
                  >
                    {lang[currentLang].abouticuida}
                  </Text>
                </Text>
              </Box>
            </Box>
            <Box
              position="absolute"
              right="10px"
              top="10px"
              onClick={(e) => setAnnounce(false)}
            >
              <XIcono />
            </Box>
          </Box>
        </Route>
        {category.category && (
          <Route path="/c/">
            <Box
              hidden={!announce}
              position="relative"
              mb="20px"
              bg="blue.6"
              mx="auto"
              p="20px"
              borderRadius="5px"
            >
              <Box>
                <Box>
                  <Text fontWeight="bold" mb="10px">
                    {currentLang === "es_ES"
                      ? category?.category.category
                      : category?.category.categoryCat}
                  </Text>
                  <Text>
                    {currentLang === "es_ES"
                      ? category?.category.description
                      : category?.category.descriptionCat}
                  </Text>
                </Box>
              </Box>
              <Box
                position="absolute"
                right="10px"
                top="10px"
                onClick={(e) => setAnnounce(false)}
              >
                <XIcono />
              </Box>
            </Box>
          </Route>
        )}
        <Box
          mb="20px"
          justifyContent="flex-end"
          backgroundColor="white"
          overflow="hidden"
          borderRadius="5px"
        >
          {category.category && (
            <Box>
              {category.category.imgUrl && (
                <Image
                  width="100%"
                  src={require("../assets/images/categorias/" +
                    category?.category.imgUrl)}
                />
              )}
            </Box>
          )}
          <Divider />
          <Box p="10px 20px">
            <Box my="10px" display="flex" alignSelf="center">
              <a>
                <UserAvatar userData={userData}/>
              </a>
              <Box>
                <Box ml="10px">
                  {userData.lastName === null ? (
                    <Box as="span" fontSize="13px" fontWeight="bold">
                      {userData.firstName}
                    </Box>
                  ) : (
                    <Box as="span" fontSize="13px" fontWeight="bold">
                      {userData.firstName + " " + userData.lastName}
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
            <Textarea
              border="none"
              borderBottom="1px solid #cbcbcb"
              borderRadius="0"
              minH="30px"
              ml="5px"
              onClick={(e) => history.push("/preguntar")}
              placeholder="¿Cuál es tu pregunta o enlace?"
            />
          </Box>
        </Box>
        <Box
          backgroundColor="white"
          overflow="hidden"
          borderRadius="5px"
          mb="10px"
        >
          <Box p="20px">
            <Row>
              {category.category && (
                <Col xs="12" lg="6">
                  <Box mb="20px">
                    <Text mb="5px" fontWeight="bold">
                      {currentLang === "es_ES"
                        ? category.category.category
                        : category.category.categoryCat}
                    </Text>
                    <Text>
                      {currentLang === "es_ES"
                        ? category.category.note
                        : category.category.noteCat}
                    </Text>
                  </Box>
                </Col>
              )}
              <Spacer />
              <Col xs="12" lg="6">
                <HStack justifyContent="flex-end">
                  <Button
                    _focus={{ outline: "none" }}
                    border="none"
                    borderRadius="20px"
                    color={showTab === "comunidad" ? "#fff" : "#000"}
                    bg={showTab === "comunidad" ? "orange.1" : "gray.3"}
                    onClick={() => setShowTab("comunidad")}
                  >
                    {langGen[currentLang].comunity}
                  </Button>
                  <Button
                    _focus={{ outline: "none" }}
                    border="none"
                    borderRadius="20px"
                    color={showTab === "oficial" ? "#fff" : "#000"}
                    bg={showTab === "oficial" ? "orange.1" : "gray.3"}
                    onClick={() => setShowTab("oficial")}
                  >
                    {langGen[currentLang].faqsoficial}
                  </Button>
                </HStack>
              </Col>
            </Row>
          </Box>
        </Box>
        {showTab === "oficial" ? (
          <PreguntasOficiales category={category?.category?.name} />
        ) : (
          ""
        )}
        {showTab === "comunidad" ? (
          <PreguntasComunidad category={category?.category?.name} />
        ) : (
          ""
        )}
      </Box>
    </Box>
  );
}
