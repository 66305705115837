import React from 'react';

import styled from '@emotion/styled'
import useClasses from "../../hooks/shared/useClasses";

const StyledLoadingOverlay = styled.div`
  background-color: #fff;
  opacity: ${props => props.opacity};
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 200;
  img {
    margin: auto;
    //width: 100%;
    //height: 100%;
  }
  &.is-fullscreen {
    position: fixed;
  }
`;

function LoadingOverlay({fullScreen = false, opacity = 0.3, active = false}) {

    const overlayClasses = useClasses({
        'is-fullscreen': fullScreen
    });

    return (
        active && <StyledLoadingOverlay opacity={opacity} className={overlayClasses}>
            <img src="/images/loader.svg" alt="Loader"/>
        </StyledLoadingOverlay>
    );
}

export default LoadingOverlay;