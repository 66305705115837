import React from 'react';

export default function IconoTresPuntos({color="#000"}) {
  return(
  <svg id="_3puntos_tuspreguntas" data-name="3puntos_tuspreguntas" xmlns="http://www.w3.org/2000/svg" width="15.527" height="4.18" viewBox="0 0 15.527 4.18">
    <path id="Trazado_700" data-name="Trazado 700" d="M210.267,78.131a2.094,2.094,0,1,1,2.231-2.09,2.164,2.164,0,0,1-2.231,2.09" transform="translate(-208.036 -73.951)" fill={color}/>
    <path id="Trazado_701" data-name="Trazado 701" d="M215.8,78.131a2.094,2.094,0,1,1,2.231-2.09,2.163,2.163,0,0,1-2.231,2.09" transform="translate(-208.036 -73.951)" fill={color}/>
    <path id="Trazado_702" data-name="Trazado 702" d="M221.332,78.131a2.094,2.094,0,1,1,2.231-2.09,2.164,2.164,0,0,1-2.231,2.09" transform="translate(-208.036 -73.951)" fill={color}/>
  </svg>

  )
}