import Form from "../../components/ui/Form";
import {Box, Button, Divider, Input, Text} from "@chakra-ui/react";
import Errors from "../../assets/functions/errors";
import React, {useState} from "react";
import LogInButton from "../../components/ui/LogInButton";
import {register} from "../../api/UserFunctions";
import {useRecommendUsers} from "../../hooks/queries/recommend";
import {useAuthStore} from "../../hooks/state/useAuthStore";
import SuccessWarning from "../../components/warnings/SuccessWarning";
import lang from "../../assets/lang/esdeveniments";
import {useLangStore} from "../../hooks/state/useLangStore";

function RecommendForm() {
  const [recommendUsers, recommendQuery] = useRecommendUsers();
  const userData = useAuthStore((state) => state.data);
  const [recommendSuccess, setRecommendSuccess] = useState(false);
  const currentLang = useLangStore((state) => state.currentLang);

  function onSubmit(data) {
    console.log(data);
    const finalData = { userId: userData.id, ...data };
    recommendUsers(finalData)
      .then((res) => {
        console.log(res);
        setRecommendSuccess(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <Form
      initialValues={{
        name1: "",
        phone1: "",
        email1: "",
        name2: "",
        phone2: "",
        email2: "",
      }}
      onSubmit={onSubmit}
    >
      {({ setFieldValue, values }) => (
        <Box maxW="800px" mx="auto" p="20px" borderRadius="5px">
          <Text mb="20px" mt="20px" textAlign="center" fontSize="2xl">
            {lang[currentLang].form.title}
          </Text>
          <Text textAlign="center">{lang[currentLang].form.text1}</Text>
          <div className="form-group">
            <Input
              required
              backgroundColor="#fff"
              name="name1"
              placeholder={lang[currentLang].form.name}
              value={values.name1}
              onChange={(e) =>
                setFieldValue("name1", e.target.value)
              }
              borderRadius="50px!important"
            />
          </div>
          <div className="form-group">
            <Input
              required
              backgroundColor="#fff"
              name="phone1"
              placeholder={lang[currentLang].form.phone}
              value={values.phone1}
              onChange={(e) =>
                setFieldValue("phone1", e.target.value)
              }
              borderRadius="50px!important"
            />
          </div>
          <div className="form-group">
            <Input
              required
              backgroundColor="#fff"
              className="place"
              name="email1"
              placeholder={lang[currentLang].form.email}
              value={values.email1}
              onChange={(e) =>
                setFieldValue("email1", e.target.value)
              }
              borderRadius="50px!important"
            />
          </div>
          <Divider  mb="20px" />
          <Text textAlign="center">{lang[currentLang].form.text2}</Text>
          <div className="form-group">
            <Input
              required
              backgroundColor="#fff"
              name="name2"
              placeholder={lang[currentLang].form.name}
              value={values.name2}
              onChange={(e) =>
                setFieldValue("name2", e.target.value)
              }
              borderRadius="50px!important"
            />
          </div>
          <div className="form-group">
            <Input
              required
              backgroundColor="#fff"
              name="phone2"
              placeholder={lang[currentLang].form.phone}
              value={values.phone2}
              onChange={(e) =>
                setFieldValue("phone2", e.target.value)
              }
              borderRadius="50px!important"
            />
          </div>
          <div className="form-group">
            <Input
              required
              mb="10px"
              backgroundColor="#fff"
              className="place"
              name="email2"
              placeholder={lang[currentLang].form.email}
              value={values.email2}
              onChange={(e) =>
                setFieldValue("email2", e.target.value)
              }
              borderRadius="50px!important"
            />
          </div>
          <Button
            isLoading={recommendQuery.isLoading}
            borderRadius="50px"
            color="#fff"
            bg="blue.1"
            type="submit"
            mt="10px"
            width="100%"
          >{lang[currentLang].form.send}</Button>
          <SuccessWarning
            hidden={!recommendSuccess}
            content={lang[currentLang].form.success}
          />
          {recommendSuccess &&
            < Button
              borderRadius="50px"
              color="#fff"
              bg="blue.1"
              onClick={() => setRecommendSuccess(false)}
              mt="10px"
            >{lang[currentLang].form.reset}</Button>
          }
        </Box>
        )}
    </Form>
  );
}

export default RecommendForm;