const lang = {
  es_ES: {
    landingPage: {
      imatge: "client/src/assets/images/activitats/20240629_tav1_es.png",
      imgAlt: "Primer Taller Avanzado de Herramientas Digitales. Junio 2024",
      
      title: "Primer Taller Avanzado de Herramientas Digitales. Junio 2024",
      
      text1: "El próximo sábado 29 de junio a las 11h iCuida os invita a asistir al primer taller avanzado de herramientas digitales 2024 de “Apps que te ayudan, cuidamos de quien cuida” que se llevará a cabo en el ",
      text1l1: {
        text: "Espacio Barcelona Cuida, calle Viladomat, 127, 08015, Barcelona",
        enllas: "https://maps.app.goo.gl/Vh1xcWSBXiMLT4RG9"
      },
      text1p1: ".",

      text2: "Esta actividad sirve para capacitaros a vosotras, las trabajadoras de los cuidados, en las herramientas y recursos digitales. Se trata de un ",
      text2b1: "taller avanzado",
      text2p1: " para aquellas que ya habéis asistido a los talleres básicos en donde os capacitaremos en herramientas digitales que mejorarán y facilitarán vuestro día a día, ",
      text2b2: "os ayudarán a tener mayores oportunidades laborales y a ser más autónomas",
      text2p2: ".",

      text3: "",
      text3b1: "El taller buscará vuestra participación, de manera que sea un taller realmente práctico ",
      text3p1: "para el aprendizaje en el uso de la aplicación y sus diferentes apartados y herramientas. También os ayudaremos a conectar con otras trabajadoras de los cuidados para que podáis crear una red de apoyo.",

      /*
      text4: "El taller se dividirá en dos partes: inicialmente, se evaluarán y discutirán las funcionalidades de la aplicación iCuida, permitiendo a las asistentes ",
      text4b1: "sugerir mejoras.",
      text4p1: " Posteriormente, se explorarán temáticas de datos relevantes para sus necesidades, promoviendo un ",
      text4b2: "proceso de cocreación",
      text4p2: " que mejorará la aplicación.",

      text5: "Este encuentro también servirá para ",
      text5b1: "fortalecer la red de apoyo entre las profesionales del cuidado",
      text5p1: ", fomentando la colaboración y el intercambio de experiencias ",
      text5b2: "con un tiempo de networking",
      text5p2: " compartiendo un pequeño refrigerio a finalizar el taller.",
      */

      text96: "",
      text96b1: "Esta actividad es gratuita",
      text96p1: " y va dirigida a trabajadoras del hogar y los cuidados que tienen la Tarjeta Cuidadora. Si todavía no tienes la Tarjeta, puedes asistir al taller gratuito ",
      text96b2: "donde te explicaremos y te ayudaremos a conseguirla",
      text96p2: ".",

      text97: "También puedes conseguirla ",
      text97l1: {
        text: "rellenando este breve formulario",
        enllas: "https://www.cuida.barcelona/es/user/register"
      },
      text97p1: ". Si tienes dudas o necesitas ayuda para pedir tu Tarjeta, conecta con el Espacio Barcelona Cuida, al teléfono 93 413 21 21, al correo electrónico ",
      text97l2: {
        text: "barcelona.cuida@bcn.cat",
        enllas: "mailto:barcelona.cuida@bcn.cat"
      },
      text97p2: " o presencialmente en la calle Viladomat, 127.",
      
      pointZero:{
        text: "Inscríbete y participa:",
        link: "https://forms.gle/ZzsVxnv5nWf58aqX6"
      },
      prePoints: "Información evento:",
      pointOne: {
        title: "Dia:",
        text: "29 de junio de 2024"
      },
      pointTwo: {
        title: "Hora:",
        text: "De las 11 a 12:30 horas"
      },
      pointThree: {
        title: "Lugar:",
        text: "Espacio Barcelona Cuida, calle Viladomat, 127, 08015, Barcelona",
        link: "https://maps.app.goo.gl/Vh1xcWSBXiMLT4RG9"
      },
      
      guest: {
        text1: "Para recomendar, ",
        text2: "inicia sessión",
        text3: " si ya eres usuario de iCuida o ",
        text4: "accede al formulario de registro.",
      }
    },
    form: {
      title: "Recomienda iCuida",
      text1: "Primer recomendado",
      text2: "Segundo recomendado",
      name: "Nombre de la persona a quien recomiendas iCuida",
      phone: "Teléfono de la persona a quien recomiendas iCuida",
      email: "Email de la persona a quien recomiendas iCuida",
      send: "Enviar recomendaciones",
      success: "Las recomendaciones se han enviado correctamente",
      reset: "Seguir recomendando",
    }
  },
  ca: {
    landingPage: {
      imatge: "client/src/assets/images/activitats/20240629_tav1_ca.png",
      imgAlt: "Primer Taller Avançat d'Eines Digitals. Juny 2024",
      
      title: "Primer Taller Avançat d'Eines Digitals. Juny 2024",
      
      text1: "Dissabte que ve 29 de juny a les 11h iCuida us convida a assistir al primer taller avançat d'eines digitals 2024 d'“Apps que t'ajuden, cuidem de qui cuida” que es durà a terme a l'",
      text1l1: {
        text: "Espai Barcelona Cuida, carrer Viladomat, 127, 08015, Barcelona",
        enllas: "https://maps.app.goo.gl/Vh1xcWSBXiMLT4RG9"
      },
      text1p1: ".",

      text2: "Aquesta activitat serveix per a capacitar-vos a vosaltres, les treballadores de les cures, en les eines i recursos digitals. Es tracta d'un ",
      text2b1: "taller avançat",
      text2p1: " per a aquelles que ja heu assistit als tallers bàsics on us capacitarem en eines digitals que milloraran i facilitaran el vostre dia a dia, us ajudaran a tenir ",
      text2b2: "millors oportunitats laborals i a ser més autònomes",
      text2p2: ".",

      text3: "",
      text3b1: "El taller buscarà la participació de totes les persones assistents, de manera que sigui un taller realment pràctic ",
      text3p1: "per a l'aprenentatge en l'ús de l'aplicació i els seus diferents apartats. Els compartirem les eines que les poden ajudar en el seu dia a dia i també les connectarem amb altres persones amb les seves mateixes necessitats per a crear una xarxa de suport.",

      /*
      text4: "El taller es dividirà en dues parts: inicialment, savaluaran i discutiran les funcionalitats de laplicació iCuida, permetent a les assistents ",
      text4b1: "suggerir millores.",
      text4p1: " Posteriorment, s'exploraran temàtiques de dades rellevants per a les vostres necessitats, promovent un ",
      text4b2: "procés de cocreació",
      text4p2: " que millorarà l'aplicació.",

      text5: "Aquesta trobada també servirà per ",
      text5b1: "enfortir la xarxa de suport entre les professionals de la cura",
      text5p1: ", fomentant la col·laboració i lintercanvi dexperiències amb un ",
      text5b2: "temps de networking",
      text5p2: " compartint un petit refrigeri a finalitzar el taller.",
      */

      text96: "",
      text96b1: "Aquesta activitat és gratuïta",
      text96p1: " i va adreçada a treballadores de la llar i les cures que tenen la Targeta Cuidadora. Si encara no tens la Targeta, pots assistir al taller gratuït on ",
      text96b2: "us explicarem i us ajudarem a aconseguir-la",
      text96p2: ".",

      text97: "També pots aconseguir-la ",
      text97l1: {
        text: "emplenant aquest breu formulari",
        enllas: "https://www.cuida.barcelona/es/user/register"
      },
      text97p1: ". Si tens dubtes o necessites ajuda per demanar la teva Targeta, connecta amb l'Espai Barcelona Cuida, al telèfon 93 413 21 21, al correu electrònic ",
      text97l2: {
        text: "barcelona.cuida@bcn.cat",
        enllas: "mailto:barcelona.cuida@bcn.cat"
      },
      text97p2: " o presencialment al carrer Viladomat, 127.",
      
      pointZero:{
        text: "Inscriu-te i participa-hi:",
        link: "https://forms.gle/ZzsVxnv5nWf58aqX6"
      },
      prePoints: "Informació de l’esdeveniment:",
      pointOne: {
        title: "Dia:",
        text: "29 de juny de 2024"
      },
      pointTwo: {
        title: "Hora:",
        text: "D'11 a 12:30 hores"
      },
      pointThree: {
        title: "Lloc:",
        text: "Espai Barcelona Cuida, carrer Viladomat, 127, 08015, Barcelona",
        link: "https://www.google.com/maps/place//data=!4m2!3m1!1s0x12a4a2b2aac1d517:0xb5de404f57101ed4?sa=X&ved=1t:8290&ictx=111"
      },

      guest: {
        text1: "Per a recomanar, ",
        text2: "inicia sessió",
        text3: " si ja ets usuari d'iCuida o ",
        text4: "accedeix al formulari de registre.",
      }
    },
    form: {
      title: "Recomana iCuida",
      text1: "Primer recomanat",
      text2: "Segon recomanat",
      name: "Nom de la persona a qui recomanes iCuida",
      phone: "Telèfon de la persona a qui recomanes iCuida",
      email: "Email de la persona a qui recomanes iCuida",
      send: "Enviar recomanacions",
      success: "Les recomanacions s'han enviat correctament",
      reset: "Continuar recomanant",
    }
  }
};

export default lang;