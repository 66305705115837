import {Box, Text, Image} from "@chakra-ui/react";
import React from "react";
import {useLangStore} from "../../hooks/state/useLangStore";
import lang from "../../assets/lang/recommend";

function RecommendContent() {
  const currentLang = useLangStore((state) => state.currentLang);

  return (

    <Box maxW="800px" mx="auto" p="20px" borderRadius="5px">
      <Box bm='15px'>
        <Image src={require("../../assets/images/activitats/recomienda-y-gana.png")} alt="Cuidadora cuídate, recomienda y gana"/>
      </Box>
      <Box mb="15px" mt="15px">
        <Text fontWeight="bold" fontSize="25px" color='tomato'>
          {lang[currentLang].landingPage.title}
        </Text>
      </Box>
      <Box mb="15px">
        <Text fontSize="16px">
          <strong>{lang[currentLang].landingPage.text1bold}</strong>{" "}
          {lang[currentLang].landingPage.text2}{" "}
          <strong>{lang[currentLang].landingPage.text3bold}</strong>{" "}
          {lang[currentLang].landingPage.text4}
        </Text>{" "}
      </Box>
      <Box mb="15px">
        <Text fontWeight="bold" fontSize="15px">
          {lang[currentLang].landingPage.pointOne.title}
        </Text>
      </Box>
      <Box mb="15px">
        <Text fontSize="14px">{lang[currentLang].landingPage.pointOne.text}</Text>{" "}
      </Box>
      <Box mb="15px">
        <Text fontWeight="bold" fontSize="15px">
          {lang[currentLang].landingPage.pointTwo.title}
        </Text>
      </Box>
      <Box mb="15px">
        <Text fontSize="14px">{lang[currentLang].landingPage.pointTwo.text}</Text>{" "}
      </Box>
      <Box mb="15px">
        <Text fontWeight="bold" fontSize="15px">
          {lang[currentLang].landingPage.pointThree.title}
        </Text>
      </Box>
      <Box mb="15px">
        <Text fontSize="14px">{lang[currentLang].landingPage.pointThree.text1}</Text>{" "}
        <Text fontSize="14px">{lang[currentLang].landingPage.pointThree.text2}</Text>{" "}
        <Text fontSize="14px">{lang[currentLang].landingPage.pointThree.text3}</Text>{" "}
      </Box>
      <Box mb="15px">
        <Text fontWeight="bold" fontSize="18px">{lang[currentLang].landingPage.title1bold}</Text>{" "}
      </Box>
      
      <Box mb="15px">
        <Text fontWeight="bold" fontSize="15px">
          {lang[currentLang].landingPage.pointFour.title}
        </Text>
      </Box>
      <Box mb="15px">
        <Text fontSize="14px">{lang[currentLang].landingPage.pointFour.text1}</Text>{" "}
        <Text fontSize="14px">{lang[currentLang].landingPage.pointFour.text2}</Text>{" "}
      </Box>
      <Box mb="15px">
        <Text fontWeight="bold" fontSize="15px">
          {lang[currentLang].landingPage.pointFive.title}
        </Text>
      </Box>
      <Box mb="15px">
        <Text fontSize="14px">{lang[currentLang].landingPage.pointFive.text}</Text>{" "}
      </Box>
      <Box mb="15px">
        <Text fontWeight="bold" fontSize="15px">
          {lang[currentLang].landingPage.pointSix.title}
        </Text>
      </Box>
      <Box mb="15px">
        <Text fontSize="14px">{lang[currentLang].landingPage.pointSix.text1}{" "}<strong>{lang[currentLang].landingPage.pointSix.text1bold}</strong></Text>{" "}
        <Text fontSize="14px">{lang[currentLang].landingPage.pointSix.text2}</Text>{" "}
      </Box>
      <Box mb="15px">
        <Text fontWeight="bold" fontSize="15px">
          {lang[currentLang].landingPage.pointSeven.title}
        </Text>
      </Box>
      <Box mb="15px">
        <Text fontSize="14px">{lang[currentLang].landingPage.pointSeven.text1}</Text>{" "}
        <Text fontSize="14px">{lang[currentLang].landingPage.pointSeven.text2}</Text>{" "}
      </Box>
      <Box mb="15px">
        <Text fontWeight="bold" fontSize="15px">
          {lang[currentLang].landingPage.pointEight.title}
        </Text>
      </Box>
      <Box mb="15px">
        <Text fontSize="14px">{lang[currentLang].landingPage.pointEight.text}</Text>{" "}
      </Box>
      <Box mb="15px">
        <Text fontSize="14px">{lang[currentLang].landingPage.text5}</Text>{" "}
      </Box>
    </Box>
  );
}

export default RecommendContent;