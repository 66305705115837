import React from "react";
import {
  Box,
  Text,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Button,
} from "@chakra-ui/react";
// import Content from "../.././content/Content";
import Events from "./content/events";
import { useHistory } from "react-router-dom";

function Eventos() {
  const history = useHistory();

  return (
    <Box>
      <Box>
        <Box p="16px">
          <Text fontSize="3xl">Eventos</Text>
        </Box>
        <Box>
          <Tabs isLazy>
            <TabPanels>
              <TabPanel>
                <Box>
                  <Box mb="10px">
                    <Button
                      bg="blue.1"
                      color="white"
                      onClick={(e) => history.push("/crear-evento")}
                    >
                      Crear nuevo evento
                    </Button>
                  </Box>
                  <Events />
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Box>
    </Box>
  );
}

export default Eventos;
