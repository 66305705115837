import React, { Component } from 'react'
import  { BrowserRouter as Router, Route, Link, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie'

const PrivateRoute = ({ component: Component, categories, ...rest}) => (
    <Route {...rest} render={(props) => (
        Cookies.get('usertoken')
        ? <Component categories={categories} {...props} />
        : <Redirect to='/login' />
    )}/>
)

export default PrivateRoute;