import React, { useEffect, useState } from "react";
import { jsx, css } from "@emotion/react";
import { useAuthStore } from "../../hooks/state/useAuthStore";
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  ModalBody,
  useDisclosure,
  Radio,
  RadioGroup,
  Stack,
  Link,
  Image,
  Text
} from "@chakra-ui/react";
import { useHistory, Route } from "react-router-dom";
import PrivateRoute from "../../components/PrivateRoute";
import NewQuestions from "../../components/NewQuestions";
import Notification from "../../pages/Notifications";
import Test from "../../pages/Test";
import Responder from "../../pages/answer/index";
import Question from "../../pages/article/index";
import Categorias from "../../pages/categories/index";
import Contacto from "../../pages/Contacto";
import ErrorPage from "../../pages/ErrorPage";
import StaticQuestion from "../../components/staticQuestion";
import CreateQuestion from "../../components/createQuestion";
import CreateStaticQuestion from "../../components/createStaticQuestion";
import StickySide from "./sticky-side/index";
import { Row, Col, Container } from "react-bootstrap";
import Search from "../search";
import { useUpdateProfile } from "../../hooks/queries/usuarios";
import { useLangStore } from "../../hooks/state/useLangStore";
import lang from "../../assets/lang/general";
import Eventos from "../Events";
import CreateEvent from "../admin/sections/events/createEvent";

function Content({ categories }) {
  const history = useHistory();
  const userData = useAuthStore((state) => state.data);
  const [updateProfile, updateProfileQuery] = useUpdateProfile();
  const currentLang = useLangStore((state) => state.currentLang);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const finalRef = React.useRef();
  const [value, setValue] = React.useState("1");
  const updateAuthData = useAuthStore((state) => state.setData);

  const checkRemainTime = () => {
    const now = new Date();
    const createdAt = new Date(userData.createdAt);
    const remainTime = 30 - Math.floor((now - createdAt) / (1000 * 60 * 60 * 24));
    return remainTime > 0 ? remainTime : 0;
  }

  useEffect(() => {
    if (!userData.careGiverCard && !userData.careGiverReminder) {
      onOpen();
    }
  }, []);
  function handlePopup() {
    if (value === "1") {
      onClose();
      history.push("/perfil?id=5");
    } else if (value === "2") {
      onClose();
      history.push("/");
    } else {
      onClose();
      const finalData = {
        userId: userData.id,
        firstName: userData.firstName,
        lastName: userData.lastName,
        type: userData.type,
        description: userData.description,
        reminder: true,
      };
      updateProfile(finalData)
        .then((res) => {
          console.log(res);
          updateAuthData(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  return (
    <Box py="30px">
      <Container fluid>
        <Row>
          <Col
            md="9"
            css={css`
              @media (min-width: 0) and (max-width: 30em) {
                padding-left: 0;
                padding-right: 0;
              }
            `}
          >
            <Box width="100%">
              <PrivateRoute
                exact
                path="/categorias"
                categories={categories}
                component={Categorias}
              />
              <PrivateRoute exact path="/" component={NewQuestions} />
              <PrivateRoute path="/notificaciones" component={Notification} />
              <PrivateRoute path="/eventos" component={Eventos} />
              <PrivateRoute path="/pregunta/:id" component={Question} />
              <PrivateRoute
                path="/pregunta-estatica/:id"
                component={StaticQuestion}
              />
              <PrivateRoute
                path="/preguntar"
                categories={categories}
                component={CreateQuestion}
              />
              {userData.isAdmin && (
                <PrivateRoute
                  path="/crear-pregunta-estatica"
                  categories={categories}
                  component={CreateStaticQuestion}
                />
              )}
              {userData.isAdmin && (
                <PrivateRoute
                  path="/crear-evento/:id"
                  component={CreateEvent}
                />
              )}

              {userData.userCreator && (
                <PrivateRoute
                  path="/crear-evento/:id"
                  component={CreateEvent}
                />
              )}
              <PrivateRoute path="/responder" component={Responder} />
              {/* <PrivateRoute path="/contacta" component={Contacto} /> */}
              {/*<PrivateRoute>
                        <ErrorPage/>
                    </PrivateRoute>*/}
              {userData.isAdmin && (
                <PrivateRoute path="/test" component={Test} />
              )}
              <PrivateRoute path="/search" component={Search} />
              {categories?.length &&
                categories.map((category, index) => (
                  <PrivateRoute path={"/c/" + category.name}>
                    <NewQuestions category={category} />
                  </PrivateRoute>
                ))}
            </Box>
          </Col>
          <Col className="d-none d-md-block" md="3">
            <StickySide categories={categories} />
          </Col>
        </Row>
      </Container>
      <Modal
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
      >
        <ModalOverlay p="0">
          <ModalContent borderRadius="20px" m="12px">
            <ModalHeader m="auto" textAlign="center">
              {lang[currentLang].popupTitle}
            </ModalHeader>

            <ModalBody>
              <Box marginBottom="4" textAlign="center">
                <Image
                  width="400px"
                  src={require("../../assets/images/tarjetaCuidadora1.png")}
                  m="auto"
                />
              </Box>
              <Box marginBottom="4" textAlign="center">
                <b>
                  <Link color="blue.600" href="/tarjeta-cuidadora">
                    {lang[currentLang].popupLink}
                  </Link>
                </b>
              </Box>
              <Box marginBottom="4">
                <p> {lang[currentLang].popupQuestion}</p>
              </Box>
              <RadioGroup onChange={setValue} value={value}>
                <Stack direction="column">
                  <Radio value="1">
                    {" "}
                    {lang[currentLang].popupGoToProfileOption}
                  </Radio>
                </Stack>
              </RadioGroup>
              {new Date(userData.createdAt).valueOf() > 1693526400000 &&
              <Text textAlign='center' fontWeight='bold'>{lang[currentLang].ramainTimeText1} {checkRemainTime()} {lang[currentLang].ramainTimeText2} </Text>
              }
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={handlePopup}>
                Aplicar
              </Button>
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </Box>
  );
}

export default Content;
