const lang = {
    ca: {
        welcome: "!Benvinguda/o a iCuida!",
        text1: "Aquí podràs consultar i compartir informació de manera col·laborativa,",
        text2: "per a les treballadores de la llar i de la cura de persones",
        text3: "de Barcelona. Coneix més",
        abouticuida: "sobre iCuida"
    },
    es_ES: {
        welcome: "!Bienvenida/o a iCuida!",
        text1: "Aquí podrás consultar y compartir información de manera colaborativa,",
        text2: "por y para las trabajadoras del hogar y del cuidado de personas",
        text3: "de Barcelona. Conoce más",
        abouticuida: "sobre iCuida"
        
    }
}

export default lang;