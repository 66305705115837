import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Box, Checkbox, Text, useDisclosure } from "@chakra-ui/react";
import { FaTrash, FaPencilAlt } from "react-icons/fa";
import { Table } from "react-bootstrap";
import { useAuthStore } from "../../../../../hooks/state/useAuthStore";
import moment from "moment";
import "moment/locale/es";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {
  useDeleteEvent,
  useGetAllEvents,
} from "../../../../../hooks/queries/event";
import VerifyDelete from "../../../../../components/utils/VerifyDelete";

function Events() {
  const history = useHistory();
  const userData = useAuthStore((state) => state.data);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { isLoading, data, refetch } = useGetAllEvents({page: null});
  const [eventData, setEventData] = useState([]);
  const [deleteEvent, deleteEventQuery] = useDeleteEvent();
  const [selectedQuestionId, setSelectedQuestionId] = useState(null);
  const [showCard, setShowCard] = useState(true);

  useEffect(() => {
    if (!isLoading) {
      setEventData(data);
    }
  });

  function formatDate(date) {
    const databaseDate = new Date(date);
    return moment(databaseDate).format("L");
  }

  function handleDelete() {
    if (selectedQuestionId) {
      deleteEvent(selectedQuestionId, {
        onSuccess: (res) => {
          onClose();
          setShowCard(false);
          refetch();
        },
        onError: (err) => {
          console.log(err);
        },
      });
    }
  }

  return (
    <Box bg="white" overflowX="auto">
      <VerifyDelete borrar={handleDelete} isOpen={isOpen} onClose={onClose} />
      {/* {/* {!isLoading && ( */}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Evento</th>
            <th>Creado</th>
            <th>Editar</th>
            <th>Borrar</th>
          </tr>
        </thead>
        <tbody>
          {eventData?.data?.length > 0 &&
            eventData?.data?.map((value, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>
                  <a href="#">{value.title}</a>
                </td>

                <td>{formatDate(value.createdAt)}</td>
                <td>
                  <Box
                    cursor="pointer"
                    _hover={{ color: "blue" }}
                    width="fit-content"
                    mx="auto"
                    onClick={() => {
                      history.push("/crear-evento/" + value.id);
                    }}
                  >
                    <FaPencilAlt />
                  </Box>
                </td>
                <td>
                  <Box
                    cursor="pointer"
                    width="fit-content"
                    mx="auto"
                    onClick={(e) => {
                      onOpen();
                      setSelectedQuestionId(value.id);
                      // handleDelete(value.id);
                    }}
                  >
                    <FaTrash />
                  </Box>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </Box>
  );
}

export default Events;
