import React, { useEffect, useState } from "react";
import { Box, Flex, Text, Avatar } from "@chakra-ui/react";
import { useAuthStore } from "../../../../hooks/state/useAuthStore";
import { useHistory } from "react-router-dom";
import UserAvatar from "../../../UserAvatar";

function ChatSideBlock({
  conversationData,
  search,
  receivenoti,
  selectedRoom,
}) {
  const userData = useAuthStore((state) => state?.data);
  const [contact, setContact] = useState();

  useEffect(() => {
    if (search) {
      setContact(conversationData);
    } else {
      setContact(
        userData?.id !== conversationData?.UserOne?.id
          ? conversationData?.UserOne
          : conversationData?.UserTwo
      );
    }
  }, [conversationData]);

  useEffect(() => {
    console.log(contact)
  }, [contact]);

  return (
    <Flex>
      <Box>
        <UserAvatar userData={contact} >
          {receivenoti &&
          receivenoti?.length > 0 &&
          receivenoti?.findIndex(
            (data) =>
              (data?.userDatail?.UserOne?.id === contact?.id ||
                data?.userDatail?.UserTwo?.id === contact?.id) &&
              data?.userId === contact?.id &&
              data?.userId !== selectedRoom?.UserOne?.id &&
              data?.userId !== selectedRoom?.UserTwo?.id
          ) !== -1 ? (
            <span
              className="start-100 bg-primary border border-light rounded-circle"
              style={{
                position: "absolute",
                left: "23px",
                bottom: "23px",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                height: "10px",
                width: "10px",
              }}
            ></span>
          ) : null}
        </UserAvatar>
      </Box>
      <Box ml="10px">
        <Text fontWeight="bold" fontSize="14px">
          {contact?.firstName} {contact?.lastName}
        </Text>
        <Text fontWeight="bold" fontSize="10px">
          {contact?.connect === true ? "Disponible" : "No disponible"}
        </Text>
      </Box>
    </Flex>
  );
}
export default ChatSideBlock;
