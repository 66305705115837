const initialState = {
  items: [],
};

export const NotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    //returns updated state
    case "NOTIFICATION":
      let arr = JSON.stringify(state);
      let newarr = JSON.parse(arr);
      newarr.items.push(action.payload);
      return newarr;
    // return { ...state, items: [...action.payload] };
    //else the current state is retained
    case "REMOVE":
      let arra = JSON.stringify(state);
      let newarra = JSON.parse(arra);
      let index = newarra.items.findIndex(
        (data) => data?.conversationId === action?.payload
      );
      if (index !== -1) {
        newarra.items.splice(index, 1);
        return newarra;
      }
    default:
      return state;
  }
};
