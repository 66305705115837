import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Box, Button, Checkbox, Text } from "@chakra-ui/react";

import "moment/locale/es";

import { Table } from "react-bootstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { useGetLastMonthMetrics } from "../../../../../hooks/queries/metrics";

function LastMonthMetrics() {
  const { isLoading, data } = useGetLastMonthMetrics();
  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    if (!isLoading) {
      setRowData(data);
    }
  });

  return (
    <Box bg="white" overflowX="auto">
      <Table bordered hover>
        <thead>
          <tr>
            <th>Categoria</th>
            <th>Comentarios</th>
            <th>Me gusta</th>
            <th>Preguntas</th>
            <th>Respuestas</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(rowData).map((key) => {
            const value = rowData[key];

            return (
              <>
                <tr>
                  <td>
                    {value.categories
                      .toUpperCase()
                      .replace(/\W/g, " ")
                      .replace("NULL", "SIN CATEGORIA")}
                  </td>
                  <td align="center">{value.numeroComentarios}</td>
                  <td align="center">{value.numeroLikes}</td>
                  <td align="center">{value.numeroPreguntas}</td>
                  <td align="center">{value.numeroRespuestas}</td>
                </tr>
              </>
            );
          })}
        </tbody>
      </Table>
    </Box>
  );
}

export default LastMonthMetrics;
