import React from 'react';
import {Box, Text, OrderedList, ListItem} from '@chakra-ui/react'
import FollowUs from '../content/sticky-side/FollowUs';


function terminosCondiciones() {
    return(
        <Box bg="#f1fcff">
            <Box maxW="800px" mx="auto" py="20px">
                <Box p="20px" borderRadius="5px">
                    <Box mb="20px">
                        <Text fontWeight="bolder" fontSize="xl">
                            Términos y condiciones  generales de uso 
                        </Text>
                        <Text>
                            Fecha: entrada en vigor 01/10/2020
                        </Text>
                    </Box>
                <Box>
                    <Box mb="15px">
                        <Text fontWeight="bold">
                            Introducción
                        </Text>
                    </Box>
                    <Box mb="15px">
                        <Text>
                            Le rogamos que lea detenidamente los siguientes términos y condiciones generales de uso antes de hacer cualquier uso de nuestra  plataforma y/o aplicación móvil (en lo sucesivo denominado “iCuida”). Estos términos y condiciones generales de uso regulan el uso y acceso a la aplicación  web icuida.barcelona y contienen información importante sobre sus derechos y obligaciones.
                        </Text>
                    </Box>
                    <Box mb="15px">
                        <Text>
                            Si usted accede, se da de alta en y/o, en cualquier forma, utiliza iCuida, proporcionar cualquier dato personal a iCuida, estará concediendo su aceptación incondicional y expresa a la totalidad de los términos y condiciones generales de uso establecidos, estos términos y condiciones constituyen un contrato jurídicamente vinculante y exigible entre usted e iCuida.
                        </Text>
                    </Box>
                    <Box mb="15px">
                        <Text>
                            Además, tenga en cuenta que al darse de alta, acceder y/o utilizar de cualquier manera iCuida  también está aceptando quedar jurídicamente vinculado por nuestra política de privacidad  y nuestra <Text as="ins" color="blue.5" fontWeight="bold"><a href="/politica-cookies">política de cookies</a></Text>, por lo que le aconsejamos encarecidamente que las lea y entienda detenidamente.
                        </Text>
                    </Box>
                    <Box mb="15px">
                        <Text>
                            Si usted no acepta cualquiera de las cláusulas anteriores, le rogamos que deje de utilizar y/o acceder a iCuida.
                        </Text>
                    </Box>
                </Box>
                <Box mb="15px">
                    <Text fontWeight="bold">
                        Aviso Legal
                    </Text>
                    <Text>
                        Le proporcionamos los siguientes datos del propietario de icuida.barcelona:
                    </Text>
                    <Text fontWeight="bold">
                        Iniciativa Barcelona Open Data. 
                    </Text>
                    <Text>
                        Calle Deu i Mata nº70, escalera B, piso 8, puerta 3 Barcelona CP 08029
                    </Text>
                    <Text>
                        Correo electrónico de contacto: equipo@iniciativabarcelonaopendata.cat
                    </Text>
                </Box>
                <Text>

                </Text>
                <Box mb="15">
                    <Text fontWeight="bold">
                    Condiciones generales de uso 
                    </Text>
                </Box>
                <Box>
                    <Box mb="15px">
                        <Text>
                        ¡Bienvenido a iCuida! iCuida es una aplicación web para obtener y compartir conocimiento por y para las <Text as="span" fontWeight="bold">trabajadoras del hogar y del cuidado de personas</Text> de Barcelona.
                        </Text>
                    </Box>
                    <OrderedList>
                        <ListItem mb="15px">
                            <Text fontWeight="bold">
                            La misión de la aplicación web iCuida 
                            </Text>
                            <Box mb="15px">
                                <Text>
                                    <Text as="span" fontWeight="bold">La misión de iCuida es compartir e incrementar</Text> información de manera colaborativa <Text as="span" fontWeight="bold">por y para las trabajadoras del hogar y del cuidado de personas</Text> de Barcelona. iCuida es un espacio para hacer preguntas, encontrar respuestas, responder y comentar. Esto permite a las personas aprender unas de otras. <Text as="span" fontWeight="bold">Además en iCuida, existe</Text> un perfil de usuario/a de la entidad  Barcelona Cuida (espacio de información y orientación especializado en este sector)  dispuesto a responder las preguntas de las usuarias y usuarios.
                                </Text>
                            </Box>

                        </ListItem>
                        <ListItem mb="15px">
                            <Box mb="15px">
                                <Text fontWeight="bold">
                                    Uso de la Plataforma iCuida 
                                </Text>
                            </Box>
                            <Box mb="15px">
                                <Text fontWeight="bold">
                                    2.1. Quién puede usarla​. 
                                </Text>
                                <Text>

                                    Se prohíbe el uso de iCuida a personas menores de edad. Usted declara que tiene 18 años o más, en caso contrario, su padre o tutor legal debe dar su consentimiento a estas Condiciones de servicio y afirmar que acepta este Acuerdo en su nombre y es responsable por su uso. 
                                </Text>
                            </Box>
                            <Box mb="15px" fontWeight="bold">
                                <Text>
                            2.2. Registro​. 
                            
                                </Text>
                                <Text>
                            Cuando crea un perfil en iCuida, se le pide que proporcione cierta información personal. Usted acepta proporcionarnos información precisa, incluido su nombre real o el nombre real de la entidad a la que representa, al crear su cuenta en iCuida. 

                                </Text>
                            </Box>
                            <Box mb="15px">
                                <Text>
                                Nosotros tratamos la información que usted proporciona como parte del registro según nuestra <Text as="ins" color="blue.5" fontWeight="bold"><a href="/politica-privacidad">​Política de privacidad​.</a></Text> Usted debe procurar mantener la confidencialidad de su contraseña. 
                                </Text>
                            </Box>
                            <Box mb="15px">
                                <Text fontWeight="bold">
                                2.3 Política para enlazar a esta web.
                                </Text>
                                <Text>
                                 Para poder válidamente establecer algún tipo de enlace con destino a iCuida es necesario solicitar autorización previamente a la realización del enlace. Bajo ninguna circunstancia, iCuida será responsable de los contenidos o servicios puestos a disposición del público en la aplicación web  desde la que se realice el hiperenlace así como de las informaciones o manifestaciones incluidas en las mismas. 

                                </Text>
                            </Box>
                            <Box mb="15px">
                                    <Text fontWeight="bold">
                                2.4 Propiedad intelectual e industrial. 

                                    </Text>
                                <Text>
                                Los contenidos de esta aplicación web: texto, imágenes, marcas, logotipos, archivos de audio y video, combinaciones de colores, se encuentra protegido por las leyes españolas e internacionales sobre derecho de propiedad Intelectual. Salvo que no se indique expresamente, los derechos de propiedad intelectual sobre estos contenidos corresponden en exclusiva a iCuida. El usuario/a reconoce que el uso de los contenidos queda estrictamente limitado a finalidades personales del propio usuario y que la reproducción, distribución, comunicación o transformación no expresamente autorizada de tales obras constituye una infracción de los derechos de propiedad intelectual. El usuario se obliga a utilizar los contenidos de forma correcta y lícita y se compromete a abstenerse de suprimir, eludir o manipular el "copyright" y demás datos identificativos de los derechos de iCuida. 
                                </Text>
                            </Box>
                            <Box mb="15px">
                                <Text fontWeight="bold">
                                2.5 Política de privacidad y protección de datos. 
                                </Text>
                                <Text>
                                Para acceder a algunos de los espacios de la aplicación web será necesario facilitar determinados datos personales que serán tratados conforme lo establecido por el RGPD. Para más información revisa nuestra política de privacidad. Nuestras prácticas de privacidad están estipuladas en nuestra ​<Text as="ins" color="blue.5" fontWeight="bold"><a href="/politica-privacidad">​Política de privacidad​.</a></Text>. Su uso de iCuida está sujeto a nuestra ​​<Text as="ins" color="blue.5" fontWeight="bold"><a href="/politica-privacidad">​Política de privacidad​.</a></Text>

                                </Text>
                            </Box>
                            <Box mb="15px">
                                <Text fontWeight="bold">
                                2.6 Consejos de navegación.
                                </Text>
                                <Text>
                                 iCuida le recomienda impostar la configuración de privacidad de su navegador a un nivel medio. Este nivel permite las siguientes acciones:

                                </Text>
                            </Box>
                            <Box mb="15px">
                                <Text>
                                Bloquea cookies de terceros que no tiene una directiva de privacidad sólida
                                </Text>
                            </Box>
                            <Box mb="15px">
                                <Text>
                                Bloquea cookies de terceros que usan información personal de identificación sin consentimiento explícito
                                </Text>
                            </Box>
                            <Box mb="15px">
                                <Text>
                                Restringe cookies internas que usan información personal de intensificación sin su consentimiento explícito.
                                </Text>
                            </Box>
                            <Box mb="15px">
                                <Text fontWeight="bold">
                            2.7 Cookies. 
                                </Text>
                                <Text>
                            Esta aplicación web utiliza cookies propias y de terceros para diversas finalidades incluyendo analíticas y publicidad adaptada a los intereses del usuario. El mero uso de esta web con las cookies activadas en el navegador implica la aceptación de su tratamiento conforme a nuestra <Text as="ins" color="blue.5" fontWeight="bold"><a href="/politica-cookies">política de cookies</a></Text>. 

                                </Text>
                            </Box>
                            <Box mb="15px">
                                <Text>
                            Lea detenidamente nuestra <Text as="ins" color="blue.5" fontWeight="bold"><a href="/politica-cookies">política de cookies</a></Text> para conocer los tipos de cookies empleados, y cómo bloquearlas y borrarlas en su navegador.
                                </Text>
                            </Box>
                            <Box mb="15px">
                                <Text fontWeight="bold">
                            2.8 Legislación y Jurisdicción aplicable. 
                                </Text>
                                <Text>
                            El presente Aviso Legal así como las relaciones entre iCuida  y los usuarios, se regirán por la normativa española vigente y cualquier controversia se someterá a los Juzgados y Tribunales de BARCELONA. De surgir cualquier controversia las partes podrán someter sus conflictos a arbitraje o acudir a la jurisdicción ordinaria cumpliendo con las normas sobre jurisdicción y competencia al respecto. ICuida tiene su domicilio en BARCELONA, España.

                                </Text>
                            </Box>
                            <Box mb="15px">
                                <Text fontWeight="bold">
                            2.9 Política de uso aceptable​. 
                                </Text>
                                <Text>
                            En sus interacciones con otras personas en iCuida, usted acepta cumplir con la Política de uso aceptable​ en todo momento. 

                                </Text>
                            </Box>
                            <Box mb="15px">
                                <Text fontWeight="bold">
                                    
                            2.10 Cancelación​. 
                                </Text>
                                <Text>

                            Puede cerrar su cuenta en cualquier momento yendo a la configuración de la cuenta y desactivando su cuenta. Podemos cancelar o suspender su cuenta de iCuida si incumple cualquiera de nuestras políticas o por cualquier otro motivo. 
                                </Text>
                            </Box>
                            <Box mb="15px">
                                <Text fontWeight="bold">
                            2.11 Modificaciones en ICuida​. 
                                </Text>
                                <Text>
                            Siempre estamos tratando de mejorar su experiencia en la aplicación web iCuida. Es posible que tengamos que añadir o cambiar funciones y que lo hagamos sin previo aviso. 
                                </Text>
                            </Box>
                            <Box mb="15px">
                                <Text fontWeight="bold">
                                2.12.  Comentarios​.
                                </Text>
                                <Text>
                                 Agradecemos sus comentarios y sugerencias sobre cómo mejorar iCuida. Envíenos sus opiniones a ​Contáctanos. Al enviar comentarios, usted acepta otorgarnos el derecho, a nuestra discreción, de utilizar, divulgar y explotar esta información, total o parcialmente, libremente y sin compensación para usted. 
                                </Text>
                            </Box>

                        </ListItem>
                        <ListItem mb="15px">
                            <Box>
                                <Text fontWeight="bold">
                                    Su Contenido 
                                </Text>
                            </Box>
                            <Box mb="15px">
                                <Text fontWeight="bold">
                            3.1 Definición de Su Contenido​.
                                
                                </Text>
                                <Text>
                                iCuida le permite añadir texto, fotos, vídeos, enlaces, otros archivos e información sobre usted para compartir con otros. Todo el material que usted suba, publique o muestre a los demás a través de iCuida se denominará colectivamente “Su Contenido”. Usted reconoce y acepta que, como parte del uso de iCuida, Su Contenido puede ser visto por el público en general. 
                                </Text>
                            </Box>
                            <Box mb="15px">
                                <Text fontWeight="bold">
                            3.2. Titularidad. 
                                
                                </Text>
                                <Text>
                                Usted, o sus otorgantes, según corresponda, retienen la titularidad de los derechos de autor y otros derechos de propiedad intelectual de Su Contenido, de acuerdo con los derechos no exclusivos otorgados a continuación. 
                                </Text>
                            </Box>
                            <Box mb="15px">
                                <Text fontWeight="bold">
                            3.3. Licencia y autorización para utilizar Su Contenido. 
                                
                                </Text>
                            </Box>
                            <Box mb="15px">
                                <Text>
                            3.3.1 Al enviar, publicar o mostrar Su Contenido en iCuida, usted otorga a iCuida una licencia no exclusiva, mundial, libre de regalías, completamente pagada, transferible, sublicenciable (en múltiples niveles) para utilizar, copiar, reproducir, procesar, adaptar, modificar, crear obras derivadas, publicar, transmitir, almacenar, mostrar, distribuir, traducir, comunicar, poner a disposición del público y usar Su Contenido de cualquier otra manera en relación con el uso de la aplicación web iCuida o de la promoción, publicidad o comercialización de iCuida o de sus socios empresariales, en cualquier soporte o método de distribución (conocido actualmente o desarrollado en el futuro), incluidas formas de distribución automática, como una interfaz de programación de aplicaciones (también conocida como "API"). Usted acepta que esta licencia incluye el derecho de iCuida de poner Su Contenido a disposición de otras empresas, organizaciones, socios comerciales o individuos que colaboran con iCuida para la redifusión, transmisión, comunicación, puesta a disposición del público, distribución o publicación de Su Contenido en iCuida o a través de otros medios o métodos de distribución. Esta licencia también incluye el derecho de otros usuarios de ICuida a usar, copiar, reproducir, adaptar, modificar, crear obras derivadas, publicar, transmitir, mostrar, distribuir, traducir, comunicar y poner a disposición del público Su Contenido, conforme a nuestras Condiciones de servicio. Con excepción de lo expresamente recogido en estas Condiciones de servicio, esta licencia no le otorga el derecho a usar tecnología automatizada para copiar o publicar preguntas y respuestas, o agregar preguntas y respuestas con el fin de crear obras derivadas. 
                                
                                </Text>
                            </Box>
                            <Box mb="15px">
                                <Text>
                            3.3.2. Una vez que publica una respuesta a una pregunta, puede eliminar su respuesta en cualquier momento para que no se muestre al público en <Text as="ins" color="blue.5" fontWeight="bold"><a href="/">https://icuida.barcelona</a></Text>​. Sin embargo, es posible que no tengamos control sobre la eliminación de la respuesta visible en canales de redifusión o en otros métodos de distribución previa fuera de <Text as="ins" color="blue.5" fontWeight="bold"><a href="/">https://icuida.barcelona</a></Text>. iCuida puede eliminar la publicidad no deseada ("spam") de sus respuestas. Una vez que usted publica una pregunta, esta puede ser editada o eliminada por iCuida en cualquier momento. El derecho de iCuida de copiar, mostrar, transmitir, publicar, realizar, distribuir, almacenar, modificar y utilizar cualquier pregunta que usted publique, y de transferir a terceros esos derechos, es perpetuo e irrevocable, hasta donde la ley lo permita, excepto que se especifique lo contrario en este Acuerdo. 
                                
                                </Text>
                            </Box>
                            <Box mb="15px">
                                <Text>
                            3.3.3. Usted reconoce y acepta que iCuida puede preservar Su Contenido y también divulgarlo, así como otra información relacionada, si así lo requiere la ley o en la creencia de buena fe de que dicha preservación o divulgación es razonablemente necesaria para: (a) cumplir con un proceso legal, leyes vigentes o requerimientos gubernamentales; (b) hacer cumplir las presentes Condiciones de servicio; (c) responder a reclamaciones de que parte de Su Contenido infringe los derechos de terceros: (d) detectar, prevenir o combatir el fraude y problemas de seguridad o técnicos; o (e) proteger los derechos, propiedad o seguridad personal de iCuida, sus usuarias y usuarios, o el público. 
                                
                                </Text>
                            </Box>
                            <Box mb="15px">
                                <Text>
                            3.3.4. Usted comprende que nosotros podemos modificar, adaptar o crear obras derivadas de Su Contenido con el fin de transmitirlo, mostrarlo o distribuirlo en redes informáticas, dispositivos, proveedores de servicios y en varios medios. También podemos eliminar o 
                            negarnos a publicar Su Contenido, en su totalidad o en parte, en cualquier momento. 
                                </Text>
                            </Box>
                            <Box mb="15px">
                                <Text>
                            3.3.5. Además usted nos otorga el permiso y la autoridad para actuar como su agente no exclusivo para tomar medidas de ejecución contra cualquier uso no autorizado por parte de terceros de Su Contenido fuera de la aplicación web iCuida o de manera que infrinja nuestras Condiciones de servicio. 
                                </Text>
                            </Box>
                        </ListItem>
                    <ListItem mb="15px">
                        <Text fontWeight="bold">
                        Sus responsabilidades respecto a Su Contenido​. 
                        </Text>
                        <Text>
                            Al publicar Su Contenido en iCuida, usted declara y garantiza que: i) posee la titularidad, o ha obtenido todas las licencias o permisos necesarios para usar Su Contenido y otorgarnos los derechos de uso de Su Contenido según se expresa en el presente Acuerdo, y ii) que publicar Su Contenido no infringe la propiedad intelectual o los derechos personales de terceros ni leyes o normativas vigentes, incluida cualquier ley o normativa que requiera la divulgación de que usted ha recibido compensación por Su contenido. Usted asume la responsabilidad total de evitar el 
                            incumplimiento de los derechos de propiedad intelectual o derechos personales de otros, o el incumplimiento de leyes y normativas en relación con Su Contenido. Usted es 
                            responsable de garantizar que Su Contenido no infringe la Política de uso aceptable​, la ​Política de derechos de autor​ ​ de iCuida, otras políticas publicadas por iCuida, o cualquier ley o normativa vigente. Usted acepta costear todas las regalías, tarifas y cualquier otro importe adeudado a cualquier individuo por causa de Su Contenido. 
                        </Text>
                    </ListItem>
                    <ListItem mb="15px">
                        <Box>
                            <Text fontWeight="bold">
                                Nuestro Contenido y Materiales 
                            </Text>
                        </Box>
                        <Box mb="15px">
                            <Text fontWeight="bold">
                                 5.1. Definición de Nuestro Contenido y Materiales.​ 
                            </Text>
                            <Text>
                                Toda la propiedad intelectual contenida o relacionada con iCuida (que incluye específicamente, entre otras cosas, nuestro software, las marcas de iCuida, el logotipo de iCuida, pero excluyendo Su Contenido) es propiedad de  Iniciativa Barcelona Open Data , o sus afiliados, o contenido publicado por otros usuarios de iCuida  cuya licencia se nos otorga (colectivamente “Nuestro Contenido y Materiales”). 
                            </Text>
                        </Box>
                        <Box mb="15px">
                            <Text fontWeight="bold">
                                5.2. Datos​. 
                            </Text>
                            <Text>
                                Todos los datos que iCuida recopila (“Datos”) sobre el uso que usted u otros hacen de la aplicación web iCuida  es propiedad de Iniciativa Barcelona Open Data y afiliados. Para mayor claridad, Datos no incluye Su Contenido y está separado de Nuestro Contenido y Materiales.
                            </Text>
                        </Box>
                        <Box mb="15px">
                            <Text fontWeight="bold">
                        5.3. La Licencia que le otorgamos a usted. 

                            </Text>
                        </Box>
                        <Box mb="15px">
                            <Text>
                            1. Le otorgamos a usted una licencia personal, limitada y no exclusiva para usar y acceder a Nuestro Contenido, Materiales y Datos que ponemos a su disposición en la aplicación web iCuida en relación al uso de la misma, sujeto a los términos y condiciones de este Acuerdo. 

                            </Text>
                        </Box>
                        <Box mb="15px">
                            <Text>

                            2. iCuida le otorga una licencia, libre de regalías, revocable, personal, no asignable y no exclusiva para republicar Nuestro Contenido y Materiales en cualquier lugar del sitio web, de acuerdo con estos Términos y Condiciones y siempre que: (a) el usuario/a que creó el contenido no haya indicado explícitamente que el contenido no se puede reproducir en iCuida; (b) no modifique el contenido; (c) le atribuya el contenido a iCuida por su nombre en texto legible y con un enlace que puedan seguir tanto máquinas como personas (una etiqueta HTML {"<a>"}) que vincule a la página que muestra la fuente original del contenido en https://icuida.barcelona/​ en cada página que contenga Nuestro Contenido y Materiales; (d) cuando así se lo solicite iCuida o un usuario/a; (e) cuando así se lo solicite iCuida o un usuario/a que contribuyó al contenido, haga un esfuerzo razonable por actualizar a la última versión un determinado fragmento del contenido en iCuida; y (f) cuando así se lo solicite iCuida o un usuario/a que contribuyó al contenido, haga un esfuerzo razonable por eliminar un contenido que se haya eliminado o se haya indicado que el contenido no se puede reproducir en iCuida; (g) solo republique un pequeño porcentaje de Nuestro Contenido y Materiales; (h) no utilice ninguna herramienta automatizada para agregar o crear obras derivadas. Al ejercer estos derechos, usted no puede afirmar implícita o explícitamente cualquier conexión, patrocinio o aval de iCuida, o de cualquier usuario/a de iCuida, sin una autorización previa, separada, expresa y por escrito de nuestra parte. 
                            </Text>
                        </Box>
                        <Box mb="15px">
                            <Text fontWeight="bold">
                            3. Podemos rescindirle nuestra licencia en cualquier momento por cualquier motivo. 
                            </Text>
                            Tenemos el derecho pero no la obligación de rechazar la distribución de cualquier contenido en iCuida o de eliminar contenido. Con excepción de los derechos y licencias otorgados en estas Condiciones de servicio, nos reservamos todos los demás derechos y no concedemos otros derechos ni licencias, implícitos ni de otra naturaleza. 
                        </Box>
                        <Box mb="15px">
                            <Text fontWeight="bold">
                            4. Usos permitidos​. 
                            </Text>
                            <Text>
                                Si usted opera un motor de búsqueda, agente de búsqueda, bot, herramienta de extracción, herramienta de minería de datos, herramienta de descarga masiva, servicio wget, u otras herramientas similares de recolección o extracción de datos, puede acceder a iCuida, sujeto a las siguientes normas adicionales: i) usted debe utilizar un encabezado de agente de usuario descriptivo; ii) debe seguir robots.txt en todo momento; iii) su acceso no debe afectar negativamente ningún aspecto del funcionamiento de la aplicación web iCuida; y iv) debe dejar en claro cómo contactarlo, ya sea en su cadena de agente de usuario o en su sitio web, si tiene uno. Usted representa y garantiza que no hará uso de ninguna herramienta automatizada, como inteligencia artificial o aprendizaje automático: i) para crear obras derivadas de Nuestro Contenido y Materiales; ii) para crear servicios que compitan con iCuida; o iii) para otros fines comerciales, excepto los permitidos en estas Condiciones de servicio o por consentimiento escrito de iCuida. 
                            </Text>
                        </Box>
                        <Box mb="15px">
                            <Text fontWeight="bold">
                            5. No implica aval ni verificación​. 
                            </Text>
                            <Text>
                                Tenga en cuenta que iCuida contiene acceso a contenido, productos y servicios de terceros, y ofrece interacciones con ellos. La participación o disponibilidad en la aplicación web iCuida no implica nuestro aval o verificación. Nosotros no ofrecemos ninguna garantía ni representación en cuanto a la exactitud, integridad o conveniencia del contenido publicado en iCuida por cualquier persona. 
                            </Text>
                        </Box>
                        <Box mb="15px">
                            <Text fontWeight="bold">
                                Titularidad​. 
                            </Text>
                            <Text>
                                Usted reconoce y acepta que Nuestro Contenido y Materiales son propiedad de iCuida o de sus usuarios/as. El contenido, información y servicios puestos a disposición en iCuida están protegidos por derechos de autor, derechos de marcas registradas y otras leyes internacionales, y usted reconoce que estos derechos son válidos y aplicables. 
                            </Text>
                        </Box>
                        <Box mb="15px">
                            <Text fontWeight="bold">
                            7. Proveedor de servicio integrado​. 
                            </Text>
                            <Text>
                                Usted puede permitir que otro proveedor de servicios en línea, como un servicio de redes sociales (“Proveedor de servicio integrado”) esté directamente integrado en su cuenta en la aplicación web iCuida. Al habilitar un servicio integrado, usted nos permite transmitir al Proveedor de servicio integrado y recibir de este su información de inicio de sesión y otros datos de usuario. Para más información sobre el uso, el almacenamiento y la divulgación de iCuida de información relacionada con usted y con su uso de servicios integrados dentro de iCuida, lea nuestra <Text as="ins" color="blue.5" fontWeight="bold"><a href="/politica-privacidad">​Política de privacidad​.</a></Text>. Tenga en cuenta que el uso que usted realice de cualquier Proveedor de servicios integrados y su gestión de sus datos e información se rigen únicamente por las condiciones de uso, políticas de privacidad y otras políticas de dicho proveedor. 
                            </Text>
                        </Box>

                    </ListItem>
                    <ListItem mb="15px">
                        <Box>
                            <Text fontWeight="bold">
                            Más información sobre determinadas ofertas en la Plataforma iCuida 
                            </Text>
                        </Box>
                        <Box mb="15px">
                            <Text>
                        i. Colaboradores legales y otros profesionales​. Algunos usuarios/as que publican contenido son profesionales legales o poseen otros títulos habilitantes (colectivamente, “Colaboradores Profesionales”). El contenido publicado por Colaboradores Profesionales no debe ser considerado como un sustituto del asesoramiento profesional adecuado para su situación específica. 
                            </Text>
                        </Box>
                        <Box mb="15px">
                            <Text>
                        ii. Botones, enlaces y widgets​. Usted tiene autorización para usar los botones, enlaces y widgets de iCuida según las presentes Condiciones de servicio y siempre que: (a) su uso de tales botones, enlaces y widgets dirijan únicamente a iCuida; (b) no modifique de ninguna manera dichos botones, enlaces o widgets, o el código asociado a ellos; (c) no utilice dichos botones, enlaces o widgets de manera que implique o sugiera que iCuida  avala, respalda o recomienda el sitio web en el cual se usan esos botones, enlaces y widgets; y (d) el uso de estos botones, enlaces y widgets, y el sitio web donde se utilizan no infringen la ​Política de uso aceptable​ de Quora. 
                            </Text>
                        </Box>
                        <Box mb="15px">
                            <Text>
                        iii. Recursos web y servicios de terceros​. iCuida también puede ofrecerle la oportunidad de visitar enlaces a otros sitios web o participar con productos o servicios de terceros. Usted asume todos los riesgos derivados de su uso de dichos sitios web o recursos. 
                            </Text>
                        </Box>

                    </ListItem>
                    <ListItem mb="15px">
                        <Text fontWeight="bold">
                        Limitación de responsabilidad
                        </Text>
                        El contenido de esta aplicación web es de carácter general y tiene una finalidad y efectos exclusivamente informativos. iCuida rechaza la responsabilidad sobre cualquier información no elaborada por ella o no publicada de forma autorizada por ella bajo su nombre, al igual que la responsabilidad que se derive de la mala utilización por terceros de los contenidos, así como se reserva el derecho a actualizarlos, a eliminarlos, limitarlos o bloquear-los, de manera temporal o definitiva. Los usuarios y usuarias exoneran a iCuida de cualquier responsabilidad que pudiera derivarse de las interrupciones de disponibilidad de la aplicación web. iCuida declina cualquier responsabilidad relacionada con el acceso y utilización de los enlaces a terceras webs.
                    </ListItem>
                    <ListItem mb="15px">
                        <Box>
                            <Text fontWeight="bold">
                            Contacto
                            </Text>
                        </Box>
                        <Box>
                            <Text>
                            Puede contactarnos a través de <Text as="ins" color="blue.5" fontWeight="bold"><a href="/contacta">Contáctanos</a></Text> para realizar cualquier consulta sobre estas condiciones. 
                            </Text>
                        </Box>
                    </ListItem>
                    </OrderedList>
                </Box>

                </Box>
            </Box>
        </Box>
    )
}

export default terminosCondiciones;