import React from "react";
import {
  Box,
  Text,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import AllMetrics from "./content/AllMetrics";
import LastMonthMetrics from "./content/LastMonthMetrics";

function Metrics() {
  return (
    <Box>
      <Box m="0 0 20px 18px">
        <Text fontSize="3xl">Metricas</Text>
      </Box>
      <Box>
        <Tabs isLazy>
          <TabList m="0 0 0 18px">
            <Tab>Todas</Tab>
            <Tab>Ultimo mes</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <AllMetrics />
            </TabPanel>
            <TabPanel>
              <LastMonthMetrics />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
}

export default Metrics;
