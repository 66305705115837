import React from 'react';

import {Formik, Form as FormikForm} from 'formik';
import useClasses from "../../hooks/shared/useClasses";
import LoadingOverlay from "./LoadingOverlay";

function Form({children, className, loading = false, ...rest}) {

    const classes = useClasses(className);

    return (
        <Formik loading={loading} {...rest}>
            {props => (
                <FormikForm className={classes}>
                    {children(props)}
                    <LoadingOverlay active={loading} />
                </FormikForm>
            )}
        </Formik>
    );
}

export default Form;