import React, { useState, useEffect } from "react";
import { Link, Route, useHistory } from "react-router-dom";
import { jsx, css } from "@emotion/react";
import {
  Avatar,
  Badge,
  Box,
  Text,
  Divider,
  IconButton,
  Input,
  Flex,
  Button,
  VStack,
  StackDivider,
  MenuButton,
  MenuList,
  Menu,
  MenuItem,
  useDisclosure,
} from "@chakra-ui/react";
import tagsData from "../../../assets/tagsData";
import moment from "moment";
import "moment/locale/es";
import IconoComentar from "../../../assets/icons/IconoComentar";
import IconoRespuesta from "../../../assets/icons/IconoRespuesta";
import IconoTresPuntos from "../../../assets/icons/IconoTresPuntos";
import LikeIcon from "../../../assets/icons/LikeIcon";
import {
  useQuestionLike,
  useQuestionDislike,
  useQuestionDelete,
} from "../../../hooks/queries/preguntas-comunidad";
import { useAuthStore } from "../../../hooks/state/useAuthStore";
import { objHasUserId } from "../../../functional/general";
import Answer from "./../../../pages/article/Reply";
import VerifyDelete from "../../utils/VerifyDelete";
import { useCreateNotification } from "../../../hooks/queries/notification";
import UserAvatar from "../../UserAvatar";

export default function ({ pregunta, index, refetch, type }) {
  const history = useHistory();
  const userData = useAuthStore((state) => state.data);

  const [comentar, setComentar] = useState(false);
  const [questionLike, questionLikeQuery] = useQuestionLike();
  const [questionDislike, questionDislikeQuery] = useQuestionDislike();
  const [questionDelete, questionDeleteQuery] = useQuestionDelete();
  const [createNotification, notificationQuery] = useCreateNotification();
  const [totalLikes, setTotalLikes] = useState();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [showCard, setShowCard] = useState(true);

  function redirect(url) {
    history.push(url);
  }

  useEffect(() => {
    setTotalLikes(pregunta?.QuestionLikes?.length);
  }, [pregunta]);

  function liked(data) {
    const formData = {
      userId: userData.id,
      questionId: pregunta.id,
    };
    questionLike(formData).then((res) => {
      setTotalLikes(pregunta.QuestionLikes.length + 1);
      const likeformData = {
        recipientId: data?.userId,
        senderId: userData?.id,
        type: "questionLike",
        referenceId: data?.id,
      };
      // Add if statement if userauth Id = question id to not create notification.
      if (userData?.id !== data?.userId) {
        createNotification(likeformData);
      }
      refetch();
    });
  }

  function disliked() {
    const formData = {
      userId: userData.id,
      questionId: pregunta.id,
    };
    questionDislike(formData).then((res) => {
      setTotalLikes(pregunta.QuestionLikes.length - 1);
      refetch();
    });
  }

  function formatDate(date) {
    const databaseDate = new Date(date);

    return moment(databaseDate).format("L");
  }

  function handleDelete() {
    questionDelete({
      userId: userData.id,
      questionId: pregunta.id,
    }).then((res) => {
      onClose();
      setShowCard(false);
      refetch();
    });
  }

  return (
    <Box
      hidden={!showCard}
      key={index}
      width="100%"
      bg="#fff"
      borderRadius="3px"
      mb="10px"
    >
      <VerifyDelete borrar={handleDelete} isOpen={isOpen} onClose={onClose} />
      <Box py="10px" px="20px">
        <Box>
          {JSON.parse(pregunta.categories) &&
            JSON.parse(pregunta.categories).map((category) => {
              return (
                <Badge
                  mr="3px"
                  fontSize="10px"
                  key={category}
                  colorScheme="blue"
                >
                  {category ? category : ""}
                </Badge>
              );
            })}
        </Box>
        <Box>
          <Box my="10px" display="flex">
            <a href={"/perfil-usuario/" + pregunta.User.id}>
              <UserAvatar userData={pregunta.User}/>
            </a>
            <Box>
              <Box ml="10px">
                <Box as="span" fontSize="13px" fontWeight="bold">
                  <a href={"/perfil-usuario/" + pregunta.User.id}>
                    {pregunta.User.firstName + " " + pregunta.User.lastName}
                  </a>
                </Box>
                <Box as="span" fontSize="13px">
                  {" "}
                  -{" "}
                  {pregunta.updatedAt
                    ? formatDate(pregunta.updatedAt)
                    : formatDate(pregunta.createdAt)}
                </Box>
              </Box>
              <Box ml="10px">
                <Box as="span" fontSize="13px">
                  Tipo de usuario
                </Box>
              </Box>
            </Box>
          </Box>
          <Box>
            <Text
              cursor="pointer"
              onClick={() => redirect("/pregunta/" + pregunta.id)}
              mb="10px"
              fontSize="lg"
              fontWeight="bold"
            >
              {pregunta.title}
            </Text>
            <Box
              dangerouslySetInnerHTML={{ __html: pregunta.description }}
            ></Box>
          </Box>
          <Divider />
          <Box mt="10px">
            {pregunta.Answers.length > 0 && (
              <Box
                dangerouslySetInnerHTML={{ __html: pregunta.Answers[0].answer }}
              ></Box>
            )}
          </Box>
          <Box mt="20px">
            <Box display="flex" justifyContent="space-between">
              <Flex>
                <Flex>
                  {objHasUserId(pregunta.QuestionLikes, userData.id) ? (
                    <Box
                      cursor="pointer"
                      onClick={() => {
                        disliked();
                      }}
                    >
                      <LikeIcon />
                    </Box>
                  ) : (
                    <Box cursor="pointer" onClick={() => liked(pregunta)}>
                      <LikeIcon color="#8B8886" />
                    </Box>
                  )}
                  <Text ml="3px">{totalLikes}</Text>
                </Flex>
                <Flex
                  cursor="pointer"
                  ml="20px"
                  onClick={() => setComentar(true)}
                >
                  <IconoRespuesta fontSize="20px" color="#8B8886" />
                  <Text ml="3px">{pregunta.Answers.length}</Text>
                </Flex>
              </Flex>
              <Flex hidden={userData.id !== pregunta.userId}>
                <Box alignSelf="center">
                  <Menu>
                    <MenuButton>
                      <IconoTresPuntos color="#8B8886" />
                    </MenuButton>
                    <MenuList>
                      <MenuItem onClick={onOpen}>
                        <Text fontSize="13px" fontWeight="semibold">
                          Borrar Mensaje
                        </Text>
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Box>
              </Flex>
            </Box>
          </Box>
        </Box>
      </Box>
      {comentar && (
        <React.Fragment>
          <Divider />
          <Box
            p="10px 20px"
            onClick={() => redirect("/pregunta/" + pregunta.id)}
          >
            <Flex>
              <UserAvatar userData={userData} />
              <Input
                mx="5px"
                borderRadius="20px"
                placeholder="¿Cuál es tu respuesta?"
              />
              <Button
                css={css`
                  @media (max-width: 500px) {
                    display: none;
                  }
                `}
                borderRadius="20px"
                colorScheme="blue"
              >
                Responder
              </Button>
            </Flex>
          </Box>
          <Divider />
          {/*** Comentarios aqui ***/}
          <VStack divider={<StackDivider borderColor="gray.200" />}>
            {pregunta.Answers.map((respuesta) => (
              <Answer
                answer={respuesta}
                refetch={() => refetch()}
                questionId={pregunta.id}
              />
            ))}
          </VStack>
        </React.Fragment>
      )}
    </Box>
  );
}
