export const processData = (data) => {
  return {
    type: "PROCESS",
    payload: data,
  };
};

export const NotificatioinData = (data) => {
  return {
    type: "NOTIFICATION",
    payload: data,
  };
};

export const removeNoti = (data) => {
  return {
    type: "REMOVE",
    payload: data,
  };
};
