import {useMutation, useQuery} from 'react-query';
import {submitAnswer, getUserAnswers, answerLike, answerDislike, updateAnswer, answerDelete} from '../../api/AnswerFunctions'

export function useUserAnswers({userId} = {}, queryConfig = {}) {
    return useQuery(['GET_RESPUESTAS_USUARIO'], () => getUserAnswers(userId), queryConfig)
}

export function useCreateAnswer(queryConfig = {}){
    return useMutation(submitAnswer, queryConfig)
}

export function useUpdateAnswer(queryConfig = {}){
    return useMutation(updateAnswer, queryConfig)
}

export function useAnswerLike(queryConfig = {}) {
    return useMutation(answerLike, queryConfig)
}

export function useAnswerDislike(queryConfig = {}) {
    return useMutation(answerDislike, queryConfig)
}

export function useAnswerDelete(queryConfig = {}) {
    return useMutation(answerDelete, queryConfig)
}