import React, { Component } from "react";
import { loginFacebook } from "../api/UserFunctions";
import FacebookLogin from "react-facebook-login";
import { FaFacebookF } from "react-icons/all";
import Cookies from "js-cookie";
import { useLangStore } from "../hooks/state/useLangStore";
import lang from "../assets/lang/login";

export default function FacebookLog({setBlockedFacebook = null}) {
  const currentLang = useLangStore((state) => state.currentLang);

  function responseFacebook(response) {
    setBlockedFacebook(null);
    console.log(response);
    console.log("hello");
    if (response) {
      loginFacebook(response).then((res) => {
        Cookies.set("usertoken", res);
        window.location.replace("/");
      }).catch(err => {
        if(setBlockedFacebook) {
          if(err.response.data.errors.password.param === 'blocked') {
            setBlockedFacebook(err.response.data.errors.password);
          }
        }
      });
    }
  }

  return (
    <FacebookLogin
      appId="683802933093203"
      autoLoad={false}
      fields="name,email,picture"
      callback={responseFacebook}
      disableMobileRedirect={true}
      cssClass="facebook-button"
      icon="fa-facebook"
      textButton={lang[currentLang].facebookLogin}
    />
  );
}
