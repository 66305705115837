import React from 'react'
import {Box, Text, Tabs, TabList, Tab, TabPanels,TabPanel} from '@chakra-ui/react'
import List from './content/List'

function UsersList() {
    return(
        <Box>
            <Box mb="20px">
                <Text fontSize="3xl">
                    Usuarios
                </Text>
            </Box>
            <Box>
                <Tabs isLazy>
                    <TabList>
                        <Tab>Lista</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <List/>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
        </Box>
    )
}

export default UsersList;