import axios from "axios";
const baseUrl = "/";

export function recommendUsers(data) {
  return axios
    .put(baseUrl + "recommends/recommend", {
      userId: data.userId,
      name1: data.name1,
      phone1: data.phone1,
      email1: data.email1,
      name2: data.name2,
      phone2: data.phone2,
      email2: data.email2,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
}