import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@chakra-ui/react";

export default function FacsButton() {
  let history = useHistory();
  function handleClick() {
    history.push("/preguntas-oficiales");
  }
  return (
    <div>
      <Button
        onClick={handleClick}
        width="100%"
        borderRadius="30px"
        height="40px"
        bg="orange.1"
        color="white"
      >
        FAQS oficiales
      </Button>
    </div>
  );
}
