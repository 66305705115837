import {
  AiFillFilePdf,
  AiFillFilePpt,
  AiFillFileExcel,
  AiFillFileWord,
} from "react-icons/ai";
import { GrDocumentTxt } from "react-icons/gr";
import { FcVideoFile } from "react-icons/fc";

export default function validationFileExtension(url) {
  const file_ext = url?.substr(url?.lastIndexOf(".") + 1, url?.length);

  switch (file_ext) {
    case "docx":
      return <AiFillFileWord style={{ height: "80px", width: "80px" }} />;
    case "txt":
      return <GrDocumentTxt style={{ height: "80px", width: "80px" }} />;
    case "pdf":
      return <AiFillFilePdf style={{ height: "80px", width: "80px" }} />;
    case "xlsx":
      return <AiFillFileExcel style={{ height: "80px", width: "80px" }} />;
    case "pptx":
      return <AiFillFilePpt style={{ height: "80px", width: "80px" }} />;
    case "mp4":
      return <FcVideoFile style={{ height: "80px", width: "80px" }} />;
    default:
      return;
  }
}
