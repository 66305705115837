import React from 'react';
import {Text} from '@chakra-ui/react'
import moment from 'moment'
import 'moment/locale/es'

function DateFormatter({date, color="#000"}) {

    function formatDate(date)  {
        const databaseDate = new Date(date);

        return moment(databaseDate).format('L');
    }

    return(
    <Text as="span" color={color}>{formatDate(date)}</Text>
    )
}

export default DateFormatter;