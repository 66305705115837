import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  Select,
  Box,
  Text,
  Divider,
  UnorderedList,
  ListItem,
  Button,
  Flex,
} from "@chakra-ui/react";
import TextEditor from "../../../../components/ui/TextEditor";
import { useAuthStore } from "../../../../hooks/state/useAuthStore";
import Form from "../../../../components/ui/Form";
import {
  useCreateEvent,
  useUpdateEvent,
  useUpdateID,
} from "../../../../hooks/queries/event";
import moment from "moment";
import "moment/locale/es";

export default function CreateQuestion(props, { categories }) {
  const userData = useAuthStore().data;
  const history = useHistory();
  const updateID = props.match.params.id;
  const [DescriptionValue, setDescriptionValue] = useState();
  const [title, setTitle] = useState();
  const [categorias, setCategories] = useState();
  const [linkTag, setLinkTag] = useState();
  const [editorState, setEditorState] = useState();
  const [createEvent, createEventQuery] = useCreateEvent();
  const [updateId, updateIdQuery] = useUpdateID();
  const [updateData, setUpdateData] = useState("");
  const [updateDetails, updateQuery] = useUpdateEvent();
  const defaultValue = "test";
  function updateTags(tags) {
    setCategories(tags);
  }

  function onSubmit(formData) {
    const data = {
      id: updateID ? updateData?.id : null,
      title: formData.title,
      day: [formData.day],
      hour: formData.hour,
      place: formData.place,
      description: formData.description,
    };
    if (!updateID) {
      createEvent(data, {
        onSuccess: (res) => {
          history.push("/panel/eventos");
        },
        onError: (err) => {
          console.log(err);
        },
      });
    } else {
      updateDetails(data, {
        onSuccess: (res) => {
          if (res) {
            history.push("/panel/eventos");
          }
        },
        onError: (err) => {
          console.log(err);
        },
      });
    }
  }

  useEffect(() => {
    updateId(updateID, {
      onSuccess: (res) => {
        setUpdateData(res);
      },
      onError: (err) => {
        console.log(err);
      },
    });
  }, [updateID]);

  function formatDate(date) {
    const databaseDate = new Date(date);
    return moment(databaseDate).format("L");
  }

  function handlePrevDate() {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  }

  return (
    <Box p="50px 20px" m="auto">
      <Box p="20px" display="flex" justifyContent="center">
        <Text fontWeight="semibold" fontSize="md">
          {!updateID ? "Añadir Evento" : "Actualizar Evento"}
        </Text>
      </Box>

      <Form
        initialValues={{
          title: updateID ? updateData?.title : "",
          day: updateID ? updateData?.day : "",
          hour: updateID ? updateData?.hour : "",
          place: updateID ? updateData?.place : "",
          description: updateID ? updateData?.description : "",
        }}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ setFieldValue, values }) => (
          <Box display="flex" justifyContent="center" flexDirection="column">
            <Box alignSelf="center" width="50%" px="10px" py="5px">
              <input
                className="form-control form-control-lg "
                name="title"
                type="text"
                value={values.title}
                onChange={(e) => setFieldValue("title", e.target.value)}
                placeholder="Elige titulo del evento"
              />
            </Box>
            <Box alignSelf="center" width="30%" px="10px" py="5px">
              <input
                className="form-control form-control-lg "
                name="day"
                type="date"
                value={moment(values.day).format("yyyy-MM-DD")}
                onChange={(e) => setFieldValue("day", e.target.value)}
                min={handlePrevDate()}
                placeholder="Que dia"
              />
            </Box>
            <Box alignSelf="center" width="30%" px="10px" py="5px">
              <input
                className="form-control form-control-lg "
                type="time"
                id="appt"
                name="hour"
                value={values.hour}
                onChange={(e) => setFieldValue("hour", e.target.value)}
                placeholder="Que hora"
              />
            </Box>
            <Box alignSelf="center" width="30%" px="10px" py="5px">
              <input
                className="form-control form-control-lg "
                name="place"
                type="text"
                value={values.place}
                onChange={(e) => setFieldValue("place", e.target.value)}
                placeholder="Que lugar"
              />
            </Box>

            <Box alignSelf="center" width="50%">
              <Box display="flex" flexDirection="column">
                <TextEditor
                  setContent={(e) => setFieldValue("description", e)}
                  defaultValue={values.description}
                />
                <Button
                  mt="10px"
                  borderRadius="20px"
                  bg="blue.7"
                  color="white"
                  type="submit"
                  width="50%"
                  alignSelf="flex-end"
                >
                  {!updateID ? "Enviar" : "Update"}
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </Form>
    </Box>
  );
}
