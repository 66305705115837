const lang = {
  ca: {
    home: "Inici",
    answer: "Respondre",
    categories: "Categories",
    notifications: "Notificacions",
    ask: "Preguntar",
    searchPlaceholder: "Busca la teva pregunta",
    myProfile: "Meu perfil",
    closeSession: "Tancar sessió",
    content: "Contingut",
    users: "Usuaris",
    events: "Esdeveniments",
    resources: "Recursos",
  },
  es_ES: {
    home: "Inicio",
    answer: "Responder",
    categories: "Categorías",
    notifications: "Notificaciones",
    ask: "Preguntar",
    searchPlaceholder: "Busca tu pregunta",
    myProfile: "Mi perfil",
    closeSession: "Cerrar sesión",
    content: "Contingut",
    users: "Usuaris",
    events: "Eventos",
    resources: "Recursos",
  },
};

export default lang;
