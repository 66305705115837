import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom'
import {Box, 
        Text, 
        Divider,
        VStack,
        StackDivider,
         Flex
    } from "@chakra-ui/react";
import Loading from '../../../components/Loading'
import {useUserComments} from '../../../hooks/queries/comentario-communidad'
import DateFormatter from '../../../components/DateFormatter';
import IconoLike from '../../../assets/icons/LikeIcon'
import IconoComentar from '../../../assets/icons/IconoComentar'
import IconoTresPuntos from '../../../assets/icons/IconoTresPuntos'

function Comments({userData}) {

    const history = useHistory();
    const userComments = useUserComments({userId: userData.id})

    return(
    <Box p="20px" h="100%" bg="white"
    borderRadius="5px" 
    boxShadow="0px 3px 6px #00000029">
        {! userComments.isLoading ?
        <React.Fragment>
            <Box p="10px" >
                <Text fontWeight="bold">Tus comentarios</Text>
            </Box>
            <Divider/>
            <Box p="10px">
                {
                    userComments.length !== 0 ?
                    <>
                        <VStack
                            divider={<StackDivider borderColor="gray.000"/>}
                            spacing={4}
                            align="stretch"
                        >
                            {userComments.data.map(value => (
                                <Box p="20px">
                                    <Box mb="30px">
                                        <Box cursor="pointer" py="5px" onClick={() => history.push('/pregunta/' +value.QuestionId)}>
                                            {/*<Text fontWeight="semibold">{value.title}</Text>*/}
                                            <Box dangerouslySetInnerHTML={{__html: value.comment}}></Box>
                                        </Box>
                                        <DateFormatter date={value.createdAt} color="gray.1"/>
                                    </Box>
                                    <Flex justifyContent="space-between">
                                        <Flex>
                                            <Box mr="20px">
                                                <Flex cursor="pointer">
                                                    <IconoLike/><Text ml="5px" as="span">{value.likes}</Text>
                                                </Flex>
                                            </Box>
                                            <Flex cursor="pointer">
                                                <IconoComentar color="#8F8F8F"/>
                                            </Flex>
                                        </Flex>
                                        <Flex cursor="pointer">
                                            <Box alignSelf="center">
                                                <IconoTresPuntos color="#8F8F8F"/>
                                            </Box>
                                        </Flex>
                                    </Flex>
                                </Box>
                            ))
                            }                
                        </VStack>
                        <Divider/>
                    </>
                    :
                    <Box>
                        <Text fontSize="md">No hay comentarios para cargar.</Text>
                    </Box>
                }
            </Box>
         </React.Fragment> 
         :
         <Loading/>
         }
    </Box>
)
}

export default Comments;