import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, Flex, Avatar, Text, Divider } from "@chakra-ui/react";
import { useLangStore } from "../../../../hooks/state/useLangStore";
import lang from "../../../../assets/lang/general";
import { updateEventID } from "../../../../api/event";
import UserAvatar from "../../../../components/UserAvatar";

function EventCreateNotiCard({ data, eventId, notiType }) {
  const currentLang = useLangStore((state) => state.currentLang);
  const history = useHistory();
  const [EventData, setEventData] = useState();
  function getEventData() {
    updateEventID(eventId)
      .then((res) => {
        if (res) {
          setEventData(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (!EventData) {
      getEventData();
    }
  }, [EventData]);

  return (
    <Box>
      {notiType !== "eventLike" ? (
        data &&
        data?.SenderId?.firstName &&
        EventData?.title && (
          <Box>
            <Flex mb="10px">
              <UserAvatar userData={data?.SenderId} />
              <Box ml="10px">
                <Text> {lang[currentLang].newEvent}</Text>
                <Text color="gray.2" fontSize="sm">
                  {data?.SenderId?.type}
                </Text>
              </Box>
            </Flex>
            <Box>
              <Text
                cursor="pointer"
                onClick={(e) => history.push("/eventos")}
                fontWeight="bold"
                dangerouslySetInnerHTML={{ __html: EventData?.title }}
              ></Text>
            </Box>
            <Divider />
          </Box>
        )
      ) : (
        <Box>
          <Flex mb="10px">
            <UserAvatar userData={data?.SenderId} />
            <Box ml="10px">
              <Text>
                {data.SenderId.firstName} {lang[currentLang].likeEvent}
              </Text>
              <Text color="gray.2" fontSize="sm">
                {data?.SenderId?.type}
              </Text>
            </Box>
          </Flex>
          <Box>
            <Text
              cursor="pointer"
              onClick={(e) => history.push("/eventos")}
              fontWeight="bold"
              dangerouslySetInnerHTML={{ __html: EventData?.title }}
            ></Text>
          </Box>
          <Divider />
        </Box>
      )}
    </Box>
  );
}

export default EventCreateNotiCard;
