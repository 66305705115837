import React from "react";
import { useHistory } from "react-router-dom";
import { Flex, Box, Text, Divider } from "@chakra-ui/react";
import {
  BsJustify,
  BsFillPeopleFill,
  BsClipboardData,
  BsCalendar,
} from "react-icons/all";
import IconoPreguntar from "../assets/icons/IconoPreguntar";
import lang from "../assets/lang/navbar";
import { useLangStore } from "../hooks/state/useLangStore";
import { useAuthStore } from "../hooks/state/useAuthStore";
import MediaQuery from "react-responsive";
import { jsx, css } from "@emotion/react";

function NavbarAdmin() {
  const history = useHistory();
  const currentLang = useLangStore((state) => state.currentLang);
  const userData = useAuthStore((state) => state.data);

  return (
    <Box>
      {userData && userData.isAdmin && (
        <Box height="50px" bg="orange.4" borderRadius="25px">
          <Flex
            height="100%"
            maxWidth="1200px"
            mx="auto"
            color="black"
            px="20px"
          >
            <Flex
              width="fit-content"
              mx="auto"
              alignSelf="center"
              height="fit-content"
              justifyContent="space-between"
            >
              <Box>
                <Flex
                  cursor="pointer"
                  px="5px"
                  onClick={() => history.push("/panel-administrador/contenido")}
                >
                  <Box alignSelf="center">
                    <IconoPreguntar bg="black" />
                  </Box>
                  <Text
                    css={css`
                      @media (max-width: 400px) {
                        font-size: 13px;
                      }
                    `}
                    ml="3px"
                  >
                    {lang[currentLang].content}
                  </Text>
                </Flex>
              </Box>
              <Box>
                <Flex
                  cursor="pointer"
                  px="5px"
                  onClick={() => history.push("/panel-administrador/usuarios")}
                >
                  <Box alignSelf="center">
                    <BsFillPeopleFill bg="#ECE8E8" />
                  </Box>
                  <Text
                    css={css`
                      @media (max-width: 400px) {
                        font-size: 13px;
                      }
                    `}
                    ml="3px"
                  >
                    {lang[currentLang].users}
                  </Text>
                </Flex>
              </Box>
              <Box>
                <Flex
                  cursor="pointer"
                  px="5px"
                  onClick={() => history.push("/panel/metricas")}
                >
                  <Box alignSelf="center">
                    <BsClipboardData bg="#ECE8E8" stroke-width="0.3px" />
                  </Box>
                  <Text
                    css={css`
                      @media (max-width: 400px) {
                        font-size: 13px;
                      }
                    `}
                    ml="3px"
                  >
                    Métricas
                  </Text>
                </Flex>
              </Box>
              <Box>
                <Flex
                  cursor="pointer"
                  px="5px"
                  onClick={() => history.push("/panel/eventos")}
                >
                  <Box alignSelf="center">
                    <BsCalendar bg="#ECE8E8" stroke-width="0.3px" />
                  </Box>
                  <Text
                    css={css`
                      @media (max-width: 400px) {
                        font-size: 13px;
                      }
                    `}
                    ml="3px"
                  >
                    {lang[currentLang].events}
                  </Text>
                </Flex>
              </Box>
            </Flex>
          </Flex>
        </Box>
      )}
      {userData && userData.userCreator && (
        <Box height="50px" bg="orange.4" borderRadius="25px">
          <Flex
            height="100%"
            maxWidth="1200px"
            mx="auto"
            color="black"
            px="20px"
          >
            <Flex
              width="fit-content"
              mx="auto"
              alignSelf="center"
              height="fit-content"
              justifyContent="space-between"
            >
              <Box>
                <Flex
                  cursor="pointer"
                  px="5px"
                  onClick={() => history.push("/panel/metricas")}
                >
                  <Box alignSelf="center">
                    <BsClipboardData bg="#ECE8E8" stroke-width="0.3px" />
                  </Box>
                  <Text ml="3px">Métricas</Text>
                </Flex>
              </Box>
              <Box>
                <Flex
                  cursor="pointer"
                  px="5px"
                  onClick={() => history.push("/panel/eventos")}
                >
                  <Box alignSelf="center">
                    <BsCalendar bg="#ECE8E8" stroke-width="0.3px" />
                  </Box>
                  <Text ml="3px">{lang[currentLang].events}</Text>
                </Flex>
              </Box>
            </Flex>
          </Flex>
        </Box>
      )}
    </Box>
  );
}

export default NavbarAdmin;
