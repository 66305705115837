import React, { useEffect, useState } from "react";
import { Box, VStack, Text, Divider, Flex, Image } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { useCategoryStore } from "../../../hooks/state/useCategoryStore";
import { useLangStore } from "../../../hooks/state/useLangStore";

function CategoriesSide({ categories }) {
  const history = useHistory();
  const setCategory = useCategoryStore((state) => state.setCategory);
  const currentLang = useLangStore((state) => state.currentLang);
  const [currentCategory, setCurrentCategory] = useState({});

  function checkCurrentCategory() {
    categories?.length && categories.map((value) => {
      if (history.location.pathname.includes(value.name)) {
        setCurrentCategory(value);
      } else {
        setCurrentCategory({});
      }
    });
  }

  function selectCategory(category) {
    setCurrentCategory(category);
    setCategory(category.name);
    window.location.href = "/c/" + category.name;
  }

  useEffect(() => {
    checkCurrentCategory();
  }, []);

  return (
    <React.Fragment>
      {currentCategory.category && (
        <Box mb="20px" backgroundColor="#fff" width="100%" borderRadius="5px">
          <Box p="10px 20px">
            <Text fontSize="md" fontWeight="bold">
              {currentCategory.category}
            </Text>
          </Box>
          {currentCategory.description && (
            <React.Fragment>
              <Divider />
              <Box p="10px 20px">
                <Text fontSize="sm">
                  {currentLang === "es_ES"
                    ? currentCategory.description
                    : currentCategory.descriptionCat}
                </Text>
              </Box>
            </React.Fragment>
          )}
        </Box>
      )}
      <Box p="20px" backgroundColor="#fff" width="100%" borderRadius="5px">
        <Box p="5px 10px" borderRadius="15px">
          <Text textAlign="center" fontSize="xl" fontWeight="bold">
            {currentLang === "es_ES" ? "Categorías" : "Categories"}
          </Text>
        </Box>
        <Divider my="5px" />
        <VStack textAlign="left">
          {categories?.length &&
            categories.map((category) => (
              <Box
                w="100%"
                cursor="pointer"
                p="5px 10px"
                borderRadius="15px"
                color={category.id === currentCategory.id ? "#fff" : "#000"}
                bg={category.id === currentCategory.id ? "orange.1" : "gray.3"}
                _hover={{ bg: "orange.1", color: "white" }}
                onClick={() => {
                  selectCategory(category);
                }}
              >
                <Text fontSize="12px">
                  {currentLang === "es_ES"
                    ? category.category
                    : category.categoryCat}
                </Text>
              </Box>
            ))}
        </VStack>
      </Box>
    </React.Fragment>
  );
}

export default CategoriesSide;
