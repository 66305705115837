import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom'
import {Box, 
        Text, 
        Divider,
        VStack,
        StackDivider,
         Flex
    } from "@chakra-ui/react";
import Loading from '../../../components/Loading'
import {useUserAnswers} from '../../../hooks/queries/respuesta-comunidad'
import DateFormatter from '../../../components/DateFormatter';
import IconoLike from '../../../assets/icons/LikeIcon'
import IconoComentar from '../../../assets/icons/IconoComentar'
import IconoTresPuntos from '../../../assets/icons/IconoTresPuntos'

function Answers({userData}) {

    const history = useHistory()
    const userAnswers = useUserAnswers({userId: userData.id})

    return(
    <Box p="20px" h="100%" bg="white"
    borderRadius="5px" 
    boxShadow="0px 3px 6px #00000029">
        {! userAnswers.isLoading ?
            <React.Fragment>
                <Box p="10px" >
            <Text fontWeight="bold">Tus respuestas</Text>
        </Box>
        <Divider/>
        <Box p="10px">
            { userAnswers.lenngth !== 0? 
                <>
                    <VStack
                        divider={<StackDivider borderColor="gray.000"/>}
                        spacing={4}
                        align="stretch"
                    >
                        {userAnswers.data.map(value => (
                            <Box p="20px">
                                <Box mb="30px">
                                    <Box cursor="pointer" py="5px" onClick={() => history.push('/pregunta/' +value.Question?.id)}>
                                        <Box mb="20px">
                                            <Text fontSize="18px" fontWeight="bold">{value.Question?.title}</Text>
                                        </Box>
                                        <Box dangerouslySetInnerHTML={{__html: value.answer}}></Box>
                                    </Box>
                                    <DateFormatter date={value.createdAt} color="gray.1"/>
                                </Box>
                                <Flex justifyContent="space-between">
                                    <Flex>
                                        <Box mr="20px">
                                            <Flex cursor="pointer">
                                                <IconoLike/><Text ml="5px" as="span">{value.likes}</Text>
                                            </Flex>
                                        </Box>
                                        <Flex cursor="pointer">
                                            <IconoComentar color="#8F8F8F"/>
                                        </Flex>
                                    </Flex>
                                    <Flex cursor="pointer">
                                        <Box alignSelf="center">
                                            <IconoTresPuntos color="#8F8F8F"/>
                                        </Box>
                                    </Flex>
                                </Flex>
                            </Box>
                        ))
                        }                
                    </VStack>
                    <Divider/>
                </>
                :
                <Box>
                    <Text fontSize="md">No hay respuestas para cargar.</Text>
                </Box>
            }
        </Box>
        </React.Fragment> 
        :
        <Loading/>
        }
    </Box>
    )
}

export default Answers;