import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Avatar,
  Text,
  Button,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  useDisclosure,
} from "@chakra-ui/react";
import IconoTresPuntos from "../../assets/icons/IconoTresPuntos";
import LikeIcon from "../../assets/icons/LikeIcon";
import IconoRespuesta from "../../assets/icons/IconoRespuesta";
import moment from "moment";
import "moment/locale/es";
import { useGetUser } from "../../hooks/queries/usuarios";
import { getUser } from "../../api/UserFunctions";
import { useCommentLike } from "../../hooks/queries/comentario-communidad";
import { useCommentDislike } from "../../hooks/queries/comentario-communidad";
import { useCreateNotification } from "../../hooks/queries/notification";
import { useUpdateComment } from "../../hooks/queries/comentario-communidad";
import { useCommentDelete } from "../../hooks/queries/comentario-communidad";
import { useAuthStore } from "../../hooks/state/useAuthStore";
import { objHasUserId } from "../../functional/general";
import UpdateTextEditor from "../../components/utils/UpdateTextEditor";
import VerifyDelete from "../../components/utils/VerifyDelete";
import { commentDelete } from "../../api/CommentFunctions";
import UserAvatar from "../../components/UserAvatar";

function Comment({ comment, refetch }) {
  const userData = useAuthStore((state) => state.data);
  const [commentData, setCommentData] = useState({});
  const [selectedUser, setSelectedUser] = useState({});
  const [commentLike, commentLikeQuery] = useCommentLike();
  const [commentDislike, commentDislikeQuery] = useCommentDislike();
  const [createNotification, notificationQuery] = useCreateNotification();
  const [updateComment, setUpdatecomment] = useUpdateComment();
  const [deleteComment, setDeleteComment] = useCommentDelete();
  const [editComment, setEditComment] = useState(false);
  const [newCommentContent, setNewCommentContent] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    setCommentData(comment);
  }, [comment, commentData]);

  function getUserData() {
    if (commentData?.UserId)
      getUser(commentData?.UserId).then((res) => {
        setSelectedUser(res);
      });
  }

  function clearCommentContent() {
    setNewCommentContent("");
    setEditComment(false);
  }

  function updateCommentLike(likeArray) {
    const likeIndex = likeArray.findIndex((val) => val.userId === userData.id);

    if (likeIndex !== -1) {
      likeArray.splice(likeIndex, 1);
    } else {
      likeArray = [
        ...likeArray,
        {
          commentId: comment.id,
          userId: userData.id,
        },
      ];
    }
    return likeArray;
  }

  function handleUpdateComment() {
    if (newCommentContent) {
      updateComment({
        userId: userData?.id,
        comment: newCommentContent,
        commentId: commentData?.id,
      }).then((res) => {
        setCommentData((oldData) => {
          oldData.comment = newCommentContent;
          return oldData;
        });
        refetch();
        setEditComment(false);
      });
    }
  }

  function handleDelete() {
    deleteComment({
      userId: userData.id,
      commentId: commentData?.id,
    }).then((res) => {
      onClose();
      refetch();
    });
  }

  function formatDate(date) {
    const databaseDate = new Date(date);

    return moment(databaseDate).format("L");
  }

  function liked(data) {
    const formData = {
      userId: userData?.id,
      commentId: commentData?.id,
    };
    commentLike(formData).then((res) => {
      console.log(refetch);
      refetch();
      setCommentData((prevCommentData) => {
        prevCommentData.CommentLikes = updateCommentLike(
          prevCommentData.CommentLikes
        );
        return prevCommentData;
      });
      const likeformData = {
        recipientId: data?.userId,
        senderId: userData?.id,
        type: "commentLike",
        referenceId: data?.id,
      };
      // Add if statement if userauth Id = question id to not create notification.
      if (userData?.id !== data?.userId) {
        createNotification(likeformData);
      }
    });
  }

  function disliked() {
    const formData = {
      userId: userData.id,
      commentId: commentData?.id,
    };
    commentDislike(formData).then((res) => {
      console.log(refetch);
      refetch();
      setCommentData((prevCommentData) => {
        prevCommentData.CommentLikes = updateCommentLike(
          prevCommentData.CommentLikes
        );
        return prevCommentData;
      });
    });
  }

  useEffect(() => {
    getUserData();
  }, [comment]);

  return (
    <Box p="10px" width="100%">
      <VerifyDelete borrar={handleDelete} isOpen={isOpen} onClose={onClose} />
      <Flex>
        <Box width="100%">
          <Box my="10px" display="flex">
            <a href={"/perfil-usuario/" + commentData?.User?.id}>
              <UserAvatar userData={commentData?.User} />
            </a>
            <Box>
              <Box ml="10px">
                <Box as="span" fontSize="13px" fontWeight="bold">
                  <a href={"/perfil-usuario/" + commentData?.User?.id}>
                    {commentData?.User?.firstName +
                      " " +
                      commentData?.User?.lastName}
                  </a>
                </Box>
                <Box as="span" fontSize="13px">
                  {" "}
                  -{" "}
                  {commentData?.updatedAt
                    ? formatDate(commentData?.updatedAt)
                    : formatDate(commentData?.createdAt)}
                </Box>
              </Box>
              <Box ml="10px">
                <Box as="span" fontSize="13px">
                  Tipo de usuario
                </Box>
              </Box>
            </Box>
          </Box>
          <Box ml="10px">
            {editComment ? (
              <Box>
                <UpdateTextEditor
                  content={newCommentContent}
                  initialValue={commentData?.comment}
                  setContent={setNewCommentContent}
                  clear={clearCommentContent}
                  submit={handleUpdateComment}
                />
              </Box>
            ) : (
              <Box as="span" fontSize="13px">
                <Box
                  dangerouslySetInnerHTML={{ __html: commentData?.comment }}
                />
              </Box>
            )}
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Flex>
              <Flex>
                {objHasUserId(commentData?.CommentLikes, userData.id) ? (
                  <Box
                    cursor="pointer"
                    onClick={() => {
                      disliked(commentData);
                    }}
                  >
                    <LikeIcon />
                  </Box>
                ) : (
                  <Box cursor="pointer" onClick={() => liked(commentData)}>
                    <LikeIcon color="#8B8886" />
                  </Box>
                )}
                <Text ml="3px">
                  {commentData &&
                    commentData?.CommentLikes &&
                    commentData?.CommentLikes?.length}
                </Text>
              </Flex>
            </Flex>
            <Flex hidden={userData.id !== commentData?.userId}>
              <Box alignSelf="center">
                <Menu>
                  <MenuButton>
                    <IconoTresPuntos color="#8B8886" />
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      onClick={() => {
                        setEditComment(true);
                      }}
                    >
                      <Text fontSize="13px" fontWeight="semibold">
                        Editar Mensaje
                      </Text>
                    </MenuItem>
                    <MenuItem onClick={onOpen}>
                      <Text fontSize="13px" fontWeight="semibold">
                        Borrar Mensaje
                      </Text>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Box>
            </Flex>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
}

export default Comment;
