import axios from "axios";
const baseUrl = "/";
// process.env.NODE_ENV === "production" ? "/api/" :

export function sendContactEmail(formData) {
  return axios
    .post(baseUrl + "contact", formData)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
}
