import {useQuery, useMutation} from 'react-query';
import {getAllNotifications, createNotification, createBcnCuidaNotification} from '../../api/notification';

export function useCreateNotification(queryConfig = {}) {
    return useMutation(createNotification, queryConfig)
}

export function useCreateBcnCuidaNotification(queryConfig = {}) {
    return useMutation(createBcnCuidaNotification, queryConfig)
}

export function useGetAllNotifications({userId} = {}, queryConfig = {}) {
    return useQuery(['GET_ALL_NOTIFICATIONS'], () => getAllNotifications(userId), queryConfig)
}