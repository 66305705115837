import axios from "axios";
const baseUrl = "/";
// process.env.NODE_ENV === "production" ? "/api/" :

export function createEvent(formData) {
  return axios
    .post(baseUrl + "event", {
      title: formData.title,
      day: formData.day,
      hour: formData.hour,
      place: formData.place,
      description: formData.description,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
}

export function updateEventID(id) {
  return axios
    .put(baseUrl + `event/all/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
}

export function updateEvent(formData) {
  console.log(formData);
  return axios
    .put(baseUrl + "event/update", {
      id: formData.id,
      title: formData.title,
      day: formData.day,
      hour: formData.hour,
      place: formData.place,
      description: formData.description,
    })
    .then((res) => {
      console.log(res.data);
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
}

export function getAllEvent({page}) {
  return axios
    .put(baseUrl + "event/all", {page})
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
}

export function deleteEvent(id) {
  return axios
    .delete(baseUrl + `event/delete/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
}

export const eventLike = (formData) => {
  return axios
    .post(baseUrl + "event-likes/like", formData)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const eventDislike = (formData) => {
  return axios
    .delete(baseUrl + "event-likes/dislike", { data: formData })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export function getEvent({page}) {
  return axios
    .put(baseUrl + "event/", {page})
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
}