
import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {
    Box, Flex,
    Avatar, Text,
    Divider
} from '@chakra-ui/react'

import {question} from '../../../../api/QuestionFunctions'
import UserAvatar from "../../../../components/UserAvatar";

function QuestionAnswerCard({data,questionId}) {

    const history = useHistory()

    const [questionData, setQuestionData] = useState(null)

    function getQuestionData() {
        question(questionId)
        .then(res => {
            if (res.data) {
                setQuestionData(res.data)
            }
        })
        .catch(err => {
            console.log(err)
        })
    }

    useEffect(() =>{ 
        getQuestionData()
        
    }, [])

    return (
        <Box>
            {
                (data.SenderId?.firstName && questionData?.title) &&
                <Box>
                    <Flex mb="10px">
                        <UserAvatar userData={data.SenderId} />
                        <Box ml="10px">
                            <Text>{data.SenderId.firstName} le gusta tu pregunta:</Text>
                            <Text color="gray.2" fontSize="sm">{data.SenderId.type}</Text>
                        </Box>
                    </Flex>
                    <Text cursor="pointer" onClick={e => history.push("/pregunta/" + questionData.id)} fontWeight="bold">{questionData.title}</Text>
                    <Divider/>
                </Box>
            }
        </Box>
    )
}

export default QuestionAnswerCard;