import React, { useState, useEffect } from 'react';
import {useHistory} from 'react-router-dom'
import {Box, Checkbox, Text, useDisclosure} from '@chakra-ui/react'
import {FaTrash, FaPencilAlt} from 'react-icons/fa'
import {Table} from 'react-bootstrap'
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import {useGetAllStaticQuestions, useStaticQuestionDelete} from '../../../../../hooks/queries/preguntas-oficiales'
import {useAuthStore} from '../../../../../hooks/state/useAuthStore'
import VerifyDelete from '../../../../../components/utils/VerifyDelete'
import moment from 'moment'
import 'moment/locale/es'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

function ContentOficial() {

    const history = useHistory();
    const userData = useAuthStore(state=>state.data)
    const {isLoading, data, refetch} = useGetAllStaticQuestions();
    const [rowData, setRowData] = useState([]);
    const {isOpen, onClose, onOpen} = useDisclosure();
    const [questionDelete, questionDeleteQuery] = useStaticQuestionDelete();
    const [showCard, setShowCard] = useState(true)
    const [selectedQuestionId, setSelectedQuestionId]  = useState(null);

    useEffect(() => {
        if (!isLoading) {
            setRowData(data)
            console.log(data)
        }
    })

    function formatDate(date)  {
        const databaseDate = new Date(date);

        return moment(databaseDate).format('L');
    }

    function handleDelete(id) {
        questionDelete({
            isAdmin: userData.isAdmin,
            questionId: selectedQuestionId
        })
        .then(res => {
            onClose()
            setShowCard(false)
            refetch()
        })
    }

    return (
        <Box bg="white" overflowX="auto">
            <VerifyDelete borrar={handleDelete} isOpen={isOpen} onClose={onClose}/>
            {!isLoading &&

                <Table striped bordered hover>
                    <thead>
                        <tr>
                        <th></th>
                        <th>#</th>
                        <th>Pregunta</th>
                        <th>Usuario</th>
                        <th>Creado</th>
                        <th>Responder</th>
                        <th>Borrar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            rowData.map((value) => (
                                <tr>
                                    <td>
                                        <Box width="fit-content">
                                            <Checkbox/>
                                        </Box>
                                    </td>
                                    <td>{value.id}</td>
                                    <td><a href={"/pregunta-estatica/"+value.id}>{value.title}</a></td>
                                    <td>{value.User.firstName} {value.User.lastName}</td>
                                    <td>{formatDate(value.createdAt)}</td>
                                    <td>
                                        <Box cursor="pointer" _hover={{color: 'blue'}} width="fit-content" mx="auto" onClick={() => {history.push('/pregunta-estatica/'+value.id)}}>
                                            <FaPencilAlt/><Text></Text>
                                        </Box>
                                    </td>
                                    <td>
                                        <Box cursor="pointer" width="fit-content" mx="auto" onClick={e => {
                                            onOpen()
                                            setSelectedQuestionId(value.id)
                                            }}>
                                            <FaTrash/>
                                        </Box>
                                    </td>
                                </tr>
                            ))
                        }
                        
                    </tbody>
                </Table>
            }
        </Box>
    )
}

export default ContentOficial