import { Box, Text } from "@chakra-ui/react";
import { useLangStore } from "../hooks/state/useLangStore";
import lang from "../assets/lang/careGiverCard";
import { useAuthStore } from "../hooks/state/useAuthStore";

function TarjetaCuidadora() {
  const currentLang = useLangStore((state) => state.currentLang);
  // const userData = useAuthStore((state) => state.data);

  return (
    <Box bg="#f1fcff">
      <Box maxW="800px" mx="auto" p="20px" borderRadius="5px">
        <Box mb="15px">
          <Text fontWeight="bold" fontSize="25px">
            {lang[currentLang].titleCaregiverCardPage}
          </Text>
        </Box>
        <Box mb="15px">
          <Text fontWeight="bold"> {lang[currentLang].whatIs}</Text>
        </Box>
        <Box mb="15px">
          <Text fontSize="14px">
            {lang[currentLang].titleCaregiverCardMeaning}
          </Text>
        </Box>
        <Box mb="5px">
          <Text fontSize="14px">{lang[currentLang].caregiverCardOffers}</Text>{" "}
        </Box>
        <Box mb="15px">
          <Text fontSize="14px">● {lang[currentLang].pointOne}</Text>
          <Text fontSize="14px">● {lang[currentLang].pointTwo}</Text>
          <Text fontSize="14px">● {lang[currentLang].pointThree}</Text>
          <Text fontSize="14px">● {lang[currentLang].pointFour}</Text>
          <Text fontSize="14px">● {lang[currentLang].pointFive}</Text>
          <Text fontSize="14px">● {lang[currentLang].pointSix}</Text>
        </Box>

        <Box mb="15px">
          <Text fontWeight="bold"> {lang[currentLang].whoIsItAddressedTo}</Text>
        </Box>
        <Box mb="8px">
          <Text fontSize="14px">{lang[currentLang].addressedTo_sentence1}</Text>
        </Box>
        <Box mb="15px">
          <Text fontSize="14px">{lang[currentLang].addressedTo_sentence2}</Text>
        </Box>
        <Box mb="8px">
          <Text fontWeight="bold"> {lang[currentLang].howCanIgetTt}</Text>
        </Box>
        <Box mb="4px">
          <Text fontSize="14px">{lang[currentLang].getIt_sentence1}</Text>
        </Box>
        <Box mb="4px">
          <Text fontSize="14px">
            ● {lang[currentLang].getIt_option1}{" "}
            <a href="https://www.barcelona.cat/ciutatcuidadora/">
              www.barcelona.cat/ciutatcuidadora
            </a>
          </Text>
          <Text fontSize="14px">● {lang[currentLang].getIt_option2}</Text>
        </Box>
      </Box>
    </Box>
  );
}

export default TarjetaCuidadora;

/* {userData && (
        <Box
          bg="#fff"
          p="20px"
          borderRadius="5px"
          mt="20px"
          width="100%"
          margin="auto"
        >
          <Box mb="20px">
            <Text fontWeight="bold" fontSize="20px">
              {lang[currentLang].caregiverCardSoonData}
            </Text>
          </Box>
        </Box>
      )}

      {!userData && (
        <Box
          bg="#fff"
          p="20px"
          borderRadius="5px"
          mt="20px"
          width="60%"
          margin="50px auto"
        >
          <Box mb="20px" margin="auto">
            <Text fontWeight="bold" fontSize="20px">
              {lang[currentLang].caregiverCardSoonData}
            </Text>
          </Box>
        </Box>
      )} */
