import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  Select,
  Box,
  Text,
  Divider,
  UnorderedList,
  ListItem,
  Button,
  Flex,
} from "@chakra-ui/react";
import TextEditor from "../components/ui/TextEditor";
import { useCreateOficial } from "../hooks/queries/preguntas-oficiales";
import { useAuthStore } from "../hooks/state/useAuthStore";
import Form from "../components/ui/Form";

export default function CreateQuestion({ categories }) {
  const userData = useAuthStore().data;

  const history = useHistory();
  const [RespuestaValue, setRespuestaValue] = useState("");
  const [title, setTitle] = useState();
  const [categorias, setCategories] = useState();
  const [linkTag, setLinkTag] = useState();
  const [editorState, setEditorState] = useState();
  const [createOficial, createOficialQuery] = useCreateOficial();

  function updateTags(tags) {
    setCategories(tags);
  }

  function onSubmit(formData) {
    const questionData = {
      title: formData.title,
      categories: [formData.categories],
      userId: userData.id,
      description: RespuestaValue,
    };

    createOficial(questionData, {
      onSuccess: (res) => {
        console.log(res);
      },
      onError: (err) => {
        console.log(err);
      },
    });
  }

  useEffect(() => {
    console.log(categories);
  });

  return (
    <Box p="50px 20px">
      <Box p="20px">
        <Text fontWeight="semibold" fontSize="md">
          Añadir pregunta
        </Text>
      </Box>
      <Box mb="20px" bg="#fff" mx="auto" p="20px" borderRadius="5px">
        <Box>
          <Box>
            <UnorderedList>
              <ListItem color="blue.5">
                <Text color="blue.5">
                  Haz que tu pregunta sea concisa y relacionada
                </Text>
              </ListItem>
              <ListItem color="blue.5">
                <Text color="blue.5">Comprueba que no haya errores</Text>
              </ListItem>
            </UnorderedList>
          </Box>
        </Box>
      </Box>
      <Form
        initialValues={{
          title: "",
          categories: "",
        }}
        onSubmit={onSubmit}
      >
        {({ setFieldValue, values }) => (
          <Box>
            <Flex className="form-group">
              <Box width="100%" px="10px">
                <input
                  className="form-control form-control-lg rounded-pill"
                  name="title"
                  type="text"
                  value={values.title}
                  onChange={(e) => setFieldValue("title", e.target.value)}
                  placeholder='Empieza la pregunta con "Que", "Cómo", "Por qué".'
                />
              </Box>
              <Box width="150px" alignSelf="center">
                <Select
                  borderRadius="20px"
                  onChange={(e) => {
                    setFieldValue("categories", e.target.value);
                  }}
                  fontSize="12px"
                >
                  {categories &&
                    categories?.length &&
                    categories.map((category) => (
                      <option value={category.name}>{category.category}</option>
                    ))}
                </Select>
              </Box>
            </Flex>
            <TextEditor setContent={setRespuestaValue} />
            <Button borderRadius="20px" bg="blue.7" color="white" type="submit">
              Enviar
            </Button>
          </Box>
        )}
      </Form>
    </Box>
  );
}
