import React from 'react';

export default ({fontSize="25px", color = "#000"}) => (
<svg width={fontSize} height={fontSize} viewBox="0 0 21.735 24.358">
  <g id="icono_respuestas_gris" transform="translate(0.104 0.25)">
    <path id="Trazado_634" data-name="Trazado 634" d="M77.035,74.935l3.327,2.783-7.452,8.99-1.441-1.191L75.793,80.3l-.449-.4-4.371,5.217-1.39-1.193Z" transform="translate(-68.073 -65)" fill={color} stroke={color} stroke-width="0.2"/>
    <path id="Trazado_635" data-name="Trazado 635" d="M68.091,88.4a.459.459,0,0,0,.647.548l3.724-1.789-3.478-2.783Z" transform="translate(-68.073 -65)" fill={color} stroke={color} stroke-width="0.2"/>
    <path id="Trazado_636" data-name="Trazado 636" d="M78.973,73.593l2.382,1.988a.564.564,0,0,1,.1.8l-.695.794-3.326-2.782.643-.746a.636.636,0,0,1,.9-.05Z" transform="translate(-68.073 -65)" fill={color} stroke={color} stroke-width="0.2"/>
    <path id="Trazado_637" data-name="Trazado 637" d="M72.863,65H85.726a3.728,3.728,0,0,1,3.728,3.726v15.5a3.728,3.728,0,0,1-1.092,2.635h0a3.765,3.765,0,0,1-2.636,1.091H72.811l.2-.1h0L74.2,86.461H85.726a2.145,2.145,0,0,0,1.592-.695h0a2.186,2.186,0,0,0,.647-1.541v-15.5a2.21,2.21,0,0,0-.647-1.59,2.3,2.3,0,0,0-1.592-.645H72.863a2.214,2.214,0,0,0-1.592.645h0a2.21,2.21,0,0,0-.647,1.59V81.345l-1.489,1.789V68.726a3.56,3.56,0,0,1,1.092-2.634h0A3.563,3.563,0,0,1,72.863,65Z" transform="translate(-68.073 -65)" fill={color} stroke={color} stroke-width="0.5"/>
  </g>
</svg>

)