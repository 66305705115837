const tagsData = {
    "derechos-laborales": {
      tagName:  'Derechos laborales',
    },
    "tramites-legales": {
        tagName: 'Trámites Legales'
    },
    "seguridad-social": {
        tagName: "Seguridad Social"
    },
    "extranjeria": {
        tagName: "Extranjería"
    },
    "centros-soport": {
        tagName: "Centros de Soporte"
    },
    "cuidado-dependientes": {
        tagName: "Cuidado de Dependientes"
    },
    "cuidado-mayores": {
        tagName: "Cuidado de Mayores"
    },
    "canguros": {
        tagName: "Canguros"
    },
    "consejos-limpieza": {
        tagName: "Consejos de Limpieza"
    },
    "empleo": {
        tagName: "Empleo"
    },
    "recursos-barcelona": {
        tagName: "Recursos de Barcelona"
    },
    "otros": {
        tagName: "Otros"
    }
}



export default tagsData;