import { useQuery, useMutation } from "react-query";
import {
  getUser,
  getAllUsers,
  updateProfile,
  updatePassword,
  uploadPhoto,
  login,
  checkEmail,
  verifyCode,
  newPassword,
  userUnblock,
  userBlock,
} from "../../api/UserFunctions";
import { getSearchUser } from "../../api/UserFunctions";

export function useGetUser({ id }, queryConfig = {}) {
  return useQuery(["GET_USER"], () => getUser(id), queryConfig);
}

export function useGetAllUsers(queryConfig = {}) {
  return useQuery(["GET_ALL_USER"], () => getAllUsers(), queryConfig);
}

export function useSearchUser({ queryConfig = {} } = {}) {
  return useMutation(getSearchUser, queryConfig);
}

export function useUserBlock({ queryConfig = {} } = {}) {
  return useMutation(userBlock, queryConfig);
}

export function useUserUnblock({ queryConfig = {} } = {}) {
  return useMutation(userUnblock, queryConfig);
}

export function useLogIn({ queryConfig = {} } = {}) {
  return useMutation(login, queryConfig);
}

export function useCheckEmail({ queryConfig = {} } = {}) {
  return useMutation(checkEmail, queryConfig);
}
export function useVerifyCode({ queryConfig = {} } = {}) {
  return useMutation(verifyCode, queryConfig);
}

export function useNewPassword({ queryConfig = {} } = {}) {
  return useMutation(newPassword, queryConfig);
}

export function useUpdateProfile({ queryConfig = {} } = {}) {
  return useMutation(updateProfile, queryConfig);
}

export function useUpdatePassword({ queryConfig = {} } = {}) {
  return useMutation(updatePassword, queryConfig);
}

export function useUploadPhoto({ queryConfig = {} } = {}) {
  return useMutation(uploadPhoto, queryConfig);
}
