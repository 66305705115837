import React, { useEffect, useState } from "react";
import { Box, Text } from "@chakra-ui/react";
import { usePreguntasOficiales } from "../hooks/queries/preguntas-oficiales";
import moment from "moment";
import "moment/locale/es";
import StaticQuestionCard from "./ui/question-card/StaticQuestionCard";
import { objectHasId } from "../functional/general";
import { staticQuestionsCategory } from "../api/StaticQuestions";

export default function NewStaticQuestion({ category }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [questionsArray, setQuestionsArray] = useState([]);
  const { refetch, isLoading, data } = usePreguntasOficiales({
    page: currentPage,
    category: category,
  });

  function nextPage(currentPage) {
    if (data.pages > currentPage) {
      setCurrentPage(currentPage + 1);
    }
  }

  function addQuestion(dataList) {
    setQuestionsArray((currentData) => {
      if (currentData.length !== 0) {
        dataList.map((value) => {
          if (!objectHasId(questionsArray, value.id)) {
            currentData.push(value);
          }
        });
        return currentData;
      } else {
        return dataList;
      }
    });
  }

  function viewMore() {
    refetch().then((res) => {
      nextPage(currentPage);
      addQuestion(res.result);
    });
  }

  // useEffect(() => {
  //   if (!isLoading) {
  //     addQuestion(data.result);
  //   }
  // }, [data]);

  useEffect(() => {
    new Promise(async (resolve, reject) => {
      const response = await staticQuestionsCategory({
        page: currentPage,
        category: category,
      });
      resolve(response);
      setQuestionsArray(response.result);
    });
  }, [category]);

  return (
    <Box>
      {questionsArray.map((pregunta, index) => (
        <StaticQuestionCard pregunta={pregunta} index={index} />
      ))}
      {!isLoading && data.pages > currentPage && (
        <Text
          cursor="pointer"
          color="gray.1"
          isLoading={isLoading}
          textAlign="center"
          onClick={() => viewMore()}
        >
          Ver más...
        </Text>
      )}
    </Box>
  );
}
