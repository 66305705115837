import React, { Component } from 'react';
import {loginFacebook, loginGoogle} from '../api/UserFunctions';
import {FaGoogle} from "react-icons/all";
import GoogleLogin from 'react-google-login';
import {Box} from '@chakra-ui/react';
import LogInButton from "../components/ui/LogInButton"
import Cookies from 'js-cookie'
import {useLangStore} from '../hooks/state/useLangStore'
import lang from '../assets/lang/login'



export default function GoogleLog({setBlockedGoogle = null}) {

    const currentLang = useLangStore(state=>state.currentLang)

    const responseGoogle = (response) => {
        setBlockedGoogle(null);
        if (response) {
            loginGoogle(response.profileObj)
                .then(res => {
                    Cookies.set('usertoken', res)
                    window.location.replace("/");
                }).catch(err => {
                    if(setBlockedGoogle) {
                        if(err.response.data.errors.password.param === 'blocked') {
                            setBlockedGoogle(err.response.data.errors.password);
                        }
                    }
            })
        }
    }

    return(
        <GoogleLogin
            clientId="804545866634-jhanamqn9qg9bb4s3h3rpv0u9o2mk87k.apps.googleusercontent.com"
            render={renderProps => (
                <LogInButton bg="#F95B5B" onClick={renderProps.onClick} content={
                    <React.Fragment>
                        <FaGoogle fontSize="20px"/><Box ml="5px">{lang[currentLang].googleLogin}</Box>
                    </React.Fragment>
                } />
            )}
            buttonText="Login"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={'single_host_origin'}
        />
    )
}

