import {Box, Text, Image} from "@chakra-ui/react";
import React from "react";
import {useLangStore} from "../../hooks/state/useLangStore";
import lang from "../../assets/lang/resources";

function RecommendContent() {
  const currentLang = useLangStore((state) => state.currentLang);

  return (

    <Box maxW="800px" mx="auto" p="20px" borderRadius="5px">
      {/* 
      <Box bm='15px'>
          <Image src={lang[currentLang].landingPage.imatge} alt={lang[currentLang].landingPage.imgAlt}/>
      </Box>
      */}
      <Box mb="15px" mt="15px">
        <Text fontWeight="bold" fontSize="25px" color='tomato' align='center'>
          {lang[currentLang].landingPage.title}
        </Text>
      </Box>
      <Box mb="15px">
        <Text fontSize="16px">
          {lang[currentLang].landingPage.text1}
        </Text>{" "}
      </Box>
      <Box mb="15px">
        <Text fontSize="16px">
          {lang[currentLang].landingPage.text2}
        </Text>{" "}
      </Box>

    </Box>
  );
}

export default RecommendContent;