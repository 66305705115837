const lang = {
    "es_ES": {
       send: "Enviar una solicitud",
       howcanwehelp: "¿Con qué podemos ayudarte?",
       email: "Correo electrónico",
       about: "Asunto",
       description: "Descripción",
       needhelp: "Necesito ayuda con mi cuenta",
       question: "Tengo una consulta de seguridad",
       opinion: "Opinion general",
       deleteaccount: "Quiero eliminar mi cuenta y mis datos"
       
    },
    "ca": {
        send: "Enviar una solicitud",
        howcanwehelp: "Amb què et podem ajudar-te?",
        email: "Correu electrònic",
        about: "Assumpte",
        description: "Descripció",
        needhelp: "Necessito ajuda amb el meu compte",
        question: "Tinc consulta de seguretat",
        opinion: "Opinió general",
        deleteaccount: "Vull eliminar el meu compte i les meves dades"
    }
}

export default lang;