import React from "react";
import { useHistory } from "react-router-dom";
import { Box, Flex, Divider, Text, Image } from "@chakra-ui/react";
import { Row, Col, Container } from "react-bootstrap";
import { useLangStore } from "../../../hooks/state/useLangStore";
import lang from "../../../assets/lang/general";
function FollowUs() {
  const currentLang = useLangStore((state) => state.currentLang);

  const history = useHistory();

  return (
    <Box
      p="20px"
      mt="20px"
      backgroundColor="#fff"
      width="100%"
      borderRadius="5px"
    >
      <Box py="15px">
        <Box>
          <Text textAlign="center" fontWeight="bold" fontSize="18px">
            {lang[currentLang].projectof}
          </Text>
        </Box>
        <Box width="fit-content" mx="auto" py="20px">
          <Image src={require("../../../assets/images/logos/LOGO-IBOD.png")} />
        </Box>
      </Box>
      <Box my="10px">
        <Box mb="10px">
          <Text textAlign="center" fontSize="18px" fontWeight="bold">
            {lang[currentLang].colaborationof}
          </Text>
        </Box>
        <Row>
          <Col xs="6" width="100px" alignSelf="center" mx="auto" py="20px">
            <Box mb="10px">
              <Image
                src={require("../../../assets/images/logos/logo_ayuntamiento_barcelona.jpg")}
              />
            </Box>
          </Col>
          <Col xs="6" width="100px" alignSelf="center" mx="auto" py="20px">
            <Box mb="10px">
              <Image
                src={require("../../../assets/images/logos/Barcelona-Cuida-Horitzontal.png")}
              />
            </Box>
          </Col>
          <Col xs="6" width="100px" alignSelf="center" mx="auto" py="20px">
            <Box mb="10px">
              <Image
                src={require("../../../assets/images/logos/logo-MWCB.png")}
              />
            </Box>
          </Col>
          <Col xs="6" width="100px" alignSelf="center" mx="auto" py="20px">
            <Box mb="10px">
              <Image
                src={require("../../../assets/images/logos/logo-DFS.png")}
              />
            </Box>
          </Col>
        </Row>
      </Box>
    </Box>
  );
}

export default FollowUs;
