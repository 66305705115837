import React from 'react'
import {Box, Text, Tabs, TabList, Tab, TabPanels,TabPanel, Button} from '@chakra-ui/react'
import Content from './content/Content'
import ContentOficial from './content/ContentOficial'
import {useHistory} from 'react-router-dom'

function Contenido() {

    const history = useHistory();

    return (
        <Box>
            <Box>
                <Box mb="20px">
                    <Text fontSize="3xl">
                        Contenido
                    </Text>
                </Box>
                <Box>
                    <Tabs isLazy>
                        <TabList>
                            <Tab>Contenido</Tab>
                            <Tab>Contenido Oficial</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <Content/>
                            </TabPanel>
                            <TabPanel>
                                <Box>
                                    <Box mb="10px">
                                        <Button bg="blue.1" color="white" onClick={e => history.push('/crear-pregunta-estatica')}>Crear nuevo contenido</Button>
                                    </Box>
                                    <ContentOficial/>
                                </Box>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Box>
            </Box>
        </Box>
    )
}

export default Contenido;