import React, { Component } from 'react'
import {SendVeriMail, verifyMail} from '../api/UserFunctions'

export default class VerifyMail extends Component {

    componentDidMount() {
        const id = this.getUrl();
        verifyMail(id)
        setTimeout(() =>{
            window.location.replace("/");
        }, 2000);
    }

    getUrl() {
        // eslint-disable-next-line
        let pathname = window.location.pathname;

        let pathId = pathname.split('/').filter(val => {
            return Number.isInteger(Number(val)) && val !== "";
        });

        return Number(pathId[0]);

    }

    render() {
        return(<div className="container py-5 icuida-blue text-center">
            <div className="jumbotron">
                <h1 className="display-4">Cuenta email verificada!</h1>
            </div>
        </div>)
    }
}