import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {staticQuestionViews } from '../api/StaticQuestions';
import {useStaticQuestionLike, useStaticQuestionDislike, useStaticQuestion, useUpdateOficial} from '../hooks/queries/preguntas-oficiales'
import { submitAnswer, getAnswers, answerLike, answerDislike } from '../api/AnswerFunctions';
import {Box, Text, Badge, Flex, Menu, MenuButton, MenuList, MenuItem, useDisclosure} from '@chakra-ui/react'
import IconoTresPuntos from '../assets/icons/IconoTresPuntos'
import LikeIcon from '../assets/icons/LikeIcon'
import '../assets/css/Login.css'
import VerifyDelete from '../components/utils/VerifyDelete'
import UpdateTextEditor from '../components/utils/UpdateTextEditor'
import {questionViews} from "../api/QuestionFunctions";
import {useAuthStore} from '../hooks/state/useAuthStore'
import { objHasUserId } from '../functional/general';

export default function StaticQuestion(props) {

        const questionId = props.match.params.id;
        const userData = useAuthStore(state=>state.data)
        const {data, isLoading, refetch} = useStaticQuestion({id: Number(questionId)})
        const [questionData, setQuestionData] =  useState(null)
        const [staticQuestionLike, setStaticQuestionLike] = useStaticQuestionLike()
        const [staticQuestionDislike, setStaticQuestionDislike] = useStaticQuestionDislike()
        const [updateStaticQuestion, setUpdateStaticQuestion] = useUpdateOficial()
        const [newStaticQuestionContent, setNewStaticQuestionContent] = useState("");
        const [answers, setAnswers] = useState(null)
        const [answer, setAnswer] = useState('')
        const [staticQuestionData, setStaticQuestionData]  = useState(null)
        const [categories, setCategories] = useState(["derechos-laborales", "tramites-legales", "seguridad-social", "recursos-barcelona"])
        const [editQuestion, setEditQuestion] = useState(false)
        const [toggle, setToggle] = useState(false)
        const { isOpen, onOpen, onClose } = useDisclosure()

    useEffect(() => {
        console.log(data)
        if (!isLoading) {
            setQuestionData(data)
        }
        //dataRequest();
        //staticQuestionViews(getUrl());
    })

    function getUrl() {
        // eslint-disable-next-line
        let pathname = window. location. pathname;

        let pathId = pathname.split('/').filter(val => {
            return Number.isInteger(Number(val)) && val !== "";
        });

        return Number(pathId[0]);

    }

    function clearCommentContent() {
        setNewStaticQuestionContent("");
        setEditQuestion(false);
    }

    function handleUpdateComment() {
        if (newStaticQuestionContent) {
            updateStaticQuestion({
                userId: userData.id,
                description: newStaticQuestionContent,
                staticQuestionId: questionData.id,
            })
            .then(res => {
                refetch();
                setEditQuestion(false)
            })
        }
    }

    function liked() {
        const formData = {
            userId: userData.id,
            questionId: questionData.id
        }
        staticQuestionLike(formData)
        .then(res => refetch())
    }

    function disliked() {
        const formData = {
            userId: userData.id,
            questionId: questionData.id
        }
        staticQuestionDislike(formData)
        .then(res => refetch())
    }


    function renderQuestion(objectData) {
        return (
            <Box>
                <VerifyDelete borrar={() => {console.log('borrado')}} isOpen={isOpen} onClose={onClose}/>
                <Box className="card" bg="linear-gradient(180deg, #fff 0%, #D2EDF5FC 10%, #FCFCFC)">
                    <Box className="card-body article-card">
                        <Box>
                            {
                                JSON.parse(objectData.categories) &&
                                JSON.parse(objectData.categories).map( (category) => {
                                    return <Badge key={category}
                                    variantColor="blue"
                                    cursor="pointer"
                                    onClick={e => {/*history.push('/c/'+category)*/}}
                                    >{category ? category : ''}</Badge>
                                })
                            }

                        </Box>
                        <Box mb="20px">
                            <Text fontWeight="bold" fontSize="xl">{objectData.title}</Text>
                        </Box>

                        <Box  ml="10px">
                            { editQuestion ? 
                            <Box>
                                <UpdateTextEditor 
                                    content={newStaticQuestionContent}
                                    initialValue={objectData.description} 
                                    setContent={setNewStaticQuestionContent} 
                                    clear={clearCommentContent} 
                                    submit={handleUpdateComment}/>
                            </Box>
                            :
                            <Box as="span" fontSize="13px">
                                <Box dangerouslySetInnerHTML={{__html: objectData.description}}/>
                            </Box>

                            }
                        </Box>
                        <Box display="flex" justifyContent="space-between">
                            <Flex>
                                <Flex>
                                    { objHasUserId(objectData.StaticQuestionLikes, userData.id) ? 
                                        <Box cursor="pointer" onClick={() =>{disliked()}}><LikeIcon/></Box> 
                                        :
                                        <Box cursor="pointer" onClick={() => liked()}><LikeIcon color="#8B8886"/></Box>
                                    }
                                    <Text ml="3px">{objectData.StaticQuestionLikes.length}</Text>
                                </Flex>
                            </Flex>
                            <Flex hidden={( !userData.isAdmin)}>
                                <Box alignSelf="center">
                                    <Menu>
                                        <MenuButton>
                                            <IconoTresPuntos color="#8B8886"/>
                                        </MenuButton>
                                        <MenuList>
                                            <MenuItem onClick={() => {setEditQuestion(true)}}>
                                                <Text fontSize="13px" fontWeight="semibold">Editar Mensaje</Text>
                                            </MenuItem>
                                            <MenuItem onClick={onOpen}>
                                                <Text fontSize="13px" fontWeight="semibold">Borrar Mensaje</Text>
                                            </MenuItem>
                                        </MenuList>
                                    </Menu>
                                </Box>
                            </Flex>
                        </Box>
                        <Box className="py-3 text-utils">
                            {/*<Box>Visitas: {objectData.views}</Box>*/}
                            <Box className="question-date">
                                <a className="text-right" >{objectData.updated_at ? objectData.update_at : objectData.created_at}</a>
                            </Box>
                            <Box>{objectData.username}</Box>
                        </Box>
                    </Box>
                </Box>
            </Box>


        );
    }

    function onSubmit (e) {
        e.preventDefault();

        const answer = {
            answer: answer,
            question_id: getUrl()
        }


        submitAnswer(answer)
            .then(res => {
                console.log(res)
            })
            .catch(err => {
                console.log(err)
            })

    }

    return(
        <Box className="p-4">
            <Box>
                {
                    questionData &&
                    renderQuestion( questionData)
                }
            </Box>
        </Box>

    )
}