import React, { useEffect, useState } from "react";
import { Box, VStack, Text, Divider, Flex, Image } from "@chakra-ui/react";
import { Route, useHistory } from "react-router-dom";
import { FaLinkedinIn, FaInstagram } from "react-icons/all";
import Sponsors from "./Sponsors";
import FollowUs from "./FollowUs";
import CategoriesSide from "./CategoriesSide";
import CarouselButton from "../../../components/CarouselButton";
import RecursosButton from "../../../components/RecursosButton";
import PreguntasOficiales from "../../../components/PreguntasOficiales";
import FacsButton from "../../../components/FacsButton";

function StickySide({ categories }) {
  return (
    <Box m="0">
      <Box position="sticky" top="80px">
        <Box mb="20px">
          <CarouselButton />
        </Box>
        <Box mb="20px">
          <Route
            exact
            path={[
              "/preguntar",
              "/responder",
              "/c/:category",
              "/categorias",
              "/notificaciones",
              "/sobre-Icuida",
              "/terminos-condiciones",
              "/politica-cookies",
              "/politica-privacidad",
              "/politica-uso",
              "/ayuda",
              "/contacta",
              "/tarjeta-cuidadora",
            ]}
          >
            <FacsButton />
          </Route>
        </Box>
        <Box mb="20px"> <RecursosButton /></Box>
        <Route
          exact
          path={["/", "/responder", "/c/:category", "/notificaciones"]}
        >
          <CategoriesSide categories={categories} />
        </Route>
        <FollowUs />
        <Sponsors />
      </Box>
    </Box>
  );
}

export default StickySide;
