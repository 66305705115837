import React, {useState} from 'react';
import {Box, 
        Text, 
        Divider,
        Flex, 
        Avatar,
        Input,
        Button,
        Select,
    } from "@chakra-ui/react";
import Form from '../../../components/ui/Form'
import {Container, Row,Col} from 'react-bootstrap';
import {FaGoogle, FaFacebookF} from "react-icons/all";
import {useUpdatePassword} from '../../../hooks/queries/usuarios'
import Errors from "../../../assets/functions/errors";
import SuccessWarning from '../../../components/warnings/SuccessWarning'

function Settings({userData}) {

    const [updatePassword, updatePasswordQuery] = useUpdatePassword()
    const [success, setSuccess] = useState(null);
    const [errors, setErrors] = useState([]);

    function handleUpdatePassword(formData) {
        
        formData.userId = userData.id;

        updatePassword(formData, {
            onSuccess: (data) => {
                setSuccess(data)
                setTimeout(() => setSuccess(null), 3000)
            },
            onError: (error) => {setErrors(error)}
        })
        
    }

    return(<Box p="20px" h="100%" bg="white"
    borderRadius="5px" 
    boxShadow="0px 3px 6px #00000029">
    <Box p="10px" >
        <Text>Ajustes</Text>
    </Box>
    <Divider/>
    <Box p="10px">
    <Row>
        <Col md="4">
            <Box p="20px">
                <Text transform="capitalize" fontWeight="bold">Correo electrónico</Text>
            </Box>
        </Col>
        <Col md="6">
            <Box p="20px">
                <Text>{userData.email}</Text>
            </Box>
        </Col>
    </Row>
    <Divider/>
    <Row>
        <Col md="4">
            <Box p="20px">
                <Text transform="capitalize" fontWeight="bold">Contraseña</Text>
            </Box>
        </Col>
        <Col md="6">
            <Form initialValues={{
                    prevPassword: "",
                    newPassword: "",
                    passwordConfirmation: ""
                    }}
                    onSubmit={handleUpdatePassword}>
                    {({setFieldValue, values}) => (
                         <Box p="20px">
                         <Box my="10px">
                             <Input placeholder="contraseña antigua"
                             value={values.prevPassword}
                             type="password"
                             onChange={e => {setFieldValue("prevPassword",e.target.value)}}
                             borderRadius="50px!important"/>
                             { Errors &&
                                        Errors.showErrors(errors, 'prevPassword')}
                         </Box>
                         <Box my="10px">
                             <Input placeholder="nueva contraseña"
                             value={values.newPassword}
                             type="password"
                             onChange={e => {setFieldValue("newPassword",e.target.value)}}
                             borderRadius="50px!important"/>
                             { Errors &&
                                        Errors.showErrors(errors, 'newPassword')}
                         </Box>
                         <Box my="10px">
                             <Input placeholder="re-introduzca nueva contraseña"
                             value={values.passwordConfirmation}
                             type="password"
                             onChange={e => {setFieldValue("passwordConfirmation",e.target.value)}}
                             borderRadius="50px!important"/>
                             { Errors &&
                                        Errors.showErrors(errors, 'passwordConfirmation')}
                         </Box>
                        <SuccessWarning hidden={!success} content={success}/>
                         <Button isLoading={updatePasswordQuery.isLoading} type="submit" borderRadius="50px" color="#fff" bg="blue.1">Cambiar</Button>
                     </Box>
                    )}
                </Form>
        </Col>
    </Row>
    <Divider/>
    <Row>
        <Col md="4">
            <Box p="20px">
                <Text textTransform="capitalize" fontWeight="bold">Cuentas conectadas</Text>
            </Box>
        </Col>
        <Col md="6">
            <Box p="20px">
                <Box>
                    <Flex mb="20px">
                        <Box alignSelf="center">
                            <FaGoogle color="#F15E50"/>
                        </Box>
                        <Box ml="20px">
                            <Text fontWeight="bold">{userData.email}</Text>
                        </Box>
                    </Flex>
                    <Flex mb="20px">
                        <Box alignSelf="center">
                            <FaFacebookF color="#2676E1"/>
                        </Box>
                        <Box ml="20px">
                            <Text fontWeight="bold">{userData.email}</Text>
                        </Box>
                    </Flex>
                </Box>
            </Box>
        </Col>
    </Row>
    <Divider/>
    </Box>  
</Box>)
}

export default Settings;