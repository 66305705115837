import {extendTheme} from "@chakra-ui/react";

const customTheme = extendTheme({
    colors: {
        gray: {
            1: "#8F8F8F",
            2: "#CBCBCB",
            3: "#ECE8E8",
            4: "#F5F5F5",
        },
        blue: {
            1: "#63CFE3",
            2: "#66CCCC",
            3: "#6BDEFF",
            4: "#C5EEF9",
            5: "#2676E1",
            6: "#ECF0FE",
            7: "#4ea4dc"
        },
        orange: {
            1: "#FE6E60",
            2: "#F15E50",
            3: "#F9C6C1",
            4: "#F29999",
            5: "#FFE6E6",

        },
        yellow: {
            1: "FBE278"
        }
    }
});

export default customTheme;