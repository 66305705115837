import axios from "axios";
const baseUrl = "/";
// process.env.NODE_ENV === "production" ? "/api/" :

export function getAllMetrics() {
  return axios
    .put(baseUrl + "data-metrics/allmetrics")
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
}

export function getLastMonthMetrics() {
  return axios
    .put(baseUrl + "data-metrics/lastmonth")
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
}
