const lang = {
  es_ES: {
    landingPage: {
      imatge: "client/src/assets/images/xx.png",
      imgAlt: "Recursos útiles para tu trabajo, cuidadora",
      title: "Recursos útiles para tu trabajo, cuidadora",
      text1: "Desde iCuida ofrecemos una serie de recursos generados con Datos Abiertos, que son de utilidad para el día a día de las personas trabajadoras del hogar y los cuidados.",
      text2: "Con estos recursos, queremos ofrecer datos de valor y útiles en situaciones cuotidianas, como encontrar lavabos de uso público o lugares donde refugiarse del calor.",
      text3: "",
      text4: "",
      pointZero:{
        text: "Inscríbete y participa:",
        link: "https://forms.gle/H5NUuG7LNwB1ezkX7"
      },
      guest: {
        text1: "Para ver los recursos ",
        text2: "inicia sessión",
        text3: " si ya eres usuario de iCuida o ",
        text4: "accede al formulario de registro.",
      }
    },
    viz1: {
      title: "Lavabos públicos",
      text1: "Lista y ubicación de los lavabos públicos de la ciudad de Barcelona",
      text2: "",
      url: "https://www.datawrapper.de/_/WmkyP/",
      code: '<iframe title="Lavabos públicos de la ciudad de Barcelona" aria-label="Mapa" id="datawrapper-chart-WmkyP" src="https://datawrapper.dwcdn.net/WmkyP/3/" scrolling="no" frameborder="0" style="width: 0; min-width: 100% !important; border: none;" height="784" data-external="1"></iframe><script type="text/javascript">!function(){"use strict";window.addEventListener("message",(function(a){if(void 0!==a.data["datawrapper-height"]){var e=document.querySelectorAll("iframe");for(var t in a.data["datawrapper-height"])for(var r=0;r<e.length;r++)if(e[r].contentWindow===a.source){var i=a.data["datawrapper-height"][t]+"px";e[r].style.height=i}}}))}();</script>',
      presource: "Fuente: ",
      source: "Open Data BCN - Servicio de datos abiertos del Ayuntamiento de Barcelona",
      urlSource: "https://opendata-ajuntament.barcelona.cat/data/es/dataset/lavabos-publics",
    },
    viz2: {
      title: "Refugios climáticos",
      text1: "Red de refugios climáticos en la ciudad de Barcelona. En la visualización, cada punto es un refugio climático. El fondo está coloreado en función del NVDI (cantidad de verde existente), por lo que las zonas rojas tienen menos vegetación y se ven más afectadas por las altas temperaturas que las zonas azules. Las áreas destinadas a refugio climático son gratuitas.",
      text2: "Un refugio climático és un espacio que proporciona confort térmico a la población, al tiempo que mantienen otros usos y funcionalidades. Están especialmente dirigidos a personas vulnerables al calor y al frío (bebés, mayores de 75 años, enfermos crónicos, personas con menos recursos, etc). Los parques urbanos que forman parte de esta red tienen una presencia elevada de verde urbano (superficie > 0,5 ha e índice NVDI —que indica la calidad de la vegetación— > 0,4), son accesibles a personas con movilidad reducida y disponen de fuentes de agua y asientos.",
      url: "https://public.flourish.studio/visualisation/14307653/",
      code: "<iframe src='https://flo.uri.sh/visualisation/14307653/embed' title='Interactive or visual content' class='flourish-embed-iframe' frameborder='0' scrolling='no' style='width:100%;height:600px;' sandbox='allow-same-origin allow-forms allow-scripts allow-downloads allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation'></iframe><div style='width:100%!;margin-top:4px!important;text-align:right!important;'><a class='flourish-credit' href='https://public.flourish.studio/visualisation/14307653/?utm_source=embed&utm_campaign=visualisation/14307653' target='_top' style='text-decoration:none!important'><img alt='Made with Flourish' src='https://public.flourish.studio/resources/made_with_flourish.svg' style='width:105px!important;height:16px!important;border:none!important;margin:0!important;'> </a></div>",
      presource: "Fuente: ",
      source: "Open Data BCN - Servicio de datos abiertos del Ayuntamiento de Barcelona",
      urlSource: "https://opendata-ajuntament.barcelona.cat/data/es/dataset/xarxa-refugis-climatics",
    },
    viz3: {
      title: "Parques y jardines de la ciudad de Barcelona",
      text1: "Listado de los parques, jardines e interiores de manzana de la ciudad de Barcelona",
      text2: "",
      url: "https://public.flourish.studio/visualisation/14272643/",
      code: "<iframe src='https://flo.uri.sh/visualisation/14272643/embed' title='Interactive or visual content' class='flourish-embed-iframe' frameborder='0' scrolling='no' style='width:100%;height:600px;' sandbox='allow-same-origin allow-forms allow-scripts allow-downloads allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation'></iframe><div style='width:100%!;margin-top:4px!important;text-align:right!important;'><a class='flourish-credit' href='https://public.flourish.studio/visualisation/14272643/?utm_source=embed&utm_campaign=visualisation/14272643' target='_top' style='text-decoration:none!important'><img alt='Made with Flourish' src='https://public.flourish.studio/resources/made_with_flourish.svg' style='width:105px!important;height:16px!important;border:none!important;margin:0!important;'> </a></div>",
      presource: "Font: ",
      source: "Open Data BCN - Servicio de datos abiertos del Ayuntamiento de Barcelona",
      urlSource: "https://opendata-ajuntament.barcelona.cat/data/es/dataset/culturailleure-parcsjardins",
    },
    viz4: {
      title: "Residencias para las personas mayores de la ciudad de Barcelona",
      text1: "",
      text2: "",
      url: "https://public.flourish.studio/visualisation/14272873/",
      code: "<iframe src='https://flo.uri.sh/visualisation/14272873/embed' title='Interactive or visual content' class='flourish-embed-iframe' frameborder='0' scrolling='no' style='width:100%;height:600px;' sandbox='allow-same-origin allow-forms allow-scripts allow-downloads allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation'></iframe><div style='width:100%!;margin-top:4px!important;text-align:right!important;'><a class='flourish-credit' href='https://public.flourish.studio/visualisation/14272873/?utm_source=embed&utm_campaign=visualisation/14272873' target='_top' style='text-decoration:none!important'><img alt='Made with Flourish' src='https://public.flourish.studio/resources/made_with_flourish.svg' style='width:105px!important;height:16px!important;border:none!important;margin:0!important;'> </a></div>",
      presource: "Fuente: ",
      source: "Open Data BCN - Servicio de datos abiertos del Ayuntamiento de Barcelona",
      urlSource: "https://opendata-ajuntament.barcelona.cat/data/es/dataset/serveissocials-residenciesgentgran",
    }
  },
  ca: {
    landingPage: {
      imatge: "client/src/assets/images/xx.png",
      imgAlt: "Recursos útils per a la teva feina, cuidadora",
      title: "Recursos útils per a la teva feina, cuidadora",
      text1: "Des d'iCuida oferim una sèrie de recursos generats amb Dades Obertes, que són útils per al dia a dia de les persones treballadores de la llar i les cures.",
      text2: "Amb aquests recursos, volem oferir dades de valor i estris en situacions quotidianes, com trobar lavabos d'ús públic o llocs on refugiar-se de la calor.",
      text3: "",
      text4: "",
      pointZero:{
        text: "Inscríbete y participa:",
        link: "https://forms.gle/H5NUuG7LNwB1ezkX7"
      },
      
      guest: {
        text1: "Per a veure els recursos, ",
        text2: "inicia sessió",
        text3: " si ja ets usuari d'iCuida o ",
        text4: "accedeix al formulari de registre.",
      }
    },
    viz1: {
      title: "Lavabos públicos",
      text1: "Lista y ubicación de los lavabos públicos de la ciudad de Barcelona",
      text2: "",
      url: "https://www.datawrapper.de/_/WmkyP/",
      code: '<iframe title="Lavabos públicos de la ciudad de Barcelona" aria-label="Mapa" id="datawrapper-chart-WmkyP" src="https://datawrapper.dwcdn.net/WmkyP/3/" scrolling="no" frameborder="0" style="width: 0; min-width: 100% !important; border: none;" height="784" data-external="1"></iframe><script type="text/javascript">!function(){"use strict";window.addEventListener("message",(function(a){if(void 0!==a.data["datawrapper-height"]){var e=document.querySelectorAll("iframe");for(var t in a.data["datawrapper-height"])for(var r=0;r<e.length;r++)if(e[r].contentWindow===a.source){var i=a.data["datawrapper-height"][t]+"px";e[r].style.height=i}}}))}();</script>',
      presource: "Font: ",
      source: "Open Data BCN - Servicio de datos abiertos del Ayuntamiento de Barcelona",
      urlSource: "https://opendata-ajuntament.barcelona.cat/data/es/dataset/lavabos-publics"
    },
    viz2: {
      title: "Refugios climáticos",
      text1: "Red de refugios climáticos en la ciudad de Barcelona. En la visualización, cada punto es un refugio climático. El fondo está coloreado en función del NVDI (cantidad de verde existente), por lo que las zonas rojas tienen menos vegetación y se ven más afectadas por las altas temperaturas que las zonas azules. Las áreas destinadas a refugio climático son gratuitas.",
      text2: "Un refugio climático és un espacio que proporciona confort térmico a la población, al tiempo que mantienen otros usos y funcionalidades. Están especialmente dirigidos a personas vulnerables al calor y al frío (bebés, mayores de 75 años, enfermos crónicos, personas con menos recursos, etc). Los parques urbanos que forman parte de esta red tienen una presencia elevada de verde urbano (superficie > 0,5 ha e índice NVDI —que indica la calidad de la vegetación— > 0,4), son accesibles a personas con movilidad reducida y disponen de fuentes de agua y asientos.",
      url: "https://public.flourish.studio/visualisation/14307653/",
      code: "<iframe src='https://flo.uri.sh/visualisation/14307653/embed' title='Interactive or visual content' class='flourish-embed-iframe' frameborder='0' scrolling='no' style='width:100%;height:600px;' sandbox='allow-same-origin allow-forms allow-scripts allow-downloads allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation'></iframe><div style='width:100%!;margin-top:4px!important;text-align:right!important;'><a class='flourish-credit' href='https://public.flourish.studio/visualisation/14307653/?utm_source=embed&utm_campaign=visualisation/14307653' target='_top' style='text-decoration:none!important'><img alt='Made with Flourish' src='https://public.flourish.studio/resources/made_with_flourish.svg' style='width:105px!important;height:16px!important;border:none!important;margin:0!important;'> </a></div>",
      presource: "Font: ",
      source: "Open Data BCN - Servicio de datos abiertos del Ayuntamiento de Barcelona",
      urlSource: "https://opendata-ajuntament.barcelona.cat/data/es/dataset/xarxa-refugis-climatics",
    },
    viz3: {
      title: "Parques y jardines de la ciudad de Barcelona",
      text1: "Listado de los parques, jardines e interiores de manzana de la ciudad de Barcelona",
      text2: "",
      url: "https://public.flourish.studio/visualisation/14272643/",
      code: "<iframe src='https://flo.uri.sh/visualisation/14272643/embed' title='Interactive or visual content' class='flourish-embed-iframe' frameborder='0' scrolling='no' style='width:100%;height:600px;' sandbox='allow-same-origin allow-forms allow-scripts allow-downloads allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation'></iframe><div style='width:100%!;margin-top:4px!important;text-align:right!important;'><a class='flourish-credit' href='https://public.flourish.studio/visualisation/14272643/?utm_source=embed&utm_campaign=visualisation/14272643' target='_top' style='text-decoration:none!important'><img alt='Made with Flourish' src='https://public.flourish.studio/resources/made_with_flourish.svg' style='width:105px!important;height:16px!important;border:none!important;margin:0!important;'> </a></div>",
      presource: "Font: ",
      source: "Open Data BCN - Servicio de datos abiertos del Ayuntamiento de Barcelona",
      urlSource: "https://opendata-ajuntament.barcelona.cat/data/es/dataset/culturailleure-parcsjardins",
    },
    viz4: {
      title: "Residencias para las personas mayores de la ciudad de Barcelona",
      text1: "",
      text2: "",
      url: "https://public.flourish.studio/visualisation/14272873/",
      code: "<iframe src='https://flo.uri.sh/visualisation/14272873/embed' title='Interactive or visual content' class='flourish-embed-iframe' frameborder='0' scrolling='no' style='width:100%;height:600px;' sandbox='allow-same-origin allow-forms allow-scripts allow-downloads allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation'></iframe><div style='width:100%!;margin-top:4px!important;text-align:right!important;'><a class='flourish-credit' href='https://public.flourish.studio/visualisation/14272873/?utm_source=embed&utm_campaign=visualisation/14272873' target='_top' style='text-decoration:none!important'><img alt='Made with Flourish' src='https://public.flourish.studio/resources/made_with_flourish.svg' style='width:105px!important;height:16px!important;border:none!important;margin:0!important;'> </a></div>",
      presource: "Font: ",
      source: "Open Data BCN - Servicio de datos abiertos del Ayuntamiento de Barcelona",
      urlSource: "https://opendata-ajuntament.barcelona.cat/data/es/dataset/serveissocials-residenciesgentgran",
    }
  }
};

export default lang;
