import React, {useState, useEffect} from 'react'
import {Box, Flex, Text, Button,useDisclosure,
    Modal,ModalOverlay,ModalContent,ModalHeader,
    ModalCloseButton,ModalBody,ModalFooter} from '@chakra-ui/react'

function CookieSticker() {

    const [translateY, setTranslateY] = useState("56px")
    const [hidden, setHidden] = useState(false)
    const { isOpen, onOpen, onClose } = useDisclosure()

    useEffect(() => {
        setTranslateY("0")
    })

    return( 
    <Flex
        hidden={hidden}
        position="fixed"
        top="0!important" 
        left="0!important"
        width="100%!important"
        height="100%!important" 
        overflow="hidden"
        zIndex="9999999999!important"
        alignItems="flex-end!important"
        justifyContent="flex-end!important"
        pointerEvents="none!important">            
        <Box m="10px" 
            borderRadius="5px"  
            width="400px" 
            bg="#fff"
            zIndex="1"
            pointerEvents="auto"
            transform={`translateY(${translateY})!important`}
            transition="transform .4s ease, -webkit-transform .4s ease!important">
            <Box p="10px 20px" boxShadow="0 8px 48px rgba(0,0,0,.15)!important" >
                <Text fontSize="sm" my="10px" fontWeight="semibold">Atención</Text>
                <Text fontSize="sm">Nosotros y socios seleccionados utilizamos cookies o tecnologías similares como se especifica en la política de cookies.</Text>
                <Box my="10px">
                    <Flex mb="5px">
                        <Box pr="3px" width="100%">
                            <Button fontSize="sm" width="100%" borderRadius="20px" color="#000"bg="gray.3" onClick={e => setHidden(true)}>Rechazar</Button>
                        </Box>
                        <Box pl="3px" width="100%">
                            <Button fontSize="sm" width="100%" borderRadius="20px" color="#fff"bg="green.300" onClick={e => setHidden(true)}>Aceptar</Button>
                        </Box>
                    </Flex>
                    <Button fontSize="sm" width="100%" borderRadius="20px" color="#fff"bg="blue.5" onClick={onOpen}>Personalización</Button>
                </Box>
            </Box>
        </Box>
        <Modal isOpen={isOpen} size="lg" onClose={onClose}>
                <ModalOverlay>
                    <ModalContent>
                    <ModalHeader>iCuida</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Box py="10px">
                            <Text fontWeight="semibold" mb="1rem">        
                                Centro de preferencias de privacidad
                            </Text>
                            <Text mb="5px" fontSize="13px">
                            Cuando visita cualquier sitio web, puede almacenar o recuperar información en su navegador, principalmente en forma de cookies. Esta información puede ser sobre usted, sus preferencias o su dispositivo, y se utiliza principalmente para hacer que el sitio funcione como espera. Por lo general, la información no lo identifica directamente, pero puede brindarle una experiencia web más personalizada. Debido a que respetamos su derecho a la privacidad, puede optar por no permitir algunos tipos de cookies. Haga clic en los títulos de las diferentes categorías para obtener más información y cambiar nuestra configuración predeterminada. Sin embargo, bloquear algunos tipos de cookies puede afectar su experiencia en el sitio y los servicios que podemos ofrecer.
                            </Text>
                            <a href=""><Text fontSize="13px" color="blue" _hover={{color: "blue", textDecoration: 'underline'}}>Más información</Text></a>

                        </Box>
                        <Box py="10px">
                            <Text fontWeight="semibold" mb="1rem">                        
                                Cookies estrictamente necesarias <Text as="span" color="blue" fontSize="12px" fontWeight="bold">Siempre activas</Text>
                            </Text>
                            <Text fontSize="13px">
                            Estas cookies son necesarias para que el sitio web funcione y no se pueden desactivar en nuestros sistemas. Por lo general, solo se establecen en respuesta a acciones realizadas por usted que equivalen a una solicitud de servicios, como establecer sus preferencias de privacidad, iniciar sesión o completar formularios. Puede configurar su navegador para bloquear o alertar sobre estas cookies, pero algunas partes del sitio no funcionarán. Estas cookies no almacenan ninguna información de identificación personal.
                            </Text>
                        </Box>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={e => {
                            onClose()
                            setHidden(true)
                            }}>
                        Confirmar
                        </Button>
                    </ModalFooter>
                    </ModalContent>
                </ModalOverlay>
            </Modal>
    </Flex>
    )
}

export default CookieSticker;