import {Box, Text, Image} from "@chakra-ui/react";
import React from "react";
import {useLangStore} from "../../hooks/state/useLangStore";
import lang from "../../assets/lang/esdeveniments";

function RecommendContent() {
  const currentLang = useLangStore((state) => state.currentLang);

  return (

    <Box maxW="800px" mx="auto" p="20px" borderRadius="5px">
      <Box bm='15px'>
          <Image src={lang[currentLang].landingPage.imatge} alt={lang[currentLang].landingPage.imgAlt}/>
      </Box>
      <Box mb="15px" mt="15px">
        <Text fontWeight="bold" fontSize="25px" color='tomato'>
          {lang[currentLang].landingPage.title}
        </Text>
      </Box>
      <Box mb="15px">
        <Text fontSize="16px">
          {lang[currentLang].landingPage.text1}
          <a href={lang[currentLang].landingPage.text1l1.enllas} target='_blank'>{lang[currentLang].landingPage.text1l1.text}</a>
          {lang[currentLang].landingPage.text1p1}
        </Text>{" "}
      </Box>
      <Box mb="15px">
        <Text fontSize="16px">
          {lang[currentLang].landingPage.text2}
          <strong>{lang[currentLang].landingPage.text2b1}</strong>
          {lang[currentLang].landingPage.text2p1}
          <strong>{lang[currentLang].landingPage.text2b2}</strong>
          {lang[currentLang].landingPage.text2p2}
        </Text>{" "}
      </Box>
      <Box mb="15px">
        <Text fontSize="16px">
          {lang[currentLang].landingPage.text3}{" "}
          <strong>{lang[currentLang].landingPage.text3b1}</strong>
          {lang[currentLang].landingPage.text3p1}
        </Text>
      </Box>
      <Box mb="15px">
        <Text fontSize="16px">
          {lang[currentLang].landingPage.text4}
          <strong>{lang[currentLang].landingPage.text4b1}</strong>
          {lang[currentLang].landingPage.text4p1}
          <strong>{lang[currentLang].landingPage.text4b2}</strong>
          {lang[currentLang].landingPage.text4p2}
        </Text>
      </Box>
      <Box mb="15px">
        <Text fontSize="16px">
          {lang[currentLang].landingPage.text5}
          <strong>{lang[currentLang].landingPage.text5b1}</strong>
          {lang[currentLang].landingPage.text5p1}
          <strong>{lang[currentLang].landingPage.text5b2}</strong>
          {lang[currentLang].landingPage.text5p2}
        </Text>
      </Box>

      <Box mb="15px">
        <Text fontSize="16px">
          {lang[currentLang].landingPage.pointZero.text}{" "}
          <a href={lang[currentLang].landingPage.pointZero.link} target='_blank'>{lang[currentLang].landingPage.pointZero.link}</a>
        </Text>
      </Box>

      <Box mb="15px">
        <Text fontSize="16px">
          {lang[currentLang].landingPage.text96}
          <strong>{lang[currentLang].landingPage.text96b1}</strong>
          {lang[currentLang].landingPage.text96p1}
          <strong>{lang[currentLang].landingPage.text96b2}</strong>
          {lang[currentLang].landingPage.text96p2}
        </Text>
      </Box>
      <Box mb="15px">
        <Text fontSize="16px">
          {lang[currentLang].landingPage.text97}
          <a href={lang[currentLang].landingPage.text97l1.enllas} target='_blank'>{lang[currentLang].landingPage.text97l1.text}</a>
          {lang[currentLang].landingPage.text97p1}
          <a href={lang[currentLang].landingPage.text97l2.enllas} target='_blank'>{lang[currentLang].landingPage.text97l2.text}</a>
          {lang[currentLang].landingPage.text97p2}
        </Text>
      </Box>
      {/* 
      */}
 
      
      <Box mb="15px">
        <Text fontSize="22px">
          {lang[currentLang].landingPage.prePoints}
        </Text>{" "}
      </Box>
      <Box mb="15px">
        <Text fontSize="16px">
          <strong>{lang[currentLang].landingPage.pointOne.title}</strong>{" "}
          {lang[currentLang].landingPage.pointOne.text}{" "}
        </Text>
        <Text fontSize="16px">
          <strong>{lang[currentLang].landingPage.pointTwo.title}</strong>{" "}
          {lang[currentLang].landingPage.pointTwo.text}{" "}
        </Text>
        <Text fontSize="16px">
          <strong>{lang[currentLang].landingPage.pointThree.title}</strong>{" "}
          <a href={lang[currentLang].landingPage.pointThree.link} target='_blank'>{lang[currentLang].landingPage.pointThree.text}</a>{" "}
        </Text>
      </Box>
    </Box>
  );
}

export default RecommendContent;