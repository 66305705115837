import React from 'react';
import {Box, 
    Text,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
} from '@chakra-ui/react';
import {MinusIcon,
    AddIcon
} from '@chakra-ui/icons';
import FollowUs from '../content/sticky-side/FollowUs';
import CarouselButton from '../../components/CarouselButton';
import {useLangStore} from '../../hooks/state/useLangStore'


function Ayuda() {

    const currentLang = useLangStore(state=>state.currentLang)
    
    return (
    <Box p="20px">
        {currentLang === "es_ES" && 
        <Box maxW="800px"  mx="auto">
            <Box bg="white" p="20px" borderRadius="5px" mb="20px">
                <Box>
                    <Box mb="15px">
                        <Text fontWeight="bold">
                            ¿Para quién?
                        </Text>
                    </Box>
                    <Box mb="15px">
                        <Text>
                            iCuida está hecho para las trabajadoras del hogar y del cuidado de personas de Barcelona.
                        </Text>
                    </Box>
                    <Box mb="15px">
                        <Text fontWeight="bold">
                            ¿Para qué? 
                        </Text>
                    </Box>
                    <Box mb="15px">
                        <Text>
                        Para consultar, preguntar y responder información de interés para las trabajadoras del hogar y cuidadoras de Barcelona. Para interactuar y conectar con otras personas, que quieren compartir conocimiento de manera colaborativa y aprender unas de otras. Pero también, para encontrar respuestas de Barcelona Cuida / Ayuntamiento de Barcelona quien colabora con nuestra aplicación web para responder las preguntas de la Comunidad.
                        </Text>
                    </Box>
                    <Box mb="15px">
                        <Text fontWeight="bold">
                            ¿Cómo usarla?
                        </Text>
                    </Box>
                    <Box mb="15px">
                        <Text>
                            Consulta información, realiza preguntas, responde, comenta, conecta y da likes a las respuestas que consideres útiles.
                        </Text>
                    </Box>
                </Box>
                <CarouselButton/>

                <Box p="20px">
                    <Accordion>
                        <AccordionItem>
                            {({ isExpanded }) => (
                            <>
                                <AccordionButton>
                                <Box flex="1" textAlign="left">
                                    <Text fontWeight="semibold">Consulta</Text>
                                </Box>
                                {isExpanded ? (
                                    <MinusIcon fontSize="12px" />
                                ) : (
                                    <AddIcon fontSize="12px" />
                                )}
                                </AccordionButton>
                                <AccordionPanel pb={4}>
                                    <Text>
                                        Consulta información por categorías, accediendo a: <Text as="strong">Faqs Oficiales</Text>, aquí encontrarás preguntas y respuestas validadas por el Ayuntamiento <Text as="strong">Comunidad</Text>,  donde encontrarás preguntas y respuestas aportadas por usuarias, usuarios y/o <Text as="ins" fontWeight="bold"><a href="https://www.barcelona.cat/ciutatcuidadora/es/espacio-barcelona-cuida/que-es">Barcelona Cuida</a></Text> el perfil oficial de iCuida.  También puedes, utilizar el buscador introduciendo la palabra clave relacionada con tu duda.
                                    </Text>
                                </AccordionPanel>
                            </>
                            )}
                        </AccordionItem>
                        <AccordionItem>
                            {({ isExpanded }) => (
                            <>
                                <AccordionButton>
                                <Box flex="1" textAlign="left">
                                    <Text fontWeight="semibold">Pregunta</Text>
                                </Box>
                                {isExpanded ? (
                                    <MinusIcon fontSize="12px" />
                                ) : (
                                    <AddIcon fontSize="12px" />
                                )}
                                </AccordionButton>
                                <AccordionPanel pb={4}>
                                    <Text>
                                        Haz preguntas sobre temas relevantes como: trámites básicos al llegar a la ciudad de Barcelona, empleo, asesoría, grupos de apoyo, derechos laborales, formación, cuidado propio y de terceros, ayudas y recursos de la ciudad, entre otros.
                                    </Text>
                                </AccordionPanel>
                            </>
                            )}
                        </AccordionItem>
                        <AccordionItem>
                            {({ isExpanded }) => (
                            <>
                                <AccordionButton>
                                <Box flex="1" textAlign="left">
                                    <Text fontWeight="semibold">Responde</Text>
                                </Box>
                                {isExpanded ? (
                                    <MinusIcon fontSize="12px" />
                                ) : (
                                    <AddIcon fontSize="12px" />
                                )}
                                </AccordionButton>
                                <AccordionPanel pb={4}>
                                <Text>
                                    Responde preguntas de otras usuarias y usuarios. Las respuestas de iCuida proceden de <Text as="strong">usuarias y/o usuarios como tú</Text>, o del <Text>equipo técnico de</Text> <Text as="ins" fontWeight="bold"><a href="https://www.barcelona.cat/ciutatcuidadora/es/espacio-barcelona-cuida/que-es">Barcelona Cuida</a></Text>, quien posee un perfil de usuario oficial dentro de la aplicación, dispuesto a responder todas las dudas de sus usuarias / usuarios.
                                </Text>
                                </AccordionPanel>
                            </>
                            )}
                        </AccordionItem>
                        <AccordionItem>
                            {({ isExpanded }) => (
                            <>
                                <AccordionButton>
                                <Box flex="1" textAlign="left">
                                    <Text fontWeight="semibold">Conecta con otras/os</Text>
                                </Box>
                                {isExpanded ? (
                                    <MinusIcon fontSize="12px" />
                                ) : (
                                    <AddIcon fontSize="12px" />
                                )}
                                </AccordionButton>
                                <AccordionPanel pb={4}>
                                    <Text>
                                    Ya no estás sola, puedes enviar mensajes directos (DM) a las usuarias y usuarios de iCuida e iniciar conversaciones. iCuida junta a personas para que colaboren entre sí, un espacio donde las trabajadoras del hogar, cuidadoras, personas /familias contratadoras, profesionales del sector y equipo técnico de Barcelona Cuida, pueden conectar e interactuar.
                                    </Text>
                                </AccordionPanel>
                            </>
                            )}
                        </AccordionItem>
                        <AccordionItem>
                            {({ isExpanded }) => (
                            <>
                                <AccordionButton>
                                <Box flex="1" textAlign="left">
                                    <Text fontWeight="semibold">Da likes</Text>
                                </Box>
                                {isExpanded ? (
                                    <MinusIcon fontSize="12px" />
                                ) : (
                                    <AddIcon fontSize="12px" />
                                )}
                                </AccordionButton>
                                <AccordionPanel pb={4}>
                                    <Text>
                                    Clica like a respuestas que creas que son útiles. Cuando veas buenas respuestas en iCuida, dá like, tus likes ayudarán a la gente a ver buenas respuestas y mejorarán iCuida.
                                    </Text>
                                </AccordionPanel>
                            </>
                            )}
                        </AccordionItem>
                        <AccordionItem>
                            {({ isExpanded }) => (
                            <>
                                <AccordionButton>
                                <Box flex="1" textAlign="left">
                                    <Text fontWeight="semibold">Empodérate</Text>
                                </Box>
                                {isExpanded ? (
                                    <MinusIcon fontSize="12px" />
                                ) : (
                                    <AddIcon fontSize="12px" />
                                )}
                                </AccordionButton>
                                <AccordionPanel pb={4}>
                                    <Text>
                                    Queremos que iCuida sea el lugar donde las trabajadoras y cuidadoras expresen su opinión y desarrollen debates. Queremos que la respuesta de iCuida, sea la respuesta para empoderar y aprender de manera colectiva.
                                    </Text>
                                </AccordionPanel>
                            </>
                            )}
                        </AccordionItem>
                    </Accordion>
                </Box>
                <Box my="15px">
                    <Box mb="15px">
                        <Text textAlign="center" fontWeight="bold" fontSize="18px">
                            Definiciones 
                        </Text>
                    </Box>
                    <Box mb="15px">
                        <Text>
                            <Text as="span" fontWeight="semibold">FAQS oficiales:</Text> son las preguntas y respuestas obtenidas a partir de documentos oficiales del Ayuntamiento de Barcelona.
                        </Text>
                    </Box>
                    <Box mb="15px">
                        <Text>
                        <Text as="span" fontWeight="semibold">Preguntas de la Comunidad:</Text> son aquellas preguntas y respuestas formuladas por las propias usuarias y usuarios de iCuida.
                        </Text>
                    </Box>
                    <Box mb="15px">
                        <Text>
                        <Text as="span" fontWeight="semibold">Respuestas de Barcelona Cuida:</Text> son respuestas formuladas por el equipo técnico del espacio  <Text as="ins" fontWeight="bold"><a href="https://www.barcelona.cat/ciutatcuidadora/es/espacio-barcelona-cuida/que-es">Barcelona Cuida</a></Text> (Ayuntamiento de Barcelona), formado por personas expertas con conocimientos actualizados y contrastados del sector.
                        </Text>
                    </Box>
                    <Box mb="15px">
                        <Text>
                        <Text as="span" fontWeight="semibold">Barcelona Cuida:</Text> es el perfil oficial de iCuida, su objetivo es responder las preguntas de las comunidad en un lapso de 48 horas.
                        </Text>
                    </Box>
                </Box>
            </Box>
        </Box>}
        {currentLang === "ca" && 
        <Box maxW="800px"  mx="auto">
            <Box bg="white" p="20px" borderRadius="5px" mb="20px">
                <Box>
                    <Box mb="15px">
                        <Text fontWeight="bold">
                            Per a qui?
                        </Text>
                    </Box>
                    <Box mb="15px">
                        <Text>
                            iCuida està fet per a les treballadores de la llar i de la cura de persones de Barcelona.
                        </Text>
                    </Box>
                    <Box mb="15px">
                        <Text fontWeight="bold">
                            Per què? 
                        </Text>
                    </Box>
                    <Box mb="15px">
                        <Text>
                        Per consultar, preguntar i respondre informació d'interès per a les treballadores de la llar i cuidadores de Barcelona. Per interactuar i connectar amb altres persones, que volen compartir coneixement de manera col·laborativa i aprendre unes de les altres. Però també, per trobar respostes de Barcelona Cuida / Ajuntament de Barcelona qui col·labora amb la nostra aplicació web per a respondre les preguntes de la Comunitat.
                        </Text>
                    </Box>
                    <Box mb="15px">
                        <Text fontWeight="bold">
                        Com fer-la servir?
                        </Text>
                    </Box>
                    <Box mb="15px">
                        <Text>
                        Consulta informació, realitza preguntes, respon, comenta, connecta i dóna likes a les respostes que consideris útils.
                        </Text>
                    </Box>
                </Box>
                <CarouselButton/>

                <Box p="20px">
                    <Accordion>
                        <AccordionItem>
                            {({ isExpanded }) => (
                            <>
                                <AccordionButton>
                                <Box flex="1" textAlign="left">
                                    <Text fontWeight="semibold">Consulta</Text>
                                </Box>
                                {isExpanded ? (
                                    <MinusIcon fontSize="12px" />
                                ) : (
                                    <AddIcon fontSize="12px" />
                                )}
                                </AccordionButton>
                                <AccordionPanel pb={4}>
                                    <Text>
                                    Consulta informació per categories, accedint a: <Text as="strong">Faqs Oficials</Text>, aquí trobaràs preguntes i respostes validades per l'Ajuntament de Barcelona; i a:<Text as="strong">Comunitat</Text>  on trobaràs preguntes i respostes aportades per usuàries, usuaris i / o <Text as="ins" fontWeight="bold"><a href="https://www.barcelona.cat/ciutatcuidadora/es/espacio-barcelona-cuida/que-es">Barcelona Cuida</a></Text> el perfil oficial de iCuida. També pots, utilitzar el cercador introduint la paraula clau relacionada amb el teu dubte.
                                    </Text>
                                </AccordionPanel>
                            </>
                            )}
                        </AccordionItem>
                        <AccordionItem>
                            {({ isExpanded }) => (
                            <>
                                <AccordionButton>
                                <Box flex="1" textAlign="left">
                                    <Text fontWeight="semibold">Pregunta</Text>
                                </Box>
                                {isExpanded ? (
                                    <MinusIcon fontSize="12px" />
                                ) : (
                                    <AddIcon fontSize="12px" />
                                )}
                                </AccordionButton>
                                <AccordionPanel pb={4}>
                                    <Text>
                                    Fes preguntes sobre temes rellevants com: tràmits bàsics a l'arribar a la ciutat de Barcelona, ​​ocupació, assessoria, grups de suport, drets laborals, formació, cura propi i de tercers, ajudes i recursos de la ciutat, entre d'altres.
                                    </Text>
                                </AccordionPanel>
                            </>
                            )}
                        </AccordionItem>
                        <AccordionItem>
                            {({ isExpanded }) => (
                            <>
                                <AccordionButton>
                                <Box flex="1" textAlign="left">
                                    <Text fontWeight="semibold">Responde</Text>
                                </Box>
                                {isExpanded ? (
                                    <MinusIcon fontSize="12px" />
                                ) : (
                                    <AddIcon fontSize="12px" />
                                )}
                                </AccordionButton>
                                <AccordionPanel pb={4}>
                                <Text>
                                Respon preguntes d'altres usuàries i usuaris. Les respostes de iCuida procedeixen <Text as="strong">d'usuàries i / o usuaris com tu</Text>, o de <Text> l'equip tècnic de</Text> <Text as="ins" fontWeight="bold"><a href="https://www.barcelona.cat/ciutatcuidadora/es/espacio-barcelona-cuida/que-es">Barcelona Cuida</a></Text>, qui posseeix un perfil d'usuari oficial dins de l'aplicació, disposat a respondre tots els dubtes dels seus usuàries / usuaris.
                                </Text>
                                </AccordionPanel>
                            </>
                            )}
                        </AccordionItem>
                        <AccordionItem>
                            {({ isExpanded }) => (
                            <>
                                <AccordionButton>
                                <Box flex="1" textAlign="left">
                                    <Text fontWeight="semibold">Connecta amb altres / us</Text>
                                </Box>
                                {isExpanded ? (
                                    <MinusIcon fontSize="12px" />
                                ) : (
                                    <AddIcon fontSize="12px" />
                                )}
                                </AccordionButton>
                                <AccordionPanel pb={4}>
                                    <Text>
                                    Ja no estàs sola, pots enviar missatges directes (DM) a les usuàries i usuaris de iCuida i iniciar converses. iCuida junta a persones perquè col·laborin entre si, un espai on les treballadores de la llar, cuidadores, persones / famílies contractadores, professionals de sector i equip tècnic de Barcelona Cuida, poden connectar i interactuar.
                                    </Text>
                                </AccordionPanel>
                            </>
                            )}
                        </AccordionItem>
                        <AccordionItem>
                            {({ isExpanded }) => (
                            <>
                                <AccordionButton>
                                <Box flex="1" textAlign="left">
                                    <Text fontWeight="semibold">Apoderat </Text>
                                </Box>
                                {isExpanded ? (
                                    <MinusIcon fontSize="12px" />
                                ) : (
                                    <AddIcon fontSize="12px" />
                                )}
                                </AccordionButton>
                                <AccordionPanel pb={4}>
                                    <Text>
                                    Volem que iCuida sigui el lloc on les treballadores i cuidadores expressin la seva opinió i desenvolupin debats. Volem que la resposta de iCuida, sigui la resposta per apoderar i aprendre de manera col·lectiva.
                                    </Text>
                                </AccordionPanel>
                            </>
                            )}
                        </AccordionItem>
                        <AccordionItem>
                            {({ isExpanded }) => (
                            <>
                                <AccordionButton>
                                <Box flex="1" textAlign="left">
                                    <Text fontWeight="semibold">Dóna likes</Text>
                                </Box>
                                {isExpanded ? (
                                    <MinusIcon fontSize="12px" />
                                ) : (
                                    <AddIcon fontSize="12px" />
                                )}
                                </AccordionButton>
                                <AccordionPanel pb={4}>
                                    <Text>
                                    Clica like a respostes que creguis que són útils. Quan vegis bones respostes en iCuida, dóna like, els teus likes ajudaran a la gent a veure bones respostes i milloraran iCuida.
                                    </Text>
                                </AccordionPanel>
                            </>
                            )}
                        </AccordionItem>
                    </Accordion>
                </Box>
                <Box my="15px">
                    <Box mb="15px">
                        <Text textAlign="center" fontWeight="bold" fontSize="18px">
                            Definiciones 
                        </Text>
                    </Box>
                    <Box mb="15px">
                        <Text>
                            <Text as="span" fontWeight="semibold">FAQS oficials:</Text> són les preguntes i respostes obtingudes a partir de documents oficials de l'Ajuntament de Barcelona.
                        </Text>
                    </Box>
                    <Box mb="15px">
                        <Text>
                        <Text as="span" fontWeight="semibold">Preguntes de la Comunitat:</Text> són aquelles preguntes i respostes formulades per les pròpies usuàries i usuaris de iCuida.
                        </Text>
                    </Box>
                    <Box mb="15px">
                        <Text>
                        <Text as="span" fontWeight="semibold">Respostes de Barcelona Cuida:</Text> són respostes formulades per l'equip tècnic de l'espai <Text as="ins" fontWeight="bold"><a href="https://www.barcelona.cat/ciutatcuidadora/es/espacio-barcelona-cuida/que-es">Barcelona Cuida</a></Text> (Ajuntament de Barcelona), format per persones expertes amb coneixements actualitzats i contrastats de el sector.
                        </Text>
                    </Box>
                    <Box mb="15px">
                        <Text>
                        <Text as="span" fontWeight="semibold">Barcelona Cuida:</Text> és el perfil oficial de iCuida, el seu objectiu és respondre les preguntes de les comunitat en un lapse de 48 hores.
                        </Text>
                    </Box>
                </Box>
            </Box>
        </Box>}
    </Box>
    )
}

export default Ayuda;