import Reeact from "react";
import { Button } from "@chakra-ui/react";
import { jsx, css } from "@emotion/react";

export default function LogInButton(props) {
  return (
    <Button
      bg={props.bg}
      height="50px"
      mb="10px"
      type={props.type}
      color="#fff"
      border={props.border ? props.border : "none"}
      onClick={props.onClick}
      width="100%"
      fontWeight="thin"
      borderRadius="50px"
    >
      {props.content}
    </Button>
  );
}
