import React from 'react'
import {Box,
        Text,
        Button, 
        Modal, 
        ModalOverlay, 
        ModalContent,
        ModalHeader, 
        ModalCloseButton, 
        ModalFooter,
        ModalBody, 
        Lorem, 
        useDisclosure} from '@chakra-ui/react'
        import Carousel from '../components/storyboard/index'


function CarouselButton() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const finalRef = React.useRef()
    
    return (
        <>
        <Button onClick={onOpen} width="100%" borderRadius="30px" height="40px" bg="orange.1" color="white">
            Tutorial
        </Button>
        <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay p="0">
                <ModalContent>
                    <ModalBody>
                        <Carousel/>
                    </ModalBody>
                    <Text mb="10px" fontWeight="semibold" onClick={() => onClose()} textAlign="center">Cerrar</Text>
                </ModalContent>
            </ModalOverlay>
        </Modal>
        </>
    )
}

export default CarouselButton