import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom'
import {
    Box,
    Text,
    Button
} from '@chakra-ui/react'
import {useQuestionsToAnswer} from '../../hooks/queries/preguntas-comunidad'
import Loading from '../../components/Loading'
import Card from './Card';
import {objectHasId} from '../../functional/general'

function Responder() {

    const [currentPage, setCurrentPage ] = useState(1)
    const [questionsArray, setQuestionsArray] = useState([])
    const {data, refetch, isLoading} = useQuestionsToAnswer({page: currentPage})

    function nextPage(currentPage) {
        if (data.pages > currentPage){
            setCurrentPage(currentPage+1)
        }
    }

    function addQuestion(dataList) {
        setQuestionsArray(currentData => {
            if (currentData.length !== 0) {
                dataList.map(value => {
                    if (!objectHasId(questionsArray,value.id)) {
                        currentData.push(value)
                    } 
                })
                return currentData;
            } else {
                return dataList;
            }
            
        })
    }

    function viewMore() {
        refetch().then(res => {
            nextPage(currentPage)
            addQuestion(res.result)
        });
    }

    useEffect(() => {
       if (!isLoading) {
           addQuestion(data.result)
        }
    }, [data])

    return(
       <Box>
            {
           !isLoading &&
           questionsArray.map((pregunta, index) => (
               <Card key={pregunta.id} pregunta={pregunta} index={index}/>
           ))
            }
            {
            !isLoading &&
            <Text cursor="pointer" hidden={!isLoading && questionsArray.length >= data.count} textAlign="center"  onClick={() => viewMore()}>Ver más...</Text>
            
            }
       </Box>
    )
}

export default Responder;