import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Box, Text, Avatar, Flex, Divider} from '@chakra-ui/react'
import {getAnswer} from '../../api/AnswerFunctions'
import UserAvatar from "../UserAvatar";

function AnswerCommentCard({data, answerId}) {

    const history = useHistory()

    const [answerData, setAnswerData] = useState(null)

    function getAnswerData() {
        getAnswer(answerId)
        .then(res => {
            if (res) {
                setAnswerData(res)
            }
            console.log(res)
        })
        .catch(err => {
            console.log(err)
        })
    }

    useEffect(() =>{ 
        console.log(data)
        getAnswerData()
    }, [])

    return (
        <Box>
            {
                ( answerData && data) &&
                <Box>
                    <Flex mb="10px">
                        <UserAvatar userData={data.SenderId} />
                        <Box ml="10px">
                            <Text>{data.SenderId.firstName} ha comentado tu respuesta:</Text>
                            <Text color="gray.2" fontSize="sm">{data.SenderId.type}</Text>
                        </Box>
                    </Flex>
                    <Text cursor="pointer" onClick={e => history.push("/pregunta/" + answerData?.questionId)} fontWeight="bold" dangerouslySetInnerHTML={{__html: answerData?.answer}}></Text>
                    <Divider/>
                </Box>
            }
        </Box>
    )
}

export default AnswerCommentCard;