import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  ModalBody,
  Lorem,
  Button,
  StatHelpText,
  Input,
} from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import StoryBoard from "../components/storyboard";
import CarouselButton from "../components/CarouselButton";
import Chat from "../components/live-chat";
// import io from 'socket.io-client'

function Test({ items }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [text, setText] = useState("");
  return (
    <Box>
      <Text>Test</Text>
      <Chat />
    </Box>
  );
}

export default Test;
