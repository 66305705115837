import React, { Component } from 'react'
import  { BrowserRouter as Router, Route, Link, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie'

const PublicRoute = ({ component: Component, ...rest}) => (
    <Route {...rest} render={(props) => (
        !Cookies.get('usertoken')
        ? <Component {...props} />
        : <Redirect to='/' />
    )}/>
)

export default PublicRoute;