import React, { useState } from "react";
import { jsx, css } from "@emotion/react";
import { Link, withRouter, Redirect, useHistory } from "react-router-dom";
import {
  MdMessage,
  BsPencilSquare,
  FaBell,
  FaHome,
  GoNoNewline,
} from "react-icons/all";
import IconoRespuesta from "../assets/icons/IconoRespuesta";
import IconoHome from "../assets/icons/IconoHome";
import IconoCategoria from "../assets/icons/IconoCategoria";
import IconoPreguntar from "../assets/icons/IconoPreguntar";
import IconoNotificacion from "../assets/icons/IconoNotificacion";
import { TiMessages } from "react-icons/ti";
import {
  Button,
  Box,
  Text,
  Input,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuGroup,
  MenuDivider,
  MenuOptionGroup,
  MenuItemOption,
  Avatar,
  Select,
  Image,
} from "@chakra-ui/react";
import Form from "../components/ui/Form";
import { useAuthStore } from "../hooks/state/useAuthStore";
import "../assets/css/Navbar.css";
import Cookies from "js-cookie";
// import io from "socket.io-client";
// import socket from "../api/socket";
import { useLangStore } from "../hooks/state/useLangStore";
import lang from "../assets/lang/navbar";
import { useEffect } from "react";
import IconoEventos from "../assets/icons/IconoEventos";
import IconoRecursos from "../assets/icons/IconoRecursos";
import { useSelector, useDispatch } from "react-redux";
import socket, { receiveNOTI, RunSocket } from "../api/socket";
import useSound from "use-sound";
import Notification from "../assets/sound/friend-request-14878.mp3";
import { getUser, userLogout } from "../api/UserFunctions";
import { deleteChatNoti, getChatCount } from "../api/notification";
import UserAvatar from "./UserAvatar";

function Navbar({ cssStyle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [play] = useSound(Notification);
  const [search, setSearch] = useState();
  const [redirect, setRedirect] = useState();
  const [pathname, setPathname] = useState("");
  const userData = useAuthStore((state) => state.data);
  const logOut = useAuthStore((state) => state.logOut);
  const refresh = useAuthStore((state) => state.refresh);
  const currentLang = useLangStore((state) => state.currentLang);
  const setSpanish = useLangStore((state) => state.setEsp);
  const setCatalan = useLangStore((state) => state.setCat);
  const [unreadChat, setUnreadChat] = useState(false);
  const [unreadNoti, setUnreadNoti] = useState(false);
  const receivenoti = useSelector((state) => state?.notification?.items);
  const [message, setMessage] = useState([]);
  const [countmess, setCountmess] = useState([]);
  const chatIcon = history.location.pathname === "/chat";
  const url =
    process.env.NODE_ENV === "production" ? "/api/socket.io/" : "/socket.io/";
  // const socket = io({
  //   path: url,
  //   withCredentials: true,
  // });

  function onSearch(e) {
    setSearch(e.target.value);
  }

  function changeLang(lang) {
    if (lang === "es_ES") {
      setSpanish();
    } else if (lang === "ca") {
      setCatalan();
    }
  }

  function submitSearch(formData) {
    if (formData.query.length > 0) {
      Cookies.set("search", formData.query);
      if (window.location.pathname === "/search") {
        history.go(0);
      } else {
        history.push("/search");
      }
    }
  }

  function loginRegLink() {
    return (
      <ul className="navbar-nav">
        <li className="nav-item">
          <Link to="/login" className="nav-link">
            Iniciar Sesion
          </Link>
        </li>
      </ul>
    );
  }

  useEffect(() => {
    if (userData?.id)
      getUser(userData?.id).then((data) => {
        if (data) {
          refresh(data);
        }
      });
  }, []);

  function userLink() {
    return (
      <Menu>
        <MenuButton
          outline="none!important"
          shadow="none!important"
          colorScheme="transparent"
        >
          <UserAvatar userData={userData} />
        </MenuButton>
        <MenuList>
          <MenuGroup title="Perfil">
            <MenuItem onClick={() => history.push("/perfil")}>
              {lang[currentLang].myProfile}
            </MenuItem>
          </MenuGroup>
          <MenuDivider />
          <MenuGroup>
            <MenuItem
              onClick={() => {
                logOut();
                userLogout(userData?.id);
              }}
            >
              {lang[currentLang].closeSession}{" "}
            </MenuItem>
          </MenuGroup>
        </MenuList>
      </Menu>
    );
  }

  // useEffect(() => {
  //   socket.on("chat-noti", (msg) => {
  //     if (userData.id === msg.to) {
  //       if (!history.location.pathname.includes("chat")) {
  //         setUnreadChat(true);
  //       }
  //     }
  //   });
  //   socket.on("new-noti", (msg) => {
  //     if (userData.id === msg.to) {
  //       if (!history.location.pathname.includes("notification")) {
  //         setUnreadNoti(true);
  //       }
  //     }
  //   });

  //   return () => {
  //     socket.off("chat-noti");
  //     socket.off("new-noti");
  //   };
  // }, []);

  useEffect(() => {
    RunSocket();
  }, [socket]);

  useEffect(() => {
    if (Object.keys(receivenoti).length !== 0) {
      play();
      setCountmess((countmess) => [...countmess, ...receivenoti]);
      // setMessage((message) => [...message, ...receivenoti])
    }
  }, [receivenoti]);

  useEffect(() => {
    if (Object.keys(receivenoti).length === 0) {
      receiveNOTI(dispatch);
    }
  }, []);

  useEffect(() => {
    setMessage("");
  }, [chatIcon]);

  useEffect(() => {
    getChatCount(userData?.id).then((data) => {
      if (data) {
        setCountmess(data?.result);
      }
    });
  }, [receivenoti, chatIcon]);

  //  function handleChatDelete() {
  //   deleteChatNoti(userData?.id).then((data) =>
  //   {
  //     if (data) {
  //       setCountmess(0)
  //     }
  //    }
  //   )
  //  }

  return (
    <Box css={cssStyle} position="sticky" top="0" zIndex="999">
      <Box bg="orange.1">
        <Box
          maxWidth="1200px"
          m="auto"
          className="navbar navbar-expand-sm navbar-light"
        >
          <div>
            <Link to="/" className="navbar-brand p-0">
              <img
                width="98.4px"
                height="24.5px"
                src={require("../assets/images/logos/icuida-letras.png")}
                onClick={() => history.push("/")}
                alt="Icuida-logo"
              />
            </Link>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="#navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav">
              <li
                css={css`
                  align-self: center;
                `}
                className="nav-item"
              >
                <Box
                  borderBottom={
                    history.location.pathname === "/" ? "2px solid white" : ""
                  }
                >
                  <Link className="nav-link" to="/">
                    <Box display="flex" px="2px">
                      <IconoHome color="#fff" />
                      <Text color="#fff" ml="3px">
                        {lang[currentLang].home}
                      </Text>
                    </Box>
                  </Link>
                </Box>
              </li>
              {/* 
              <li
                css={css`
                  align-self: center;
                `}
                className="nav-item"
              >
                <Box
                  borderBottom={
                    history.location.pathname === "/responder"
                      ? "2px solid white"
                      : ""
                  }
                >
                  <Link className="nav-link" to="/responder">
                    <Box display="flex" px="2px">
                      <IconoRespuesta color="#fff" />
                      <Text color="#fff" ml="3px">
                        {lang[currentLang].answer}
                      </Text>
                    </Box>
                  </Link>
                </Box>
              </li>
              */}
              <li
                css={css`
                  align-self: center;
                `}
                className="nav-item"
              >
                <Box
                  borderBottom={
                    history.location.pathname === "/categorias"
                      ? "2px solid white"
                      : ""
                  }
                >
                  <Link className="nav-link" to="/categorias">
                    <Box display="flex" px="2px">
                      <IconoCategoria color="#fff" />
                      <Text color="#fff" ml="3px">
                        {lang[currentLang].categories}
                      </Text>
                    </Box>
                  </Link>
                </Box>
              </li>
              <li
                css={css`
                  align-self: center;
                `}
                className="nav-item"
              >
                <Box
                  onClick={(e) => setUnreadNoti(false)}
                  borderRadius={unreadNoti ? "20px" : ""}
                  bg={unreadNoti ? "orange.2" : ""}
                  borderBottom={
                    history.location.pathname === "/notificaciones"
                      ? "2px solid white"
                      : ""
                  }
                >
                  <Link className="nav-link" to="/notificaciones">
                    <Box display="flex" px="2px">
                      <IconoNotificacion color="#fff" />
                      <Text color="#fff" ml="3px">
                        {lang[currentLang].notifications}
                      </Text>
                    </Box>
                  </Link>
                </Box>
              </li>
              <li
                css={css`
                  align-self: center;
                `}
                className="nav-item"
              >
                <Box
                  onClick={(e) => setUnreadNoti(false)}
                  borderRadius={unreadNoti ? "20px" : ""}
                  bg={unreadNoti ? "orange.2" : ""}
                  borderBottom={
                    history.location.pathname === "/eventos"
                      ? "2px solid white"
                      : ""
                  }
                >
                  <Link className="nav-link" to="/eventos">
                    <Box display="flex" px="2px">
                      <IconoEventos color="#fff" />
                      <Text color="#fff" ml="3px">
                        {lang[currentLang].events}
                      </Text>
                    </Box>
                  </Link>
                </Box>
              </li>
              
              <li
                css={css`
                  align-self: center;
                `}
                className="nav-item"
              >
                <Box
                  onClick={(e) => setUnreadNoti(false)}
                  borderRadius={unreadNoti ? "20px" : ""}
                  bg={unreadNoti ? "orange.2" : ""}
                  borderBottom={
                    history.location.pathname === "/recursos"
                      ? "2px solid white"
                      : ""
                  }
                >
                  <Link className="nav-link" to="/recursos">
                    <Box display="flex" px="2px">
                      <IconoRecursos color="#fff" />
                      <Text color="#fff" ml="3px">
                        {lang[currentLang].resources}
                      </Text>
                    </Box>
                  </Link>
                </Box>
              </li>
              
              
              <li
                css={css`
                  align-self: center;
                `}
                className="nav-item"
              >
                <Box
                  borderBottom={
                    history.location.pathname === "/preguntar"
                      ? "2px solid white"
                      : ""
                  }
                >
                  <Link className="nav-link" to="/preguntar">
                    <Button
                      width="100px"
                      borderRadius="20px"
                      ml="2px"
                      variant="outline"
                      outline="none"
                      color="white"
                      alignSelf="center"
                      _focus={{ outline: "none" }}
                      fontSize="12px"
                    >
                      <Box pr="3px">
                        <IconoPreguntar bg="#fff" color="#FE6E60" />
                      </Box>
                      {lang[currentLang].ask}
                    </Button>
                  </Link>
                </Box>
              </li>
            </ul>
            {/*SEARCH BAR BELOW */}
            {pathname !== "/preguntas" && (
              <div className="search-form">
                <nav>
                  <Form
                    className="form-inline my-2 my-lg-0"
                    initialValues={{ query: "" }}
                    onSubmit={submitSearch}
                  >
                    {({ setFieldValue, values }) => (
                      <Input
                        className="form-control mr-sm-2"
                        borderRadius="20px"
                        backgroundColor="white"
                        maxWidth="600px"
                        type="search"
                        aria-label="Search"
                        placeholder={lang[currentLang].searchPlaceholder}
                        id="searchInput"
                        value={values.query}
                        onChange={(e) => setFieldValue("query", e.target.value)}
                      />
                    )}
                  </Form>
                </nav>
                {redirect && (
                  <React.Fragment>
                    <Redirect
                      to={{
                        pathname: "/preguntas",
                        search: "?q=" + search,
                      }}
                    />
                    {setRedirect(false)}
                  </React.Fragment>
                )}
              </div>
            )}
          </div>

          {userLink()}
          <Box
            ml="10px"
            onClick={(e) => {
              history.push("/chat");
              // countmess > 0 && handleChatDelete();
            }}
          >
            <Button
              width="40px"
              height="40px"
              onClick={(e) => setUnreadChat(false)}
              border={unreadChat ? "" : "1px solid white"}
              bg={unreadChat ? "orange.2" : ""}
              borderRadius="20px"
              outline="none!important"
              shadow="none!important"
              colorScheme="transparent"
            >
              <Box color="white" fontSize="20px">
                <TiMessages />
                {countmess?.length > 0
                  ? !chatIcon && (
                      <span
                        className="position-absolute top-0 start-100 p-2 bg-danger border border-light rounded-circle"
                        style={{
                          left: "28px",
                          bottom: "25px",
                          height: "20px",
                          width: "20px",
                          fontSize: "11px",
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {countmess?.length}
                      </span>
                    )
                  : null}
              </Box>
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default withRouter(Navbar);
